import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { PanelType } from '../../model/panel/index.tsx';
import type { State } from '../../state/types.tsx';
import { getPanelType } from '../../state/ui/panel/selectors.tsx';
import TableOverlay from './view.tsx';

type StateProps = {
	panelType: PanelType;
};

export default connect(
	(state: State): StateProps => ({
		panelType: getPanelType(state),
	}),
	{},
)(TableOverlay);
