// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { isBaseLevel } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import { DEPENDENCIES_FLYOUT_TYPE } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/overlay.tsx';
import type { Position } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/common.tsx';
import { getIssueLevel } from '../../../../../../state/entities/issues/selectors.tsx';
import { getImplicitDependencyItemsForIssue } from '../../../../../../state/selectors/dependencies/index.tsx';
import {
	getIssueDependencies,
	getIssueDependees,
} from '../../../../../../state/selectors/issues/index.tsx';
import type { State } from '../../../../../../state/types.tsx';
import { showToolTip } from '../../../../../../state/ui/table/actions.tsx';
import BarIcon from './view.tsx';

type OwnProps = {
	id: string;
	isColorInverted?: boolean;
};

const EMPTY_ARRAY: Array<IssueId> = [];

type DispatchProps = {
	onClickIcon: (fromIds: IssueId[], toIds: IssueId[], position: Position) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any, { id }: { id: string }): DispatchProps => ({
	onClickIcon: (
		fromIds: IssueId[],
		toIds: IssueId[],
		position: Position,
		triggerRef?: React.RefObject<HTMLElement>,
	) => {
		dispatch(
			showToolTip({
				id,
				type: DEPENDENCIES_FLYOUT_TYPE,
				links: { fromIds, toIds },
				position,
				showImplicitDependency: true,
				...(fg('jsw_roadmaps_timeline-dependency-flyout-refocus') ? { triggerRef } : {}),
			}),
		);
	},
});

type StateProps = {
	hasImplicitDependency: boolean;
	level: number;
	dependencies: IssueId[];
	dependees: IssueId[];
};

const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
	ownProps: OwnProps,
): JSX.LibraryManagedAttributes<typeof BarIcon, ComponentProps<typeof BarIcon>> => ({
	...stateProps,
	...dispatchProps,
	...(fg('jsw_roadmaps_timeline-post-project-a11y-fix') ? ownProps : {}),
});

export default connect(
	(state: State, { id }: OwnProps): StateProps => {
		const issueId: IssueId = id;
		const level = getIssueLevel(state, issueId);
		const implicitDependencyItems = !isBaseLevel(level)
			? getImplicitDependencyItemsForIssue(state, issueId) || EMPTY_ARRAY
			: EMPTY_ARRAY;

		return {
			dependencies: getIssueDependencies(state, issueId) || EMPTY_ARRAY,
			dependees: getIssueDependees(state, issueId) || EMPTY_ARRAY,
			level,
			hasImplicitDependency: implicitDependencyItems.length > 0,
		};
	},
	mapDispatchToProps,
	mergeProps,
)(BarIcon);
