import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

export const getContainerHeight = ({
	itemHeight,
	depth,
}: {
	itemHeight: number;
	depth: number;
}) => {
	const height =
		// eslint-disable-next-line no-nested-ternary
		depth > 0
			? isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2')
				? token('space.500', '40px')
				: token('space.400', '32px')
			: `${itemHeight}px`;

	return height;
};
