import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import type { MeatballsButtonProps } from './types.tsx';
import MeatBallsButton from './view.tsx';

const ListItemMeatBallsButton = (props: MeatballsButtonProps) => (
	<JSErrorBoundary
		id="timeline-table-list-item-meatballs-button"
		packageName="timelineTable"
		fallback="flag"
	>
		<MeatBallsButton {...props} />
	</JSErrorBoundary>
);

export default ListItemMeatBallsButton;
