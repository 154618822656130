import { useRef, useCallback } from 'react';
import type { ItemId } from '../../../../types/item.tsx';
import { useFocusMarshal } from '../index.tsx';
import type { CreateTriggerType } from '../types.tsx';

export const useCreateTrigger = (rowId: ItemId, type: CreateTriggerType) => {
	const element = useRef<HTMLButtonElement | null>(null);
	const { setActiveCreateTrigger } = useFocusMarshal();

	const setCreateTriggerRef = useCallback((ref: HTMLButtonElement | null) => {
		element.current = ref;
	}, []);

	const onCreateTriggerClick = useCallback(() => {
		if (element.current) {
			setActiveCreateTrigger(rowId, type, element.current);
		}
	}, [rowId, setActiveCreateTrigger, type]);

	return { createTriggerRef: setCreateTriggerRef, onCreateTriggerClick };
};
