import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getIssueKeyById } from '../../state/entities/issues/selectors.tsx';
import type { State } from '../../state/types.tsx';
import { clearLevelOneSetting } from '../../state/ui/views/actions.tsx';
import ItemDisappearsFromDisplayRangeFlag from './view.tsx';

type OwnProps = {
	issueId: IssueId;
	id: string | number;
	onDismissed: (id: number | string) => void;
};

export default connect(
	(state: State, ownProps: OwnProps) => ({
		issueKey: getIssueKeyById(state, ownProps.issueId),
	}),
	{
		onClearDisplayOption: clearLevelOneSetting,
	},
	(stateProps, dispatchProps, ownProps) => {
		const { issueKey } = stateProps;
		const { id, onDismissed } = ownProps;
		return {
			...dispatchProps,
			issueKey,
			id,
			onDismissed,
		};
	},
)(ItemDisappearsFromDisplayRangeFlag);
