import { combineEpics } from 'redux-observable';
import 'rxjs/add/observable/of';
import type { StateEpic } from '../common/types.tsx';
import initializeCriticalData from './critical-data.tsx';
import initializeDeriveFields from './derive-fields/index.tsx';
import initializeNonCriticalData from './non-critical-data/index.tsx';
import updateData from './update-data.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default combineEpics(
	initializeCriticalData,
	initializeNonCriticalData,
	initializeDeriveFields,
	updateData,
) as StateEpic;
