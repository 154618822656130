import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useThemeObserver } from '@atlaskit/tokens';
import { fireTrackAnalyticsDeferred, MountEvent } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { ViewAnalyticData } from '../../state/selectors/analytics/index.tsx';

export type Props = {
	attributes: ViewAnalyticData;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ attributes }: Props) => {
	const { colorMode } = useThemeObserver();
	const { isSiteAdmin } = useTenantContext();

	const fireRoadmapViewedAnalytic = (analyticsEvent: UIAnalyticsEvent) =>
		fireTrackAnalyticsDeferred(analyticsEvent, 'roadmap viewed', {
			...attributes,
			theme: colorMode,
			isSiteAdmin,
		});

	return <MountEvent onMount={fireRoadmapViewedAnalytic} />;
};
