import { createSelector } from 'reselect';
import { getExpandedItems, getCreateItemAnchor } from '../../ui/table/selectors.tsx';
import { getFilteredNonBaseLevelIssueIds, getFilteredChildIssueIdsHash } from '../issues/index.tsx';
import { getItemOffsetPositions } from '../items.tsx';
import { getTableHeaderItemsHeight, getItemHeight } from './index.tsx';

export const getChartItemTopPositionHash = createSelector(
	getTableHeaderItemsHeight,
	getItemHeight,
	getFilteredNonBaseLevelIssueIds,
	getFilteredChildIssueIdsHash,
	getExpandedItems,
	getCreateItemAnchor,
	getItemOffsetPositions,
);
