import 'rxjs/add/observable/zip';
import 'rxjs/add/observable/of';
import flattenDeep from 'lodash/flattenDeep';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import { Observable } from 'rxjs/Observable';
import type {
	GenericAction,
	Engine,
	HydrationActionCreator,
	StateConnector,
	StorageConfiguration,
	StorageKey,
	GenericStorageValue,
	Transformer,
	SingleHydrationActionCreator,
	SingleStateConnector,
} from './types.tsx';

export const defineStorage = <
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	State extends Record<any, any>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Input extends Record<any, any>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Output extends Record<any, any>,
>(
	requestedStorageKeys: (state: State) => StorageKey[],
	hydrationActionCreator: HydrationActionCreator,
	stateConnector: StateConnector<State, Input>,
	transformer: Transformer<Input, Output>,
): StorageConfiguration<State> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let memoizedProps: Record<any, any>;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isModified = (newProps: Record<any, any>): boolean =>
		some(
			newProps,
			(val: unknown, key: string) =>
				!memoizedProps || !memoizedProps[key] || !isEqual(memoizedProps[key], val),
		);

	return {
		hydrate: (state: State, engine: Engine): Observable<GenericAction[]> => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const params: Observable<any>[] = [];
			forEach(requestedStorageKeys(state), (storageKey) => {
				params.push(engine.get(storageKey));
			});

			if (params.length === 0) {
				return Observable.of([]);
			}

			return Observable.zip(...params, (args) => {
				const { value: currentValue } = stateConnector(state);
				if (args !== null && !isEqual(args, currentValue)) {
					return flattenDeep([hydrationActionCreator(args)]);
				}
				return [];
			});
		},
		modify: (state: State, engine: Engine): void => {
			const props = stateConnector(state);

			if (!engine.allowsModificationWithoutHydration && memoizedProps === undefined) {
				// in hydration
				memoizedProps = props;
			} else if (isModified(props)) {
				const transformed = transformer(props);
				forEach(transformed, (prop, key) => engine.set(key, prop));

				memoizedProps = props;
			}
		},
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defineSimpleStorage = <TState extends Record<any, any>, T extends GenericStorageValue>(
	key: () => StorageKey,
	hydrationActionCreator: SingleHydrationActionCreator<T>,
	stateConnector: SingleStateConnector<TState, T>,
): StorageConfiguration<TState> =>
	defineStorage(
		() => [key()],
		hydrationActionCreator,
		(state) => ({ value: stateConnector(state) }),
		(t) => ({ [key()]: t.value }),
	);

export const combineStorage = <State,>(
	...storageConfigs: StorageConfiguration<State>[]
): StorageConfiguration<State> => ({
	hydrate: (state: State, engine: Engine): Observable<GenericAction[]> => {
		const hydrations: Observable<GenericAction[]>[] = [];
		forEach(storageConfigs, (def: StorageConfiguration<State>) => {
			hydrations.push(def.hydrate(state, engine));
		});

		const flat = flattenDeep(hydrations);

		if (flat.length === 0) {
			return Observable.of([]);
		}
		return Observable.zip(...flat, (...hydrationActions) => flattenDeep(hydrationActions));
	},
	modify: (state: State, engine: Engine): void => {
		forEach(storageConfigs, (def: StorageConfiguration<State>) => {
			def.modify(state, engine);
		});
	},
});
