import React from 'react';
import {
	RELEASES_HEADER_ID,
	SPRINTS_HEADER_ID,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/index.tsx';
import type { HeaderItem } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/header.tsx';
import ReleasesHeader from './releases/index.tsx';
import SprintsHeader from './sprints/index.tsx';

export const renderChartHeaderItem = ({ id }: HeaderItem) => {
	switch (id) {
		case RELEASES_HEADER_ID:
			return <ReleasesHeader />;

		case SPRINTS_HEADER_ID:
			return <SprintsHeader />;

		default:
			return null;
	}
};
