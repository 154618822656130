import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import isNil from 'lodash/isNil';
import {
	UNASSIGNED_USER_ID,
	UNASSIGNED_VERSION_ID,
	UNASSIGNED_LABEL_ID,
	UNASSIGNED_COMPONENT_ID,
} from '@atlassian/jira-software-filters/src/common/constants.tsx';
import {
	getRequiredFieldsByIssueTypeId,
	getFullDerivedFields,
	getSprintCustomFieldId,
} from '../../../state/configuration/selectors.tsx';
import { type CreateIssueAction, CREATE_ISSUE } from '../../../state/entities/issues/actions.tsx';
import { getIssuesFilter } from '../../../state/selectors/filters/index.tsx';
import type { State } from '../../../state/types.tsx';
import { getCreateItemAnchor } from '../../../state/ui/table/selectors.tsx';
import type { StateEpic } from '../../common/types.tsx';
import inlineCreate from './inline-create.tsx';
import { derivedFieldsToIssueContext, mergeIssueContexts } from './issue-field-builder/index.tsx';
import requiredFieldsCreate from './required-fields-create.tsx';

// code-reaper-ignore -- Exported for testing only.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFieldDefined = (field: any): boolean => {
	if (!isNil(field)) {
		if (Array.isArray(field) && field.length > 0) {
			return true;
		}
		const fieldType = typeof field;
		if (fieldType === 'boolean' || fieldType === 'number') {
			return true;
		}
		if (fieldType === 'string') {
			return field.length > 0;
		}
		if (typeof field === 'object') {
			return isFieldDefined(field.value);
		}
	}
	// unknown field type
	return false;
};

/* This is the entry point for creating an issue on the server. The bulk of the work is
 * is delegated to the relevant utilities depending on whether an issue has required fields.
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<CreateIssueAction>, store: MiddlewareAPI<State>) =>
	action$.ofType(CREATE_ISSUE).mergeMap((action: CreateIssueAction) => {
		const state = store.getState();
		const createItemAnchor = getCreateItemAnchor(state);

		if (createItemAnchor === undefined) {
			throw new Error('Cannot create an issue without an anchor (anchor was undefined)');
		}

		const { summary, issueTypeId } = action.payload;
		const parentId =
			'parentId' in createItemAnchor && createItemAnchor.parentId
				? createItemAnchor.parentId
				: undefined;
		const { assignees, labels, versions, components } = getIssuesFilter(state);
		const filteredAssignees = assignees.filter((assignee) => assignee !== UNASSIGNED_USER_ID);
		const assignee = filteredAssignees[filteredAssignees.length - 1]; // retrieve last selected assignee
		const filteredVersions = versions.filter((version) => version !== UNASSIGNED_VERSION_ID);
		const filteredLabels = labels.filter((label) => label !== UNASSIGNED_LABEL_ID);
		const filteredComponents = components.filter(
			(component) => component !== UNASSIGNED_COMPONENT_ID,
		);
		const requiredFields = getRequiredFieldsByIssueTypeId(state, issueTypeId);
		const derivedFieldsHash = getFullDerivedFields(state);
		const sprintCFId = getSprintCustomFieldId(state);
		const optimisticIssue = mergeIssueContexts(
			derivedFieldsToIssueContext(derivedFieldsHash, sprintCFId),
			{
				summary: { value: summary },
				assignee,
				issueTypeId,
				parentId: { value: parentId },
				labels: filteredLabels,
				versionIds: filteredVersions,
				componentIds: filteredComponents,
			},
		);
		if (
			requiredFields.some(
				(requiredField) =>
					// @ts-expect-error - We are handling any type
					!isFieldDefined(optimisticIssue[requiredField]) &&
					!isFieldDefined(derivedFieldsHash[requiredField]),
			)
		) {
			return requiredFieldsCreate(store, action, optimisticIssue);
		}

		return inlineCreate(store, action, optimisticIssue, createItemAnchor);
	})) as StateEpic;
