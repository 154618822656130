import type { Status } from '@atlassian/jira-software-roadmap-model/src/status/index.tsx';
import type { GraphQLStatus } from '../issues/types.tsx';

export const transformStatus = (status: GraphQLStatus | null | undefined): Status | undefined => {
	if (status != null) {
		return {
			id: status.id,
			// @ts-expect-error(Suppressed for relay bump) - Type 'string | null | undefined' is not assignable to type 'string'.
			name: status.name,
			// @ts-expect-error(Suppressed for relay bump) - 'status.statusCategory' is possibly 'null' or 'undefined'.
			statusCategoryId: status.statusCategory.id,
		};
	}

	return undefined;
};
