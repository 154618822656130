import { combineEpics } from 'redux-observable';
import type { StateEpic } from '../common/types.tsx';
import clearFilter from './clear-filter.tsx';
import clear from './clear.tsx';
import customFiltersRequest from './custom-filters-request.tsx';
import jqlFiltersReapply from './jql-filters-reapply.tsx';
import quickFiltersRequest from './quick-filters-request.tsx';
import setAssignee from './set-assignee.tsx';
import setComponent from './set-component.tsx';
import setCustomFilter from './set-custom-filter.tsx';
import setDependenciesView from './set-dependencies-view.tsx';
import setGoal from './set-goal.tsx';
import setIssueParent from './set-issue-parent.tsx';
import setIssueType from './set-issue-type.tsx';
import setLabel from './set-label.tsx';
import setLevelOneSettingView from './set-level-one-setting-view.tsx';
import setProgressView from './set-progress-view.tsx';
import setQuickFilter from './set-quick-filter.tsx';
import setReleasesView from './set-releases-view.tsx';
import setShared from './set-shared.tsx';
import setStatusCategory from './set-status-category.tsx';
import setText from './set-text.tsx';
import setTimelineMode from './set-timeline-mode.tsx';
import setVersion from './set-version.tsx';
import setWarningsView from './set-warnings-view.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default combineEpics(
	clear,
	clearFilter,
	setShared,
	setStatusCategory,
	setIssueType,
	setIssueParent,
	setTimelineMode,
	setDependenciesView,
	setProgressView,
	setWarningsView,
	setReleasesView,
	setLevelOneSettingView,
	setText,
	setLabel,
	setAssignee,
	setVersion,
	setComponent,
	setQuickFilter,
	quickFiltersRequest,
	jqlFiltersReapply,
	setCustomFilter,
	customFiltersRequest,
	setGoal,
) as StateEpic;
