import React from 'react';
import flow from 'lodash/flow';
import memoizeOne from 'memoize-one';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { initializeNonCriticalData } from '../state/app/actions.tsx';
import { getBoardId, getMacroHideHeader, isClassic } from '../state/app/selectors.tsx';
import {
	getProjectId,
	getProjectKey,
	getIsSprintsFeatureEnabled,
	getChildIssuePlanningMode,
} from '../state/configuration/selectors.tsx';
import { getIssueIds } from '../state/entities/issues/selectors.tsx';
import { isShared } from '../state/router/selectors.tsx';
import type { State } from '../state/types.tsx';
import { getHasHealthcheck } from '../state/ui/healthcheck/selectors.tsx';
import { setShared } from '../state/ui/share/actions.tsx';
import DocumentTitle from './document-title/index.tsx';
import { withViewExperienceTracker, type Props } from './experience-tracker/index.tsx';
import Header from './header/index.tsx';
import Healthcheck from './healthcheck/index.tsx';
import MountAnalytic from './mount-analytic/index.tsx';
import RealtimeProvider from './realtime-provider/index.tsx';
import RouterUpdater from './router-updater/index.tsx';
import Table from './table/index.tsx';
import Standard, { type Props as OwnProps } from './view.tsx';

const mapStateToProps = (state: State, { isMacroView }: { isMacroView: boolean }) => {
	const projectId = getProjectId(state) || '';
	const boardId = getBoardId(state);
	return {
		boardId: getBoardId(state),
		projectKey: getProjectKey(state),
		isHealthy: !getHasHealthcheck(state),
		isShared: isShared(state),
		isClassic: isClassic(state),
		issueIds: getIssueIds(state),
		Header,
		Table,
		Healthcheck,
		DocumentTitle,
		RealtimeProvider,
		RouterUpdater,
		MountAnalytic,
		isHeaderHidden: getMacroHideHeader(state),
		shouldMountKeyboardShortcuts: !isMacroView,
		objectId: String(boardId),
		projectId,
		boardType: getIsSprintsFeatureEnabled(state) ? 'Scrum' : 'Kanban',
		childIssuePlanningMode: getChildIssuePlanningMode(state),
	};
};
const mapDispatchToProps = {
	onSetShared: setShared,
	initializeNonCriticalData,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const onLoadedFactory = memoizeOne(
	(
		dispatchPropsInitializeNonCriticalData: DispatchProps['initializeNonCriticalData'],
		statePropsIsShared: StateProps['isShared'],
		onSetShared: DispatchProps['onSetShared'],
	) =>
		() => {
			dispatchPropsInitializeNonCriticalData();

			if (statePropsIsShared) {
				onSetShared(false);
			}
		},
);
const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => ({
	...stateProps,
	...ownProps,
	onLoaded: onLoadedFactory(
		dispatchProps.initializeNonCriticalData,
		stateProps.isShared,
		dispatchProps.onSetShared,
	),
});

const View = withViewExperienceTracker(
	flow([connect(mapStateToProps, mapDispatchToProps, mergeProps)])(Standard),
);

const StandardMacro = (props: Props) => (
	<ContextualAnalyticsData sourceName="roadmapMacro" sourceType={SCREEN}>
		<View {...props} />
	</ContextualAnalyticsData>
);
const ClassicStandardMacro = (props: Props) => (
	<ContextualAnalyticsData sourceName="classicRoadmapMacro" sourceType={SCREEN}>
		<View {...props} />
	</ContextualAnalyticsData>
);
const StandardView = (props: Props) => (
	<ContextualAnalyticsData sourceName="roadmap" sourceType={SCREEN}>
		<View {...props} />
	</ContextualAnalyticsData>
);
const ClassicStandardView = (props: Props) => (
	<ContextualAnalyticsData sourceName="classicRoadmap" sourceType={SCREEN}>
		<View {...props} />
	</ContextualAnalyticsData>
);

export { StandardMacro, ClassicStandardMacro };
export { StandardView, ClassicStandardView };
