import { filterItemsByJQLFilters } from '../../issues/filter.tsx';
import type { EagerLoadingDataFetcher } from './types.tsx';

export const filterItemsByJQLFetcher: EagerLoadingDataFetcher = {
	match: ({ query: { quickFilter, customFilter } }) =>
		quickFilter !== undefined || customFilter !== undefined,
	fetch: (sourceAri, { query: { quickFilter, customFilter } }) => {
		const quickFilterIds = quickFilter ? quickFilter.split(',') : [];
		const customFilterIds = customFilter ? customFilter.split(',') : [];
		return filterItemsByJQLFilters(sourceAri, quickFilterIds, customFilterIds).map(
			(filteredIssueIds) => ({ filteredIssueIds }),
		);
	},
};
