import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/concat';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import setup from '@atlassian/jira-software-roadmap-services/src/healthcheck/setup.tsx';
import { getCriticalDataObservable } from '@atlassian/jira-software-roadmap-services/src/initialize/critical-data.tsx';
import type { CriticalData } from '@atlassian/jira-software-roadmap-services/src/initialize/types.tsx';
import { getSourceARI } from '../../state/app/selectors.tsx';
import { getProjectId } from '../../state/configuration/selectors.tsx';
import { aggError } from '../../state/flags/actions.tsx';
import { getQuery } from '../../state/router/selectors.tsx';
import type { State } from '../../state/types.tsx';
import { RESOLVE, type ResolveAction, setResolving } from '../../state/ui/healthcheck/actions.tsx';
import { getCriticalDataActions } from '../common/initialize/utils.tsx';
import type { StateEpic } from '../common/types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<ResolveAction>, store: MiddlewareAPI<State>) =>
	action$.ofType(RESOLVE).mergeMap((action: ResolveAction) =>
		Observable.concat(
			Observable.of(setResolving(true)),
			setup(
				getProjectId(store.getState()),
				action.payload.epicName,
				action.payload.epicDescription,
				action.payload.issueTypeToPromote,
			).mergeMap(() => {
				const state = store.getState();
				const query = getQuery(state);

				return getCriticalDataObservable(getSourceARI(state), query, undefined)
					.mergeMap((criticalData: CriticalData) =>
						Observable.of(
							batchActions([...getCriticalDataActions(criticalData), setResolving(false)]),
						),
					)
					.catch(() => Observable.of(batchActions([aggError()])));
			}),
		),
	)) as StateEpic;
