import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getIssueLevel } from '../../../../../state/entities/issues/selectors.tsx';
import type { State } from '../../../../../state/types.tsx';
import type { OwnProps, StateProps } from './types.tsx';
import ReparentMenuOption from './view.tsx';

const mapStateToProps = (state: State, { id }: OwnProps): StateProps => ({
	issueLevel: getIssueLevel(state, id),
});

export default connect(mapStateToProps)(ReparentMenuOption);
