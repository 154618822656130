import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import uuid from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { updateRoadmapItemMutation$data } from '@atlassian/jira-relay/src/__generated__/updateRoadmapItemMutation.graphql';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	BASE_LEVEL,
	PARENT_LEVEL,
	isBaseLevel,
} from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import { SPRINT_IDS } from '@atlassian/jira-software-roadmap-model/src/issue-view/index.tsx';
import type {
	Issue,
	IssueScheduleFields,
} from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import { ACTIVE } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import { updateItemGraphqlOnServer } from '@atlassian/jira-software-roadmap-services/src/issues/update-roadmap-item.tsx';
import { INTERACTED_ITEMS_LIMIT } from '../../constants.tsx';
import type { IssueHash, IssueFieldModification } from '../../model/issue/index.tsx';
import { ISSUE_DETAILS } from '../../model/panel/index.tsx';
import { getSourceARI } from '../../state/app/selectors.tsx';
import {
	getIsSprintsPlanningEnabled,
	getIsChildIssuePlanningEnabled,
	getStartDateCustomFieldId,
	getEpicIssueTypeIds,
	getProjectId,
} from '../../state/configuration/selectors.tsx';
import {
	type UpdateIssueAction,
	UPDATE_ISSUE,
	extendIssues,
	scheduleIssues,
	clearTransitionState,
	resetTransitionState,
} from '../../state/entities/issues/actions.tsx';
import {
	getIssueKeyById,
	getSafeId,
	getIssue,
	getIssueTypeIdForIssueId,
	getIssueStartDate,
	getIssueDueDate,
	getIssueChildrenHash,
} from '../../state/entities/issues/selectors.tsx';
import {
	issueDisappearsFromDisplayRange,
	sprintScopeChanged,
	type SprintScopeChangeAction,
	aggError,
	updateItemPermission,
	interactedItemsLimitExceeded,
} from '../../state/flags/actions.tsx';
import { ASSIGN, UNASSIGN } from '../../state/flags/types.tsx';
import {
	createGetIntersectingSprintId,
	getSprintState,
} from '../../state/selectors/sprint/index.tsx';
import type { State } from '../../state/types.tsx';
import { reapplyJQLFilters } from '../../state/ui/filters/actions.tsx';
import { getPanelType } from '../../state/ui/panel/selectors.tsx';
import { getSelectedItemIds } from '../../state/ui/table/selectors.tsx';
import { isPermissionError } from '../common/get-error.tsx';
import { onHandleAggErrors } from '../common/handle-agg-errors.tsx';
import { getResetActions } from '../common/selected-issues.tsx';
import type { StateEpic, GenericAction } from '../common/types.tsx';
import { fireInteractedItemsLimitExceededAnalytics } from './common/analytics.tsx';
import { formatDate } from './common/dates.tsx';
import {
	getIsIssueHiddenByFiltersOrSettings,
	type UpdateAnalyticsMetadata,
	getFieldModForIssueUpdate,
} from './common/index.tsx';
import {
	shouldScheduleChildrenIssues,
	shouldBulkSchedule,
	getScheduleFieldsForBulkDatesUpdate,
} from './common/schedule.tsx';
import { createTransitionIssueWithUpdatedItems } from './common/update.tsx';
import refreshIssuePage from './refresh-issue-page.tsx';

export const persistUpdate = (
	isHiddenBySetting: boolean,
	issueIdObservable: Observable<IssueId>,
	issue: Issue,
	transitionId: string,
	startDateCustomFieldId: string,
	analyticsMetadata: UpdateAnalyticsMetadata,
	sourceARI: Ari,
	unsafeIssueId: IssueId,
): Observable<GenericAction[]> =>
	issueIdObservable.mergeMap((issueId) => {
		const fieldMod = getFieldModForIssueUpdate(issue);

		return updateItemGraphqlOnServer({
			sourceARI,
			input: {
				itemId: issueId,
				projectId: analyticsMetadata.projectId,
				summary: fieldMod?.summary,
				dueDate: formatDate(fieldMod?.dueDate),
				startDate: formatDate(fieldMod?.startDate),
				color: fieldMod?.color,
				parentId: fieldMod?.parentId,
				sprintId: fieldMod?.sprintId,
				clearFields: fieldMod?.clearFields,
			},
		}).mergeMap((data?: updateRoadmapItemMutation$data) => {
			const updateRoadmapItemData = data?.roadmaps?.updateRoadmapItem;
			const errors = updateRoadmapItemData?.errors;
			const success = updateRoadmapItemData?.success;

			if (success === false && errors?.length) {
				if (isPermissionError(errors)) {
					// Display Schedule issue permission error
					return Observable.of(updateItemPermission()).map((permissionErrorAction) => [
						permissionErrorAction,
						resetTransitionState({ issueId: unsafeIssueId, transitionId }),
					]);
				}
				return onHandleAggErrors(!!updateRoadmapItemData, errors).map((aggErrorAction) => [
					aggErrorAction,
					resetTransitionState({ issueId: unsafeIssueId, transitionId }),
				]);
			}

			const clonedEvent = analyticsMetadata.event.clone();

			if (clonedEvent) {
				const { level, updatedItems, issueTypeId, hasOverdueReleaseDate } =
					analyticsMetadata.attributes;

				fireTrackAnalytics(clonedEvent, 'issue updated', issueId, {
					level,
					updatedItems,
					issueTypeId,
					projectType: 'software',
					hasOverdueReleaseDate,
				});
			}

			const actions: GenericAction[] = [
				clearTransitionState({ issueId, transitionId }, UPDATE_ISSUE),
			];

			if (!fg('jsw-roadmap-state-change-based-issue-hidden-flags') && isHiddenBySetting)
				actions.push(issueDisappearsFromDisplayRange({ issueId }));

			return Observable.of(actions);
		});
	});

export const generateScopeChangeAction = (
	bypassSprintScopeCheck: boolean,
	modifiedProperties: IssueFieldModification,
	analyticsEvent: UIAnalyticsEvent,
	issueId: IssueId,
	issue: Issue | undefined,
	state: State,
	transitionId: string,
	hasOverdueReleaseDate?: boolean,
): SprintScopeChangeAction | undefined => {
	if (
		(!bypassSprintScopeCheck && modifiedProperties.sprintId !== undefined) ||
		modifiedProperties.clearFields?.includes(SPRINT_IDS)
	) {
		const sourceSprintId =
			issue !== undefined && issue.sprintIds.value !== undefined && issue.sprintIds.value.length > 0
				? issue.sprintIds.value[0]
				: undefined;
		const sprintStateSelector = getSprintState(state);

		const targetSprintState = sprintStateSelector(modifiedProperties.sprintId);
		const sourceSprintState = sprintStateSelector(sourceSprintId);

		if (targetSprintState === ACTIVE || sourceSprintState === ACTIVE) {
			return sprintScopeChanged({
				direction: targetSprintState === ACTIVE ? ASSIGN : UNASSIGN,
				issueId,
				targetSprintId: modifiedProperties.sprintId,
				sourceSprintId,
				properties: modifiedProperties,
				sourceEvent: analyticsEvent,
				transitionId,
				hasOverdueReleaseDate,
			});
		}
	}
	return undefined;
};

export const getIntersectingDate = (
	updatedStartDate: number,
	updatedDueDate: number,
	isDragged?: boolean,
): number =>
	isDragged === true
		? moment
				.utc((updatedStartDate + updatedDueDate) / 2)
				.add(12, 'hours')
				.startOf('day')
				.valueOf()
		: updatedStartDate;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<UpdateIssueAction>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(UPDATE_ISSUE)
		.mergeMap(
			({
				payload: { id: unsafeIssueId, properties },
				meta: { isDragged, hasOverdueReleaseDate, bypassSprintScopeCheck, analyticsEvent },
			}: UpdateIssueAction) => {
				const state = store.getState();
				const issue = getIssue(state, unsafeIssueId);

				if (issue === undefined) {
					return Observable.empty<never>();
				}

				let modifiedProperties = { ...properties };
				const sourceARI = getSourceARI(state);
				const isSprintsPlanning = getIsSprintsPlanningEnabled(state);
				const isChildIssuePlannedEnabled = getIsChildIssuePlanningEnabled(state);
				const issueKey = getIssueKeyById(state, unsafeIssueId);
				const projectId = getProjectId(state);
				const issueTypeId = getIssueTypeIdForIssueId(state, unsafeIssueId);
				const level = getEpicIssueTypeIds(state).includes(issueTypeId) ? PARENT_LEVEL : BASE_LEVEL;
				const isIssueBaseLevel = isBaseLevel(level);

				if (isIssueBaseLevel) {
					const updatedStartDate = properties.startDate;
					const updatedDueDate = properties.dueDate;
					const currentSprintIds = issue?.sprintIds?.value ?? [];

					if (isSprintsPlanning && modifiedProperties.sprintId === undefined) {
						const intersectingSprintId =
							updatedStartDate === undefined || updatedDueDate === undefined
								? undefined
								: createGetIntersectingSprintId(state)(
										getIntersectingDate(updatedStartDate, updatedDueDate, isDragged),
										currentSprintIds[currentSprintIds.length - 1],
									);
						const { startDate, dueDate, ...otherProperties } = modifiedProperties;

						if (intersectingSprintId === undefined) {
							modifiedProperties = otherProperties;
						} else {
							modifiedProperties = {
								...otherProperties,
								sprintId: intersectingSprintId,
							};
						}
					}

					if (Object.keys(modifiedProperties).length === 0) {
						return Observable.empty<never>();
					}
				}

				let scheduleFields: IssueScheduleFields[] = [];
				const transitionId = uuid.v4();
				const selectedItemIds = getSelectedItemIds(state);
				const prevStartDate = getIssueStartDate(state, unsafeIssueId);
				const prevDueDate = getIssueDueDate(state, unsafeIssueId);

				if (modifiedProperties.startDate === prevStartDate) delete modifiedProperties.startDate;

				if (modifiedProperties.dueDate === prevDueDate) delete modifiedProperties.dueDate;

				const childIds = getIssueChildrenHash(state)[unsafeIssueId] || [];
				const canBulkSchedule = shouldBulkSchedule(
					unsafeIssueId,
					modifiedProperties,
					{ startDate: prevStartDate, dueDate: prevDueDate },
					selectedItemIds,
				);
				const canScheduleChildren = shouldScheduleChildrenIssues(
					level,
					modifiedProperties,
					{ startDate: prevStartDate, dueDate: prevDueDate },
					childIds.length,
					isSprintsPlanning,
					isChildIssuePlannedEnabled,
				);

				const isIssueOutsideSelection =
					selectedItemIds.length > 0 && !selectedItemIds.includes(unsafeIssueId);
				const isInteractedItemsLimitExceeded =
					canScheduleChildren && childIds.length + 1 > INTERACTED_ITEMS_LIMIT;
				const isIssueDetailsShown = getPanelType(state) === ISSUE_DETAILS;
				const resetActions = getResetActions(
					isIssueOutsideSelection,
					isIssueOutsideSelection && isIssueDetailsShown,
				);
				const resetObservable = resetActions.length
					? Observable.of(resetActions.length > 1 ? batchActions(resetActions) : resetActions[0])
					: Observable.empty<never>();

				if (canBulkSchedule || canScheduleChildren) {
					const { startDate, dueDate } = modifiedProperties;

					if (isInteractedItemsLimitExceeded) {
						fireInteractedItemsLimitExceededAnalytics(analyticsEvent);
					} else {
						scheduleFields = getScheduleFieldsForBulkDatesUpdate(
							store,
							unsafeIssueId,
							{ startDate, dueDate },
							isIssueBaseLevel,
							selectedItemIds,
						);
					}
				}

				if (scheduleFields.length) {
					return Observable.concat(
						resetObservable,
						Observable.of(scheduleIssues(scheduleFields, analyticsEvent, hasOverdueReleaseDate)),
					);
				}

				// create new issue object
				const [transitionIssue, updatedItems] = createTransitionIssueWithUpdatedItems(
					issue,
					modifiedProperties,
					transitionId,
				);

				const transitionExtend = getSafeId(state, unsafeIssueId).mergeMap((issueId: IssueId) => {
					if (issueId === undefined) {
						return Observable.empty<never>();
					}
					const extendHash: IssueHash = {};
					extendHash[issueId] = transitionIssue;

					return Observable.of(
						extendIssues({
							sequence: [], // do not modify sequence
							hash: extendHash,
						}),
					);
				});

				const extendHash: IssueHash = {};
				extendHash[unsafeIssueId] = transitionIssue;

				const handlePersistUpdate = () => {
					const sprintScopeChangedAction: SprintScopeChangeAction | undefined =
						generateScopeChangeAction(
							bypassSprintScopeCheck,
							modifiedProperties,
							analyticsEvent,
							unsafeIssueId,
							issue,
							state,
							transitionId,
							hasOverdueReleaseDate,
						);

					if (sprintScopeChangedAction) {
						return Observable.of(sprintScopeChangedAction);
					}

					if (isInteractedItemsLimitExceeded) {
						return Observable.of(
							interactedItemsLimitExceeded({
								transitionId,
								issueId: unsafeIssueId,
								hasOverdueReleaseDate,
								properties: modifiedProperties,
								sourceEvent: analyticsEvent,
							}),
						);
					}

					const { isHiddenBySetting } = getIsIssueHiddenByFiltersOrSettings(
						state,
						transitionIssue,
						unsafeIssueId,
					);
					const analyticsMetadata = {
						event: analyticsEvent,
						projectId,
						attributes: {
							issueTypeId,
							updatedItems,
							level,
							hasOverdueReleaseDate,
						},
					};

					return persistUpdate(
						isHiddenBySetting,
						getSafeId(state, unsafeIssueId),
						transitionIssue,
						transitionId,
						getStartDateCustomFieldId(state),
						analyticsMetadata,
						sourceARI,
						unsafeIssueId,
					)
						.map((actions: GenericAction[]) => {
							refreshIssuePage(state, issueKey);

							return batchActions(actions);
						})
						.catch(() =>
							Observable.from([
								aggError(),
								resetTransitionState({
									issueId: unsafeIssueId,
									transitionId,
								}),
							]),
						);
				};

				return Observable.concat(
					resetObservable,
					// update `unsafe` issue first
					Observable.of(
						extendIssues(
							{
								sequence: [],
								hash: extendHash,
							},
							UPDATE_ISSUE,
						),
					),
					// schedule update of persisted issue
					transitionExtend,
					handlePersistUpdate(),
					fg('jsw-roadmap-state-change-based-issue-hidden-flags')
						? Observable.empty()
						: Observable.of(reapplyJQLFilters()),
				);
			},
		)) as StateEpic;
