/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { inMonthOrder, inMonthOrderOld } from '../messages.tsx';

const headerStyles = css({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.medium'),
	color: token('color.text.subtlest', N300),
	marginTop: 0,
});

const headerTextTransformStyles = css({
	textTransform: 'uppercase',
});

const contrastStyles = css({
	color: token('color.text.accent.gray.bolder'),
});

type Props = {
	monthNumber: number;
	// Set to required on FG cleanup jsw_roadmaps_timeline-gridcell-header-contrast
	isHighlighted?: boolean;
	yearCaption?: string | undefined | null;
};

const MonthCell = ({ monthNumber, yearCaption, isHighlighted }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<small
			css={[
				headerStyles,
				!isVisualRefreshEnabled() && headerTextTransformStyles,
				isHighlighted && fg('jsw_roadmaps_timeline-gridcell-header-contrast') && contrastStyles,
			]}
		>
			{formatMessage(
				isVisualRefreshEnabled() ? inMonthOrder[monthNumber] : inMonthOrderOld[monthNumber],
			)}{' '}
			{yearCaption}
		</small>
	);
};

MonthCell.defaultProps = {
	yearCaption: null,
};

export default MonthCell;
export type { Props as APIProps };
