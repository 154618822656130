import {
	SUMMARY_TYPE,
	START_DATE_TYPE,
	COLOR_TYPE,
	STATUS_TYPE,
	ASSIGNEE_TYPE,
	LABELS_TYPE,
	ISSUE_TYPE as ISSUE_TYPE_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

// In roadmaps the issue property name is different to what we receive from Bento, these
// fields are roadmap domain fields.
export const SUMMARY = SUMMARY_TYPE;
export const START_DATE = START_DATE_TYPE;
export const DUE_DATE = 'dueDate' as const;
export const COLOR = COLOR_TYPE;
export const STATUS = STATUS_TYPE;
export const ASSIGNEE = ASSIGNEE_TYPE;
export const LABELS = LABELS_TYPE;
export const PARENT_ID = 'parentId' as const;
export const SPRINT_IDS = 'sprintIds' as const;
export const VERSION_IDS = 'versionIds' as const;
export const COMPONENT_IDS = 'componentIds' as const;
export const ISSUE_TYPE = ISSUE_TYPE_TYPE;

export type FieldName =
	| typeof SUMMARY
	| typeof START_DATE
	| typeof DUE_DATE
	| typeof COLOR
	| typeof STATUS
	| typeof ASSIGNEE
	| typeof LABELS
	| typeof PARENT_ID
	| typeof SPRINT_IDS
	| typeof VERSION_IDS
	| typeof COMPONENT_IDS
	| typeof ISSUE_TYPE
	| string;

export type AssigneeFieldValueType = {
	accountId: string;
} | null;
export type VersionsFieldValueType =
	| {
			fromCache: boolean;
			id: number;
			name: string;
	  }[]
	| null;
export type ComponentsFieldValueType =
	| {
			fromCache: boolean;
			id: number;
			name: string;
	  }[]
	| null;
export type LabelsFieldValueType = string[] | null;
export type IssueTypeFieldValueType = string;
