// eslint-disable-next-line jira/restricted/react
import { PureComponent, useCallback, useEffect, useRef } from 'react';
import type { TimelineTableInteractionMetricType as TTMetricType } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/interaction-metrics.tsx';
import longTask from './long-task/index.tsx';

type Props<T> = HookProps<T> & {
	isDragging: boolean;
};

type HookProps<T> = {
	metricType: T;
	extraProps?: {
		[key: string]: boolean;
	};
};

const onDragStart = <T,>(metricType: T) => longTask.start(metricType);

const onDragEnd = <T,>(
	metricType: T,
	extraProps?: {
		[key: string]: boolean;
	},
) => longTask.stop(metricType, extraProps);

// eslint-disable-next-line jira/react/no-class-components
export class DragAndDropLongTaskMetric<T = TTMetricType> extends PureComponent<Props<T>> {
	static defaultProps = {
		extraProps: {},
	};

	componentDidUpdate(prevProps: Props<T>) {
		if (!prevProps.isDragging && this.props.isDragging) {
			onDragStart(this.props.metricType);
		} else if (prevProps.isDragging && !this.props.isDragging) {
			const extraProps = {
				...this.props.extraProps,
			};
			onDragEnd(this.props.metricType, extraProps);
		}
		// do nothing when isDragging did not change
	}

	componentWillUnmount() {
		/**
		 * Stop the metric when the component has been unmounted when it is still dragging.
		 * e.g. issue-deleted JIRT event or child reparenting
		 * Child issue will be unmounted when it is dragged-moved to another epic.
		 */
		if (this.props.isDragging) {
			const extraProps = {
				...this.props.extraProps,
			};
			onDragEnd(this.props.metricType, extraProps);
		}
	}

	render() {
		return null;
	}
}

export const useDragAndDropLongTaskMetric = <T,>({ metricType, extraProps }: HookProps<T>) => {
	const isDragging = useRef<boolean>(false);

	const start = () => {
		if (!isDragging.current) {
			onDragStart(metricType);
			isDragging.current = true;
		}
	};

	const stop = useCallback(() => {
		if (isDragging.current) {
			const extraData = {
				...extraProps,
			};
			onDragEnd(metricType, extraData);
			isDragging.current = false;
		}
	}, [metricType, extraProps]);

	useEffect(() => stop, [stop]);

	return { start, stop };
};

export default DragAndDropLongTaskMetric;
