import { combineReducers } from 'redux';
import globalIssueCreate from './global-issue-create/reducer.tsx';
import goals from './goals/reducer.tsx';
import issues from './issues/reducer.tsx';
import statusCategories from './status-categories/reducer.tsx';
import subtasks from './subtasks/reducer.tsx';
import users from './users/reducer.tsx';

export default combineReducers({
	issues,
	statusCategories,
	users,
	globalIssueCreate,
	subtasks,
	goals,
});
