import React, { memo } from 'react';
import { useListWidth } from '../../common/context/list/index.tsx';
import type { RenderChartOverlay } from '../../renderers/index.tsx';
import ChartOverlay from './chart-overlay/index.tsx';
import InlineIndicators from './inline-indicators/index.tsx';

type Props = {
	renderChartOverlay: RenderChartOverlay;
};

const StaticOverlay = ({ renderChartOverlay }: Props) => {
	const [listWidth] = useListWidth();

	return (
		<>
			<InlineIndicators />
			<ChartOverlay listWidth={listWidth} renderChartOverlay={renderChartOverlay} />
		</>
	);
};

StaticOverlay.displayName = 'StaticOverlay';
StaticOverlay.whyDidYouRender = true;

export default memo<Props>(StaticOverlay);
