import { RESOURCES_CACHE_UPDATER_SOURCE } from '@atlassian/jira-software-resource-invalidator/src/common/types.tsx';
import type { CriticalData } from '@atlassian/jira-software-roadmap-services/src/initialize/types.tsx';
import { setConfiguration } from '../../../state/configuration/actions.tsx';
import { setGoals } from '../../../state/entities/goals/actions.tsx';
import { updateIssuesHashAndSequence } from '../../../state/entities/issues/actions.tsx';
import { mergeStatusCategories } from '../../../state/entities/status-categories/actions.tsx';
import { addUsers } from '../../../state/entities/users/actions.tsx';
import { setMetadata } from '../../../state/metadata/actions.tsx';
import { updateResourceCache } from '../../../state/resource-cache/actions.tsx';
import { setSettings } from '../../../state/settings/actions.tsx';
import { jqlFiltersSuccess } from '../../../state/ui/filters/actions.tsx';
import { updateHealthcheck } from '../../../state/ui/healthcheck/actions.tsx';
import {
	setDependenciesViewVisibility,
	setLevelOneSetting,
	setProgressVisibility,
	setReleasesVisibility,
	setWarningsVisibility,
} from '../../../state/ui/views/actions.tsx';
import type { BasicInitAction } from './types.tsx';

export const getCriticalDataActions = (criticalData: CriticalData): Array<BasicInitAction> => {
	const {
		configuration,
		users,
		issues,
		statusCategories,
		settings,
		metadata,
		healthcheck,
		filteredIssueIds,
		eagerLoadedData,
		resourcesCacheSource,
	} = criticalData;

	const basicInitActions: BasicInitAction[] = [
		updateHealthcheck(healthcheck),
		setMetadata(metadata),
	];

	if (resourcesCacheSource !== RESOURCES_CACHE_UPDATER_SOURCE) {
		basicInitActions.push(updateResourceCache(criticalData));
	}

	if (configuration) {
		basicInitActions.push(setConfiguration(configuration));
	}

	if (settings) {
		basicInitActions.push(setSettings(settings));

		if (settings.isDependenciesVisible !== undefined) {
			basicInitActions.push(setDependenciesViewVisibility(settings.isDependenciesVisible));
		}
		if (settings.isProgressVisible !== undefined) {
			basicInitActions.push(setProgressVisibility(settings.isProgressVisible));
		}
		if (settings.isWarningsVisible !== undefined) {
			basicInitActions.push(setWarningsVisibility(settings.isWarningsVisible));
		}
		if (settings.isReleasesVisible !== undefined) {
			basicInitActions.push(setReleasesVisibility(settings.isReleasesVisible));
		}
		if (
			settings.levelOneViewSettings !== undefined &&
			!(settings.levelOneViewSettings.showCompleted && settings.levelOneViewSettings.period === 12)
		) {
			basicInitActions.push(setLevelOneSetting(settings.levelOneViewSettings));
		}
		if (filteredIssueIds !== undefined) {
			basicInitActions.push(jqlFiltersSuccess(filteredIssueIds));
		}
	}

	if (healthcheck !== undefined) {
		return basicInitActions;
	}

	if (eagerLoadedData) {
		if (
			configuration?.projectConfiguration?.isGoalsFeatureEnabled === true &&
			eagerLoadedData.goals
		) {
			basicInitActions.push(setGoals(eagerLoadedData.goals));
		}

		if (eagerLoadedData.filteredIssueIds) {
			basicInitActions.push(jqlFiltersSuccess(eagerLoadedData.filteredIssueIds));
		}
	}

	basicInitActions.push(addUsers(users), updateIssuesHashAndSequence(issues));

	if (statusCategories) {
		basicInitActions.push(mergeStatusCategories(statusCategories));
	}

	return basicInitActions;
};
