import { combineEpics } from 'redux-observable';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { StateEpic } from '../../common/types.tsx';
import realtimeEvents from './realtime-events.tsx';
import realtimeReapplyJQL from './realtime-reapply-jql.tsx';

const realTimeEpicsNew = (): StateEpic => combineEpics(realtimeEvents, realtimeReapplyJQL);

const realTimeEpicsOld = (): StateEpic => combineEpics(realtimeEvents);

export default functionWithCondition(
	() => fg('jsw-roadmap-state-change-based-issue-hidden-flags'),
	realTimeEpicsNew(),
	realTimeEpicsOld(),
);
