/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@compiled/react';
import { N0, N20, N40A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import {
	DEFAULT_HEADER_ROW_ID,
	DEFAULT_SUBHEADER_HEIGHT,
	zIndex,
} from '../../common/constants.tsx';
import { useHeaderState } from '../../common/context/header/index.tsx';
import { isScrollToTop } from '../../common/context/scroll-meta/main.tsx';
import type { HeaderItem } from '../../common/types/header.tsx';
import type { RenderHeaderItem } from '../../renderers/index.tsx';
import ScrollableOverlay from '../scrollable-overlay/index.tsx';
import { ChartHeaderCell } from './chart/index.tsx';
import ListHeaderCell from './list/index.tsx';

const headerContainerStyles = css({
	position: 'sticky',
	boxSizing: 'border-box',
	width: '100%',
	top: 0,
	background: token('elevation.surface', N0),
	borderRadius: `${token('border.radius', '3px')} 0 0 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	zIndex: zIndex.ROW_HEADER,
});

const headerRowStyles = css({
	position: 'relative',
	display: 'flex',
});

const headerShadow = css({
	boxShadow: `${token(
		'elevation.shadow.overflow',
		`0 ${token('space.050', '4px')} ${token('space.050', '4px')} -${token('space.025', '2px')} ${N40A}`,
	)}`,
});

const defaultBackground = css({
	backgroundColor: token('color.background.neutral', N20),
});

type Props = {
	renderListHeaderItem: RenderHeaderItem;
	renderChartHeaderItem: RenderHeaderItem;
};

const Header = ({ renderListHeaderItem, renderChartHeaderItem }: Props) => {
	const [{ headerItems, headerHeight, isRowCountKnown }] = useHeaderState();
	const [isAtTop] = isScrollToTop();

	return (
		<UFOSegment name="timeline-table-header">
			<div
				role="rowgroup"
				css={[headerContainerStyles, !isAtTop && headerShadow]}
				style={{ height: headerHeight }}
			>
				{headerItems.map(({ height = DEFAULT_SUBHEADER_HEIGHT, id }: HeaderItem, index: number) => (
					<div
						key={id}
						data-testid={`roadmap.timeline-table.main.header.sub-header-${id.toLowerCase()}`}
						role="row"
						aria-rowindex={isRowCountKnown ? index + 1 : undefined}
						css={[headerRowStyles, id === DEFAULT_HEADER_ROW_ID && defaultBackground]}
						style={{ height }}
					>
						<ListHeaderCell
							id={id}
							itemHeight={height}
							renderListHeaderItem={renderListHeaderItem}
						/>
						<ChartHeaderCell
							id={id}
							itemHeight={height}
							renderChartHeaderItem={renderChartHeaderItem}
						/>
					</div>
				))}
				<ScrollableOverlay isOverlayInHeader />
			</div>
		</UFOSegment>
	);
};

Header.displayName = 'Header';
Header.whyDidYouRender = true;

export default memo<Props>(Header);
