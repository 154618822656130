import { useRef, useCallback } from 'react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { ROW_DRAG_AND_DROP_ID } from '../../../../../common/constants.tsx';
import type { RowDragData } from '../../../../../common/types/drag-and-drop.tsx';
import type { ItemId } from '../../../../../common/types/item.tsx';

type Props = {
	canDrag: boolean;
	id: ItemId;
	parentId: ItemId | undefined;
	level: number;
	depth: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	sideEffectMarshal: any;
};

type UseDrag = [(el: HTMLElement | null) => void, () => void];

const useListItemDrag = ({
	id,
	level,
	depth,
	parentId,
	canDrag,
	sideEffectMarshal,
}: Props): UseDrag => {
	const cleanupDragAndDrop = useRef<null | (() => void)>(null);
	const { onRowGenerateDragPreview } = sideEffectMarshal;

	const makeDraggable = useCallback(
		(dragElement: HTMLElement | null) => {
			const el = dragElement;
			if (el === null) {
				return;
			}
			cleanupDragAndDrop.current = combine(
				draggable({
					element: el,
					canDrag: () => canDrag,
					getInitialData: () =>
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						({
							type: ROW_DRAG_AND_DROP_ID,
							id,
							level,
							depth,
							parentId,
						}) as RowDragData,
					onGenerateDragPreview: () => {
						onRowGenerateDragPreview();
					},
				}),
			);
		},
		[canDrag, id, level, depth, parentId, onRowGenerateDragPreview],
	);

	const cleanupDraggable = useCallback(() => {
		cleanupDragAndDrop.current?.();
	}, []);

	return [makeDraggable, cleanupDraggable];
};

export default useListItemDrag;
