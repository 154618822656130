import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { KeyDate } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/key-date.tsx';
import {
	getHighlightedKeyDates,
	createGetVersionsForKeyDates,
} from '../../../../../state/selectors/versions/index.tsx';
import type { State } from '../../../../../state/types.tsx';
import { HighlightLines } from './view.tsx';

type StateProps = {
	highlightedKeyDates: string[];
	getVersionsForKeyDate: (today: number) => ReadonlyArray<KeyDate>;
};

const mapStateToProps = (state: State): StateProps => ({
	highlightedKeyDates: getHighlightedKeyDates(state),
	getVersionsForKeyDate: createGetVersionsForKeyDates(state),
});

export default connect(mapStateToProps, {})(HighlightLines);
