import type { ComponentType } from 'react';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { hasMacroConfig } from '../../state/app/selectors.tsx';
import {
	hasAdministerProjectPermission,
	getIsUserBoardAdmin,
} from '../../state/configuration/selectors.tsx';
import type { State } from '../../state/types.tsx';
import Feedback from './feedback/async.tsx';
import FilterBar from './filter-bar/index.tsx';
import MoreSettings from './more-settings/index.tsx';
import Share from './share/index.tsx';
import ViewSettings from './view-settings/index.tsx';
import Header from './view.tsx';

type StateProps = {
	isMacroView: boolean;
	Share: ComponentType<{}>;
	Filters: ComponentType<{ isMacroView: boolean }>;
	ViewSettings: ComponentType<{}>;
	MoreSettings: ComponentType<{}> | undefined;
	// clean up Feedback prop along with getWillShowNav4()
	Feedback: ComponentType<{}>;
};

export default connect(
	(state: State): StateProps => ({
		isMacroView: hasMacroConfig(state),
		Share,
		Filters: FilterBar,
		ViewSettings,
		MoreSettings:
			getWillShowNav4() || hasAdministerProjectPermission(state) || getIsUserBoardAdmin(state)
				? MoreSettings
				: undefined,
		// clean up Feedback prop along with getWillShowNav4()
		Feedback,
	}),
	{},
)(Header);
