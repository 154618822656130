import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { MiddlewareAPI } from 'redux';
import { batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { getRoadmapType } from '../../state/app/selectors.tsx';
import {
	ROUTER_LOCATION_CHANGE,
	type RouterLocationChangeAction as Action,
} from '../../state/router/actions.tsx';
import type { State } from '../../state/types.tsx';
import { convertStringToQuery } from '../../utils/router.tsx';
import { getSyncActions } from '../common/selected-issues.tsx';
import type { StateEpic } from '../common/types.tsx';

/* This epic keeps the panel state synchronised with the url.
 * This is necessary because the url is the source of truth for the visibility of the issue details panel type.
 * E.g. Users may come to the page from a shared link or bookmark, or navigate history. This may affect the panel visibility in the UI.
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(ROUTER_LOCATION_CHANGE).mergeMap(({ payload: location }: Action) => {
		const state = store.getState();
		const { selectedIssue } = convertStringToQuery(location.search, getRoadmapType(state));
		const actions = getSyncActions(state, selectedIssue);

		return actions.length
			? Observable.of(actions.length > 1 ? batchActions(actions) : actions[0])
			: Observable.empty<never>();
	})) as StateEpic;
