import { createStore, createContainer, createHook } from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions/index.tsx';

type State = {
	isDragEnabled: boolean;
	isSelectEnabled: boolean;
	isMultiSelectEnabled: boolean;
	isCreateLastEnabled: boolean;
	isCreateSiblingEnabled: boolean;
	isCreateChildEnabled: boolean;
	isHighlightToday: boolean;
	isTreeDrag: boolean;
	isMeatballsButtonEnabled: boolean;
};

const initialState: State = {
	isDragEnabled: false,
	isSelectEnabled: false,
	isMultiSelectEnabled: false,
	isCreateLastEnabled: false,
	isCreateSiblingEnabled: false,
	isCreateChildEnabled: false,
	isHighlightToday: true,
	isTreeDrag: false,
	isMeatballsButtonEnabled: false,
};

export const enablementStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'timeline-table.enablements',
});

export const useEnablements = createHook(enablementStore);

export const EnablementProvider = createContainer<State, Actions, State>(enablementStore, {
	onInit:
		() =>
		({ setState }, enablements: State) => {
			setState({ ...enablements });
		},
	onUpdate:
		() =>
		({ setState }, enablements: State) => {
			setState({ ...enablements });
		},
});
