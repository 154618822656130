import React, { useCallback, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import CustomizeIcon from '@atlaskit/icon/core/migration/customize--preferences';
import { type TriggerProps, Popup } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ResponsiveButton } from '@atlassian/jira-responsive-button/src/ui/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import ViewSettingsButton from './button/index.tsx';
import messages from './button/messages.tsx';
import ViewSettingsMenu from './menu/async.tsx';

const ViewSettings = () => {
	const [viewSettingsOpen, setViewSettingsOpen] = useState(false);
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const toggleViewSettings = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireTrackAnalytics(analyticsEvent, `viewSettings ${viewSettingsOpen ? 'closed' : 'opened'}`);

		setViewSettingsOpen(!viewSettingsOpen);
	}, [createAnalyticsEvent, viewSettingsOpen]);

	const onClose = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireTrackAnalytics(analyticsEvent, 'viewSettings closed');

		setViewSettingsOpen(false);
	}, [createAnalyticsEvent]);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) =>
			// eslint-disable-next-line no-nested-ternary
			isVisualRefreshEnabled() ? (
				<IconButton
					isSelected={viewSettingsOpen}
					icon={CustomizeIcon}
					onClick={toggleViewSettings}
					label={formatMessage(messages.viewSettings)}
					{...triggerProps}
				/>
			) : getWillShowNav4() ? (
				<ResponsiveButton
					isSelected={viewSettingsOpen}
					onClick={toggleViewSettings}
					icon={CustomizeIcon}
					label={formatMessage(messages.viewSettings)}
					{...triggerProps}
				/>
			) : (
				<div {...triggerProps}>
					<ViewSettingsButton isSelected={viewSettingsOpen} onClick={toggleViewSettings} />
				</div>
			),
		[viewSettingsOpen, toggleViewSettings, formatMessage],
	);

	const renderContent = useCallback(() => <ViewSettingsMenu onClose={onClose} />, [onClose]);

	return (
		<Popup
			placement="bottom-end"
			isOpen={viewSettingsOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			shouldUseCaptureOnOutsideClick
		/>
	);
};

export default ViewSettings;
