import React, {
	type MouseEvent,
	type KeyboardEvent,
	type SetStateAction,
	type Dispatch,
	type ReactNode,
	useRef,
	useState,
	useCallback,
} from 'react';
import { mergeRefs } from 'use-callback-ref';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { ButtonItem } from '@atlaskit/menu';
import Popup, { type TriggerProps, type ContentProps } from '@atlaskit/popup';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TriggerProps as TooltipTriggerProps } from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useOnClickOutside } from '../../use-on-click-outside/index.tsx';

type Props = {
	label: string;
	isDisabled?: boolean;
	disabledTooltip?: string;
	triggerRef?: React.Ref<HTMLButtonElement>;
	interactionName?: string;
	setInitialFocusRef?: Dispatch<SetStateAction<HTMLElement | null>>;
	children: (props: {
		setInitialFocusRef: Dispatch<SetStateAction<HTMLElement | null>>;
	}) => ReactNode;
};

const PopupButton = ({
	label,
	isDisabled,
	disabledTooltip,
	triggerRef,
	interactionName,
	setInitialFocusRef,
	children,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);
	const ref = useRef<HTMLButtonElement>(null);
	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);
	const onMenuToggle = useCallback((e: MouseEvent | KeyboardEvent) => {
		e.stopPropagation();
		setIsOpen((currentState) => !currentState);
	}, []);

	useOnClickOutside({ menuRef, closeMenu: onClose, isMenuOpen: isOpen, triggerRef: ref });
	const renderContent = useCallback(
		({ setInitialFocusRef: setFocusRef }: ContentProps) => (
			<Box
				ref={menuRef}
				testId="roadmap.timeline-table.main.content.list.list-item.list-item-menu-option.popup-button.content"
			>
				{children({ setInitialFocusRef: setFocusRef })}
			</Box>
		),
		[children],
	);

	const renderButtonItem = useCallback(
		(popupTriggerProps: TriggerProps, tooltipTriggerProps?: TooltipTriggerProps) => {
			const refs = [popupTriggerProps.ref];

			if (tooltipTriggerProps?.ref) {
				refs.push(tooltipTriggerProps.ref);
			}

			if (setInitialFocusRef) {
				refs.push(setInitialFocusRef);
			}

			if (triggerRef) {
				refs.push(triggerRef);
			}

			refs.push(ref);

			return (
				<ButtonItem
					{...tooltipTriggerProps}
					{...popupTriggerProps}
					onClick={onMenuToggle}
					isDisabled={isDisabled}
					iconAfter={
						!isDisabled ? (
							<ChevronRightIcon
								color={token('color.icon.subtle', '')}
								label=""
								testId="roadmap.timeline-table.main.content.list.list-item.list-item-menu-option.popup-button.chevron-right-icon"
							/>
						) : undefined
					}
					ref={mergeRefs(refs)}
					{...(fg('platform_button_item-add-ufo-metrics') ? { interactionName } : {})}
				>
					{label}
				</ButtonItem>
			);
		},
		[interactionName, isDisabled, label, onMenuToggle, setInitialFocusRef, triggerRef],
	);

	const trigger = useCallback(
		(triggerProps: TriggerProps) =>
			isDisabled && disabledTooltip ? (
				<Tooltip content={disabledTooltip}>
					{(tooltipProps: TooltipTriggerProps) => renderButtonItem(triggerProps, tooltipProps)}
				</Tooltip>
			) : (
				renderButtonItem(triggerProps)
			),

		[isDisabled, disabledTooltip, renderButtonItem],
	);

	return (
		<Popup
			role="dialog"
			shouldRenderToParent
			shouldUseCaptureOnOutsideClick
			isOpen={isOpen}
			placement="right-start"
			onClose={onClose}
			content={renderContent}
			trigger={trigger}
		/>
	);
};

export default PopupButton;
