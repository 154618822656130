import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { clearPayloadGlobalIssueCreate } from '../../../state/entities/global-issue-create/actions.tsx';
import { getPayloadGlobalIssueCreate } from '../../../state/entities/global-issue-create/selectors.tsx';
import type { State } from '../../../state/types.tsx';
import LazyJiraGlobalIssueCreateDialogTrigger from './async.tsx';

export default connect(
	(state: State) => ({
		payloadGIC: getPayloadGlobalIssueCreate(state),
	}),
	{
		onClearGICPayload: clearPayloadGlobalIssueCreate,
	},
)(LazyJiraGlobalIssueCreateDialogTrigger);
