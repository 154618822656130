import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { token } from '@atlaskit/tokens';
import { Skeleton, SkeletonCompiled } from './styled.tsx';

// This data is sourced from the Atlassian Design typography guidelines.
// See: https://atlassian.design/guidelines/product/foundations/typography
// There is a PlainText export for regular text, a Paragraph export for grouping multiple
// lines of regular text, and various exports for headings:
//
// * Hx00 sets height equal to font size and margins to fill out the line height for the
//   "Text size x00" typography.  It doesn't account for the prescribed top-margin.
// * Hx00M adds the top margin specified for that typography.
// * Hx00L uses the "long form" line height and top margin for that font size

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const base = styled(Skeleton)({
	width: '100%',
});
/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PlainText = styled(base)({
	height: '14px',
	marginBottom: token('space.075', '6px'),
});
/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Paragraph = styled.div`
	width: 100%;
	margin-top: ${token('space.150', '12px')};
	/* stylelint-disable-next-line */

	& > ${PlainText} {
		margin: 0;
	}

	/* stylelint-disable-next-line */

	& > ${PlainText} + ${PlainText} {
		margin-top: 3px;
	}
`;

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H100 = styled(base)({
	height: '11px',
	marginTop: token('space.025', '2px'),
	marginBottom: '3px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H100M = styled(H100)({
	marginTop: '18px',
});
/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H200 = styled(base)({
	height: '12px',
	marginTop: token('space.025', '2px'),
	marginBottom: token('space.025', '2px'),
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
export const H100L = H100M;

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H200M = styled(H200)({
	marginTop: '18px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H300 = styled(base)({
	height: '12px',
	marginTop: token('space.025', '2px'),
	marginBottom: token('space.025', '2px'),
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H300M = styled(H300)({
	marginTop: '22px',
});
/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H400 = styled(base)({
	height: '14px',
	marginTop: '1px',
	marginBottom: '1px',
});
/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
export const H200L = H200M;

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
export const H300L = H300M;

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H400M = styled(H400)({
	marginTop: '17px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H400L = styled(H400M)({
	marginTop: '27px',
	marginBottom: '3px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H500 = styled(base)({
	height: '20px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H500M = styled(H500)({
	marginTop: '26px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H500L = styled(H500M)({
	marginTop: '36px',
	marginBottom: token('space.050', '4px'),
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H600 = styled(base)({
	height: '20px',
	marginTop: token('space.025', '2px'),
	marginBottom: token('space.025', '2px'),
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H600M = styled(H600)({
	marginTop: '30px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H600L = styled(H600M)({
	marginTop: '38px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H700 = styled(base)({
	height: '24px',
	marginTop: token('space.025', '2px'),
	marginBottom: token('space.025', '2px'),
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H700M = styled(H700)({
	marginTop: '42px',
});
/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
export const H700L = H700M;
/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H800 = styled(base)({
	height: '29px',
	marginTop: '1px',
	marginBottom: token('space.025', '2px'),
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H800M = styled(H800)({
	marginTop: '41px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H800L = styled(H800M)({
	marginTop: '49px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H900 = styled(base)({
	height: '35px',
	marginTop: token('space.025', '2px'),
	marginBottom: '3px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H900M = styled(H900)({
	marginTop: '54px',
});

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
export const H900L = H900M;

// This data is sourced from the Atlassian Design typography guidelines.
// See: https://atlassian.design/guidelines/product/foundations/typography
// There is a PlainText export for regular text, a Paragraph export for grouping multiple
// lines of regular text, and various exports for headings:
//
// * Hx00 sets height equal to font size and margins to fill out the line height for the
//   "Text size x00" typography.  It doesn't account for the prescribed top-margin.
// * Hx00M adds the top margin specified for that typography.
// * Hx00L uses the "long form" line height and top margin for that font size

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const baseCompiled = styled2(SkeletonCompiled)({
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PlainTextCompiled = styled2(baseCompiled)({
	height: '14px',
	marginBottom: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ParagraphCompiled = styled2.div({
	width: '100%',
	marginTop: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H100Compiled = styled2(baseCompiled)({
	height: '11px',
	marginTop: token('space.025', '2px'),
	marginBottom: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H100MCompiled = styled2(H100Compiled)({
	marginTop: '18px',
});

export const H100LCompiled = H100MCompiled;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H200Compiled = styled2(baseCompiled)({
	height: '12px',
	marginTop: token('space.025', '2px'),
	marginBottom: token('space.025', '2px'),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H200MCompiled = styled2(H200Compiled)({
	marginTop: '18px',
});
export const H200LCompiled = H200MCompiled;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H300Compiled = styled2(baseCompiled)({
	height: '12px',
	marginTop: token('space.025', '2px'),
	marginBottom: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H300MCompiled = styled2(H300Compiled)({
	marginTop: '22px',
});

export const H300LCompiled = H300MCompiled;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H400Compiled = styled2(baseCompiled)({
	height: '14px',
	marginTop: '1px',
	marginBottom: '1px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H400MCompiled = styled2(H400Compiled)({
	marginTop: '17px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H400LCompiled = styled2(H400MCompiled)({
	marginTop: '27px',
	marginBottom: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H500Compiled = styled2(baseCompiled)({
	height: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H500MCompiled = styled2(H500Compiled)({
	marginTop: '26px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H500LCompiled = styled2(H500MCompiled)({
	marginTop: '36px',
	marginBottom: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H600Compiled = styled2(baseCompiled)({
	height: '20px',
	marginTop: token('space.025', '2px'),
	marginBottom: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H600MCompiled = styled2(H600Compiled)({
	marginTop: '30px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H600LCompiled = styled2(H600MCompiled)({
	marginTop: '38px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H700Compiled = styled2(baseCompiled)({
	height: '24px',
	marginTop: token('space.025', '2px'),
	marginBottom: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H700MCompiled = styled2(H700Compiled)({
	marginTop: '42px',
});
export const H700LCompiled = H700MCompiled;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H800Compiled = styled2(baseCompiled)({
	height: '29px',
	marginTop: '1px',
	marginBottom: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H800MCompiled = styled2(H800Compiled)({
	marginTop: '41px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H800LCompiled = styled2(H800MCompiled)({
	marginTop: '49px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H900Compiled = styled2(baseCompiled)({
	height: '35px',
	marginTop: token('space.025', '2px'),
	marginBottom: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H900MCompiled = styled2(H900Compiled)({
	marginTop: '54px',
});

export const H900LCompiled = H900MCompiled;
