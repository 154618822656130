import React from 'react';
import {
	SummaryContainer,
	SummaryIcon,
	SummaryTitle,
	SummaryKeyLabel,
} from '../../common/ui/summary/index.tsx';

type Props = {
	isDone: boolean;
	title: string;
	iconUrl: string;
	itemKey?: string;
};

const RoadmapListItemContentLite = ({ title, iconUrl, itemKey, isDone }: Props) => (
	<SummaryContainer>
		<SummaryIcon src={iconUrl} alt="" />
		{itemKey && <SummaryKeyLabel isDone={isDone}>{itemKey}</SummaryKeyLabel>}
		<SummaryTitle type="div">{title}</SummaryTitle>
	</SummaryContainer>
);

RoadmapListItemContentLite.defaultProps = {
	iconUrl: '',
	isDone: false,
};

export { RoadmapListItemContentLite };
