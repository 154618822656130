import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { BoardId } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import {
	isClassic as getIsClassic,
	getBoardId,
	getCloudId,
} from '../../../state/app/selectors.tsx';
import {
	getIsSprintsFeatureEnabled,
	getIsUserBoardAdmin,
	getProjectKey,
	hasAdministerProjectPermission,
} from '../../../state/configuration/selectors.tsx';
import type { State } from '../../../state/types.tsx';
import MoreSettings from './view.tsx';

type StateProps = {
	isCMP: boolean;
	boardId: BoardId;
	projectKey: ProjectKey;
	cloudId: string;
	boardType: string;
	canConfigureTimeline: boolean;
};

export default connect(
	(state: State): StateProps => ({
		isCMP: getIsClassic(state),
		boardId: getBoardId(state),
		projectKey: getProjectKey(state),
		cloudId: getCloudId(state) ?? '',
		boardType: getIsSprintsFeatureEnabled(state) ? 'Scrum' : 'Kanban',
		canConfigureTimeline: hasAdministerProjectPermission(state) || getIsUserBoardAdmin(state),
	}),
	{},
)(MoreSettings);
