import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import type { State } from '../../state/types.tsx';
import {
	SET_DEPENDENCIES_VIEW_VISIBILITY,
	type SetDependenciesViewVisibilityAction as Action,
} from '../../state/ui/views/actions.tsx';
import type { StateEpic } from '../common/types.tsx';
import updateQuery from '../common/update-query/index.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
	{ router: { push } },
) =>
	action$.ofType(SET_DEPENDENCIES_VIEW_VISIBILITY).map((action: Action) => {
		const hideDependencies = action.payload ? undefined : true;

		return updateQuery(push, store.getState(), (query) => ({
			...query,
			hideDependencies,
		}));
	})) as StateEpic;
