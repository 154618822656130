import React from 'react';
import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { HEADER_CONTENT_HEIGHT } from '@atlassian/jira-software-roadmap-timeline-table/src/common/constants.tsx';
import {
	BAR_GAP_WIDTH,
	EXPANDABLE_INDICATOR_WIDTH,
} from '../../../../../common/constants/chart-header.tsx';

const POLYGON_COORDINATES = `0,0 ${EXPANDABLE_INDICATOR_WIDTH - BAR_GAP_WIDTH},${
	HEADER_CONTENT_HEIGHT / 2
} 0,${HEADER_CONTENT_HEIGHT}`;

type PropsOld = {
	backgroundColor: string;
};

type Props = {
	color: string;
};

const ExpandableIndicatorOld = ({ backgroundColor }: PropsOld) => (
	<svg
		width={EXPANDABLE_INDICATOR_WIDTH}
		height={HEADER_CONTENT_HEIGHT}
		style={{
			// @ts-expect-error([Part of upgrade 4.9-5.4]) - Object literal may only specify known properties, and '[`${'--icon-primary-color'}`]' does not exist in type 'Properties<number | (string & {}), string & {}>'.
			[`${'--icon-primary-color'}`]: backgroundColor,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			color: 'var(--icon-primary-color)',
		}}
	>
		<g fill="currentColor">
			<polygon points={POLYGON_COORDINATES} />
		</g>
	</svg>
);

const ExpandableIndicator = ({ color }: Props) => (
	<Box xcss={ExpandableIndicatorWrapperStyles}>
		<svg width="7" height="20" viewBox="0 0 7 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0 1.11111V18.8889V20L1.5 19.4444L3 18.3333L6 10.5556V9.44444L5 6.11111L2.5 1.11111L0 0V1.11111Z"
				fill={token('elevation.surface')}
			/>
			<path
				d="M0 18.8889V20H1.05038C2.23595 20 3.3103 19.2242 3.79181 18.0205L6.45848 11.3538C6.80324 10.4919 6.80324 9.50809 6.45848 8.6462L3.79181 1.97954C3.3103 0.775779 2.23595 0 1.05038 0H0V1.11111H1.05038C1.84076 1.11111 2.55699 1.62829 2.87799 2.4308L5.54466 9.09747C5.77451 9.67206 5.77451 10.3279 5.54466 10.9025L2.87799 17.5692C2.55699 18.3717 1.84076 18.8889 1.05038 18.8889H0Z"
				fill={color}
			/>
		</svg>
	</Box>
);

export { ExpandableIndicator, ExpandableIndicatorOld };

const ExpandableIndicatorWrapperStyles = xcss({
	position: 'absolute',
	top: '0px',
	right: '0px',
});
