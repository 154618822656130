import React, { memo, useEffect } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ChangeColorMenuAsync, ChangeColorMenuLazy } from './change-color-menu/async.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ChangeColorOption = ({ id, isColorEditable, color, Option, setCloseMenu }: Props) => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		ChangeColorMenuLazy.preload();
	}, []);

	return (
		<Option
			isDisabled={!isColorEditable}
			{...(fg('platform_button_item-add-ufo-metrics')
				? { interactionName: 'timeline-change-color-primary-option-clicked' }
				: {})}
			label={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.changeIssueColorIssueTermRefresh
					: messages.changeIssueColor,
			)}
		>
			{({ setInitialFocusRef }) => (
				<ChangeColorMenuAsync
					id={id}
					color={color}
					setInitialFocusRef={setInitialFocusRef}
					setCloseMenu={setCloseMenu}
				/>
			)}
		</Option>
	);
};

export default memo<Props>(ChangeColorOption);
