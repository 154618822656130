import type { Environment } from 'react-relay';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';

export const invalidateRelayStore = (
	environment: Environment,
	cloudId: CloudId,
	boardId: string,
) => {
	const boardAri = createAri({
		resourceOwner: 'jira-software',
		cloudId,
		resourceType: 'board',
		resourceId: boardId,
	});
	environment.commitUpdate((store) => {
		const boardScopeRecord = store.getRoot().getLinkedRecord('boardScope', { boardId: boardAri });
		if (boardScopeRecord) {
			boardScopeRecord.invalidateRecord();
		}
	});
};
