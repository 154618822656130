import memoizeOne from 'memoize-one';
import { THUMB_MINIMUM_SIZE } from './constants.tsx';

export const getRelativeScrollPosition = ({
	thumbPosition,
	thumbSize,
	trackPosition,
	trackSize,
}: {
	thumbPosition: number;
	thumbSize: number;
	trackPosition: number;
	trackSize: number;
}): number => {
	const relativePosition = thumbPosition - trackPosition;
	const newThumbOffset = relativePosition - thumbSize / 2;
	return newThumbOffset / trackSize;
};

// Gets the absolute position in pixels of the thumb within the scroll track
export const getThumbPosition = (scrollOffsetRatio: number, trackSize: number, thumbSize: number) =>
	scrollOffsetRatio * (trackSize - thumbSize);

// Gets the absolute size in pixels of the thumb
export const getThumbSize = (contentToViewportRatio: number, trackSize: number): number => {
	const size = Math.round(contentToViewportRatio * trackSize);
	return Math.max(THUMB_MINIMUM_SIZE, size);
};

// Keep referential equality for the state if the values are the same
export const getMemoisedThumbState = memoizeOne(
	(horizontalThumbSize: number, verticalThumbSize: number) => ({
		horizontalThumbSize,
		verticalThumbSize,
	}),
);
