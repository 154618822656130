import { BASE_LEVEL_ITEM_HEIGHT, CREATE_ITEM_TYPE, ITEM_TYPE } from '../../constants.tsx';
import type { FlattenedEnrichedItem } from '../../types/hierarchy-enriched-item.tsx';

// Transform array of item to array of itemHeight
export const getItemHeights = (items: FlattenedEnrichedItem[], itemHeight: number): number[] =>
	items.map((item) => {
		const depth = item.type === ITEM_TYPE && item?.item?.depth;
		// depth shouldn't be undefined

		if (item.type === ITEM_TYPE && depth === 0) {
			return itemHeight;
		}
		if (item.type === CREATE_ITEM_TYPE) {
			return itemHeight;
		}
		return BASE_LEVEL_ITEM_HEIGHT();
	});
