import type { MiddlewareAPI } from 'redux';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/skipUntil';
import { INITIALIZE_NON_CRITICAL_DATA } from '../../../state/app/actions.tsx';
import {
	type ClearTransitionStateAction,
	type ExtendIssuesAction,
	type ReplaceIssueAction,
	CLEAR_TRANSITION_STATE,
	CREATE_ISSUE,
	EXTEND_ISSUES,
	REPLACE_ISSUE,
	SCHEDULE_ISSUES,
	UPDATE_ISSUE,
	GLOBAL_ISSUE_CREATE,
} from '../../../state/entities/issues/actions.tsx';
import { getQuickFilters, getCustomFilters } from '../../../state/router/selectors.tsx';
import { getFilteredIssueIds } from '../../../state/selectors/issues/index.tsx';
import type { State } from '../../../state/types.tsx';
import { observeSelector } from '../../common/reselect.tsx';
import type { StateEpic } from '../../common/types.tsx';
import { handleHiddenGlobalIssueCreated, handleHiddenIssueCreated } from './create.tsx';
import { handleHiddenIssueRescheduled } from './schedule-issues.tsx';
import { handleHiddenIssueUpdated } from './update.tsx';
import { type BatchActionsObservable, groupBatchedActionsObservable } from './utils.tsx';

type Action = BatchActionsObservable<
	ExtendIssuesAction | ReplaceIssueAction | ClearTransitionStateAction
>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: Action, store: MiddlewareAPI<State>) => {
	// Wait until the critical data has loaded.
	const afterCriticalDataLoad$ = action$.skipUntil(action$.ofType(INITIALIZE_NON_CRITICAL_DATA));

	// Create an observable which emits changes to the filtered issue IDs.
	const selectorResult$ = observeSelector(afterCriticalDataLoad$, store, getFilteredIssueIds);

	// Create an observable of the before and after values of each change, and attach the action that caused it.
	const resultChangeWithAction$ = selectorResult$
		.pairwise()
		.withLatestFrom(action$, (change, action) => ({
			change,
			action,
		}));

	// Process the observable such that any batch actions are split up, with the underlying actions
	// grouped by their type.
	const groupedAction$ = groupBatchedActionsObservable(resultChangeWithAction$);

	return groupedAction$
		.filter(({ action }) =>
			[EXTEND_ISSUES, REPLACE_ISSUE, CLEAR_TRANSITION_STATE].includes(action.type),
		)
		.mergeMap(({ change, action }) => {
			const state = store.getState();
			const { type, payload } = action;
			const [before, after] = change;

			const quickFilterIds = getQuickFilters(state);
			const customFilterIds = getCustomFilters(state);
			const isJQLFilterApplied = quickFilterIds.length > 0 || customFilterIds.length > 0;

			// Assume all actions in a group come from the first caller.
			const caller = payload[0]?.meta?.caller;

			if (type === REPLACE_ISSUE && caller === CREATE_ISSUE)
				return handleHiddenIssueCreated(store, before, after, payload);

			if (type === EXTEND_ISSUES && caller === GLOBAL_ISSUE_CREATE)
				return handleHiddenGlobalIssueCreated(store, before, after, payload);

			if (type === EXTEND_ISSUES && caller === UPDATE_ISSUE)
				return handleHiddenIssueUpdated(action$, store, before, after, payload, isJQLFilterApplied);

			if (type === EXTEND_ISSUES && caller === SCHEDULE_ISSUES)
				return handleHiddenIssueRescheduled(
					action$,
					store,
					before,
					after,
					payload,
					isJQLFilterApplied,
				);

			return Observable.empty();
		});
}) as StateEpic;
