import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { hasMacroConfig } from '../../../../../state/app/selectors.tsx';
import type { State } from '../../../../../state/types.tsx';
import { OverlayButton } from './view.tsx';

export default connect(
	(state: State) => ({
		isMacroView: hasMacroConfig(state),
	}),
	{},
)(OverlayButton);
