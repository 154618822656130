import { PURPLE, type Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Hash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import { BASE_LEVEL } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import type { Sprint } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import { getChartDateData } from '../../common/chart-dates/index.tsx';
import { getBaseLevelDates } from '../../common/index.tsx';
import type { ChartData, DateError } from '../common/types.tsx';

const getBaseLevelColor = (
	issueParentIdHash: Hash<IssueId | undefined>,
	issueColorHash: Hash<Color>,
	issueId: IssueId,
): Color | undefined => {
	const parentId = issueParentIdHash[issueId];
	if (parentId !== undefined) {
		return issueColorHash[parentId];
	}
	// This case should be impossible.
	return undefined;
};

export const getBaseLevelChartData = (
	isSprintsPlanning: boolean,
	isChildIssuePlanningEnabled: boolean,
	issueParentIdHash: Hash<IssueId | undefined>,
	issueColorHash: Hash<Color>,
	issueStartDateHash: Hash<number | undefined>,
	issueDueDateHash: Hash<number | undefined>,
	issueSprintsHash: Hash<Sprint[]>,
	issueDateErrorsHash: Hash<DateError[]>,
	issueId: IssueId,
): ChartData => {
	const explicitStartDate = issueStartDateHash[issueId];
	const explicitDueDate = issueDueDateHash[issueId];
	const sprints = issueSprintsHash[issueId];

	const {
		startDate: baseLevelStartDate,
		dueDate: baseLevelDueDate,
		startDateType,
		dueDateType,
		placeholder,
	} = getBaseLevelDates(
		explicitStartDate,
		explicitDueDate,
		sprints,
		isSprintsPlanning,
		isChildIssuePlanningEnabled,
	);

	const color = getBaseLevelColor(issueParentIdHash, issueColorHash, issueId) || PURPLE;
	const { startDate, dueDate } = getChartDateData(
		baseLevelStartDate,
		baseLevelDueDate,
		BASE_LEVEL,
		isChildIssuePlanningEnabled,
	);
	return {
		startDate,
		dueDate,
		startDateType,
		dueDateType,
		placeholder,
		color,
		warnings: isChildIssuePlanningEnabled ? issueDateErrorsHash[issueId] : [],
		level: BASE_LEVEL,
	};
};
