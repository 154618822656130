import React from 'react';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { Prefetch } from './prefetch/index.tsx';

export type Props = {
	issueIds: IssueId[];
	issueKeys?: string[];
};

function PrefetchClientOnly(props: Props) {
	if (__SERVER__) {
		return null;
	}
	return <Prefetch {...props} />;
}

export default PrefetchClientOnly;
