import React, { type ReactNode } from 'react';
import { useSideEffectMarshal } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/side-effect-marshal/index.tsx';
import { useTimelineState } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';
import {
	createStore,
	createContainer,
	createHook,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import type { Interval } from '../../../common/types/interval.tsx';
import { useAdjustScrollPosition } from '../../../common/utils/adjust-scroll-position.tsx';
import { actions, type Actions } from './actions/index.tsx';
import { DEFAULT_STATE } from './common/constants.tsx';
import type { State, ContainerProps, InteractiveChartItem } from './common/types.tsx';
import {
	getInteractionDataById,
	getTargetIntervalId,
	getIntervalDropZoneDataById,
	getIsHeaderModalActive,
} from './selectors/index.tsx';
import { getSimpleIntervals } from './utils.tsx';

export const chartItemInteractionStore = createStore<State, Actions>({
	initialState: DEFAULT_STATE,
	actions,
	name: 'timeline-table-kit.table-providers.chart-item-interactions',
});

const useChartInteractionActions = createHook(chartItemInteractionStore, {
	selector: null,
});

export const useResetChartInteractionState = () => {
	const [, chartInteractionActions] = useChartInteractionActions();
	return { resetChartInteractionState: chartInteractionActions.onLingerEnd };
};

export const useInteractionData = createHook(chartItemInteractionStore, {
	selector: getInteractionDataById,
});

export const useTargetIntervalId = createHook(chartItemInteractionStore, {
	selector: getTargetIntervalId,
});

export const useIntervalDropZoneData = createHook(chartItemInteractionStore, {
	selector: getIntervalDropZoneDataById,
});

/**
 * Access the Header object whether the modal window is active (e.g. sprints or releases/versions)
 */
export const useIsHeaderModalActive = createHook(chartItemInteractionStore, {
	selector: getIsHeaderModalActive,
});

/**
 * Access the Header object actions to toggle the modal on/off notification (e.g. for sprints or releases/versions modal windows)
 */
export const useHeaderItemActions = createActionsHook(chartItemInteractionStore);

const Container = createContainer<State, Actions, ContainerProps>(chartItemInteractionStore);

type Props = {
	isDragAlongEnabled: boolean;
	items: InteractiveChartItem[];
	selectedItemIds: string[];
	intervals: ReadonlyArray<Interval>;
	children: ReactNode;
};

export const RoadmapChartItemInteractionProvider = ({
	items,
	selectedItemIds,
	isDragAlongEnabled,
	intervals,
	children,
}: Props) => {
	const sideEffectMarshal = useSideEffectMarshal();
	const [{ timelineMode, timelineDuration, timelineWidth }] = useTimelineState();
	const { updateViewportCache, adjustScrollPositionAndGetOffset } = useAdjustScrollPosition();
	const simpleIntervals = getSimpleIntervals(intervals, timelineDuration, timelineWidth);

	return (
		<Container
			items={items}
			selectedItemIds={selectedItemIds}
			sideEffectMarshal={sideEffectMarshal}
			timelineMode={timelineMode}
			timelineWidth={timelineWidth}
			timelineDuration={timelineDuration}
			setViewportCache={updateViewportCache}
			getScrollOffset={adjustScrollPositionAndGetOffset}
			isDragAlongEnabled={isDragAlongEnabled}
			simpleIntervals={simpleIntervals}
		>
			{children}
		</Container>
	);
};

RoadmapChartItemInteractionProvider.defaultProps = {
	selectedItemIds: [],
};
