import React, { useCallback } from 'react';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import UploadIcon from '@atlaskit/icon/core/migration/upload--export';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { ResponsiveButton } from '@atlassian/jira-responsive-button/src/ui/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { ExportCsvActionPayload } from '../../../common/types/export-action.tsx';
import messages from './messages.tsx';

type Props = {
	onExportCsv: (payload: ExportCsvActionPayload) => void;
	onExportAsImageClick?: () => void;
	/* Indicates whether the button should be rendered with a themed appearance,
	specifically for views used in Business projects with background styling */
	isThemedButton?: boolean;
};

const ExportButton = ({ onExportCsv, onExportAsImageClick, isThemedButton }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onExportCsvCallback = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({});
		fireUIAnalytics(analyticsEvent, 'exportCsv clicked');
		onExportCsv({
			inferredStartDateHeader: formatMessage(messages.exportSpreadsheetInferredStartDate),
			inferredDueDateHeader: formatMessage(messages.exportSpreadsheetInferredDueDate),
		});
	}, [createAnalyticsEvent, onExportCsv, formatMessage]);

	return (
		<DropdownMenu
			trigger={({ triggerRef, ...props }) =>
				// eslint-disable-next-line no-nested-ternary
				isVisualRefreshEnabled() ? (
					isThemedButton ? (
						<ThemedButton
							aria-label={formatMessage(messages.exportButton)}
							label={formatMessage(messages.exportButton)}
							iconBefore={<UploadIcon label="" color="currentColor" spacing="spacious" />}
							ref={triggerRef}
							{...props}
							testId="roadmap.timeline-table-kit.ui.header.export.export-button"
						/>
					) : (
						<IconButton
							label={formatMessage(messages.exportButton)}
							icon={UploadIcon}
							ref={triggerRef}
							isTooltipDisabled={false}
							{...props}
							testId="roadmap.timeline-table-kit.ui.header.export.export-button"
						/>
					)
				) : (
					<ResponsiveButton
						label={formatMessage(messages.exportButton)}
						icon={UploadIcon}
						ref={triggerRef}
						{...props}
					/>
				)
			}
		>
			<DropdownItemGroup>
				<DropdownItem onClick={onExportAsImageClick}>
					{formatMessage(messages.exportImagePng)}
				</DropdownItem>
				<DropdownItem onClick={onExportCsvCallback}>
					{formatMessage(messages.exportSpreadsheetCsv)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export { ExportButton };
