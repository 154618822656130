import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { ScrollMeta, MetaParam, PositionParam } from '../common/types.tsx';

const updateScrollMeta =
	({ isScrollingX, isScrollingY }: MetaParam) =>
	({ setState }: StoreActionApi<ScrollMeta>) => {
		setState({
			isScrollingX,
			isScrollingY,
		});
	};

const updateScrollPosition =
	({ scrollTop, scrollBottom }: PositionParam) =>
	({ setState }: StoreActionApi<ScrollMeta>) => {
		const positions = {
			top: scrollTop,
			bottom: scrollBottom,
		};

		setState({
			positions,
		});
	};

export const scrollMetaActions = {
	updateScrollMeta,
	updateScrollPosition,
} as const;
