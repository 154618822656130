import { ROADMAPS } from '@atlassian/jira-software-resource-invalidator/src/common/types.tsx';
import createResourcesCacheMiddleware from '@atlassian/jira-software-resource-invalidator/src/controllers/create-resources-cache-middleware/index.tsx';
import {
	INITIALIZE_APP_PROPS,
	INITIALIZE_CRITICAL_DATA,
	INITIALIZE_NON_CRITICAL_DATA,
	UPDATE_CRITICAL_DATA,
} from '../../app/actions.tsx';
import {
	SET_TODAY,
	SET_CONFIGURATION,
	SET_DERIVE_FIELDS,
	DERIVE_FIELDS_REQUEST,
} from '../../configuration/actions.tsx';
import { SET_GOALS } from '../../entities/goals/actions.tsx';
import { EXTEND_ISSUES } from '../../entities/issues/actions.tsx';
import { MERGE_STATUS_CATEGORIES } from '../../entities/status-categories/actions.tsx';
import { REALTIME_DISPATCH_EVENT } from '../../realtime/actions.tsx';
import { SET_SETTINGS } from '../../settings/actions.tsx';
import {
	SET_TEXT_FILTER,
	SET_ASSIGNEE_FILTER,
	SET_STATUS_CATEGORY_FILTER,
	CLEAR_FILTER,
	SET_ISSUE_TYPE_FILTER,
	SET_LABEL_FILTER,
	SET_ISSUE_PARENT_FILTER,
	SET_VERSION_FILTER,
	SET_COMPONENT_FILTER,
	SET_FILTER_CONFIGURATION,
	SET_QUICK_FILTERS_FILTER,
	QUICK_FILTERS_REQUEST,
	JQL_FILTERS_SUCCESS,
	QUICK_FILTERS_CLEAR,
	CUSTOM_FILTERS_CLEAR,
	SET_CUSTOM_FILTER,
	SET_GOAL_FILTER,
} from '../../ui/filters/actions.tsx';
import {
	SET_RESOLVING,
	RESOLVE,
	EXTEND_ISSUE_TYPE_COUNT,
	RETRIEVE_ISSUE_TYPE_COUNT,
} from '../../ui/healthcheck/actions.tsx';
import { SHOW_ISSUE_DETAILS, HIDE_PANEL, SET_SELECTED_ISSUE } from '../../ui/panel/actions.tsx';
import { SET_SHARED } from '../../ui/share/actions.tsx';
import {
	SET_CREATE_ITEM_ANCHOR,
	RESET_CREATE_ITEM_ANCHOR,
	CHANGE_EXPANSION_STATE,
	SHOW_TOOLTIP,
	HIDE_TOOLTIP,
} from '../../ui/table/actions.tsx';

const ELIGIBLE_FOR_INVALIDATION = /^(state\.).+$/;
// We ignore the flag actions
const ROADMAP_IGNORE_REGEX = /^(state\.flags\.).+$/;

export const shouldTriggerCacheInvalidation = ({ type }: { type: string }) => {
	switch (type) {
		case INITIALIZE_APP_PROPS: // fired on startup
		case INITIALIZE_CRITICAL_DATA: // fired on startup
		case INITIALIZE_NON_CRITICAL_DATA: // fired on startup
		case UPDATE_CRITICAL_DATA: // fired on startup
		case SET_SETTINGS: // fired on startup
		case SET_CONFIGURATION: // no data changes
		case SET_TODAY: // no data changes
		case SET_DERIVE_FIELDS: // fired on startup
		case DERIVE_FIELDS_REQUEST: // non-critical data change
		case SET_CREATE_ITEM_ANCHOR: // no data changes
		case RESET_CREATE_ITEM_ANCHOR: // no data changes
		case EXTEND_ISSUES: // side effect of data changes
		case MERGE_STATUS_CATEGORIES: // side effect
		case SET_TEXT_FILTER: // doesn't persist
		case CLEAR_FILTER: // doesn't persist
		case SET_ISSUE_TYPE_FILTER: // doesn't persist
		case SET_LABEL_FILTER: // doesn't persist
		case SET_ISSUE_PARENT_FILTER: // doesn't persist
		case SET_VERSION_FILTER: // doesn't persist
		case SET_COMPONENT_FILTER: // doesn't persist
		case SET_QUICK_FILTERS_FILTER: // doesn't persist
		case QUICK_FILTERS_REQUEST: // doesn't persist
		case JQL_FILTERS_SUCCESS: // doesn't persist
		case QUICK_FILTERS_CLEAR: // doesn't persist
		case SET_CUSTOM_FILTER: // doesn't persist
		case CUSTOM_FILTERS_CLEAR: // doesn't persist
		case SET_ASSIGNEE_FILTER: // doesn't persist
		case SET_STATUS_CATEGORY_FILTER: // doesn't persist
		case SET_FILTER_CONFIGURATION: // doesn't persist
		case SET_SHARED: // doesn't persist
		case CHANGE_EXPANSION_STATE: // doesn't persist
		case SHOW_TOOLTIP: // doesn't persist
		case HIDE_TOOLTIP: // doesn't persist
		case SHOW_ISSUE_DETAILS: // side-panel state doesn't persist
		case HIDE_PANEL: // side-panel state doesn't persist
		case SET_SELECTED_ISSUE: // side-panel state doesn't persist
		case REALTIME_DISPATCH_EVENT: // Don't invalidate on the request, invalidate on success/failure
		case EXTEND_ISSUE_TYPE_COUNT: // no data changes
		case RETRIEVE_ISSUE_TYPE_COUNT: // no data changes
		case SET_RESOLVING: // we already refreshed resource cache when resolve healthcheck
		case RESOLVE: // we already refreshed resource cache when resolve healthcheck
		case SET_GOALS: // goals are async loaded and not part of critical data
		case SET_GOAL_FILTER: // doesn't persist
			return false;
		default:
			break;
	}

	// Ignore stuff that isn't applicable (e.g. COMMON/REDUX_DISPOSABLE/DISPOSE_ACTION)
	if (!ELIGIBLE_FOR_INVALIDATION.exec(type) || ROADMAP_IGNORE_REGEX.exec(type)) {
		return false;
	}

	// Just invalidate on anything. We'll roll out some exclusion logic in the next PR
	return true;
};

export default createResourcesCacheMiddleware(ROADMAPS, shouldTriggerCacheInvalidation);
