import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import uuid from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	ProjectId,
	IssueId,
	IssueTypeId,
	IssueKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import {
	BASE_LEVEL,
	PARENT_LEVEL,
	isBaseLevel,
} from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import type {
	Issue,
	AnalyticsUpdatedItems,
	IssueScheduleFields,
} from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import { scheduleItems } from '@atlassian/jira-software-roadmap-services/src/issues/schedule-roadmap-items.tsx';
import type { IssueHash } from '../../model/issue/index.tsx';
import { getSourceARI } from '../../state/app/selectors.tsx';
import { getEpicIssueTypeIds, getProjectId } from '../../state/configuration/selectors.tsx';
import {
	type ScheduleIssuesAction,
	type ClearTransitionStateAction,
	SCHEDULE_ISSUES,
	extendIssues,
	clearTransitionState,
} from '../../state/entities/issues/actions.tsx';
import {
	getIssueKeyById,
	getSafeIds,
	getIssue,
	getIssueTypeIdForIssueId,
	getIssueChildrenHash,
} from '../../state/entities/issues/selectors.tsx';
import {
	issueDisappearsFromDisplayRange,
	type IssueDisappearsFromDisplayRangeAction,
	type IssueHiddenAction,
	aggError,
	issueHidden,
} from '../../state/flags/actions.tsx';
import { getSelectedIssueKey } from '../../state/router/selectors.tsx';
import type { State } from '../../state/types.tsx';
import { reapplyJQLFilters } from '../../state/ui/filters/actions.tsx';
import { getExpandedItems } from '../../state/ui/table/selectors.tsx';
import type { StateEpic, GenericAction } from '../common/types.tsx';
import { getIsIssueHiddenByFiltersOrSettings } from './common/index.tsx';
import { createTransitionIssueWithUpdatedItems } from './common/update.tsx';
import refreshIssuePage from './refresh-issue-page.tsx';

type ScheduleIssuesAttributes = {
	issueId: IssueId;
	issueTypeId: IssueTypeId;
	updatedItems: AnalyticsUpdatedItems;
	level: number;
	hasOverdueReleaseDate?: boolean | undefined;
};

type ExtendedIssue = {
	issueId: IssueId;
	level: number;
	issue: Issue;
};

type ScheduleIssuesAnalyticsMetadata = {
	event: UIAnalyticsEvent;
	projectId: ProjectId;
	attributes: ScheduleIssuesAttributes[];
	scheduledIssues: {
		[level: number]: ExtendedIssue[];
	};
};

const getPostScheduleActions = (
	state: State,
	scheduledIssues: {
		[level: number]: ExtendedIssue[];
	},
): (IssueHiddenAction | IssueDisappearsFromDisplayRangeAction)[] => {
	const nonBaseLevelIssues = scheduledIssues[PARENT_LEVEL];
	const baseLevelIssues = scheduledIssues[BASE_LEVEL];
	const actions: Array<IssueHiddenAction | IssueDisappearsFromDisplayRangeAction> = [];
	const issueChildrenHash = getIssueChildrenHash(state);
	let activeIssueLevel: number = PARENT_LEVEL;
	let isHiddenIssueRescheduledCalled = false;
	let hasIssueCollapsedWithScheduledChildIssue = false;

	nonBaseLevelIssues
		.concat(baseLevelIssues)
		.some(({ issueId, level, issue }: ExtendedIssue, index: number) => {
			if (index === 0) {
				activeIssueLevel = level;
			}

			// For bulk-scheduling epics, exit early when
			// - "Hidden Issue Rescheduled" flag is counted once + all epics have been checked since epic view settings is not applicable to base-level issues
			// NOTE: the check should continue when "Hidden Issue Rescheduled" hasn't been counted since "Disappears from Display Range" flag can still occur + "Hidden Issue Rescheduled" flag can still occur on filtered base-level issues
			// For bulk-scheduling base-level issues, exit early when
			// - "Hidden Issue Rescheduled" flag is counted once since epic view settings is not applicable to base-level issues
			const shouldExitEarly =
				activeIssueLevel === PARENT_LEVEL
					? isHiddenIssueRescheduledCalled && level === BASE_LEVEL
					: isHiddenIssueRescheduledCalled;

			if (shouldExitEarly) {
				return true;
			}

			// NOTE: isHiddenByFilter can never be true for the active issue since it's the one interacted by users
			const { isHiddenByFilter, isHiddenBySetting } = getIsIssueHiddenByFiltersOrSettings(
				state,
				issue,
				issueId,
			);

			// NOTE: For each issue, either "Disappears from Display Range" flag or "Hidden Issue Rescheduled" flag is applicable, but not both
			if (level === PARENT_LEVEL && isHiddenBySetting) {
				actions.push(issueDisappearsFromDisplayRange({ issueId }));
			} else if (!isHiddenIssueRescheduledCalled) {
				// NOTE: isHiddenBySetting can never be true for base-level issues since it's "epic" view settings
				if (isHiddenByFilter) {
					// Check if there are any issues from the same hierarchy level as the active issue that are hidden by filters
					actions.push(issueHidden({ ids: [] }));
					isHiddenIssueRescheduledCalled = true;

					return false;
				}

				// If no child items are being scheduled, skip checking for parent items' child item state or collapse state
				if (level === PARENT_LEVEL && baseLevelIssues.length) {
					// Check if the parent issue has any child issues that are being scheduled (required for bar-resizing cases)
					const hasChildIssueScheduled = (issueChildrenHash[issueId] ?? []).some(
						(childIssueId: IssueId) =>
							baseLevelIssues.some(
								({ issueId: baseLevelIssueId }: ExtendedIssue) => baseLevelIssueId === childIssueId,
							),
					);
					// Check if the parent is collapsed
					const isCollapsed = !(getExpandedItems(state)[issueId] ?? false);

					// The parent collapse state is only taken into account when it has child issues that are being scheduled
					hasIssueCollapsedWithScheduledChildIssue = hasChildIssueScheduled ? isCollapsed : false;

					if (hasIssueCollapsedWithScheduledChildIssue) {
						actions.push(issueHidden({ ids: [] }));
						isHiddenIssueRescheduledCalled = true;

						return false;
					}
				}
			}

			return false;
		});

	return actions;
};

const persistScheduleIssues = (
	issueIdsObservable: Observable<IssueId[]>,
	safeIssueScheduleFields: IssueScheduleFields[],
	transitionId: string,
	{ scheduledIssues, attributes: issuesAttributes, event }: ScheduleIssuesAnalyticsMetadata,
	store: MiddlewareAPI<State>,
): Observable<GenericAction[]> =>
	issueIdsObservable.mergeMap(() => {
		const state = store.getState();

		return scheduleItems(getSourceARI(state), safeIssueScheduleFields).map((data) => {
			const scheduleRoadmapItemsData = data?.roadmaps?.scheduleRoadmapItems;
			const errors = scheduleRoadmapItemsData?.errors;
			const success = scheduleRoadmapItemsData?.success;

			if (success === false && errors && errors?.length) {
				throw new Error(
					`ScheduleItems failed: ${errors[0]?.message || 'unknown'}, ${
						errors[0]?.extensions?.statusCode || 'statusCode unknown'
					}`,
				);
			}

			const clearTransitions: Array<ClearTransitionStateAction> = [];
			const clonedEvent = event.clone();
			const activeIssueAttributes = issuesAttributes[0];

			if (clonedEvent) {
				const { issueId, ...otherAttributes } = activeIssueAttributes;
				const numberOfSelectedIssuesShiftedTogether =
					(scheduledIssues[activeIssueAttributes.level] ?? []).length - 1;
				const numberOfChildIssuesShiftedTogether = (
					scheduledIssues[activeIssueAttributes.level - 1] ?? []
				).length;

				fireTrackAnalytics(clonedEvent, 'issue updated', issueId, {
					...otherAttributes,
					projectType: 'software',
					numberOfSelectedIssuesShiftedTogether,
					numberOfChildIssuesShiftedTogether,
				});
			}

			safeIssueScheduleFields.forEach((scheduleField: IssueScheduleFields) => {
				clearTransitions.push(
					clearTransitionState({ issueId: scheduleField.issueId, transitionId }, SCHEDULE_ISSUES),
				);
			});

			if (fg('jsw-roadmap-state-change-based-issue-hidden-flags')) return clearTransitions;

			return [...clearTransitions, ...getPostScheduleActions(state, scheduledIssues)];
		});
	});

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<ScheduleIssuesAction>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(SCHEDULE_ISSUES)
		.mergeMap(
			({
				payload: { issueScheduleFields },
				meta: { hasOverdueReleaseDate, analyticsEvent },
			}: ScheduleIssuesAction) => {
				const state = store.getState();

				const transitionId = uuid.v4();
				const issueKeys: Array<IssueKey> = [];
				const ids: Array<IssueId> = [];
				const projectIds: Array<ProjectId> = [];
				const issueTransitions = new Map();
				const safeIssueScheduleFields: Array<IssueScheduleFields> = [];
				const issuesAttributes: Array<ScheduleIssuesAttributes> = [];
				const baseLevelIssues: ExtendedIssue[] = [];
				const nonBaseLevelIssues: ExtendedIssue[] = [];
				const extendHash: IssueHash = {};

				// Generate transition issues
				issueScheduleFields.forEach((issueScheduleField: IssueScheduleFields) => {
					const { issueId, startDate, dueDate } = issueScheduleField;
					const issue = getIssue(state, issueId);

					if (!issue) {
						return;
					}

					const projectId = getProjectId(state);

					if (!projectIds.includes(projectId)) {
						projectIds.push(projectId);
					}

					const issueTypeId = getIssueTypeIdForIssueId(state, issueId);
					const level = getEpicIssueTypeIds(state).includes(issueTypeId)
						? PARENT_LEVEL
						: BASE_LEVEL;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const updateProps: Record<string, any> = {};

					if (startDate !== issue.startDate.value) {
						updateProps.startDate = startDate;
					}

					if (dueDate !== issue.dueDate.value) {
						updateProps.dueDate = dueDate;
					}

					// create new issue object
					const [transitionIssue, updatedItems] = createTransitionIssueWithUpdatedItems(
						issue,
						updateProps,
						transitionId,
					);

					if (transitionIssue) {
						extendHash[issueId] = transitionIssue;

						const issueKey = getIssueKeyById(state, issueId);
						const isIssueBaseLevel = isBaseLevel(level);

						ids.push(issueId);
						issueKey && issueKeys.push(issueKey);
						issueTransitions.set(issueId, transitionIssue);
						safeIssueScheduleFields.push(issueScheduleField);
						issuesAttributes.push({
							issueId,
							issueTypeId,
							level,
							updatedItems,
							...(isIssueBaseLevel ? undefined : { hasOverdueReleaseDate }),
						});

						(isIssueBaseLevel ? baseLevelIssues : nonBaseLevelIssues).push({
							issueId,
							level,
							issue: transitionIssue,
						});
					}
				});

				if (!safeIssueScheduleFields.length) {
					return Observable.empty<never>();
				}

				// Extend issues
				const transitionExtend = getSafeIds(store.getState(), ids).mergeMap(
					(issueIds: IssueId[]) => {
						const transitionExtendHash: IssueHash = {};

						issueIds.forEach((issueId: IssueId) => {
							if (issueId !== undefined) {
								const transitionIssue = issueTransitions.get(issueId);

								if (transitionIssue) {
									transitionExtendHash[issueId] = transitionIssue;
								}
							}
						});

						return Observable.of(
							extendIssues({
								sequence: [], // do not modify sequence
								hash: transitionExtendHash,
							}),
						);
					},
				);

				const analyticsMetadata = {
					projectId: projectIds[0], // assume single project
					scheduledIssues: {
						[BASE_LEVEL]: baseLevelIssues,
						[PARENT_LEVEL]: nonBaseLevelIssues,
					},
					attributes: issuesAttributes,
					event: analyticsEvent,
				};

				return Observable.concat(
					// update `unsafe` issues first
					Observable.of(
						extendIssues(
							{
								sequence: [],
								hash: extendHash,
							},
							SCHEDULE_ISSUES,
						),
					),
					// persist schedule issues
					transitionExtend,
					persistScheduleIssues(
						getSafeIds(store.getState(), ids),
						safeIssueScheduleFields,
						transitionId,
						analyticsMetadata,
						store,
					)
						.map((actions: GenericAction[]) => {
							const selectedIssueKey = getSelectedIssueKey(state);

							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							issueKeys.includes(selectedIssueKey as string) &&
								refreshIssuePage(state, selectedIssueKey);

							return batchActions(actions);
						})
						.catch(() => Observable.from([aggError()])),
					fg('jsw-roadmap-state-change-based-issue-hidden-flags')
						? Observable.empty()
						: Observable.of(reapplyJQLFilters()),
				);
			},
		)) as StateEpic;
