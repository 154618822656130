import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { ColumnDuration, TimelineDuration } from '../../../common/types/timeline.tsx';
import { getColumnPositions, getTodayColumnIndex } from '../../../common/utils/columns.tsx';
import Columns from './view.tsx';

type Props = {
	isHighlightToday: boolean;
	today: number;
	timelineDuration: TimelineDuration;
	columnDurations: ColumnDuration[];
	// Remove optional when cleaning up fg('visual-refresh')
	isOverlayInHeader?: boolean;
	// Remove optional when cleaning up fg('visual-refresh')
	subheaderHeight?: number;
};

const ColumnsOverlay = ({
	isHighlightToday,
	today,
	timelineDuration,
	columnDurations,
	isOverlayInHeader,
	subheaderHeight,
}: Props) => (
	<Columns
		isHighlightToday={isHighlightToday}
		columnPositions={getColumnPositions(timelineDuration, columnDurations)}
		todayColumnIndex={getTodayColumnIndex(today, columnDurations)}
		{...(isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2')
			? { isOverlayInHeader, subheaderHeight }
			: {})}
	/>
);

export default ColumnsOverlay;
export type { Props as APIProps };
