import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { async } from 'rxjs/scheduler/async';
import { filterItemsByJQLFilters } from '@atlassian/jira-software-roadmap-services/src/issues/filter.tsx';
import { getSourceARI } from '../../state/app/selectors.tsx';
import type { State } from '../../state/types.tsx';
import {
	QUICK_FILTERS_REQUEST,
	type QuickFiltersRequestAction as Action,
	type QuickFiltersClearAction,
	type JQLFiltersSuccessAction,
	clearQuickFilters,
	jqlFiltersSuccess,
} from '../../state/ui/filters/actions.tsx';

const filter = (
	action: Action,
	store: MiddlewareAPI<State>,
): Observable<QuickFiltersClearAction | JQLFiltersSuccessAction> => {
	const state = store.getState();
	const quickFilterIds = action.payload;

	if (!quickFilterIds || !quickFilterIds.length) {
		return Observable.of(clearQuickFilters());
	}

	return filterItemsByJQLFilters(getSourceARI(state), quickFilterIds).map((filteredIssueIds) =>
		jqlFiltersSuccess(filteredIssueIds),
	);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(QUICK_FILTERS_REQUEST)
		.throttleTime(500, async, {
			leading: true,
			trailing: true,
		})
		.switchMap((action: Action) => filter(action, store));
