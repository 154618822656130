import type { Observable as ObservableType } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { canInviteOthersResponse } from './types.tsx';

const getCanInviteOthersUrl = (projectId: number) =>
	`/rest/internal/latest/invite-user/project/${projectId}/can-invite-others`;

export const getUserInviteCapability = (
	projectId: number,
): ObservableType<canInviteOthersResponse> =>
	fetchJson$(getCanInviteOthersUrl(projectId), {
		method: 'GET',
	});

/**
 * Checks whether current user can invite others to a TMP project based on user role and project access settings
 * This endpoint does NOT work with CMP project
 */
export const fetchCanUserInviteService = (
	projectId: number,
): ObservableType<canInviteOthersResponse> => getUserInviteCapability(projectId);
