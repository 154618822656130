/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsx jsx */

/* ===========================================================================
 * === LEGACY @atlassian/jira-drag-observer implementation ===
 *
 *
 * This entire .thumb-tracks` module can be deleted once the new
 * `thumb-tracks` implementation with @atlaskit/pragmatic-drag-and-drop has been
 * soaked in production and the feature gate is deprecated.
 *
 * TODO(FG-REMOVE): jsw_roadmaps_timeline_table_custom_scroll_pdnd
 *
 * https://switcheroo.atlassian.com/ui/gates/49cac7b3-a5f8-45d9-9533-08d52c4f3345
 *
 * =========================================================================== */
import React, {
	forwardRef,
	useRef,
	useState,
	useCallback,
	useImperativeHandle,
	type MouseEvent,
	type ForwardedRef,
} from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { OnDragStart, OnDrag } from '@atlassian/jira-drag-observer/src/common/types.tsx';
import { withDragObserver } from '@atlassian/jira-drag-observer/src/ui/index.tsx';
import { zIndex } from '../../../common/constants.tsx';
import {
	SCROLLBAR_SIZE,
	SCROLLBAR_OFFSET,
	THUMB_DRAG_THRESHOLD,
	DEFAULT_DRAG_CACHE,
} from './constants.tsx';
import type { ImperativeRef, DragCache, OnSetScroll } from './types.tsx';
import {
	getRelativeScrollPosition,
	getThumbPosition,
	getThumbSize,
	getMemoisedThumbState,
} from './utils.tsx';

const baseTrackStyles = css({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${SCROLLBAR_SIZE}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.SCROLLBAR,
	'&:hover': {
		transition: 'background 0.1s ease-in',
		backgroundColor: token('color.background.neutral.pressed', 'rgba(0,0,0,0.3)'),
	},
	'&:active': {
		backgroundColor: token('color.background.neutral.pressed', 'rgba(0,0,0,0.3)'),
	},
});

const baseThumbStyles = css({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${SCROLLBAR_SIZE}px`,
	backgroundColor: token('color.interaction.pressed', 'rgba(0,0,0,0.4)'),
});

const visibleStyles = css({
	visibility: 'visible',
});

const notVisibleStyles = css({
	visibility: 'hidden',
});

const verticalTrackStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	bottom: `${SCROLLBAR_OFFSET}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${SCROLLBAR_SIZE}px`,
	right: 0,
});

const horizontalTrackStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	right: `${SCROLLBAR_OFFSET}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${SCROLLBAR_SIZE}px`,
	bottom: 0,
});

const verticalThumbStyles = css({
	width: '100%',
});

const horizontalThumbStyles = css({
	height: '100%',
});

const HorizontalThumb = ({
	size,
	innerRef,
	children,
	...rest
}: {
	size: number;
	innerRef: React.MutableRefObject<HTMLDivElement>;
	onDragStart: (nativeEvent: React.SyntheticEvent) => void;
	onDrag: (nativeEvent: React.SyntheticEvent) => void;
	children?: React.ReactNode;
}) => (
	<div
		data-testid="roadmap.timeline-table.main.custom-scrollbars.thumb-tracks.horizontal-thumb"
		css={[baseThumbStyles, horizontalThumbStyles]}
		ref={innerRef}
		style={{
			width: `${size}px`,
		}}
		{...rest}
	>
		{children}
	</div>
);

const VerticalThumb = ({
	size,
	innerRef,
	children,
	...rest
}: {
	size: number;
	innerRef: React.MutableRefObject<HTMLDivElement>;
	onDragStart: (nativeEvent: React.SyntheticEvent) => void;
	onDrag: (nativeEvent: React.SyntheticEvent) => void;
	children?: React.ReactNode;
}) => (
	<div
		data-testid="roadmap.timeline-table.main.custom-scrollbars.thumb-tracks.vertical-thumb"
		css={[baseThumbStyles, verticalThumbStyles]}
		ref={innerRef}
		style={{
			height: `${size}px`,
		}}
		{...rest}
	>
		{children}
	</div>
);

const DraggableHorizontalThumb = withDragObserver(HorizontalThumb, THUMB_DRAG_THRESHOLD);
const DraggableVerticalThumb = withDragObserver(VerticalThumb, THUMB_DRAG_THRESHOLD);

type Props = {
	isHideScrollbar: boolean;
	listWidth: number;
	timelineTop: number;
	onSetScrollLeft: OnSetScroll;
	onSetScrollTop: OnSetScroll;
};

/* This component is responsible for displaying the custom track and thumb.
 *
 * Responding to user actions (e.g. clicking or dragging) are handled internally, while syncing with changes from external
 * sources (e.g. window resizes) are managed by the parent component via a custom ref. The track dimensions are not a 1:1
 * recreation of the real track, since it is only displayed over the timeline, and so all parameters from the parent are
 * "viewport relative" and translated internally to "track relative" coordinate system.

 */
const ScrollbarThumbTracks = (
	{ listWidth, isHideScrollbar, timelineTop, onSetScrollLeft, onSetScrollTop }: Props,
	ref: ForwardedRef<ImperativeRef>,
) => {
	const horizontalTrackRef = useRef<HTMLDivElement>(null);
	const horizontalThumbRef = useRef<HTMLDivElement>(null);

	const verticalTrackRef = useRef<HTMLDivElement>(null);
	const verticalThumbRef = useRef<HTMLDivElement>(null);

	const dragCache = useRef<DragCache>(DEFAULT_DRAG_CACHE);

	// State updates are not batched outside React synthetic events so we consolidate the data here
	const [{ horizontalThumbSize, verticalThumbSize }, setThumbSizes] = useState({
		horizontalThumbSize: 0,
		verticalThumbSize: 0,
	});

	// ========================= //
	// === IMPERATIVE EFFECT === //
	// ========================= //

	const setHorizontalThumbPosition = useCallback(
		(trackSize: number, scrollRatio: number, thumbSize: number) => {
			if (scrollRatio >= 0) {
				const position = getThumbPosition(scrollRatio, trackSize, thumbSize);

				if (horizontalThumbRef.current) {
					horizontalThumbRef.current.style.transform = `translateX(${position}px)`;
				}
			}
		},
		[],
	);

	const setVerticalThumbPosition = useCallback(
		(trackSize: number, scrollRatio: number, thumbSize: number) => {
			if (scrollRatio >= 0) {
				const position = getThumbPosition(scrollRatio, trackSize, thumbSize);

				if (verticalThumbRef.current) {
					verticalThumbRef.current.style.transform = `translateY(${position}px)`;
				}
			}
		},
		[],
	);

	useImperativeHandle(
		ref,
		() => ({
			setThumbPositions: ({ horizontalScrollRatio, verticalScrollRatio }) => {
				if (!horizontalTrackRef.current || !verticalTrackRef.current) {
					throw new Error('An element required to set the thumb positions were null');
				}

				// DOM reads //
				const { clientWidth } = horizontalTrackRef.current;
				const { clientHeight } = verticalTrackRef.current;

				// DOM writes //
				setHorizontalThumbPosition(clientWidth, horizontalScrollRatio, horizontalThumbSize);
				setVerticalThumbPosition(clientHeight, verticalScrollRatio, verticalThumbSize);
			},
			update: (
				{ horizontalScrollRatio, verticalScrollRatio },
				{ horizontalContentRatio, verticalContentRatio },
			) => {
				if (!horizontalTrackRef.current || !verticalTrackRef.current) {
					throw new Error('An element required to update the thumb tracks were null');
				}

				// DOM reads //
				const { clientWidth } = horizontalTrackRef.current;
				const { clientHeight } = verticalTrackRef.current;

				let hThumbSize = 0;
				let vThumbSize = 0;

				// DOM writes //
				if (horizontalContentRatio < 1) {
					hThumbSize = getThumbSize(horizontalContentRatio, clientWidth);
					setHorizontalThumbPosition(clientWidth, horizontalScrollRatio, hThumbSize);
				}

				if (verticalContentRatio < 1) {
					vThumbSize = getThumbSize(verticalContentRatio, clientHeight);
					setVerticalThumbPosition(clientHeight, verticalScrollRatio, vThumbSize);
				}

				setThumbSizes(getMemoisedThumbState(hThumbSize, vThumbSize));
			},
		}),
		[horizontalThumbSize, verticalThumbSize, setHorizontalThumbPosition, setVerticalThumbPosition],
	);

	// ===================================== //
	// ==== EVENT HANDLING - HORIZONTAL ==== //
	// ===================================== //

	const onStartDragHorizontalThumb: OnDragStart = ({ x }) => {
		if (!horizontalTrackRef.current || !horizontalThumbRef.current) {
			throw new Error('An element required to drag the horizontal scroll thumb was null');
		}

		const { left, width } = horizontalTrackRef.current.getBoundingClientRect();
		const thumbClientRect = horizontalThumbRef.current.getBoundingClientRect();
		const relativeMousePosition = x - thumbClientRect.left;

		dragCache.current = {
			thumbOrigin: Math.round(horizontalThumbSize / 2 - relativeMousePosition),
			trackPosition: left,
			trackSize: width,
		};
	};

	const onDragHorizontalThumb: OnDrag = (from, to) => {
		const { thumbOrigin, trackPosition, trackSize } = dragCache.current;

		const relativeScrollPosition = getRelativeScrollPosition({
			thumbPosition: thumbOrigin + to.x,
			thumbSize: horizontalThumbSize,
			trackPosition,
			trackSize,
		});

		onSetScrollLeft(relativeScrollPosition);
	};

	const onMouseDownHorizontalTrack = (event: MouseEvent<HTMLDivElement>) => {
		const { left, width } = event.currentTarget.getBoundingClientRect();

		const relativeScrollPosition = getRelativeScrollPosition({
			thumbPosition: event.pageX,
			thumbSize: horizontalThumbSize,
			trackPosition: left,
			trackSize: width,
		});

		onSetScrollLeft(relativeScrollPosition);
	};

	// =================================== //
	// ==== EVENT HANDLING - VERTICAL ==== //
	// =================================== //

	const onStartDragVerticalThumb: OnDragStart = ({ y }) => {
		if (!verticalTrackRef.current || !verticalThumbRef.current) {
			throw new Error('An element required to drag the vertical scroll thumb was null');
		}

		const { top, height } = verticalTrackRef.current.getBoundingClientRect();
		const thumbClientRect = verticalThumbRef.current.getBoundingClientRect();
		const relativeMousePosition = y - thumbClientRect.top;

		dragCache.current = {
			thumbOrigin: Math.round(verticalThumbSize / 2 - relativeMousePosition),
			trackPosition: top,
			trackSize: height,
		};
	};

	const onDragVerticalThumb: OnDrag = (from, to) => {
		const { thumbOrigin, trackPosition, trackSize } = dragCache.current;

		const relativeScrollPosition = getRelativeScrollPosition({
			thumbPosition: thumbOrigin + to.y,
			thumbSize: verticalThumbSize,
			trackPosition,
			trackSize,
		});

		onSetScrollTop(relativeScrollPosition);
	};

	const onMouseDownVerticalTrack = (event: MouseEvent<HTMLDivElement>) => {
		const { top, height } = event.currentTarget.getBoundingClientRect();

		const relativeScrollPosition = getRelativeScrollPosition({
			thumbPosition: event.pageY,
			thumbSize: verticalThumbSize,
			trackPosition: top,
			trackSize: height,
		});

		onSetScrollTop(relativeScrollPosition);
	};

	// ================ //
	// ==== RENDER ==== //
	// ================ //

	return (
		<>
			<div
				ref={horizontalTrackRef}
				data-testid="roadmap.timeline-table.main.custom-scrollbars.thumb-tracks.horizontal-track"
				css={[
					baseTrackStyles,
					horizontalThumbSize > 0 && !isHideScrollbar ? visibleStyles : notVisibleStyles,
					horizontalTrackStyles,
				]}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					left: `${listWidth + SCROLLBAR_OFFSET}px`,
				}}
				onMouseDown={onMouseDownHorizontalTrack}
			>
				<DraggableHorizontalThumb
					// TODO: Not sure how to type innerRef when it's not an styled component. Is it due to DraggableHorizontalThumb being a class component?
					// @ts-expect-error - TS2322 - Type '{ innerRef: MutableRefObject<unknown>; size: number; onDrag: OnDrag; onDragStart: OnDragStart; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<DragObserver> & Pick<Readonly<Props> & Readonly<...>, "children"> & Partial<...> & Partial<...>'.
					innerRef={horizontalThumbRef}
					size={horizontalThumbSize}
					onDrag={onDragHorizontalThumb}
					onDragStart={onStartDragHorizontalThumb}
				/>
			</div>
			<div
				ref={verticalTrackRef}
				data-testid="roadmap.timeline-table.main.custom-scrollbars.thumb-tracks.vertical-track"
				css={[
					baseTrackStyles,
					verticalThumbSize > 0 && !isHideScrollbar ? visibleStyles : notVisibleStyles,
					verticalTrackStyles,
				]}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					top: `${timelineTop + SCROLLBAR_OFFSET}px`,
				}}
				onMouseDown={onMouseDownVerticalTrack}
			>
				<DraggableVerticalThumb
					// TODO: Not sure how to type innerRef when it's not an styled component. Is it due to DraggableHorizontalThumb being a class component?
					// @ts-expect-error - TS2322 - Type '{ innerRef: MutableRefObject<unknown>; size: number; onDrag: OnDrag; onDragStart: OnDragStart; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<DragObserver> & Pick<Readonly<Props> & Readonly<...>, "children"> & Partial<...> & Partial<...>'.
					innerRef={verticalThumbRef}
					size={verticalThumbSize}
					onDrag={onDragVerticalThumb}
					onDragStart={onStartDragVerticalThumb}
				/>
			</div>
		</>
	);
};

export default forwardRef<ImperativeRef, Props>(ScrollbarThumbTracks);
