import { combineReducers } from 'redux';
import app from './app/reducer.tsx';
import configuration from './configuration/reducer.tsx';
import entities from './entities/reducer.tsx';
import metadata from './metadata/reducer.tsx';
import router from './router/reducer.tsx';
import settings from './settings/reducer.tsx';
import ui from './ui/reducer.tsx';

export default combineReducers({
	app,
	configuration,
	entities,
	ui,
	router,
	settings,
	metadata,
});
