import type { Store } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/mergeMap';
import { batchActions } from 'redux-batched-actions';
import { Observable } from 'rxjs/Observable';
import type { Engine, StorageConfiguration, GenericAction } from './types.tsx';

export const attachToStore = <State,>(
	store: Store<State>,
	engine: Engine,
	rootStorage: StorageConfiguration<State>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	waitUntil?: Observable<any>,
): Observable<Store<State>> => {
	const trigger = waitUntil || Observable.from([true]);

	let initialized = false;

	return trigger.first().mergeMap(() => {
		if (initialized) {
			return Observable.of(store);
		}

		initialized = true;

		const hydrationActions = rootStorage.hydrate(store.getState(), engine);

		if (hydrationActions !== undefined) {
			return hydrationActions.mergeMap((actions: GenericAction[]) => {
				store.dispatch(batchActions(actions));
				store.subscribe(() => {
					rootStorage.modify(store.getState(), engine);
				});
				return Observable.of(store);
			});
		}
		return Observable.of(store);
	});
};
