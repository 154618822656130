import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { isDependenciesVisible } from '../../state/selectors/filters/index.tsx';
import type { State } from '../../state/types.tsx';
import { setDependenciesViewVisibility } from '../../state/ui/views/actions.tsx';
import Flag from './view.tsx';

export default connect(
	(state: State) => ({
		isDependenciesVisible: isDependenciesVisible(state),
	}),
	{
		setShowDependencies: () => setDependenciesViewVisibility(true),
	},
)(Flag);
