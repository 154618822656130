/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { zIndex } from '../../../common/constants.tsx';
import { useItemsContainerStyles } from '../../../common/context/items/index.tsx';
import { useListWidth } from '../../../common/context/list/index.tsx';
import { getItemsBackgroundClassName } from '../../../common/context/side-effect-marshal/style-marshal/utils.tsx';

const container = css({
	display: 'flex',
});

const sticky = css({
	position: 'sticky',
	left: 0,
	pointerEvents: 'none',
});

/**
 * Use "100vw" as the width of the background rather than using the viewport dimension.
 * The accurate dimension does not matter here, as long as it covers the viewport.
 */
const widthApproximation = css({
	width: '100vw',
});

/* Generates a complex background that imitates the row structure.
 * This means that when scrolling through the virtual list, a user won't see just
 * empty space even when the browser is struggling to keep up row rendering.
 *
 * WARNING: When the area (w x h) of this background gets too large, it can cause
 * awful bottlenecks in the browser rendering. For that reason, we don't use the
 * full table width, but rather a width just large enough to cover the viewport.
 */
const ItemsBackground = () => {
	const [{ itemsBackground, totalHeight }] = useItemsContainerStyles();
	const [listWidth] = useListWidth();

	return (
		<div role="presentation" css={container} style={{ height: `${totalHeight}px` }}>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={getItemsBackgroundClassName().className}
				css={sticky}
				style={{
					background: itemsBackground,
					width: listWidth,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					zIndex: zIndex.LIST,
				}}
			/>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={getItemsBackgroundClassName().className}
				css={[sticky, widthApproximation]}
				style={{
					background: itemsBackground,
				}}
			/>
		</div>
	);
};

export { ItemsBackground };
