/** @jsx jsx */
import React, { memo, useEffect, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { B100, N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { zIndex, LAST, LIST_COLUMN_COORDINATE } from '../../../../../common/constants.tsx';
import { useEnablements } from '../../../../../common/context/enablements/index.tsx';
import { useIsTableEmpty } from '../../../../../common/context/items/index.tsx';
import { useListWidth } from '../../../../../common/context/list/index.tsx';
import { useGridCell } from '../../../../../common/context/side-effect-marshal/focus-marshal/use-grid-cell/index.tsx';
import { getListContentPaddingLeft } from '../../../../../common/styled/list.tsx';
import type { OnCreateClick } from '../../../../../common/types/callbacks.tsx';
import type { ItemId } from '../../../../../common/types/item.tsx';
import { fireUIAnalyticsEvent } from '../../../../../common/utils/analytics.tsx';
import { isRoot } from '../../../../../common/utils/hierarchy-enriched-items.tsx';
import type { RenderListItemCreate } from '../../../../../renderers/index.tsx';

const listCreateItemCellStylesOld = css({
	position: 'sticky',
	display: 'flex',
	alignItems: 'center',
	flex: '0 0 auto',
	boxSizing: 'border-box',
	left: 0,
	padding: `0 ${token('space.150', '12px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST_CREATE_ITEM,
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

const listCreateItemCellStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	position: 'sticky',
	flex: '0 0 auto',
	boxSizing: 'border-box',
	left: 'space.0',
	':focus': {
		outline: 'none',
	},
	':focus-visible': {
		outline: `2px solid ${token('color.border.focused', B100)}`,
	},
});

type Props = {
	id: ItemId;
	isActive: boolean;
	canCreateChildOverride: boolean;
	anchorId: ItemId | undefined;
	level: number;
	depth: number;
	itemHeight: number;
	backgroundColor: string;
	onCreateClick: OnCreateClick;
	renderListItemCreate: RenderListItemCreate;
};

const ListCreateItem = ({
	id,
	isActive,
	anchorId,
	level,
	depth,
	itemHeight,
	backgroundColor,
	onCreateClick,
	renderListItemCreate,
	canCreateChildOverride,
}: Props) => {
	let isMultiSelectEnabled: boolean | undefined;

	if (fg('project_timeline_multi-select_and_checkboxes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ isMultiSelectEnabled }] = useEnablements();
	}
	const [isListEmpty] = useIsTableEmpty();
	const [listWidth] = useListWidth();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cellRef = useGridCell(id, LIST_COLUMN_COORDINATE);

	// If there are no items, we want to promote users to create one.
	useEffect(() => {
		if (isListEmpty) {
			onCreateClick({ position: LAST });
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onClick = useCallback(() => {
		traceUFOPress('timeline-add-item-clicked');

		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'issueCreateButton',
			attributes: {
				position: LAST,
			},
		});

		fireUIAnalyticsEvent(analyticsEvent);

		onCreateClick({ position: LAST });
	}, [onCreateClick, createAnalyticsEvent]);

	const createItemBackgroundColor =
		isActive && isRoot(depth) ? token('elevation.surface.pressed', N40) : backgroundColor;

	const paddingLeftStyle = !isRoot(depth)
		? {
				paddingLeft: `${getListContentPaddingLeft({
					depth,
					...(fg('project_timeline_multi-select_and_checkboxes') && {
						hasCheckbox: isMultiSelectEnabled,
					}),
					isCreate: true,
					isParent: false,
				})}px`,
			}
		: {};

	return isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? (
		<Box
			ref={cellRef}
			role="gridcell"
			tabIndex={-1}
			paddingInline="space.050"
			paddingBlock="space.0"
			xcss={listCreateItemCellStyles}
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values
				zIndex: zIndex.LIST_CREATE_ITEM,
				width: `${listWidth}px`,
				height: `${itemHeight}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				...paddingLeftStyle,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				backgroundColor: createItemBackgroundColor,
			}}
		>
			{renderListItemCreate({ anchorId, level, onClick, canCreateChildOverride }, { isActive })}
		</Box>
	) : (
		<div
			ref={cellRef}
			role="gridcell"
			tabIndex={-1}
			css={listCreateItemCellStylesOld}
			style={{
				width: `${listWidth}px`,
				height: `${itemHeight}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				...paddingLeftStyle,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				backgroundColor: createItemBackgroundColor,
			}}
		>
			{renderListItemCreate({ anchorId, level, onClick, canCreateChildOverride }, { isActive })}
		</div>
	);
};

export default memo<Props>(ListCreateItem);
