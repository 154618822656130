import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/bufferWhen';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import without from 'lodash/without';
import { Observable } from 'rxjs/Observable';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import {
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_TRANSITIONED_EVENT,
	ISSUE_UPDATED_EVENT,
} from '@atlassian/jira-realtime/src/common/constants/events.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { removeIssues } from '../../../state/entities/issues/actions.tsx';
import { isIssueExists } from '../../../state/entities/issues/selectors.tsx';
import { removeSubtasks } from '../../../state/entities/subtasks/actions.tsx';
import {
	type RealtimeDispatchEventAction,
	REALTIME_DISPATCH_EVENT,
} from '../../../state/realtime/actions.tsx';
import { getSubtaskParentHash } from '../../../state/selectors/subtasks.tsx';
import type { State } from '../../../state/types.tsx';
import { isCurrentUser, isIssueEvent } from '../common/realtime.tsx';
import { reloadSubtaskProgress } from '../common/reload-subtask-progress.tsx';
import { reloadIssuesWithSequence } from '../common/reload-with-sequence.tsx';
import { getRealtimeBufferDuration, getMaxBufferSize, isBrowserTabVisible } from './utils.tsx';

const sendAnalyticEvent = (batchEventCount: number): void => {
	getAnalyticsWebClientPromise().then((client) => {
		const analyticsClient = client.getInstance();
		if (!analyticsClient) {
			return;
		}

		analyticsClient.sendTrackEvent({
			action: 'update',
			actionSubject: 'realtime',
			attributes: {
				batchEventCount,
			},
			source: 'roadmapRealtime',
		});
	});
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<RealtimeDispatchEventAction>,
	store: MiddlewareAPI<State>,
) => {
	let bufferCounter = 0;
	return action$
		.ofType(REALTIME_DISPATCH_EVENT)
		.filter(({ payload: { type, payload } }) => {
			if (!isIssueEvent(type, payload)) return false;

			const state = store.getState();
			const { atlassianId, issueId } = payload;

			if (isCurrentUser(state, atlassianId)) return false;

			return isIssueExists(state, String(issueId)) || type === ISSUE_CREATED_EVENT;
		})
		.do(() => {
			bufferCounter += 1;
		})
		.bufferWhen(() =>
			Observable.interval(getRealtimeBufferDuration()).filter(
				() => bufferCounter >= getMaxBufferSize() || isBrowserTabVisible(),
			),
		)
		.mergeMap((actions: RealtimeDispatchEventAction[]) => {
			bufferCounter = 0;

			if (actions.length === 0) {
				return Observable.empty<never>();
			}
			const subtaskParentHash = getSubtaskParentHash(store.getState());
			const issueIdsToReload: Array<IssueId> = [];
			const issueIdsToRemove: Array<IssueId> = [];

			const subtaskParentIdsToReload: IssueId[] = [];
			const subtaskIissueIdsToRemove: { id: IssueId; parentId: IssueId }[] = [];
			actions.forEach((action) => {
				if (action) {
					const {
						payload: { payload, type },
					} = action;

					if (!isIssueEvent(type, payload)) return;

					const { issueId } = payload;

					if (
						type === ISSUE_CREATED_EVENT ||
						type === ISSUE_TRANSITIONED_EVENT ||
						type === ISSUE_UPDATED_EVENT
					) {
						if (subtaskParentHash[issueId]) {
							subtaskParentIdsToReload.push(subtaskParentHash[issueId]);
						} else {
							issueIdsToReload.push(String(issueId));
						}
					} else if (type === ISSUE_DELETED_EVENT) {
						if (subtaskParentHash[issueId]) {
							subtaskIissueIdsToRemove.push({
								id: `${issueId}`,
								parentId: subtaskParentHash[issueId],
							});
						} else {
							issueIdsToRemove.push(String(issueId));
						}
					}
				}
			});

			const observablesToZip = [];

			if (issueIdsToRemove.length > 0) {
				observablesToZip.push(Observable.of(removeIssues(issueIdsToRemove)));
			}

			if (subtaskIissueIdsToRemove.length > 0) {
				observablesToZip.push(Observable.of(removeSubtasks(subtaskIissueIdsToRemove)));
			}

			// can disregard deleted issues
			// edge case when reload happens in one interval, and remove happens in another, need to handle errors when issue does not exist
			const remainingIssuesIdsToReload = without(issueIdsToReload, ...issueIdsToRemove);
			if (remainingIssuesIdsToReload.length > 0) {
				observablesToZip.push(reloadIssuesWithSequence(store, remainingIssuesIdsToReload));
				sendAnalyticEvent(remainingIssuesIdsToReload.length);
			}

			// disregard deleted subtasks
			const remainingSubtaskParentToReload = without(
				subtaskParentIdsToReload,
				...subtaskIissueIdsToRemove.map(({ parentId }) => parentId),
			);
			if (remainingSubtaskParentToReload.length > 0) {
				observablesToZip.push(reloadSubtaskProgress(store, remainingSubtaskParentToReload));
			}

			if (observablesToZip.length > 0) {
				return Observable.concat(...observablesToZip);
			}

			return Observable.empty<never>();
		});
};
