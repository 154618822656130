import type { RoadmapGoalHash } from '@atlassian/jira-software-roadmap-model/src/goals/index.tsx';
import { SET_GOALS, type SetGoalsAction } from './actions.tsx';
import type { GoalsState } from './types.tsx';

type Action = SetGoalsAction;

const NO_GOALS: RoadmapGoalHash = {};

const initialState: GoalsState = {
	isLoaded: false,
	hash: NO_GOALS,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: GoalsState = initialState, action: Action): GoalsState => {
	switch (action.type) {
		case SET_GOALS:
			return {
				isLoaded: true,
				hash: action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
