import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ListItemMenuOptionProps } from '@atlassian/jira-software-roadmap-timeline-table/src/renderers/index.tsx';
import type { EditSprintOptionParams } from '../../../../../model/list-item-menu-option/index.tsx';
import { getEditSprintOptionParams } from '../../../../../state/selectors/list-item-menu-option/index.tsx';
import type { State } from '../../../../../state/types.tsx';
import EditSprintOption from './view.tsx';

type OwnProps = {
	id: IssueId;
	Option: ListItemMenuOptionProps['Option'];
	setCloseMenu: ListItemMenuOptionProps['setCloseMenu'];
};

type StateProps = EditSprintOptionParams;

const mapStateToProps = (state: State, { id }: OwnProps): StateProps => {
	const editSprintOptionParams = getEditSprintOptionParams(state, id);

	return {
		...editSprintOptionParams,
	};
};

export default connect(mapStateToProps)(EditSprintOption);
