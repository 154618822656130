import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	isDependenciesVisibleInQuery,
	isProgressVisibleInQuery,
	isWarningsVisibleInQuery,
	isReleasesVisibleInQuery,
} from '../../../../state/router/selectors.tsx';
import { getRoadmapImageExportMetrics } from '../../../../state/selectors/analytics/index.tsx';
import type { State } from '../../../../state/types.tsx';
import ShareModal from './share-modal/async.tsx';
import ShareModalWithAnalytics, { type StateProps } from './view.tsx';

export default connect(
	(state: State): StateProps => ({
		ShareModal,
		isDependenciesVisible: isDependenciesVisibleInQuery(state),
		isProgressVisible: isProgressVisibleInQuery(state),
		isWarningsVisible: isWarningsVisibleInQuery(state),
		isReleasesVisible: isReleasesVisibleInQuery(state),
		imageExportMetrics: getRoadmapImageExportMetrics(state),
	}),
	{},
)(ShareModalWithAnalytics);
