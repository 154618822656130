import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IdentifiableHash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import count from '@atlassian/jira-software-roadmap-services/src/issues/count.tsx';
import {
	type RetrieveIssueTypeCountAction,
	RETRIEVE_ISSUE_TYPE_COUNT,
	extendIssueTypeCount,
} from '../../state/ui/healthcheck/actions.tsx';
import type { StateEpic } from '../common/types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<RetrieveIssueTypeCountAction>) =>
	action$.ofType(RETRIEVE_ISSUE_TYPE_COUNT).mergeMap((action: RetrieveIssueTypeCountAction) => {
		const { issueTypeId, projectId } = action.payload;
		return count(projectId, issueTypeId).map((issueCount) => {
			const newCounts: IdentifiableHash<IssueTypeId, number> = {};
			newCounts[issueTypeId] = issueCount;
			return extendIssueTypeCount(newCounts);
		});
	})) as StateEpic;
