import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	hasAdministerProjectPermission,
	getIsUserBoardAdmin,
} from '../../state/configuration/selectors.tsx';
import type { State } from '../../state/types.tsx';
import UnableToRankIssueFlag from './view.tsx';

type StateProps = {
	isAdmin: boolean;
};

export default connect(
	(state: State): StateProps => ({
		isAdmin: hasAdministerProjectPermission(state) || getIsUserBoardAdmin(state),
	}),
	{},
)(UnableToRankIssueFlag);
