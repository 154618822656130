import { combineStorage } from '@atlassian/jira-common-storage-redux/src/config.tsx';
import type { State } from '../../../state/types.tsx';
import dependenciesView from './dependencies-view.tsx';
import levelOneSettingView from './level-one-setting-view.tsx';
import progressView from './progress-view.tsx';
import releasesView from './releases-view.tsx';
import timelineMode from './timeline-mode.tsx';
import warningsView from './warnings-view.tsx';

export default combineStorage<State>(
	timelineMode,
	dependenciesView,
	progressView,
	warningsView,
	releasesView,
	levelOneSettingView,
);
