import { combineEpics } from 'redux-observable';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { StateEpic } from '../common/types.tsx';
import attemptRank from './attempt-rank.tsx';
import attemptRemove from './attempt-remove-issue.tsx';
import count from './count.tsx';
import create from './create/index.tsx';
import dependencies from './dependencies/index.tsx';
import expand from './expand/index.tsx';
import exportCsv from './export-csv/index.tsx';
import globalIssueCreate from './global-issue-create.tsx';
import hiddenFlag from './hidden-flag/index.tsx';
import optimisticUpdate from './optimistic-update/index.tsx';
import rank from './rank.tsx';
import realtime from './realtime/index.tsx';
import reload from './reload.tsx';
import scheduleIssues from './schedule-issues.tsx';
import prevSelect, { multiselect, select } from './select.tsx';
import updateAndRank from './update-and-rank.tsx';
import update from './update.tsx';

const issuesEpicsOld = (): StateEpic =>
	combineEpics(
		count,
		create,
		expand,
		globalIssueCreate,
		select,
		multiselect,
		// Remove with project_timeline_multi-select_and_checkboxes
		prevSelect,
		update,
		updateAndRank,
		reload,
		rank,
		attemptRank,
		dependencies,
		realtime,
		optimisticUpdate,
		scheduleIssues,
		attemptRemove,
		exportCsv,
	);

const issuesEpicsNew = (): StateEpic =>
	combineEpics(
		count,
		create,
		expand,
		globalIssueCreate,
		hiddenFlag,
		select,
		multiselect,
		// Remove with project_timeline_multi-select_and_checkboxes
		prevSelect,
		update,
		updateAndRank,
		reload,
		rank,
		attemptRank,
		dependencies,
		realtime,
		optimisticUpdate,
		scheduleIssues,
		attemptRemove,
		exportCsv,
	);

export default functionWithCondition(
	() => fg('jsw-roadmap-state-change-based-issue-hidden-flags'),
	issuesEpicsNew(),
	issuesEpicsOld(),
);
