import { createSelector } from 'reselect';
import { convertStringToQuery } from '../../utils/router.tsx';
import { getRoadmapType } from '../app/selectors.tsx';
import { getGoalsByKeyHash } from '../entities/goals/selectors.tsx';
import type { State } from '../types.tsx';

export const getLocation = (state: State) => state.router.location;

export const getLocationUrl = createSelector(
	getLocation,
	(location) => `${location.pathname}${location.search}${location.hash}`,
);

export const getQuery = createSelector(getLocation, getRoadmapType, (location, roadmapType) =>
	convertStringToQuery(location.search.replace(/^\?/, ''), roadmapType),
);

export const getStatus = createSelector(getQuery, (query) => query.statuses || []);
export const getAssignee = createSelector(getQuery, (query) => query.assignee || []);
export const getIssueType = createSelector(getQuery, (query) => query.issueType || []);

export const getLabels = createSelector(getQuery, (query) => query.label || []);

export const getIssueParents = createSelector(getQuery, (query) => query.issueParent || []);

export const getVersions = createSelector(getQuery, (query) => query.version || []);

export const getGoalAris = createSelector(getQuery, getGoalsByKeyHash, (query, goals) =>
	(query.goal || []).map((ari) => goals[ari]?.goal?.id).filter((id) => id !== undefined),
);

export const isGoalFilterActive = createSelector(getGoalAris, (goals) => goals.length > 0);

export const getHighlightedVersionsInQuery = createSelector(
	getQuery,
	(query) => query.highlightedVersions || [],
);

export const getComponents = createSelector(getQuery, (query) => query.component || []);

export const getQuickFilters = createSelector(getQuery, (query) => query.quickFilter || []);

export const getCustomFilters = createSelector(getQuery, (query) => query.customFilter || []);

export const getQueryContainsJQLFilters = createSelector(
	getQuickFilters,
	getCustomFilters,
	(quickFilters, customFilters) => customFilters.length > 0 || quickFilters.length > 0,
);

export const getRapidViewId = createSelector(getQuery, (query) => query.rapidView);

export const getSelectedIssueKey = createSelector(getQuery, (query) => query.selectedIssue);

export const isDependenciesVisibleInQuery = createSelector(
	getQuery,
	(query) => !query.hideDependencies,
);

export const isProgressVisibleInQuery = createSelector(getQuery, (query) => !query.hideProgress);

export const isWarningsVisibleInQuery = createSelector(getQuery, (query) => !query.hideWarnings);

export const isReleasesVisibleInQuery = createSelector(
	getQuery,
	(query) => !query.hideVersionHeader,
);

export const isShared = createSelector(getQuery, (query) => query.shared);

export const getEpicSettingInQuery = createSelector(getQuery, (query) => query.epic);
