import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IdentifiableHash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type {
	Healthcheck,
	HealthcheckIssueType,
} from '@atlassian/jira-software-roadmap-model/src/healthcheck/index.tsx';
import type { State } from '../../types.tsx';

export const getIssueCounts = (state: State): IdentifiableHash<IssueTypeId, number> =>
	state.ui.healthcheck.issueTypeCounts;
export const getHealthcheckIssueTypes = (
	state: State,
): IdentifiableHash<IssueTypeId, HealthcheckIssueType> | undefined =>
	state.ui.healthcheck.issueTypes;
export const isResolving = (state: State): boolean => state.ui.healthcheck.isResolving;

export const getHealthcheck = (state: State): Healthcheck | undefined =>
	state.ui.healthcheck.current;
export const getHasHealthcheck = (state: State): boolean =>
	state.ui.healthcheck.current !== undefined;
