import type { Dispatch } from 'redux';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	TEXT,
	ASSIGNEE,
	LABEL,
	ISSUE_PARENT,
	ISSUE_TYPE,
	STATUS_CATEGORY,
	VERSION,
	COMPONENT,
	QUICK_FILTER,
	CUSTOM_FILTER,
	GOAL,
} from '@atlassian/jira-software-filters/src/common/constants.tsx';
import type { Filters, FilterType } from '@atlassian/jira-software-filters/src/common/types.tsx';
import { getBoardId, isClassic as getIsClassic } from '../../../state/app/selectors.tsx';
import {
	type DeriveFieldsRequestAction,
	deriveFieldsRequest,
} from '../../../state/configuration/actions.tsx';
import {
	getLevelOneName,
	getProjectId,
	getProjectName,
	getProjectKey,
	hasAdministerProjectPermission,
	getCanInviteOthersToProject,
} from '../../../state/configuration/selectors.tsx';
import { getGoals } from '../../../state/entities/goals/selectors.tsx';
import { getComponents } from '../../../state/selectors/components/index.tsx';
import { getCustomFilters } from '../../../state/selectors/custom-filters.tsx';
import { getAssignees } from '../../../state/selectors/header/filters/assignee/selectors.tsx';
import { getIssueParents } from '../../../state/selectors/header/filters/issue-parent/selectors.tsx';
import { getIssueTypes } from '../../../state/selectors/header/filters/issue-type-filter/selectors.tsx';
import { getLabels } from '../../../state/selectors/header/filters/label/selectors.tsx';
import { getStatusCategories } from '../../../state/selectors/header/filters/status/selectors.tsx';
import { getQuickFilters } from '../../../state/selectors/quick-filters.tsx';
import { getVersionsForFilter } from '../../../state/selectors/versions/index.tsx';
import type { State } from '../../../state/types.tsx';
import {
	clearFilter,
	type ClearFilterAction,
	setTextFilter,
	type SetTextFilterAction,
	setLabelFilter,
	type SetLabelFilterAction,
	setIssueParentFilter,
	type SetIssueParentFilterAction,
	setAssigneeFilter,
	type SetAssigneeFilterAction,
	setIssueTypeFilter,
	type SetIssueTypeFilterAction,
	setStatusCategoryFilter,
	type SetStatusCategoryFilterAction,
	setVersionFilter,
	type SetVersionFilterAction,
	setComponentFilter,
	type SetComponentFilterAction,
	setQuickFiltersFilter,
	type SetQuickFiltersFilterAction,
	quickFiltersRequest,
	type QuickFiltersRequestAction,
	clearQuickFilters,
	type QuickFiltersClearAction,
	setCustomFilter,
	type SetCustomFilterAction,
	customFiltersRequest,
	type CustomFiltersRequestAction,
	setGoalFilter,
	type SetGoalFilterAction,
} from '../../../state/ui/filters/actions.tsx';
import { getIsJQLFilterLoading } from '../../../state/ui/filters/selectors.tsx';
import AddPeopleModal from './app-people-modal/index.tsx';
import { getFilters } from './selectors.tsx';
import FilterBar, { type APIProps } from './view.tsx';

type Action =
	| ClearFilterAction
	| SetTextFilterAction
	| SetAssigneeFilterAction
	| SetLabelFilterAction
	| SetIssueParentFilterAction
	| SetIssueTypeFilterAction
	| SetStatusCategoryFilterAction
	| SetVersionFilterAction
	| SetComponentFilterAction
	| SetQuickFiltersFilterAction
	| QuickFiltersRequestAction
	| QuickFiltersClearAction
	| SetCustomFilterAction
	| CustomFiltersRequestAction
	| DeriveFieldsRequestAction
	| SetGoalFilterAction;
type OwnProps = {
	isMacroView: boolean;
};

type DispatchProps = {
	onClearAllButtonClicked: () => void;
	onChange: (filters: Filters, filterType: FilterType | null) => void;
};
type StateProps = APIProps;

const EMPTY_ARRAY: Array<string> = [];

const shouldShowAddPeopleButton = (state: State) => {
	const isCMP = getIsClassic(state);
	const canAdministerProject = hasAdministerProjectPermission(state);

	if (isCMP) {
		return canAdministerProject;
	}

	const canInviteOthersToProject = getCanInviteOthersToProject(state);

	return canAdministerProject || canInviteOthersToProject;
};

const mapStateToProps = (state: State, { isMacroView }: OwnProps): StateProps => ({
	issueTypes: getIssueTypes(state),
	assignees: getAssignees(state),
	labels: getLabels(state),
	issueParents: getIssueParents(state),
	issueParentsLabel: getLevelOneName(state),
	statusCategories: getStatusCategories(state),
	AddPeopleModal: isMacroView ? undefined : AddPeopleModal,
	filter: getFilters(state),
	boardId: getBoardId(state),
	projectId: parseInt(getProjectId(state), 10),
	projectName: getProjectName(state),
	projectKey: getProjectKey(state),
	versions: getVersionsForFilter(state),
	components: getComponents(state),
	customFilters: getCustomFilters(state),
	quickFilters: getQuickFilters(state),
	goals: getGoals(state),
	isAddPeopleButtonEnabled: !expValEquals(
		'open_invite_for_open_tmp_projects',
		'cohort',
		'experiment',
	)
		? hasAdministerProjectPermission(state) // Existing permission check
		: // Permission check based on project type and access setting
			shouldShowAddPeopleButton(state),
	isCustomFilterManageLinkEnabled: hasAdministerProjectPermission(state),
	isVersionFilterSpotlightEnabled: hasAdministerProjectPermission(state),
	isCustomFiltersLoading: getIsJQLFilterLoading(state),
	isCMP: getIsClassic(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
	onClearAllButtonClicked: () => {
		dispatch(clearFilter());
		dispatch(clearQuickFilters());
		dispatch(deriveFieldsRequest());
	},
	onChange: (filters: Filters, filterType: FilterType | null) => {
		if (filterType === TEXT) {
			const textValue = filters[TEXT] || '';
			dispatch(setTextFilter(textValue));
		} else if (filterType === ASSIGNEE) {
			const assigneeValue = filters[ASSIGNEE] || EMPTY_ARRAY;
			dispatch(setAssigneeFilter(assigneeValue));
		} else if (filterType === LABEL) {
			const labelValue = filters[LABEL] || EMPTY_ARRAY;
			dispatch(setLabelFilter(labelValue));
		} else if (filterType === ISSUE_PARENT) {
			const issueParentValue = filters[ISSUE_PARENT] || EMPTY_ARRAY;
			dispatch(setIssueParentFilter(issueParentValue));
		} else if (filterType === ISSUE_TYPE) {
			const issueTypeValue = filters[ISSUE_TYPE] || EMPTY_ARRAY;
			dispatch(setIssueTypeFilter(issueTypeValue));
		} else if (filterType === STATUS_CATEGORY) {
			const statusValue = filters[STATUS_CATEGORY] || EMPTY_ARRAY;
			dispatch(setStatusCategoryFilter(statusValue));
		} else if (filterType === VERSION) {
			const versionValue = filters[VERSION] || EMPTY_ARRAY;
			dispatch(setVersionFilter(versionValue));
		} else if (filterType === COMPONENT) {
			const componentValue = filters[COMPONENT] || EMPTY_ARRAY;
			dispatch(setComponentFilter(componentValue));
		} else if (filterType === GOAL) {
			const goalValue = filters[GOAL] || EMPTY_ARRAY;
			dispatch(setGoalFilter(goalValue));
		} else if (filterType === QUICK_FILTER) {
			const quickFilterValue = filters[QUICK_FILTER] || EMPTY_ARRAY;
			dispatch(setQuickFiltersFilter(quickFilterValue));
			dispatch(quickFiltersRequest(quickFilterValue));
			dispatch(deriveFieldsRequest());
		} else if (filterType === CUSTOM_FILTER) {
			const customFilterValue = filters[CUSTOM_FILTER] || EMPTY_ARRAY;
			dispatch(setCustomFilter(customFilterValue));
			dispatch(customFiltersRequest(customFilterValue));
			dispatch(deriveFieldsRequest());
		}
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
