/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { H700MCompiled } from '@atlassian/jira-common-components-skeleton/src/text.tsx';
import {
	DEFAULT_IMAGE_WIDTH,
	DEFAULT_IMAGE_HEIGHT,
	LARGE_IMAGE_WIDTH,
	LARGE_IMAGE_HEIGHT,
	Content,
} from '../common/utils.tsx';

const DEFAULT_IMAGE_SIZE = 'default';
const LARGE_IMAGE_SIZE = 'large';

type Props = {
	// Used to match the two types of image sizes in a real healthcheck
	imageSize: typeof DEFAULT_IMAGE_SIZE | typeof LARGE_IMAGE_SIZE;
	// Used to approximate the varying paragraph sizes in a real healthcheck
	paragraphHeight: number;
};

const headerStyles = css({
	width: '80%',
});

const paragraphSkeletonStyles = css({
	width: '95%',
	marginTop: token('space.250', '20px'),
});

const HealthcheckSkeleton = ({ imageSize, paragraphHeight }: Props) => {
	const imgWidth = imageSize === DEFAULT_IMAGE_SIZE ? DEFAULT_IMAGE_WIDTH : LARGE_IMAGE_WIDTH;
	const imgHeight = imageSize === DEFAULT_IMAGE_SIZE ? DEFAULT_IMAGE_HEIGHT : LARGE_IMAGE_HEIGHT;

	return (
		<Content>
			<SkeletonCompiled
				style={{
					width: `${imgWidth}px`,
					height: `${imgHeight}px`,
				}}
			/>
			<div css={headerStyles} />
			<H700MCompiled css={headerStyles} />
			<SkeletonCompiled
				css={paragraphSkeletonStyles}
				style={{
					height: `${paragraphHeight}px`,
				}}
			/>
		</Content>
	);
};

export default HealthcheckSkeleton;
