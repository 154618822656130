// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentType, ComponentProps } from 'react';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getSourceARI } from '../../../state/app/selectors.tsx';
import { getRoadmapAri } from '../../../state/selectors/share.tsx';
import type { State } from '../../../state/types.tsx';
import { exportCsv } from '../../../state/ui/share/actions.tsx';
import ShareModalWithAnalytics from './share-modal-with-analytics/index.tsx';
import type ShareModal from './share-modal-with-analytics/share-modal/async.tsx';
import Share from './view.tsx';

type StateProps = {
	ShareModalWithAnalytics: ComponentType<ComponentProps<typeof ShareModal>>;
	roadmapAri: string;
};

export default connect(
	(state: State): StateProps => ({
		ShareModalWithAnalytics,
		roadmapAri: getSourceARI(state) || getRoadmapAri(state),
	}),
	{
		onExportCsv: exportCsv,
	},
)(Share);
