import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src/config.tsx';
import type { State } from '../../../state/types.tsx';
import { setProgressVisibility } from '../../../state/ui/views/actions.tsx';
import { isProgressVisibleInView } from '../../../state/ui/views/selectors.tsx';

export default defineSimpleStorage<State, boolean | null>(
	() => 'isProgressVisible',
	(isVisible: boolean | null) => (isVisible === null ? [] : [setProgressVisibility(isVisible)]),
	(state) => isProgressVisibleInView(state),
);
