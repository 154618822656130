import { createSelector } from 'reselect';
import type { Version } from '@atlassian/jira-filters/src/common/types.tsx';
import type { VersionId } from '@atlassian/jira-shared-types/src/general.tsx';
import { createSelectorWithDefaultEqual } from '../../../common/utils/reselect/index.tsx';
import { getFullVersionHash, getIsReleasesFeatureEnabled } from '../../configuration/selectors.tsx';
import { getIssueVersionIdsHash } from '../../entities/issues/selectors.tsx';
import {
	isReleasesVisibleInQuery,
	getHighlightedVersionsInQuery,
} from '../../router/selectors.tsx';
import { getHighlightedVersionsInSettings } from '../../settings/selectors.tsx';
import { isReleasesVisibleInView } from '../../ui/views/selectors.tsx';
import {
	createGetSanitisedIssueVersionIdsHash as createGetSanitisedIssueVersionIdsHashPure,
	createGetUniqueVersions as createGetUniqueVersionsPure,
	createGetVersionsForFilter as createGetVersionsForFilterPure,
	createGetVersionsForKeyDates as createGetVersionsForKeyDatesPure,
} from './pure/index.tsx';

export const getSanitisedIssueVersionIdsHash = createSelector(
	getIssueVersionIdsHash,
	getFullVersionHash,
	createGetSanitisedIssueVersionIdsHashPure,
);

export const getUniqueVersions = createSelector(
	getSanitisedIssueVersionIdsHash,
	createGetUniqueVersionsPure,
);

export const getVersionsForFilter = createSelectorWithDefaultEqual(
	getUniqueVersions,
	getFullVersionHash,
	createGetVersionsForFilterPure,
);

export const getVersionIdsForFilter = createSelector(
	getVersionsForFilter,
	(versionsForFilter: Version[]): string[] => versionsForFilter.map(({ id }: Version) => id),
);

export const createGetVersionsForKeyDates = createSelector(
	getFullVersionHash,
	createGetVersionsForKeyDatesPure,
);

export const getIsReleasesVisible = createSelector(
	getIsReleasesFeatureEnabled,
	isReleasesVisibleInQuery,
	isReleasesVisibleInView,
	(isReleaseFeatureEnable: boolean, isVisibleInQuery: boolean, isVisibleInView: boolean) =>
		!isReleaseFeatureEnable || !isVisibleInQuery ? false : isVisibleInView,
);

const EMPTY_ARRAY: Array<VersionId> = [];

export const getHighlightedVersions = createSelector(
	getHighlightedVersionsInQuery,
	getHighlightedVersionsInSettings,
	getIsReleasesVisible,
	(settingFromQuery, settingFromStore, isReleasesVisible): VersionId[] => {
		if (!isReleasesVisible) {
			return EMPTY_ARRAY;
		}
		return settingFromQuery.length > 0 ? settingFromQuery : settingFromStore;
	},
);

export const getHighlightedKeyDates = createSelector(
	getHighlightedVersions,
	(highlightedVersions: VersionId[]) => highlightedVersions.map((version) => version.toString()),
);
