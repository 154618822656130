import 'rxjs/add/operator/map';
import 'rxjs/add/observable/fromPromise';
import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import roadmapFilterConfigurationConcreteQuery, {
	type roadmapFilterConfigurationQuery,
} from '@atlassian/jira-relay/src/__generated__/roadmapFilterConfigurationQuery.graphql';
import { generateFilterConfigurationQueryVariables } from '@atlassian/jira-software-roadmap-data-queries/src/queries/roadmap-filter-configuration/index.tsx';
import type { FilterConfiguration } from '@atlassian/jira-software-roadmap-model/src/configuration/index.tsx';
import { FILTER_CONFIGURATION } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runQueryWithMetadata } from '../common/agg/run-query.tsx';
import { queryMetrics } from '../performance-analytics/index.tsx';
import { transformFilterConfiguration } from './transformers.tsx';
import type { GraphQLFilterConfiguration } from './types.tsx';

const analyticKey = 'jira.frontend.fe.software.roadmap.filter-configuration';
const concurrentMetricDefinition = queryMetrics[FILTER_CONFIGURATION];

export const getFilterConfiguration = (sourceAri: Ari): Observable<FilterConfiguration> =>
	runQueryWithMetadata<roadmapFilterConfigurationQuery>(
		roadmapFilterConfigurationConcreteQuery,
		generateFilterConfigurationQueryVariables(sourceAri),
		analyticKey,
		concurrentMetricDefinition,
	).map(({ data }) => {
		const filterConfig: GraphQLFilterConfiguration = {
			quickFilters: data.roadmaps?.roadmapFilterConfiguration?.quickFilters ?? [],
			customFilters: data.roadmaps?.roadmapFilterConfiguration?.customFilters ?? [],
		};

		return transformFilterConfiguration(filterConfig);
	});
