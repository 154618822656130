import type { Parent } from '@atlassian/jira-filters/src/common/types.tsx';
import type { IssueId, Hash } from '@atlassian/jira-shared-types/src/general.tsx';
import { createSelectorWithDefaultEqual } from '../../../../../common/utils/reselect/index.tsx';
import { getIssueParentIds, getIssueSummaryHash } from '../../../../entities/issues/selectors.tsx';

export const getIssueParents = createSelectorWithDefaultEqual(
	getIssueParentIds,
	getIssueSummaryHash,
	(uniqueIssueParentIds: IssueId[], issueSummaryHash: Hash<string>): Parent[] =>
		uniqueIssueParentIds.map((parentId) => ({
			id: parentId,
			name: issueSummaryHash[parentId],
		})),
);
