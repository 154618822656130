import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'roadmap.standard-roadmap.flags.sprint-scope-change.title',
		defaultMessage: 'Active sprint scope has changed',
		description: 'Flag title. Informs user that they have changed the scope of the active sprint.',
	},
	descriptionAssign: {
		id: 'roadmap.standard-roadmap.flags.sprint-scope-change.description-assign',
		defaultMessage:
			'<b>{issueKey}</b> has been assigned to <b>{changedSprintName}</b>, which will affect its scope.',
		description:
			'Flag description. Explains in more detail why the scope of the active sprint has changed.',
	},
	descriptionUnassign: {
		id: 'roadmap.standard-roadmap.flags.sprint-scope-change.description-unassign',
		defaultMessage:
			'<b>{issueKey}</b> has been unassigned from <b>{changedSprintName}</b>, which will affect its scope.',
		description:
			'Flag description. Explains in more detail why the scope of the active sprint has changed.',
	},
	undo: {
		id: 'roadmap.standard-roadmap.flags.sprint-scope-change.undo',
		defaultMessage: 'Undo',
		description: 'Flag action. Undoes the change.',
	},
	descriptionAssignIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.sprint-scope-change.description-assign-issue-term-refresh',
		defaultMessage:
			'<b>{issueKey}</b> has been assigned to <b>{changedSprintName}</b>, which will affect its scope.',
		description:
			'Flag description. Explains in more detail why the scope of the active sprint has changed.',
	},
	descriptionUnassignIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.sprint-scope-change.description-unassign-issue-term-refresh',
		defaultMessage:
			'<b>{issueKey}</b> has been unassigned from <b>{changedSprintName}</b>, which will affect its scope.',
		description:
			'Flag description. Explains in more detail why the scope of the active sprint has changed.',
	},
});
