import type { ClientBounding } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/viewport/common/types.tsx';
import { EXPLICIT } from '../../../constants/date.tsx';
import { LEFT, RIGHT, LEFT_AND_RIGHT } from '../../../constants/index.tsx';
import type { Placeholder, BarDragType } from '../../../types/chart-item.tsx';
import type { DateType } from '../../../types/date.tsx';
import { isStartDatePlaceholder, isDueDatePlaceholder } from '../../../utils/bar/placeholder.tsx';

const APPROX_DATE_PIXELS = 85;
const APPROX_PLACEHOLDER_PIXELS = 98;
const APPROX_ICON_PIXELS = 20;
const APPOX_DURATION_PIXELS = 33;

const add = (accumulator: number, nextValue: number): number => accumulator + nextValue;

/* When hovering a bar, it's dates may be obscured by the scroll container of the chart.
 * If possible, we would want to show both dates within the viewport. Rather than measure
 * the text we make an approximation since we can make assumptions about the font family,
 * font size and number of characters.
 */
export const calculateDateVisibilities = (
	barRect: ClientRect,
	viewportRect: ClientBounding,
	placeholder: Placeholder,
	startDateType: DateType,
	dueDateType: DateType,
	isDurationVisible: boolean,
): {
	isStartDateVisible: boolean;
	isDueDateVisible: boolean;
} => {
	const { left: barLeft, right: barRight } = barRect;
	const { left: viewportLeft, right: viewportRight } = viewportRect;
	const availableLeftSpace = barLeft - viewportLeft;
	const availableRightSpace = viewportRight - barRight;

	const hasStartDateIcon = startDateType !== EXPLICIT;
	const hasDueDateIcon = dueDateType !== EXPLICIT;

	const spaceRequiredLeft = [
		isStartDatePlaceholder(placeholder) ? APPROX_PLACEHOLDER_PIXELS : APPROX_DATE_PIXELS,
		hasStartDateIcon ? APPROX_ICON_PIXELS : 0,
	];

	const spaceRequiredRight = [
		isDueDatePlaceholder(placeholder) ? APPROX_PLACEHOLDER_PIXELS : APPROX_DATE_PIXELS,
		hasDueDateIcon ? APPROX_ICON_PIXELS : 0,
		isDurationVisible ? APPOX_DURATION_PIXELS : 0,
	];

	return {
		isStartDateVisible: spaceRequiredLeft.reduce(add, 0) <= availableLeftSpace,
		isDueDateVisible: spaceRequiredRight.reduce(add, 0) <= availableRightSpace,
	};
};

/* Determines where to place the dates relative to the bar (left, right or both).
 *
 * Treat no visible dates like both visible dates. It is unlikely, but possible
 * that a user is hovering the bar and scrolls until it is in the viewport. This
 * should be a better experience than seeing nothing.
 */
export const getDatePlacement = (
	dragType: BarDragType | undefined,
	isStartDateVisible: boolean,
	isDueDateVisible: boolean,
) => {
	if (
		dragType === LEFT_AND_RIGHT ||
		(isStartDateVisible && isDueDateVisible) ||
		(!isStartDateVisible && !isDueDateVisible)
	) {
		return LEFT_AND_RIGHT;
	}

	return isStartDateVisible ? LEFT : RIGHT;
};
