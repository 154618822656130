import { createSelector } from 'reselect';
import pickBy from 'lodash/pickBy';
import {
	TEXT,
	ASSIGNEE,
	LABEL,
	ISSUE_PARENT,
	ISSUE_TYPE,
	STATUS_CATEGORY,
	VERSION,
	COMPONENT,
	QUICK_FILTER,
	CUSTOM_FILTER,
	GOAL,
} from '@atlassian/jira-software-filters/src/common/constants.tsx';
import { getIssuesFilter } from '../../../state/selectors/filters/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNotEmpty = (a: any[]) => a.length !== 0;
/**
 * Get Filters values for filter-bar
 */
export const getFilters = createSelector(
	getIssuesFilter,
	({
		assignees,
		issueTypes,
		statuses,
		text,
		labels,
		issueParents,
		versions,
		components,
		quickFilters,
		customFilters,
		goals,
	}) => {
		const filters = {
			[TEXT]: text,
			[ASSIGNEE]: assignees,
			[LABEL]: labels,
			[ISSUE_PARENT]: issueParents,
			[ISSUE_TYPE]: issueTypes,
			[STATUS_CATEGORY]: statuses,
			[VERSION]: versions,
			[COMPONENT]: components,
			[QUICK_FILTER]: quickFilters,
			[CUSTOM_FILTER]: customFilters,
			[GOAL]: goals,
		};

		return pickBy(filters, isNotEmpty);
	},
);
