import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Hash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import { PARENT_LEVEL } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import { getChartDateData } from '../../common/chart-dates/index.tsx';
import { getParentLevelDates } from '../../common/index.tsx';
import type { RolledUpDates } from '../../dates/index.tsx';
import type { ChartData, DateError } from '../common/types.tsx';

export const getParentLevelChartData = (
	issueColorHash: Hash<Color>,
	issueStartDateHash: Hash<number | undefined>,
	issueDueDateHash: Hash<number | undefined>,
	parentRolledUpDatesHash: Hash<RolledUpDates>,
	issueDateErrorsHash: Hash<DateError[]>,
	issueId: IssueId,
	isChildIssuePlanningEnabled: boolean,
): ChartData => {
	const explicitStartDate = issueStartDateHash[issueId];
	const explicitDueDate = issueDueDateHash[issueId];

	const {
		startDate: parentLevelStartDate,
		dueDate: parentLevelDueDate,
		startDateType,
		dueDateType,
		placeholder,
	} = getParentLevelDates(
		explicitStartDate,
		explicitDueDate,
		parentRolledUpDatesHash[issueId],
		isChildIssuePlanningEnabled,
	);
	const color = issueColorHash[issueId];

	const { startDate, dueDate } = getChartDateData(
		parentLevelStartDate,
		parentLevelDueDate,
		PARENT_LEVEL,
		isChildIssuePlanningEnabled,
	);

	return {
		startDateType,
		dueDateType,
		placeholder,
		color,
		startDate,
		dueDate,
		warnings: issueDateErrorsHash[issueId],
		level: PARENT_LEVEL,
	};
};
