import React, { Component } from 'react';
import type { Store } from 'redux';
import { styled } from '@compiled/react';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import Spinner from '@atlaskit/spinner';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import AppStyle from '@atlassian/jira-common-components-app-style/src/index.tsx';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { disposableEpic } from '@atlassian/jira-common-redux-disposable/src/controllers/disposable-epic/index.tsx';
import { DISPOSE_ACTION } from '@atlassian/jira-common-redux-disposable/src/index.tsx';
import { attachToStore } from '@atlassian/jira-common-storage-redux/src/redux.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { FlagsDispatcher } from '@atlassian/jira-flags';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	ROADMAPS,
	RESOURCES_CACHE_UPDATER_SOURCE,
	RESOURCES_CACHE_UPDATER_SOURCE_KEY,
} from '@atlassian/jira-software-resource-invalidator/src/common/types.tsx';
import { triggerCacheRefreshAnalytics } from '@atlassian/jira-software-resource-invalidator/src/controllers/resources-cache-refresh-analytics-subject/index.tsx';
import ResourcesCacheWrapper from '@atlassian/jira-software-resource-invalidator/src/ui/resources-cache-wrapper/index.tsx';
import { NEXTGEN, CLASSIC } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import type { CriticalData } from '@atlassian/jira-software-roadmap-services/src/initialize/types.tsx';
import { type RouterActionPush, type Location, withRouter } from '@atlassian/react-resource-router';
import { toRoadmapAppId, ROADMAP_PACKAGE_NAME } from './constants.tsx';
import { flagsMapper } from './flags/index.tsx';
import type { AppProps } from './model/app/index.tsx';
import createRootEpic from './ops/index.tsx';
import rootStorageConfig from './ops/storage.tsx';
import {
	initializeAppProps,
	initializeCriticalData,
	updateCriticalData,
} from './state/app/actions.tsx';
import createStore from './state/index.tsx';
import { routerLocationChange } from './state/router/actions.tsx';
import type { State } from './state/types.tsx';
import { engine as userPropertiesEngine } from './storage/engine/user-properties-engine.tsx';
import {
	StandardView,
	ClassicStandardView,
	ClassicStandardMacro,
	StandardMacro,
} from './view/index.tsx';

export type Props = {
	readonly isServer: boolean;
} & AppProps & {
		readonly criticalData: CriticalData;
		location: Location;
		push: RouterActionPush;
		readonly onUnmount: () => void;
		locale?: Locale;
		isSiteAdmin?: boolean;
	};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const isDevelopment = window.BUILD_KEY === 'JF-TEST';

// eslint-disable-next-line jira/react/no-class-components
class StandardRoadmap extends Component<Props> {
	static defaultProps: Partial<Props> = {
		isServer: false,
		isReadOnly: false,
		cloudId: undefined,
		activationId: undefined,
		macroConfig: undefined,
		type: NEXTGEN,
		onUnmount: noop,
	};

	constructor(props: Props) {
		super(props);

		const { criticalData } = props;
		this.initialiseStore(criticalData);
	}

	componentDidMount() {
		const { criticalData } = this.props;
		if (criticalData) {
			this.initialiseUserPropertiesEngine();
		}
	}

	componentDidUpdate(prevProps: Props) {
		// Update stale data from cache with latest critical-data
		if (
			this.store &&
			!isEqual(prevProps.criticalData, this.props.criticalData) &&
			this.props.criticalData &&
			// Ignore critical-data updates if it is from resources cache updater
			(!(RESOURCES_CACHE_UPDATER_SOURCE_KEY in this.props.criticalData) ||
				this.props.criticalData[RESOURCES_CACHE_UPDATER_SOURCE_KEY] !==
					RESOURCES_CACHE_UPDATER_SOURCE)
		) {
			triggerCacheRefreshAnalytics(ROADMAPS);
			this.store.dispatch(updateCriticalData(this.props.criticalData));
		}
	}

	componentWillUnmount() {
		if (this.store && this.store.dispatch) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			this.store.dispatch(DISPOSE_ACTION as any);
		}
		this.props.onUnmount();
	}

	initialiseStore(criticalData: CriticalData) {
		const { push, location, type } = this.props;
		const rootEpic = createRootEpic(type);
		this.store = createStore(type, disposableEpic(rootEpic), {
			router: { push },
		});

		this.store.dispatch(initializeAppProps(this.getAppProps()));
		this.store.dispatch(initializeCriticalData(criticalData));
		this.store.dispatch(routerLocationChange(location));
	}

	initialiseUserPropertiesEngine() {
		const { criticalData, boardId, sourceAri, loggedInAccountId, macroConfig } = this.props;

		// hydration
		const settings = criticalData.settings || {};
		const userPropertyKey = `roadmap${macroConfig ? '-macro' : ''}`;
		if (this.store) {
			attachToStore(
				this.store,
				userPropertiesEngine(
					userPropertyKey,
					boardId,
					sourceAri,
					() => loggedInAccountId,
					settings,
				),
				rootStorageConfig,
			).subscribe();
		}
	}

	getAppProps(): AppProps {
		const {
			isReadOnly,
			boardId,
			sourceAri,
			loggedInAccountId,
			cloudId,
			activationId,
			macroConfig,
			type,
			containerName,
		} = this.props;

		return {
			isReadOnly,
			boardId,
			sourceAri,
			loggedInAccountId,
			cloudId,
			activationId,
			macroConfig,
			containerName,
			type,
		};
	}

	store!: Store<State>;

	getRoadmapView() {
		const { macroConfig, type } = this.props;
		if (type === CLASSIC && !macroConfig) {
			return ClassicStandardView;
		}
		if (type === CLASSIC && macroConfig) {
			return ClassicStandardMacro;
		}
		if (macroConfig) {
			return StandardMacro;
		}

		return StandardView;
	}

	render() {
		const { isServer, macroConfig, type, criticalData } = this.props;

		if (!criticalData) {
			return (
				<SpinnerWrapper>
					<Spinner size="large" />
				</SpinnerWrapper>
			);
		}

		const RoadmapView = this.getRoadmapView();

		return (
			<AppBase
				id={toRoadmapAppId(type)}
				store={this.store}
				teamName="chronos"
				packageName="@atlassian/jira-software-roadmap-standard"
			>
				<ReportErrors
					id={toRoadmapAppId(type)}
					packageName={ROADMAP_PACKAGE_NAME}
					attributes={{
						isDevelopment,
					}}
				>
					{getWillShowNav4() ? (
						<>
							<RoadmapView isServer={isServer} isMacroView={Boolean(macroConfig)} />
							{type !== CLASSIC && <ResourcesCacheWrapper origin={ROADMAPS} />}
							<FlagsDispatcher mapper={flagsMapper} />
						</>
					) : (
						<AppStyle>
							<RoadmapView isServer={isServer} isMacroView={Boolean(macroConfig)} />
							{type !== CLASSIC && <ResourcesCacheWrapper origin={ROADMAPS} />}
							<FlagsDispatcher mapper={flagsMapper} />
						</AppStyle>
					)}
				</ReportErrors>
			</AppBase>
		);
	}
}

export default withRouter(StandardRoadmap);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	ROADMAP_CRITICAL_DATA_SUCCEEDED,
	ROADMAP_CRITICAL_DATA_FAILED,
	ROADMAP_RESOURCE_DATA_FAILED,
	TTRSLO,
	TTISLO,
	NOT_ENROLLED,
	CONTROL,
	EXPERIMENT,
} from './constants';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	display: 'flex',
	width: '85%',
	height: '100%',
	position: 'absolute',
	alignItems: 'center',
	justifyContent: 'center',
});
