import React, {
	memo,
	useMemo,
	useCallback,
	type Dispatch,
	type SetStateAction,
	type MouseEvent,
	type KeyboardEvent,
} from 'react';
import { useIntl } from 'react-intl-next';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { fg } from '@atlassian/jira-feature-gating';
import { listItemMenu, ITEM_TYPE } from '../../../../../../../../common/constants.tsx';
import { useItems } from '../../../../../../../../common/context/items/index.tsx';
import { useListItemMenuOptions } from '../../../../../../../../common/context/list/index.tsx';
import type { ListItemOptionId } from '../../../../../../../../common/types/item.tsx';
import { ListItemMenuOption } from '../../../../list-item-menu-option/index.tsx';
import type { MoveItemMenuProps } from '../../../types.tsx';
import { renderOverrideLabel } from '../../../utils.tsx';
import messages from './messages.tsx';

type Option = {
	id: ListItemOptionId;
	onClick: (event: KeyboardEvent | MouseEvent) => void;
	interactionName?: string;
	isDisabled: boolean;
};
const MoveItemMenu = ({
	id,
	level,
	parentId,
	onDrop,
	setCloseMenu,
	setInitialFocusRef,
}: MoveItemMenuProps) => {
	const [items] = useItems();
	const { formatMessage } = useIntl();
	const [{ overrideLabels }] = useListItemMenuOptions();
	const siblingItemIds = useMemo(() => {
		const siblingIds: string[] = [];
		items.forEach((item) => {
			const { type, meta } = item;
			if (type === ITEM_TYPE) {
				if (meta.parentId === parentId) {
					siblingIds.push(item.item.id);
				}
			}
		});
		return siblingIds;
	}, [items, parentId]);
	const siblingItemsLength = siblingItemIds.length;

	const onMoveUp = useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			const currentIndex = siblingItemIds.findIndex((itemId) => itemId === id);
			if (currentIndex > 0) {
				if (!fg('platform_button_item-add-ufo-metrics')) {
					traceUFOPress('timeline-move-up-option-clicked', event.timeStamp);
				}
				onDrop(id, {
					level,
					rankRequest: { rankBeforeId: siblingItemIds[currentIndex - 1] },
					isMeatballsAction: true,
				});
			}
		},
		[id, level, siblingItemIds, onDrop],
	);

	const onMoveDown = useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			const currentIndex = siblingItemIds.findIndex((itemId) => itemId === id);
			if (currentIndex < siblingItemsLength - 1) {
				if (!fg('platform_button_item-add-ufo-metrics')) {
					traceUFOPress('timeline-move-down-option-clicked', event.timeStamp);
				}
				onDrop(id, {
					level,
					rankRequest: { rankAfterId: siblingItemIds[currentIndex + 1] },
					isMeatballsAction: true,
				});
			}
		},
		[siblingItemIds, siblingItemsLength, id, onDrop, level],
	);

	const onMoveToTop = useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			if (siblingItemsLength > 0) {
				if (!fg('platform_button_item-add-ufo-metrics')) {
					traceUFOPress('timeline-move-to-top-option-clicked', event.timeStamp);
				}
				onDrop(id, {
					level,
					rankRequest: { rankBeforeId: siblingItemIds[0] },
					isMeatballsAction: true,
				});
			}
		},
		[siblingItemsLength, onDrop, id, level, siblingItemIds],
	);

	const onMoveToBottom = useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			if (siblingItemsLength > 0) {
				if (!fg('platform_button_item-add-ufo-metrics')) {
					traceUFOPress('timeline-move-to-bottom-option-clicked', event.timeStamp);
				}
				onDrop(id, {
					level,
					rankRequest: { rankAfterId: siblingItemIds[siblingItemsLength - 1] },
					isMeatballsAction: true,
				});
			}
		},
		[onDrop, id, level, siblingItemIds, siblingItemsLength],
	);

	const moveItemChildOptions = useMemo(() => {
		const isMoveUpDisabled = !siblingItemsLength || id === siblingItemIds?.[0];
		const isMoveDownDisabled = !siblingItemsLength || id === siblingItemIds[siblingItemsLength - 1];
		const options: Option[] = [
			{
				id: listItemMenu.MOVE_ITEM_UP,
				onClick: onMoveUp,
				isDisabled: isMoveUpDisabled,
				...(fg('platform_button_item-add-ufo-metrics')
					? { interactionName: 'timeline-move-up-option-clicked' }
					: {}),
			},
			{
				id: listItemMenu.MOVE_ITEM_DOWN,
				onClick: onMoveDown,
				isDisabled: isMoveDownDisabled,
				...(fg('platform_button_item-add-ufo-metrics')
					? { interactionName: 'timeline-move-down-option-clicked' }
					: {}),
			},
			{
				id: listItemMenu.MOVE_ITEM_TO_TOP,
				onClick: onMoveToTop,
				isDisabled: isMoveUpDisabled,
				...(fg('platform_button_item-add-ufo-metrics')
					? { interactionName: 'timeline-move-to-top-option-clicked' }
					: {}),
			},
			{
				id: listItemMenu.MOVE_ITEM_TO_BOTTOM,
				onClick: onMoveToBottom,
				isDisabled: isMoveDownDisabled,
				...(fg('platform_button_item-add-ufo-metrics')
					? { interactionName: 'timeline-move-to-bottom-option-clicked' }
					: {}),
			},
		];
		return options;
	}, [id, onMoveDown, onMoveToBottom, onMoveToTop, onMoveUp, siblingItemIds, siblingItemsLength]);

	const firstEnabledOptionIndex = moveItemChildOptions.findIndex((option) => !option.isDisabled);

	const renderMoveItemChildOptions = useCallback(
		(
			{ id: optionId, onClick, isDisabled, interactionName }: Option,
			index: number,
			setChildFocusRef: Dispatch<SetStateAction<HTMLElement | null>>,
		) => {
			const handleClick = (e: MouseEvent | KeyboardEvent) => {
				onClick(e);
				setCloseMenu(e);
			};

			return (
				<ListItemMenuOption
					key={optionId}
					label={
						renderOverrideLabel(formatMessage, overrideLabels[optionId]) ||
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						formatMessage(messages[optionId as keyof typeof messages])
					}
					setInitialFocusRef={firstEnabledOptionIndex === index ? setChildFocusRef : undefined}
					isDisabled={isDisabled}
					{...(fg('platform_button_item-add-ufo-metrics') ? { interactionName } : {})}
					onClick={handleClick}
				/>
			);
		},
		[firstEnabledOptionIndex, formatMessage, overrideLabels, setCloseMenu],
	);

	return (
		<ListItemMenuOption
			setInitialFocusRef={setInitialFocusRef}
			isDisabled={siblingItemsLength <= 1}
			label={
				renderOverrideLabel(formatMessage, overrideLabels[listItemMenu.MOVE_ITEM]) ||
				formatMessage(messages.moveItem)
			}
			{...(fg('platform_button_item-add-ufo-metrics')
				? { interactionName: 'timeline-move-primary-option-clicked' }
				: {})}
		>
			{({ setInitialFocusRef: setChildFocusRef }) => (
				<>
					{moveItemChildOptions.map((option, index) =>
						renderMoveItemChildOptions(option, index, setChildFocusRef),
					)}
				</>
			)}
		</ListItemMenuOption>
	);
};

export default memo<MoveItemMenuProps>(MoveItemMenu);
