import noop from 'lodash/noop';
import memoizeOne from 'memoize-one';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { RealtimeEvent } from '@atlassian/jira-realtime/src/common/types/events.tsx';
import Realtime from '@atlassian/jira-realtime/src/main.tsx';
import { ROADMAP_APP_ID } from '../../constants.tsx';
import { REALTIME_EVENTS } from '../../model/realtime/constants.tsx';
import { getCloudId } from '../../state/app/selectors.tsx';
import { getProjectId } from '../../state/configuration/selectors.tsx';
import { dispatchRealtimeEvent } from '../../state/realtime/actions.tsx';
import { getRealtimeChannels } from '../../state/realtime/selectors.tsx';
import type { State } from '../../state/types.tsx';

type StateProps = {
	cloudId: string;
	projectId: string;
	channels: string[];
};

const mapStateToProps = (state: State) => ({
	cloudId: getCloudId(state) || '',
	projectId: getProjectId(state),
	channels: getRealtimeChannels(state),
});

const mapDispatchToProps = {
	dispatchRealtimeEvent,
} as const;

type DispatchProps = typeof mapDispatchToProps;
const onReceiveFactory = memoizeOne(
	(dispatchPropsDispatchRealtimeEvent: DispatchProps['dispatchRealtimeEvent']) =>
		(event: RealtimeEvent) => {
			if (event) {
				dispatchPropsDispatchRealtimeEvent(event);
			}
		},
);

export const mergeProps = (stateProps: StateProps, dispatchProps: typeof mapDispatchToProps) => ({
	appId: ROADMAP_APP_ID,
	channels: stateProps.channels,
	events: REALTIME_EVENTS,
	onJoin: noop,
	onReceive: onReceiveFactory(dispatchProps.dispatchRealtimeEvent),
	onLeave: noop,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Realtime);
