import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	progressMetadataTooltip: {
		id: 'roadmap.timeline-table-kit.list-item-content.summary.progress-indicator.progress-metadata-tooltip',
		defaultMessage: '{completed} of {total} child issues complete',
		description:
			'Tooltip around an icon indicating how many issues are complete out of total child issues',
	},
	progressMetadataTooltipIssueTermRefresh: {
		id: 'roadmap.timeline-table-kit.list-item-content.summary.progress-indicator.progress-metadata-tooltip-issue-term-refresh',
		defaultMessage: '{completed} of {total} child work items complete',
		description:
			'Tooltip around an icon indicating how many issues are complete out of total child issues',
	},
});
