import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type {
	START_DATE_LATER_DUE_DATE_MESSAGE_ID,
	END_DATE_LATER_RELEASE_DATE_MESSAGE_ID,
	PARENT_STARTS_AFTER_CHILD_MESSAGE_ID,
	PARENT_ENDS_BEFORE_CHILD_MESSAGE_ID,
	PARENT_STARTS_AFTER_CHILD_AND_ENDS_BEFORE_CHILD_MESSAGE_ID,
	SPRINT_ENDS_BEFORE_CHILD_MESSAGE_ID,
} from '../../../../constants.tsx';

type MessageId =
	| typeof START_DATE_LATER_DUE_DATE_MESSAGE_ID
	| typeof END_DATE_LATER_RELEASE_DATE_MESSAGE_ID
	| typeof PARENT_STARTS_AFTER_CHILD_MESSAGE_ID
	| typeof PARENT_ENDS_BEFORE_CHILD_MESSAGE_ID
	| typeof PARENT_STARTS_AFTER_CHILD_AND_ENDS_BEFORE_CHILD_MESSAGE_ID
	| typeof SPRINT_ENDS_BEFORE_CHILD_MESSAGE_ID;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default defineMessages({
	startDateLaterDueDate: {
		id: 'roadmap.standard-roadmap.state.selectors.table.bar-warnings.start-date-later-due-date',
		defaultMessage: 'Start date must be before Due date.',
		description:
			'Heading text. Gives context for a bar that has incorrect start date and due date. Shown on a warning tooltip when user hovers on the warning icon of a bar which has the start date after the due date.',
	},
	endDateLaterReleasesDate: {
		id: 'roadmap.standard-roadmap.state.selectors.table.bar-warnings.end-date-later-releases-date',
		defaultMessage: "This issue is scheduled to end after the release to which it's been assigned.",
		description:
			'Heading text. Gives context for a bar that has incorrect end date. Shown on a warning tooltip when user hovers on the warning icon of a bar which has the end date after its release date.',
	},
	parentStartsAfterChild: {
		id: 'roadmap.standard-roadmap.state.selectors.table.bar-warnings.parent-starts-after-child',
		defaultMessage: 'This parent issue begins after the start date of a child issue.',
		description:
			"Heading text. Gives context for a bar that has incorrect start date and due date. Shown on a warning tooltip when user hovers on the warning icon of a bar which has the start date after the children's combined start date.",
	},
	parentEndsBeforeChild: {
		id: 'roadmap.standard-roadmap.state.selectors.table.bar-warnings.parent-ends-before-child',
		defaultMessage: 'This parent issue ends before its child issues are scheduled to complete.',
		description:
			"Heading text. Gives context for a bar that has incorrect start date and due date. Shown on a warning tooltip when user hovers on the warning icon of a bar which has the due date before the children's combined due date.",
	},
	parentStartsAfterChildAndEndsBeforeChild: {
		id: 'roadmap.standard-roadmap.state.selectors.table.bar-warnings.parent-starts-after-child-and-ends-before-child',
		defaultMessage: 'Child issues begin and end outside the dates set for this parent issue.',
		description:
			"Heading text. Gives context for a bar that has incorrect start date and due date. Shown on a warning tooltip when user hovers on the warning icon of a bar which has both the start date and due date that lies inside of the children's combined start date and due date.",
	},
	sprintEndsBeforeChild: {
		id: 'roadmap.standard-roadmap.state.selectors.table.bar-warnings.sprint-ends-before-child',
		defaultMessage: "This issue is scheduled to end after the sprint to which it's been assigned.",
		description:
			"Heading text. Gives context for a bar that has incorrect start date and due date. Shown on a warning tooltip when user hovers on the warning icon of a bar which has the due date before the sprint's due date.",
	},
}) as {
	[key in MessageId]: MessageDescriptor;
};
