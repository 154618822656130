import includes from 'lodash/includes';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import type { AggErrors } from '@atlassian/jira-software-roadmap-services/src/common/types.tsx';
import { aggError, type AggErrorAction } from '../../state/flags/actions.tsx';
import { JQL_VALIDATION_FAILED } from './constants.tsx';

export const onHandleAggErrors = (
	isDataValid: boolean,
	errors?: AggErrors | null,
): Observable<AggErrorAction> => {
	if (!isDataValid) {
		return Observable.of(aggError());
	}
	if (errors && errors.length) {
		const errorTypes = errors.reduce(
			(allErrorTypes: string[], error) =>
				error && error.extensions && error.extensions.errorType
					? allErrorTypes.concat([error.extensions.errorType])
					: allErrorTypes,
			[],
		);

		// Check if it's a JQL validation error
		if (errorTypes.length > 0 && includes(errorTypes, JQL_VALIDATION_FAILED)) {
			return Observable.of(aggError({ errorType: JQL_VALIDATION_FAILED, description: undefined }));
		}

		const errorMessage = `${errors
			.reduce(
				(errorMsg, error) =>
					error && error.message !== undefined && error.message !== null
						? `${errorMsg}${error.message} `
						: '',
				'',
			)
			.trim()}`;

		if (errorMessage.length) {
			const formattedErrorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
			return Observable.of(aggError({ description: formattedErrorMessage, errorType: undefined }));
		}
	}
	return Observable.of(aggError());
};
