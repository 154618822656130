/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@compiled/react';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useTimelineState } from '../../../../common/context/timeline/index.tsx';
import { itemTransition } from '../../../../common/styled/timeline.tsx';
import type { OnCreateClick } from '../../../../common/types/callbacks.tsx';
import type { ItemId } from '../../../../common/types/item.tsx';
import { isRoot } from '../../../../common/utils/hierarchy-enriched-items.tsx';
import type { RenderListItemCreate } from '../../../../renderers/index.tsx';
import ListCreateItem from './list-create-item/index.tsx';

type Props = {
	id: ItemId;
	isActive: boolean;
	level: number;
	canCreateChildOverride: boolean;
	depth: number;
	anchorId: ItemId | undefined;
	itemHeight: number;
	backgroundColor: string;
	ariaRowIndex: number | undefined;
	topOffset: number;
	renderListItemCreate: RenderListItemCreate;
	onCreateClick: OnCreateClick;
};

const itemBaseStyles = css({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	transition: itemTransition,
});

const containerStyles = css({
	position: 'absolute',
	width: '100%',
});

const chartCreateItemStyles = css({
	position: 'relative',
	flex: '0 0 auto',
});

const CreateItem = ({
	id,
	level,
	depth,
	anchorId,
	canCreateChildOverride,
	itemHeight,
	topOffset,
	ariaRowIndex,
	backgroundColor,
	isActive,
	onCreateClick,
	renderListItemCreate,
}: Props) => {
	const [{ timelineWidth }] = useTimelineState();

	const createItemBackgroundColor =
		isActive && isRoot(depth) ? token('elevation.surface.pressed', N40) : backgroundColor;

	return (
		<div
			data-testid="roadmap.timeline-table.main.content.list.create-item.button"
			role="row"
			aria-rowindex={ariaRowIndex}
			css={[itemBaseStyles, containerStyles]}
			style={{
				top: `${topOffset}px`,
				height: `${itemHeight}px`,
			}}
		>
			<ListCreateItem
				id={id}
				isActive={isActive}
				anchorId={anchorId}
				canCreateChildOverride={canCreateChildOverride}
				level={level}
				depth={depth}
				itemHeight={itemHeight}
				backgroundColor={backgroundColor}
				onCreateClick={onCreateClick}
				renderListItemCreate={renderListItemCreate}
			/>
			<div
				css={chartCreateItemStyles}
				style={{
					width: `${timelineWidth}px`,
					height: `${itemHeight}px`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					backgroundColor: createItemBackgroundColor,
				}}
			/>
		</div>
	);
};

export default memo<Props>(CreateItem);
