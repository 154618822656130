import type { State } from '../../types.tsx';

export const getCreateItemAnchor = (state: State) => state.ui.table.createItemAnchor;

export const getExpandedItems = (state: State) => state.ui.table.expandedItems;

export const getSelectedItemIds = (state: State) => state.ui.table.selectedItemIds;

export const getSelectionAnchors = (state: State) => state.ui.table.selectionAnchors;

export const getTooltipParams = (state: State) => state.ui.table.tooltip;

export const getActiveItemRef = (state: State) => state.ui.table.activeItemRef;
