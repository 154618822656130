/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { B100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import {
	CHART_COLUMN_COORDINATE,
	DEFAULT_HEADER_ROW_ID,
} from '../../../../../../common/constants.tsx';
import { useGridCell } from '../../../../../../common/context/side-effect-marshal/focus-marshal/use-grid-cell/index.tsx';

const wrapperStyles = css({
	position: 'absolute',
	top: token('space.0', '0'),
	bottom: token('space.0', '0'),
	flex: '0 0 auto',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	boxSizing: 'border-box',
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

type Props = {
	left: number;
	right: number;
	column: number;
	children: ReactNode;
};

export const CalendarCellWrapper = ({ left, right, column, children }: Props) => {
	const cellRef = useGridCell(DEFAULT_HEADER_ROW_ID, CHART_COLUMN_COORDINATE + column);

	return (
		<div
			ref={cellRef}
			role="columnheader"
			tabIndex={-1}
			css={wrapperStyles}
			style={{
				left: `${left}%`,
				right: `${right}%`,
			}}
		>
			{children}
		</div>
	);
};
