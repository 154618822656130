import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import {
	isExpandedObservationData,
	type ObservationData,
	type LongTasksSloSettings,
} from '../types.tsx';
import { generateLongTaskAnalyticsSummaryWithSlo } from './common.tsx';

export const scrollReporterWithSlo =
	(sloSettings?: LongTasksSloSettings) =>
	(
		observationData: ObservationData,
		extraProps: {
			[key: string]: boolean;
		},
	) => {
		if (!isExpandedObservationData(observationData) || sloSettings === undefined) {
			return;
		}

		const startTime = observationData.events.map((event) => event.timestamp);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const analyticsData: any = {
			...extraProps,
			...generateLongTaskAnalyticsSummaryWithSlo('scroll', observationData.events, sloSettings),
			type: observationData.type || 'unknown',
			measureTimespan: performance.now() - Math.min(observationData.startTimestamp, ...startTime),
		};

		const attributes = {
			...extraProps,
			type: observationData.type || 'unknown',
			isSuccess: analyticsData.scrollLongTaskSloIsSuccess,
			...sloSettings,
		};

		getAnalyticsWebClientPromise().then((client) => {
			const payload = {
				source: observationData.appName,
				action: 'complete',
				actionSubject: 'scroll operation',
				attributes,
			};

			client.getInstance().sendOperationalEvent(payload);
		});
	};
