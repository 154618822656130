import {
	BLUE,
	GREEN,
	GREY,
	ORANGE,
	PURPLE,
	TEAL,
	YELLOW,
	type Color,
} from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { MINIMUM_BAR_LENGTH } from '../../../../common/constants/chart-item.tsx';
import { LEFT_AND_RIGHT, LEFT, RIGHT } from '../../../../common/constants/index.tsx';
import type { BarDragType, DatesUpdate } from '../../../../common/types/chart-item.tsx';

// Anything bar below the minimum length should be extended to allow for users to still interact with it
export const getMinimumPositions = (
	leftPosition: number,
	rightPosition: number,
	timelineWidth: number,
) => {
	const barLength = timelineWidth - rightPosition - leftPosition;

	if (barLength < MINIMUM_BAR_LENGTH) {
		const minimumOffset = (MINIMUM_BAR_LENGTH - barLength) / 2;
		return {
			minimumLeft: leftPosition - minimumOffset,
			minimumRight: rightPosition - minimumOffset,
		};
	}

	return { minimumLeft: undefined, minimumRight: undefined };
};

export const getUpdatedDates = (startDate?: number, dueDate?: number, dragType?: BarDragType) => {
	if (dragType === LEFT) {
		return {
			startDate,
		};
	}
	if (dragType === RIGHT) {
		return {
			dueDate,
		};
	}
	if (dragType === LEFT_AND_RIGHT) {
		return {
			startDate,
			dueDate,
		};
	}
	return {};
};

export const getAnalyticsType = (updatedDates: Partial<DatesUpdate>): string => {
	const { startDate, dueDate } = updatedDates;
	if (startDate && !dueDate) {
		return 'resizeLeft';
	}
	if (!startDate && dueDate) {
		return 'resizeRight';
	}
	return 'moveBar';
};

const lightColors: Color[] = [PURPLE, BLUE, GREEN, TEAL, YELLOW, ORANGE, GREY];

export const shouldInvertColor = (color: Color) => {
	return lightColors.includes(color);
};
