import React, { useEffect } from 'react';
import noop from 'lodash/noop';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { JQL_VALIDATION_FAILED } from '../../ops/common/constants.tsx';
import messages from './messages.tsx';

type Props = {
	id: string | number;
	description: string | undefined;
	onDismissed: (id: string | number) => void;
	errorType: string | undefined;
};

const AggErrorFlag = ({ id, onDismissed, description, errorType }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		fireTrackAnalytics(
			createAnalyticsEvent({ action: 'viewed', actionSubject: 'roadmapErrorFlag' }),
			'viewed roadmapErrorFlag',
			{
				cause: 'AGG',
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDismissedCallback = () => {
		onDismissed(id);
	};

	const getDescription = () => {
		if (errorType === JQL_VALIDATION_FAILED) {
			return formatMessage(messages.jqlValidationErrorDescription);
		}
		return description !== undefined ? description : formatMessage(messages.description);
	};

	return (
		<ErrorFlag
			onDismissed={onDismissedCallback}
			id={id}
			title={formatMessage(messages.title)}
			description={getDescription()}
		/>
	);
};

AggErrorFlag.defaultProps = {
	description: undefined,
	onDismissed: noop,
};

export default AggErrorFlag;
