import React, { useCallback } from 'react';
import type { Interval } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/interval.tsx';
import { RoadmapIntervals } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-header-item/intervals/index.tsx';
import RoadmapSprintFlyoutAsync from './flyout/async.tsx';

type Props = {
	intervals: ReadonlyArray<Interval>;
};

const RoadmapIntervalsHeader = ({ intervals }: Props) => {
	const renderPopupContent = useCallback(
		(
			selectedIntervals: ReadonlyArray<Interval>,
			setInitialFocusRef?: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
		) => (
			<RoadmapSprintFlyoutAsync
				intervals={selectedIntervals}
				setInitialFocusRef={setInitialFocusRef}
			/>
		),
		[],
	);

	return <RoadmapIntervals intervals={intervals} renderPopupContent={renderPopupContent} />;
};

export default RoadmapIntervalsHeader;
