import React from 'react';
import { ContextualAnalyticsData, MODAL } from '@atlassian/jira-product-analytics-bridge';
import Flag, { type Props } from './view.tsx';

const FlagWithAnalytics = (props: Props) => (
	<ContextualAnalyticsData sourceName="standardRoadmapFlags" sourceType={MODAL}>
		<Flag {...props} />
	</ContextualAnalyticsData>
);

export default FlagWithAnalytics;
