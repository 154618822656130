import React, { memo, useEffect } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { PARENT_LEVEL } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import messages from './messages.tsx';
import {
	ReparentMenuWrapperAsync,
	ReparentMenuWrapperLazy,
} from './reparent-menu-wrapper/async.tsx';
import type { Props } from './types.tsx';

const ReparentMenuOption = memo(({ id, issueLevel, Option: MenuOption, setCloseMenu }: Props) => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		ReparentMenuWrapperLazy.preload();
	}, []);

	return (
		<MenuOption
			label={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.changeParentIssueIssueTermRefresh
					: messages.changeParentIssue,
			)}
			isDisabled={issueLevel === PARENT_LEVEL}
			{...(fg('platform_button_item-add-ufo-metrics')
				? { interactionName: 'timeline-reparent-primary-option-clicked' }
				: {})}
		>
			{({ setInitialFocusRef }) => (
				<ReparentMenuWrapperAsync
					id={id}
					setInitialFocusRef={setInitialFocusRef}
					setCloseMenu={setCloseMenu}
				/>
			)}
		</MenuOption>
	);
});

export default ReparentMenuOption;
