import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { withRouter } from '@atlassian/react-resource-router';
import { routerLocationChange } from '../../state/router/actions.tsx';
import { getLocation as getLocationFromStore } from '../../state/router/selectors.tsx';
import type { State } from '../../state/types.tsx';
import RouterUpdater from './view.tsx';

export default connect(
	(state: State) => ({
		locationFromStore: getLocationFromStore(state),
	}),
	{
		onLocationChanged: routerLocationChange,
	},
)(withRouter(RouterUpdater));
