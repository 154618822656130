import type { VersionId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IdentifiableHash } from '../common/index.tsx';

export const RELEASED = 'RELEASED' as const;
export const UNRELEASED = 'UNRELEASED' as const;

export type VersionStatusType = typeof RELEASED | typeof UNRELEASED;

export type VersionWithStatus = {
	name: string;
	status: VersionStatusType;
	releaseDate?: number | undefined;
};

export type VersionsHash = IdentifiableHash<VersionId, VersionWithStatus>;
