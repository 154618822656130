import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src/config.tsx';
import type { State } from '../../../state/types.tsx';
import { setReleasesVisibility } from '../../../state/ui/views/actions.tsx';
import { isReleasesVisibleInView } from '../../../state/ui/views/selectors.tsx';

export default defineSimpleStorage<State, boolean | null>(
	() => 'isReleasesVisible',
	(isVisible: boolean | null) => (isVisible === null ? [] : [setReleasesVisibility(isVisible)]),
	(state) => isReleasesVisibleInView(state),
);
