import React, { type ReactNode, useState, useMemo } from 'react';
import { PortalScopeContext, type PortalScopeProps } from '../context/index.tsx';

interface Props {
	children: ReactNode;
}

const TablePortalProvider = (props: Props) => {
	const [portalContainerElement, setPortalContainerElement] = useState<Element | null>(null);
	const portalState = useMemo<PortalScopeProps>(
		() => ({
			setPortalContainerElement,
			portalContainerElement,
		}),
		[portalContainerElement],
	);

	return (
		<PortalScopeContext.Provider value={portalState}>{props.children}</PortalScopeContext.Provider>
	);
};

export default TablePortalProvider;
