import { to1DP } from '../../../common/utils.tsx';

/* We can assert that *both* the left and right drag positions are already on the grid.
 * But because we can't directly compare floating point numbers, we can sanely use 1DP as a proxy.
 * That is, if the difference between the left and right is 0.1, we accept it as just a rounding error.
 */
export const isValidDrag = (
	leftPosition: number,
	rightPosition: number,
	gridSize: number,
	timelineWidth: number,
) => {
	const distance = Math.abs(timelineWidth - rightPosition - leftPosition);
	return to1DP(distance) >= to1DP(gridSize);
};
