import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src/config.tsx';
import type { VersionId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getHighlightedVersions } from '../../../state/selectors/versions/index.tsx';
import { setHighlightedVersions } from '../../../state/settings/actions.tsx';
import type { State } from '../../../state/types.tsx';

export default defineSimpleStorage<State, VersionId[] | null>(
	() => 'highlightedVersions',
	(highlightedVersions: VersionId[] | null) =>
		highlightedVersions === null ? [] : [setHighlightedVersions(highlightedVersions)],
	(state) => getHighlightedVersions(state),
);
