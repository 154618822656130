import React, { useCallback, type MouseEvent, type KeyboardEvent } from 'react';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const EditDatesOption = ({ isDateEditable, Option, setOpenModal }: Props) => {
	const { formatMessage } = useIntl();

	const onClick = useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			event.preventDefault();
			if (!fg('platform_button_item-add-ufo-metrics')) {
				traceUFOPress('timeline-edit-dates-option-clicked', event.timeStamp);
			}
			setOpenModal(event);
		},
		[setOpenModal],
	);

	return (
		<Option
			isDisabled={!isDateEditable}
			label={formatMessage(messages.editDates)}
			onClick={onClick}
			{...(fg('platform_button_item-add-ufo-metrics')
				? { interactionName: 'timeline-edit-dates-option-clicked' }
				: {})}
		/>
	);
};

export default EditDatesOption;
