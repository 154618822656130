/** @jsx jsx */
import { css, jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { N10A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { secondaryBorder } from '../../../common/styled/timeline.tsx';

type Props = {
	isHighlightToday: boolean;
	todayColumnIndex: number;
	columnPositions: [number, number][];
	// Remove optional when cleaning up fg('visual-refresh')
	isOverlayInHeader?: boolean;
	// Remove optional when cleaning up fg('visual-refresh')
	subheaderHeight?: number;
};

const Columns = ({
	isHighlightToday,
	todayColumnIndex,
	columnPositions,
	isOverlayInHeader,
	subheaderHeight,
}: Props) => {
	const visuallyRefreshedContent =
		isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2') ? (
			<Box xcss={todayColumnBorderStyles}>
				<Box
					xcss={[isOverlayInHeader ? todayHeaderColumnStyles : todayColumnStyles]}
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
						...(isOverlayInHeader && { height: `calc(100% - ${subheaderHeight}px)` }),
					}}
				/>
			</Box>
		) : (
			<div css={todayColumnStylesOld} />
		);

	return (
		<div css={containerStyles}>
			{columnPositions.map(([left, right]: [number, number], index: number) => (
				<div
					css={columnStyles}
					key={`column-position-${index}`}
					style={{ left: `${left}%`, right: `${right}%` }}
				>
					{todayColumnIndex === index && isHighlightToday ? visuallyRefreshedContent : null}
				</div>
			))}
		</div>
	);
};

export default Columns;

const containerStyles = css({
	position: 'absolute',
	display: 'flex',
	top: 0,
	width: '100%',
	height: '100%',
	pointerEvents: 'none',
});

const columnStyles = css({
	position: 'absolute',
	boxSizing: 'border-box',
	flex: '0 0 auto',
	top: 0,
	bottom: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRight: secondaryBorder,
});

const todayColumnStylesOld = css({
	position: 'absolute',
	boxSizing: 'border-box',
	top: 0,
	right: '-1px',
	left: '-1px',
	height: '100%',
	backgroundColor: token('color.background.neutral', 'rgba(9, 30, 66, 0.04)'),
	borderLeft: `1px solid ${token('color.border', N10A)}`,
	borderRight: `1px solid ${token('color.border', N10A)}`,
});

const todayColumnStyles = xcss({
	position: 'absolute',
	boxSizing: 'border-box',
	top: '0',
	right: '-1px',
	left: '-1px',
	height: '100%',
	backgroundColor: 'color.background.neutral',
});

const todayHeaderColumnStyles = xcss({
	position: 'absolute',
	boxSizing: 'border-box',
	top: '0',
	right: '-1px',
	left: '-1px',
	backgroundColor: 'color.background.neutral',
});

const todayColumnBorderStyles = xcss({
	position: 'absolute',
	boxSizing: 'border-box',
	top: '0',
	right: '-1px',
	left: '-1px',
	height: '100%',
	borderLeft: `1px solid ${token('color.border', N10A)}`,
	borderRight: `1px solid ${token('color.border', N10A)}`,
});
