import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../common/types.tsx';

export type SetListWidthAction = (listWidth: number) => Action<State>;

export const setListWidth: SetListWidthAction =
	(listWidth: number) =>
	({ setState }) => {
		setState({ listWidth });
	};
