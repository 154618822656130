import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import type { State } from '../../state/types.tsx';
import { SET_SHARED, type SetSharedAction as Action } from '../../state/ui/share/actions.tsx';
import type { StateEpic } from '../common/types.tsx';
import updateQuery from '../common/update-query/index.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
	{ router: { push } },
) =>
	action$.ofType(SET_SHARED).map((action) =>
		updateQuery(push, store.getState(), (query) => ({
			...query,
			shared: action.payload,
		})),
	)) as StateEpic;
