import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ChangeEvent } from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	RankRequest,
	IssueScheduleFields,
	Issue,
	SortedIssueHash,
} from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import type { CreateItemAnchor } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/create.tsx';
import type {
	IssueFieldModification,
	IssueCreateFields,
	GlobalIssueCreateProps,
} from '../../../model/issue/index.tsx';

type CallerActionMeta = {
	caller?: string;
};

// === Reset Transition State ===

export const RESET_TRANSITION_STATE = 'state.entities.issues.RESET_TRANSITION_STATE' as const;

type ResetTransitionStateActionPayload = {
	issueId: IssueId;
	transitionId: string;
};

export type ResetTransitionStateAction = {
	type: typeof RESET_TRANSITION_STATE;
	payload: ResetTransitionStateActionPayload;
};

export const resetTransitionState = (
	payload: ResetTransitionStateActionPayload,
): ResetTransitionStateAction => ({
	type: RESET_TRANSITION_STATE,
	payload,
});

// === Clear Transition State ===

export const CLEAR_TRANSITION_STATE = 'state.entities.issues.CLEAR_TRANSITION_STATE' as const;

type ClearTransitionStateActionPayload = {
	issueId: IssueId;
	transitionId: string;
};

export type ClearTransitionStateAction = {
	type: typeof CLEAR_TRANSITION_STATE;
	payload: ClearTransitionStateActionPayload;
	meta?: CallerActionMeta;
};

export const clearTransitionState = (
	payload: ClearTransitionStateActionPayload,
	caller?: string,
): ClearTransitionStateAction => ({
	type: CLEAR_TRANSITION_STATE,
	payload,
	...(caller
		? {
				meta: {
					caller,
				},
			}
		: {}),
});

// === Extend ===

export const EXTEND_ISSUES = 'state.entities.issues.EXTEND_ISSUES' as const;

type ExtendIssuesActionPayload = SortedIssueHash;

export type ExtendIssuesAction = {
	type: typeof EXTEND_ISSUES;
	payload: ExtendIssuesActionPayload;
	meta?: CallerActionMeta;
};

export const extendIssues = (
	payload: ExtendIssuesActionPayload,
	caller?: string,
): ExtendIssuesAction => ({
	type: EXTEND_ISSUES,
	payload,
	...(caller
		? {
				meta: {
					caller,
				},
			}
		: {}),
});

// === Remove ===

export const REMOVE_ISSUE = 'state.entities.issues.REMOVE_ISSUE' as const;

export type RemoveIssueAction = {
	type: typeof REMOVE_ISSUE;
	payload: IssueId;
};

export const removeIssue = (payload: IssueId): RemoveIssueAction => ({
	type: REMOVE_ISSUE,
	payload,
});

export const REMOVE_ISSUES = 'state.entities.issues.REMOVE_ISSUES' as const;

export type RemoveIssuesAction = {
	type: typeof REMOVE_ISSUES;
	payload: IssueId[];
};

export const removeIssues = (payload: IssueId[]): RemoveIssuesAction => ({
	type: REMOVE_ISSUES,
	payload,
});

export const ATTEMPT_REMOVE_ISSUE = 'state.entities.issues.ATTEMPT_REMOVE_ISSUE' as const;

export type AttemptRemoveIssueAction = {
	type: typeof ATTEMPT_REMOVE_ISSUE;
	payload: IssueId;
};

export const attemptRemoveIssue = (payload: IssueId): AttemptRemoveIssueAction => ({
	type: ATTEMPT_REMOVE_ISSUE,
	payload,
});

// === Insert ===

export const INSERT_ISSUE = 'state.entities.issues.INSERT_ISSUE' as const;

type InsertIssueActionPayload = {
	id: IssueId;
	issue: Issue;
	anchor: NonNullable<CreateItemAnchor>;
};

export type InsertIssueAction = {
	type: typeof INSERT_ISSUE;
	payload: InsertIssueActionPayload;
};

export const insertIssue = (payload: InsertIssueActionPayload): InsertIssueAction => ({
	type: INSERT_ISSUE,
	payload,
});

// === Create ===

export const CREATE_ISSUE = 'state.entities.issues.CREATE_ISSUE' as const;

export type CreateIssueAction = {
	type: typeof CREATE_ISSUE;
	payload: IssueCreateFields;
	meta: {
		resetCreateItemAnchor: boolean;
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const createAndPersistIssue = (
	payload: IssueCreateFields,
	meta: {
		resetCreateItemAnchor: boolean;
		analyticsEvent: UIAnalyticsEvent;
	},
): CreateIssueAction => ({
	type: CREATE_ISSUE,
	payload,
	meta: {
		resetCreateItemAnchor: meta.resetCreateItemAnchor,
		analyticsEvent: meta.analyticsEvent,
	},
});

// === Update ===

export const UPDATE_ISSUE = 'state.entities.issues.UPDATE_ISSUE' as const;

export type UpdateIssueAction = {
	type: typeof UPDATE_ISSUE;
	payload: {
		id: IssueId;
		properties: IssueFieldModification;
	};
	meta: {
		analyticsEvent: UIAnalyticsEvent;
		bypassSprintScopeCheck: boolean;
		isDragged?: boolean;
		hasOverdueReleaseDate: boolean | undefined;
	};
};

export const updateAndPersistIssue = (
	id: IssueId,
	properties: IssueFieldModification,
	event: UIAnalyticsEvent,
	meta?: {
		isDragged: boolean;
		hasOverdueReleaseDate: boolean | undefined;
	},
): UpdateIssueAction => ({
	type: UPDATE_ISSUE,
	payload: {
		id,
		properties,
	},
	meta: {
		analyticsEvent: event,
		bypassSprintScopeCheck: false,
		isDragged: !!meta?.isDragged,
		hasOverdueReleaseDate: meta?.hasOverdueReleaseDate,
	},
});

export const updateAndPersistIssueWithoutSprintScopeCheck = (
	id: IssueId,
	properties: IssueFieldModification,
	event: UIAnalyticsEvent,
	hasOverdueReleaseDate?: boolean,
): UpdateIssueAction => ({
	type: UPDATE_ISSUE,
	payload: {
		id,
		properties,
	},
	meta: {
		analyticsEvent: event,
		bypassSprintScopeCheck: true,
		hasOverdueReleaseDate,
	},
});

// === Schedule multiple issues ===

export const SCHEDULE_ISSUES = 'state.entities.issues.SCHEDULE_ISSUES' as const;

export type ScheduleIssuesAction = {
	type: typeof SCHEDULE_ISSUES;
	payload: {
		issueScheduleFields: IssueScheduleFields[];
	};
	meta: {
		analyticsEvent: UIAnalyticsEvent;
		hasOverdueReleaseDate: boolean | undefined;
	};
};

export const scheduleIssues = (
	issueScheduleFields: IssueScheduleFields[],
	event: UIAnalyticsEvent,
	hasOverdueReleaseDate?: boolean,
): ScheduleIssuesAction => ({
	type: SCHEDULE_ISSUES,
	payload: {
		issueScheduleFields,
	},
	meta: {
		analyticsEvent: event,
		hasOverdueReleaseDate,
	},
});

// === Replace ===

export const REPLACE_ISSUE = 'state.entities.issues.REPLACE_ISSUE' as const;

export type ReplaceIssueActionPayload = {
	temporaryId: IssueId;
	id: IssueId;
	issue: Issue;
};

export type ReplaceIssueAction = {
	type: typeof REPLACE_ISSUE;
	payload: ReplaceIssueActionPayload;
	meta?: CallerActionMeta;
};

export const replaceIssue = (
	payload: ReplaceIssueActionPayload,
	caller?: string,
): ReplaceIssueAction => ({
	type: REPLACE_ISSUE,
	payload,
	...(caller
		? {
				meta: {
					caller,
				},
			}
		: {}),
});

// === Reload ===

export const RELOAD_ISSUE = 'state.entities.issues.RELOAD_ISSUE' as const;

export type ReloadIssueAction = {
	type: typeof RELOAD_ISSUE;
	payload: ChangeEvent;
};

export const reload = (event: ChangeEvent): ReloadIssueAction => ({
	type: RELOAD_ISSUE,
	payload: event,
});

// === Rank ===

export const RANK_ISSUE = 'state.entities.issues.RANK_ISSUE' as const;

export type RankIssuePayload = {
	rankRequest: RankRequest;
	issueId: IssueId;
};

export type RankIssueAction = {
	type: typeof RANK_ISSUE;
	payload: RankIssuePayload;
	meta: {
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const rankIssue = (
	payload: RankIssuePayload,
	analyticsEvent: UIAnalyticsEvent,
): RankIssueAction => ({
	type: RANK_ISSUE,
	payload,
	meta: {
		analyticsEvent,
	},
});

export const ATTEMPT_RANK_ISSUE = 'state.entities.issues.ATTEMPT_RANK_ISSUE' as const;

export type AttemptRankIssuePayload = {
	rankRequest: RankRequest;
	issueId: IssueId;
};

export type AttemptRankIssueAction = {
	type: typeof ATTEMPT_RANK_ISSUE;
	payload: AttemptRankIssuePayload;
	meta: {
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const attemptRankIssue = (
	payload: AttemptRankIssuePayload,
	analyticsEvent: UIAnalyticsEvent,
): AttemptRankIssueAction => ({
	type: ATTEMPT_RANK_ISSUE,
	payload,
	meta: {
		analyticsEvent,
	},
});

// === Update and Rank ===

export const UPDATE_RANK_ISSUE = 'state.entities.issues.UPDATE_RANK_ISSUE' as const;

export type UpdateAndRankIssuePayload = {
	issueId: IssueId;
	rankRequest: RankRequest;
	properties: IssueFieldModification;
};

export type UpdateAndRankIssueAction = {
	type: typeof UPDATE_RANK_ISSUE;
	payload: UpdateAndRankIssuePayload;
	meta: {
		analyticsEvent: UIAnalyticsEvent;
	};
};
export const updateAndRankAndPersistIssue = (
	payload: UpdateAndRankIssuePayload,
	event: UIAnalyticsEvent,
): UpdateAndRankIssueAction => ({
	type: UPDATE_RANK_ISSUE,
	payload,
	meta: {
		analyticsEvent: event,
	},
});

// create/add dependency (op action)

export const CREATE_DEPENDENCY = 'state.entities.issues.CREATE_DEPENDENCY' as const;

export type CreateDependencyPayload = {
	dependee: IssueId;
	dependency: IssueId;
};

export type CreateDependencyAction = {
	type: typeof CREATE_DEPENDENCY;
	payload: CreateDependencyPayload;
	meta: {
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const createDependency = (
	payload: CreateDependencyPayload,
	analyticsEvent: UIAnalyticsEvent,
): CreateDependencyAction => ({
	type: CREATE_DEPENDENCY,
	payload,
	meta: { analyticsEvent },
});

// create/add dependency (reducer action)

export const ADD_DEPENDENCY = 'state.entities.issues.ADD_DEPENDENCY' as const;

export type AddDependencyPayload = {
	dependee: IssueId;
	dependency: IssueId;
};

export type AddDependencyAction = {
	type: typeof ADD_DEPENDENCY;
	payload: AddDependencyPayload;
};

export const addDependency = (payload: AddDependencyPayload): AddDependencyAction => ({
	type: ADD_DEPENDENCY,
	payload,
});

// remove/delete dependency (reducer action)

export const DELETE_DEPENDENCY = 'state.entities.issues.DELETE_DEPENDENCY' as const;

export type DeleteDependencyPayload = {
	dependee: IssueId;
	dependency: IssueId;
};

export type DeleteDependencyAction = {
	type: typeof DELETE_DEPENDENCY;
	payload: DeleteDependencyPayload;
};

export const deleteDependency = (payload: DeleteDependencyPayload): DeleteDependencyAction => ({
	type: DELETE_DEPENDENCY,
	payload,
});

// remove/delete dependency (op action)

export const REMOVE_DEPENDENCY = 'state.entities.issues.REMOVE_DEPENDENCY' as const;

export type RemoveDependencyPayload = {
	dependee: IssueId;
	dependency: IssueId;
};

export type RemoveDependencyAction = {
	type: typeof REMOVE_DEPENDENCY;
	payload: RemoveDependencyPayload;
	meta: {
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const removeDependency = (
	payload: RemoveDependencyPayload,
	analyticsEvent: UIAnalyticsEvent,
): RemoveDependencyAction => ({
	type: REMOVE_DEPENDENCY,
	payload,
	meta: {
		analyticsEvent,
	},
});

// === Update sequence ===
export const UPDATE_ISSUES_SEQUENCE = 'state.entities.issues.UPDATE_ISSUES_SEQUENCE' as const;

type UpdateIssuesSequenceActionPayload = IssueId[];

export type UpdateIssuesSequenceAction = {
	type: typeof UPDATE_ISSUES_SEQUENCE;
	payload: UpdateIssuesSequenceActionPayload;
};

export const updateIssuesSequence = (
	payload: UpdateIssuesSequenceActionPayload,
): UpdateIssuesSequenceAction => ({
	type: UPDATE_ISSUES_SEQUENCE,
	payload,
});

// === Update issues hash and sequence ===
// Similar to EXTEND_ISSUE but this replaces the entire issues data with payload
export const UPDATE_ISSUES_HASH_AND_SEQUENCE =
	'state.entities.issues.UPDATE_ISSUES_HASH_AND_SEQUENCE' as const;

type UpdateIssuesHashAndSequenceActionPayload = SortedIssueHash;

export type UpdateIssuesHashAndSequenceAction = {
	type: typeof UPDATE_ISSUES_HASH_AND_SEQUENCE;
	payload: UpdateIssuesHashAndSequenceActionPayload;
};

export const updateIssuesHashAndSequence = (
	payload: UpdateIssuesHashAndSequenceActionPayload,
): UpdateIssuesHashAndSequenceAction => ({
	type: UPDATE_ISSUES_HASH_AND_SEQUENCE,
	payload,
});

// === Global issue create ===
export const GLOBAL_ISSUE_CREATE = 'state.entities.issues.GLOBAL_ISSUE_CREATE';

export type GlobalIssueCreateAction = {
	type: typeof GLOBAL_ISSUE_CREATE;
	payload: GlobalIssueCreateProps[];
};

export const globalIssueCreate = (payload: GlobalIssueCreateProps[]): GlobalIssueCreateAction => ({
	type: GLOBAL_ISSUE_CREATE,
	payload,
});
