import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { isParentLevel } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import type { ChangeColorOptionParams } from '../../../../model/list-item-menu-option/index.tsx';

export const getChangeColorOptionParamsPure = (
	level: number,
	color: Color,
): ChangeColorOptionParams => ({
	isColorEditable: isParentLevel(level),
	color,
});
