// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { ROADMAP_PACKAGE_NAME } from '../../../../../constants.tsx';
import type ContextMenuType from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ContextMenu = lazyAfterPaint<typeof ContextMenuType>(
	() => import(/* webpackChunkName: "async-standard-roadmap-context-menu" */ './index'),
	{ ssr: false },
);

type Props = JSX.LibraryManagedAttributes<
	typeof ContextMenuType,
	ComponentProps<typeof ContextMenuType>
>;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<JSErrorBoundary
		id="async-standard-roadmap-context-menu"
		packageName={ROADMAP_PACKAGE_NAME}
		fallback="flag"
	>
		<Placeholder name="standard-roadmap-context-menu" fallback={null}>
			<ContextMenu {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
