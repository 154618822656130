import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-auto-dismiss-flag';
import { useIntl } from '@atlassian/jira-intl';
import { INTERACTED_ITEMS_LIMIT } from '../../constants.tsx';
import messages from './messages.tsx';

export interface Props {
	id: string | number;
	onDismissed?: (id: number | string, analyticsEvent: UIAnalyticsEvent) => void;
}

const InteractedItemsLimitExceededFlag = ({ id, onDismissed }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<SuccessFlag
			id={id}
			icon={
				<WarningIcon
					color={token('color.icon.warning', Y300)}
					spacing="spacious"
					LEGACY_size="medium"
					label=""
				/>
			}
			title={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.titleIssueTermRefresh
					: messages.title,
				{ limit: INTERACTED_ITEMS_LIMIT },
			)}
			onDismissed={onDismissed}
		/>
	);
};

export default InteractedItemsLimitExceededFlag;
