import 'rxjs/add/operator/map';
import 'rxjs/add/observable/fromPromise';
import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import roadmapDeriveFieldsConcreteQuery, {
	type roadmapDeriveFieldsQuery,
} from '@atlassian/jira-relay/src/__generated__/roadmapDeriveFieldsQuery.graphql';
import { DERIVE_FIELDS } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runQueryWithMetadata } from '../common/agg/run-query.tsx';
import { queryMetrics } from '../performance-analytics/index.tsx';
import type { DeriveFields, DeriveField } from './types.tsx';

const analyticKey = 'jira.frontend.fe.software.roadmap.derive-fields';
const concurrentMetricDefinition = queryMetrics[DERIVE_FIELDS];

export const getDeriveFields = (
	sourceAri: Ari,
	jqlContexts: string[],
	customFilterIds: string[],
): Observable<DeriveFields> =>
	runQueryWithMetadata<roadmapDeriveFieldsQuery>(
		roadmapDeriveFieldsConcreteQuery,
		{ sourceAri, jqlContexts, customFilterIds },
		analyticKey,
		concurrentMetricDefinition,
	)
		.map(
			({ data }) =>
				data.roadmaps?.roadmapDeriveFields?.reduce<DeriveField[]>((acc, deriveField) => {
					if (deriveField != null) {
						acc.push(deriveField);
					}

					return acc;
				}, []) ?? [],
		)
		// gracefully handle any errors
		.catch(() => []);
