import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	getInteractionPerformanceExtraData,
	type InteractionPerformanceExtraData,
} from '../state/selectors/metrics.tsx';
import type { State } from '../state/types.tsx';
import interactionPerformanceFactory from './utils/index.tsx';

export const interactionPerformance =
	interactionPerformanceFactory<InteractionPerformanceExtraData>();

export type RequiredStateProps = {
	interactionPerformanceExtraData: InteractionPerformanceExtraData;
};

const CaptureInteractionMetricsExtraData = ({
	interactionPerformanceExtraData,
}: RequiredStateProps) => {
	interactionPerformance.setExtraData(interactionPerformanceExtraData);
	return null;
};

export const InteractionMetricsExtraData = connect(
	(state: State) => ({
		interactionPerformanceExtraData: getInteractionPerformanceExtraData(state),
	}),
	{},
)(CaptureInteractionMetricsExtraData);
