import { graphql } from 'react-relay';
import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type {
	scheduleRoadmapItemsMutation$data,
	scheduleRoadmapItemsMutation,
} from '@atlassian/jira-relay/src/__generated__/scheduleRoadmapItemsMutation.graphql';
import type { IssueScheduleFields } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import { BULK_ISSUES_SCHEDULED } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runMutation } from '../common/agg/run-mutation.tsx';
import { mutationMetrics } from '../performance-analytics/index.tsx';
import { transformScheduleItemsRequest } from './transformers.tsx';

const analyticKey = 'jira.frontend.fe.software.roadmap.bulk-issues-scheduled';
const scheduleItemsMetric = mutationMetrics[BULK_ISSUES_SCHEDULED];
const mutationName = 'scheduleRoadmapItems';

export const scheduleItems = (
	sourceARI: Ari,
	issueScheduleFields: IssueScheduleFields[],
): Observable<scheduleRoadmapItemsMutation$data> =>
	runMutation<scheduleRoadmapItemsMutation>(
		mutationName,
		graphql`
			mutation scheduleRoadmapItemsMutation(
				$sourceARI: ID!
				$scheduleRequests: [RoadmapScheduleItemInput]!
			) {
				roadmaps {
					scheduleRoadmapItems(
						sourceARI: $sourceARI
						input: { scheduleRequests: $scheduleRequests }
					) {
						success
						errors {
							message
							extensions {
								... on RoadmapMutationErrorExtension {
									statusCode
									errorType
								}
							}
						}
					}
				}
			}
		`,
		{ sourceARI, scheduleRequests: transformScheduleItemsRequest(issueScheduleFields) },
		analyticKey,
		scheduleItemsMetric,
	);
