import React, {
	type ReactNode,
	type MouseEvent,
	type KeyboardEvent,
	type Dispatch,
	type SetStateAction,
	useCallback,
} from 'react';
import { mergeRefs } from 'use-callback-ref';
import { ButtonItem } from '@atlaskit/menu';
import Tooltip, { type TriggerProps } from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import PopupButton from './popup-button/index.tsx';

export type ListItemMenuOptionProps = {
	isDisabled?: boolean;
	label: string;
	disabledTooltip?: string;
	/** The triggerButtonRef prop can be used to access the underlying button element from
	 * the ButtonItem atlaskit component. A common use case is to use this with an EntryPoint trigger ref when asynchronously loading components:
	 * https://hello.atlassian.net/wiki/spaces/UAF/pages/2333297492/Tutorial+EntryPoint+implementation+strategies
	 */
	triggerRef?: React.Ref<HTMLButtonElement>;
	interactionName?: string;
	onClick?: (e: MouseEvent | KeyboardEvent) => void;
	children?: (props: {
		setInitialFocusRef: Dispatch<SetStateAction<HTMLElement | null>>;
	}) => ReactNode;
	setInitialFocusRef?: Dispatch<SetStateAction<HTMLElement | null>>;
};

const ListItemMenuOption = ({
	isDisabled,
	label,
	disabledTooltip,
	triggerRef,
	interactionName,
	onClick,
	setInitialFocusRef,
	children,
}: ListItemMenuOptionProps) => {
	const renderButtonItem = useCallback(
		(triggerProps?: TriggerProps) => {
			const refs = [];

			if (setInitialFocusRef) {
				refs.push(setInitialFocusRef);
			}

			if (triggerProps?.ref) {
				refs.push(triggerProps.ref);
			}

			if (triggerRef) {
				refs.push(triggerRef);
			}

			return (
				<ButtonItem
					{...triggerProps}
					isDisabled={isDisabled}
					onClick={onClick}
					ref={mergeRefs(refs)}
					{...(fg('platform_button_item-add-ufo-metrics') ? { interactionName } : {})}
				>
					{label}
				</ButtonItem>
			);
		},
		[setInitialFocusRef, triggerRef, isDisabled, onClick, interactionName, label],
	);

	if (children) {
		return (
			<PopupButton
				isDisabled={isDisabled}
				disabledTooltip={disabledTooltip}
				label={label}
				setInitialFocusRef={setInitialFocusRef}
				triggerRef={triggerRef}
				{...(fg('platform_button_item-add-ufo-metrics') ? { interactionName } : {})}
			>
				{children}
			</PopupButton>
		);
	}

	return isDisabled && disabledTooltip ? (
		<Tooltip content={disabledTooltip}>{renderButtonItem}</Tooltip>
	) : (
		renderButtonItem()
	);
};
export { ListItemMenuOption };
