import React, { type ReactNode, useLayoutEffect, useCallback, useRef, useMemo } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { CREATE_ITEM_TYPE } from '../../constants.tsx';
import { useHeaderState } from '../../context/header/index.tsx';
import { useItems, useItemHeight } from '../../context/items/index.tsx';
import {
	VirtualiseProviderSweetState,
	useVirtualisedActions,
} from '../../context/virtualise/main.tsx';
import { getItemHeights } from './utils.tsx';

type Props = {
	isEnabled: boolean;
	scrollElement: HTMLElement | null;
	children: ReactNode;
};

type UseVirtualItemsParams = {
	scrollElement: HTMLElement;
	itemHeights: number[];
};

const useVirtualItems = ({ scrollElement, itemHeights }: UseVirtualItemsParams) => {
	const [, { generateVirtualMeta }] = useVirtualisedActions();

	const parentRef = useRef(scrollElement);
	const getRowHeight = useCallback((index: number) => itemHeights[index], [itemHeights]);

	const [{ headerHeight }] = useHeaderState();

	const virtualizer = useVirtualizer({
		count: itemHeights.length,
		getScrollElement: () => parentRef.current,
		estimateSize: getRowHeight,
		overscan: 0,
		paddingStart: headerHeight,
	});

	useLayoutEffect(() => {
		const virtualItems = virtualizer.getVirtualItems();
		if (virtualizer.getVirtualItems().length > 0) {
			const { index: startIndex } = virtualItems[0];
			const { index: endIndex } = virtualItems[virtualItems.length - 1];

			const visibleItemCount = endIndex - startIndex;
			const roundedStart = Math.round(startIndex / 5) * 5;

			generateVirtualMeta(roundedStart, Math.min(roundedStart + visibleItemCount, endIndex));
		} else {
			// Unset start and end index to disable virtualisation
			generateVirtualMeta(0, itemHeights.length - 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generateVirtualMeta, virtualizer.getVirtualItems(), itemHeights]);
};

const Container = ({ itemHeights, scrollElement }: UseVirtualItemsParams) => {
	useVirtualItems({ itemHeights, scrollElement });
	return null;
};

const VirtualiseContainer = ({ isEnabled, children, scrollElement }: Props) => {
	const [items] = useItems();
	const [itemHeight] = useItemHeight();
	const itemHeights = useMemo(() => getItemHeights(items, itemHeight), [items, itemHeight]);
	const createItemIndex = useMemo(
		() => items.findIndex((item) => item.type === CREATE_ITEM_TYPE),
		[items],
	);

	if (
		// react-virtual uses ResizeObserver to get parentRef's dimension
		typeof ResizeObserver === 'undefined'
	) {
		return <>{children}</>;
	}

	return (
		<VirtualiseProviderSweetState
			itemHeights={itemHeights}
			createItemIndex={createItemIndex}
			isEnabled={isEnabled}
			items={items}
		>
			{scrollElement && isEnabled && (
				<Container itemHeights={itemHeights} scrollElement={scrollElement} />
			)}
			{children}
		</VirtualiseProviderSweetState>
	);
};

export default VirtualiseContainer;
