import { combineReducers } from 'redux';
import filter from './filters/reducer.tsx';
import healthcheck from './healthcheck/reducer.tsx';
import panel from './panel/reducer.tsx';
import table from './table/reducer.tsx';
import views from './views/reducer.tsx';

export default combineReducers({
	healthcheck,
	table,
	views,
	panel,
	filter,
});
