import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	areDependenciesSupported,
	getIsChildIssuePlanningEnabled,
} from '../../../../../state/configuration/selectors.tsx';
import { getIssueLevel } from '../../../../../state/entities/issues/selectors.tsx';
import type { State } from '../../../../../state/types.tsx';
import type { OwnProps, StateProps } from './types.tsx';
import EditDependencyOption from './view.tsx';

const mapStateToProps = (state: State, { id }: OwnProps): StateProps => ({
	areDependenciesSupported: areDependenciesSupported(state),
	isChildIssuePlanningEnabled: getIsChildIssuePlanningEnabled(state),
	level: getIssueLevel(state, id),
});

export default connect(mapStateToProps)(EditDependencyOption);
