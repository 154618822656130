import type { ResizeObserverCallback } from './types.tsx';

const elementsForCallback = new Map<ResizeObserverCallback, Element[]>();

/* Multiple observed elements can refer to the same callback. Rather than call
 * them in sequence, we batch them as a performance optimisation.
 */
export const runCallbacks = (entries: ResizeObserverEntry[]) => {
	elementsForCallback.forEach((elements, callback) => {
		const relevantEntries: Array<ResizeObserverEntry> = [];

		entries.forEach((entry) => {
			if (elements.includes(entry.target)) {
				relevantEntries.push(entry);
			}
		});

		if (relevantEntries.length > 0) {
			callback(relevantEntries);
		}
	});
};

/* Aside from passing the relevant element to the resize observer, we store it
 * with its associated callback to be leveraged later (see runCallbacks).
 */
export const observeElement = (
	resizeObserver: ResizeObserver,
	element: HTMLElement,
	callback: ResizeObserverCallback,
) => {
	const existingElements = elementsForCallback.get(callback);

	if (existingElements) {
		elementsForCallback.set(callback, [...existingElements, element]);
	} else {
		elementsForCallback.set(callback, [element]);
	}

	resizeObserver.observe(element);
};

export const unobserveElement = (
	resizeObserver: ResizeObserver,
	element: HTMLElement,
	callback: ResizeObserverCallback,
) => {
	elementsForCallback.delete(callback);
	resizeObserver.unobserve(element);
};
