import { useCallback, useRef, useState } from 'react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { DEPENDENCY_DRAG_ITEM_ID } from '../../../../../../../common/constants/dependency.tsx';
import { TOP } from '../../../../../../../common/constants/index.tsx';
import type {
	DependencyDragData,
	OnDropDependency,
} from '../../../../../../../common/types/dependency.tsx';

type UseDrop = [{ isDraggedOver: boolean }, (el: HTMLElement | null) => void, () => void];

const useDependenciesDrop = (id: string, onDrop: OnDropDependency): UseDrop => {
	const cleanupDropTarget = useRef<null | (() => void)>(null);
	const [dragState, setDragState] = useState({ isDraggedOver: false });
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const makeDroppable = useCallback(
		(dropTargetElement: HTMLElement | null) => {
			const el = dropTargetElement;
			if (el === null) {
				return;
			}

			cleanupDropTarget.current = combine(
				dropTargetForElements({
					element: el,
					canDrop: ({ source }) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const item = source.data as DependencyDragData;
						return item.type === DEPENDENCY_DRAG_ITEM_ID && item.id !== id;
					},
					onDragEnter: ({ source }) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const item = source.data as DependencyDragData;
						if (item.type === DEPENDENCY_DRAG_ITEM_ID && item.id !== id) {
							setDragState({ isDraggedOver: true });
						}
					},
					onDragLeave: ({ source }) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const item = source.data as DependencyDragData;
						if (item.type === DEPENDENCY_DRAG_ITEM_ID && item.id !== id) {
							setDragState({ isDraggedOver: false });
						}
					},
					onDrop: ({ source }) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const item = source.data as DependencyDragData;
						const analyticsEvent = createAnalyticsEvent({
							action: 'dropped',
							actionSubject: 'dropArea',
						});
						fireUIAnalytics(analyticsEvent, 'dependencyDropArea');
						const from = item.handleType === TOP ? item.id : id;
						const to = item.handleType === TOP ? id : item.id;

						onDrop(from, to, analyticsEvent);
						setDragState({ isDraggedOver: false });
					},
				}),
			);
		},
		[id, onDrop, createAnalyticsEvent],
	);

	const cleanupDroppable = useCallback(() => {
		cleanupDropTarget.current?.();
	}, []);

	return [dragState, makeDroppable, cleanupDroppable];
};

export { useDependenciesDrop };
