import {
	ADD_PAYLOAD_GLOBAL_ISSUE_CREATE,
	CLEAR_PAYLOAD_GLOBAL_ISSUE_CREATE,
	type AddPayloadGlobalIssueCreateAction,
	type ClearPayloadGlobalIssueCreateAction,
} from './actions.tsx';
import type { GlobalIssueCreateState } from './types.tsx';

export type Action = AddPayloadGlobalIssueCreateAction | ClearPayloadGlobalIssueCreateAction;

const initialState: GlobalIssueCreateState = {
	payloadGIC: undefined,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: GlobalIssueCreateState = initialState,
	action: Action,
): GlobalIssueCreateState => {
	switch (action.type) {
		case ADD_PAYLOAD_GLOBAL_ISSUE_CREATE: {
			return {
				...state,
				payloadGIC: action.payload,
			};
		}
		case CLEAR_PAYLOAD_GLOBAL_ISSUE_CREATE: {
			return {
				...state,
				payloadGIC: undefined,
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
