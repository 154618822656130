import type { ComponentType } from 'react';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { isClassic } from '../../state/app/selectors.tsx';
import type { State } from '../../state/types.tsx';
import BoardDescription from './board-description/view.tsx';
import type { DescriptionProps } from './common/types.tsx';
import ProjectDescription from './project-description/view.tsx';
import IrrelevantItemCreatedFlag from './view.tsx';

type StateProps = {
	Description: ComponentType<DescriptionProps>;
};

export default connect(
	(state: State): StateProps => ({
		Description: isClassic(state) ? BoardDescription : ProjectDescription,
	}),
	{},
)(IrrelevantItemCreatedFlag);
