import max from 'lodash/max';
import min from 'lodash/min';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IdentifiableHash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import { CHILD_PLACEHOLDER_DAYS } from '../../../../../constants.tsx';
import type { IssueSprintDates } from '../../../../../model/sprint/types.tsx';

type IssueDates = {
	startDate: number;
	dueDate: number;
	isStartDateInferred: boolean;
	isStartDatePlaceholder: boolean;
	isDueDateInferred: boolean;
	isDueDatePlaceholder: boolean;
	areDatesFlipped: boolean;
};

// if issue has dates, then there is an issueDates object, otherwise it doesnt exist in the hash, i.e. undefined
export type IssueDatesHashType = IdentifiableHash<IssueId, IssueDates>;

export type RolledUpDates = {
	startDate: number | undefined;
	dueDate: number | undefined;
	latestStartDate: number | undefined;
	earliestDueDate: number | undefined;
	isStartDatePlaceholder: boolean;
	isDueDatePlaceholder: boolean;
};

export type BoundaryDates = {
	startDateBoundary: number;
	dueDateBoundary: number;
};

const getChildInferredSprintDates = (
	childIssueId: IssueId,
	getIssueSprintDates: (issueId: IssueId) => IssueSprintDates,
): {
	startDate: number | undefined;
	dueDate: number | undefined;
} => {
	const { inferredStartDate: startDate, inferredEndDate: dueDate } =
		getIssueSprintDates(childIssueId);
	return { startDate, dueDate };
};

export const trySetPlaceholderDates = ({
	startDate,
	dueDate,
}: {
	startDate: number | undefined;
	dueDate: number | undefined;
}): {
	startDate: number | undefined;
	dueDate: number | undefined;
	isStartDatePlaceholder: boolean;
	isDueDatePlaceholder: boolean;
	areDatesFlipped: boolean;
} => {
	if (startDate !== undefined && dueDate !== undefined) {
		return {
			startDate: Math.min(startDate, dueDate),
			dueDate: Math.max(startDate, dueDate),
			isStartDatePlaceholder: false,
			isDueDatePlaceholder: false,
			areDatesFlipped: startDate > dueDate,
		};
	}

	if (startDate !== undefined) {
		// No due date - default to showing the due date one month after the start date
		const placeholderDueDate = moment
			.utc(startDate)
			.add(CHILD_PLACEHOLDER_DAYS, 'days')
			.startOf('day')
			.valueOf();

		return {
			startDate,
			dueDate: placeholderDueDate,
			isStartDatePlaceholder: false,
			isDueDatePlaceholder: true,
			areDatesFlipped: false,
		};
	}

	if (dueDate !== undefined) {
		// No start date - default to showing the start date once month before the due date
		const placeholderStartDate = moment
			.utc(dueDate)
			.subtract(CHILD_PLACEHOLDER_DAYS, 'days')
			.startOf('day')
			.valueOf();

		return {
			startDate: placeholderStartDate,
			dueDate,
			isStartDatePlaceholder: true,
			isDueDatePlaceholder: false,
			areDatesFlipped: false,
		};
	}

	// No dates - default to showing a bar that has no dates
	return {
		startDate: undefined,
		dueDate: undefined,
		isStartDatePlaceholder: false,
		isDueDatePlaceholder: false,
		areDatesFlipped: false,
	};
};

const getChildExplicitDates = (
	childIssueId: IssueId,
	issueStartDateHash: IdentifiableHash<IssueId, number | undefined>,
	issueDueDateHash: IdentifiableHash<IssueId, number | undefined>,
): {
	startDate: number | undefined;
	dueDate: number | undefined;
} => {
	const startDate = issueStartDateHash[childIssueId];
	const dueDate = issueDueDateHash[childIssueId];
	return { startDate, dueDate };
};

export const getChildIssueDatesHash = (
	childIssueIds: IssueId[],
	getIssueSprintDates: (issueId: IssueId) => IssueSprintDates,
	issueStartDateHash: IdentifiableHash<IssueId, number | undefined>,
	issueDueDateHash: IdentifiableHash<IssueId, number | undefined>,
	isSprintsPlanning: boolean,
): IssueDatesHashType => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const issueDatesHash: Record<string, any> = {};

	childIssueIds.forEach((childIssueId) => {
		const { startDate, dueDate, isStartDatePlaceholder, isDueDatePlaceholder, areDatesFlipped } =
			trySetPlaceholderDates(
				isSprintsPlanning
					? getChildInferredSprintDates(childIssueId, getIssueSprintDates)
					: getChildExplicitDates(childIssueId, issueStartDateHash, issueDueDateHash),
			);
		// either both dates are defined, or both are undefined
		if (startDate !== undefined && dueDate !== undefined) {
			issueDatesHash[`${childIssueId}`] = {
				startDate,
				dueDate,
				isStartDateInferred: isSprintsPlanning,
				isStartDatePlaceholder,
				isDueDateInferred: isSprintsPlanning,
				isDueDatePlaceholder,
				areDatesFlipped,
			};
		}
	});
	return issueDatesHash;
};

const startDateComparator = (first: number, second: number): boolean => first < second;
const dueDateComparator = (first: number, second: number): boolean => first > second;

const getRolledUpDate = (
	previousDate: number | undefined,
	date: number,
	isPreviousDatePlaceholder: boolean,
	isDatePlaceholder: boolean,
	shouldUseCurrentDateComparator: (first: number, second: number) => boolean,
): {
	date: number;
	isDatePlaceholder: boolean;
} => {
	if (previousDate === undefined) {
		return {
			date,
			isDatePlaceholder,
		};
	}
	return {
		date: shouldUseCurrentDateComparator(date, previousDate) ? date : previousDate,
		isDatePlaceholder: isDatePlaceholder || isPreviousDatePlaceholder,
	};
};

const getRolledUpChildDates = (childIssueIds: IssueId[], childIssueDatesHash: IssueDatesHashType) =>
	childIssueIds.reduce(
		(rolledUpDatesAcc: RolledUpDates, childIssueId: IssueId) => {
			// will be undefined if child issue does not have any dates
			const dates = childIssueDatesHash[childIssueId];

			if (dates !== undefined) {
				const { date: startDate, isDatePlaceholder: isStartDatePlaceholder } = getRolledUpDate(
					rolledUpDatesAcc.startDate,
					dates.startDate,
					rolledUpDatesAcc.isStartDatePlaceholder,
					dates.isStartDatePlaceholder,
					startDateComparator,
				);

				const { date: latestStartDate } = getRolledUpDate(
					rolledUpDatesAcc.latestStartDate,
					dates.startDate,
					rolledUpDatesAcc.isStartDatePlaceholder,
					dates.isStartDatePlaceholder,
					dueDateComparator,
				);

				const { date: earliestDueDate } = getRolledUpDate(
					rolledUpDatesAcc.earliestDueDate,
					dates.dueDate,
					rolledUpDatesAcc.isDueDatePlaceholder,
					dates.isDueDatePlaceholder,
					startDateComparator,
				);

				const { date: dueDate, isDatePlaceholder: isDueDatePlaceholder } = getRolledUpDate(
					rolledUpDatesAcc.dueDate,
					dates.dueDate,
					rolledUpDatesAcc.isDueDatePlaceholder,
					dates.isDueDatePlaceholder,
					dueDateComparator,
				);
				return {
					startDate,
					dueDate,
					isStartDatePlaceholder,
					isDueDatePlaceholder,
					latestStartDate,
					earliestDueDate,
				};
			}

			return rolledUpDatesAcc;
		},
		// initialize placeholder to true in case there are no explicit dates to override the value
		{
			startDate: undefined,
			dueDate: undefined,
			latestStartDate: undefined,
			earliestDueDate: undefined,
			isStartDatePlaceholder: true,
			isDueDatePlaceholder: true,
		},
	);

const getParentRolledUpDatesHashOld = (
	childrenHash: IdentifiableHash<IssueId, IssueId[]>,
	getIssueSprintDates: (issueId: IssueId) => IssueSprintDates,
): IdentifiableHash<IssueId, RolledUpDates> =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.keys(childrenHash).reduce<Record<string, any>>(
		(acc: IdentifiableHash<IssueId, RolledUpDates>, parentIssueId) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const childIssueIds = childrenHash[parentIssueId] as IssueId[];
			const rolledUpDates: RolledUpDates = childIssueIds.reduce(
				(rolledupAcc: RolledUpDates, childIssueId: IssueId) => {
					const {
						inferredStartDate: childInferredStartDate,
						inferredEndDate: childInferredDueDate,
					} = getIssueSprintDates(childIssueId);
					return {
						startDate: min([rolledupAcc.startDate, childInferredStartDate]),
						dueDate: max([rolledupAcc.dueDate, childInferredDueDate]),
						latestStartDate: undefined,
						earliestDueDate: undefined,
						isStartDatePlaceholder: false,
						isDueDatePlaceholder: false,
					};
				},
				{
					startDate: undefined,
					dueDate: undefined,
					earliestDueDate: undefined,
					latestStartDate: undefined,
					isStartDatePlaceholder: true,
					isDueDatePlaceholder: true,
				},
			);
			acc[parentIssueId] = rolledUpDates;
			return acc;
		},
		{},
	);

export const getBoundaryDatesHash = (
	childrenHash: IdentifiableHash<IssueId, IssueId[]>,
	issueStartDateHash: IdentifiableHash<IssueId, number | undefined>,
	issueDueDateHash: IdentifiableHash<IssueId, number | undefined>,
	getIssueSprintDates: (issueId: IssueId) => IssueSprintDates,
	isSprintsPlanning: boolean,
): IdentifiableHash<IssueId, BoundaryDates> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};

	Object.keys(childrenHash).forEach((parentIssueId) => {
		const parentStartDate = issueStartDateHash[parentIssueId];
		const parentDueDate = issueDueDateHash[parentIssueId];
		let earliestStart = parentStartDate;
		let earliestEnd = parentDueDate;
		let latestStart = parentStartDate;
		let latestEnd = parentDueDate;
		childrenHash[parentIssueId].forEach((childIssueId) => {
			const { inferredEndDate, inferredStartDate } = getIssueSprintDates(childIssueId);
			const startDate = isSprintsPlanning ? inferredStartDate : issueStartDateHash[childIssueId];
			const endDate = isSprintsPlanning ? inferredEndDate : issueDueDateHash[childIssueId];

			if (parentStartDate !== undefined) {
				earliestStart = min([earliestStart, startDate]);
				latestStart = max([latestStart, startDate]);
			}
			if (parentDueDate !== undefined) {
				earliestEnd = min([earliestEnd, endDate]);
				latestEnd = max([latestEnd, endDate]);
			}
		});
		result[parentIssueId] = {
			startDateBoundary: min([earliestStart, earliestEnd]),
			dueDateBoundary: max([latestStart, latestEnd]),
		};
	});
	return result;
};

export const getParentRolledUpDatesHash = (
	childrenHash: IdentifiableHash<IssueId, IssueId[]>,
	getIssueSprintDates: (issueId: IssueId) => IssueSprintDates,
	issueStartDateHash: IdentifiableHash<IssueId, number | undefined>,
	issueDueDateHash: IdentifiableHash<IssueId, number | undefined>,
	isSprintsPlanning: boolean,
	isChildIssuePlanningEnabled: boolean,
): IdentifiableHash<IssueId, RolledUpDates> => {
	if (!isChildIssuePlanningEnabled) {
		return getParentRolledUpDatesHashOld(childrenHash, getIssueSprintDates);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return Object.keys(childrenHash).reduce<Record<string, any>>(
		(acc: IdentifiableHash<IssueId, RolledUpDates>, parentIssueId) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const childIssueIds = childrenHash[parentIssueId] as IssueId[];
			const childIssueDatesHash = getChildIssueDatesHash(
				childIssueIds,
				getIssueSprintDates,
				issueStartDateHash,
				issueDueDateHash,
				isSprintsPlanning,
			);

			const dueDate = issueDueDateHash[parentIssueId];
			const startDate = issueStartDateHash[parentIssueId];

			const {
				dueDate: rolledUpDueDate,
				isDueDatePlaceholder: isRolledUpDueDatePlaceholder,
				isStartDatePlaceholder: isRolledUpStartDatePlaceholder,
				startDate: rolledUpStartDate,
				latestStartDate,
				earliestDueDate,
			} = getRolledUpChildDates(childIssueIds, childIssueDatesHash);
			acc[parentIssueId] = {
				dueDate: rolledUpDueDate,
				isDueDatePlaceholder: dueDate === undefined && isRolledUpDueDatePlaceholder,
				isStartDatePlaceholder: startDate === undefined && isRolledUpStartDatePlaceholder,
				startDate: rolledUpStartDate,
				latestStartDate,
				earliestDueDate,
			};
			return acc;
		},
		{},
	);
};
