/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@compiled/react';
import { fg } from '@atlassian/jira-feature-gating';
import ScrollMetaContainer from '../common/components/scroll-meta/view.tsx';
import Virtualiser from '../common/components/virtualiser/view.tsx';
import type {
	OnItemDrop,
	OnItemSelect,
	OnItemExpandChanged,
	OnCreateClick,
	OnSetListWidth,
} from '../common/types/callbacks.tsx';
import type { DndLongTaskMetricComponent } from '../common/types/drag-and-drop.tsx';
import type { ItemId } from '../common/types/item.tsx';
import type { Renderers } from '../renderers/index.tsx';
import Content from './content/index.tsx';
import CustomScrollbars from './custom-scrollbars/index.tsx';
import DragMonitor from './drag-monitor/index.tsx';
import Header from './header/index.tsx';
import ItemsHighlighter from './items-highlighter/index.tsx';
// Remove the whole folder with project_timeline_multi-select_and_checkboxes
import ItemsSelector from './items-selector/index.tsx';
import Borders from './static-overlay/borders/index.tsx';
import StaticOverlay from './static-overlay/index.tsx';
import { getRenderersWithDefaults } from './utils/get-renderers-with-defaults.tsx';

const containerStyles = css({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	width: 'fit-content',
	height: 'fit-content',
	minHeight: '100%',
});

type Props = {
	isVirtualiseEnabled: boolean;
	isResizeEnabled: boolean;
	isHideScrollbar: boolean;
	scope: string;
	// Remove this with project_timeline_multi-select_and_checkboxes
	selectedItemIds: ItemId[];
	onItemDrop: OnItemDrop;
	onItemSelect: OnItemSelect;
	onItemExpandChanged: OnItemExpandChanged;
	onCreateClick: OnCreateClick;
	onSetListWidth: OnSetListWidth;
	DndLongTaskMetric: DndLongTaskMetricComponent | undefined;
	renderers: Renderers;
};

const TableMain = ({
	isVirtualiseEnabled,
	isResizeEnabled,
	isHideScrollbar,
	scope,
	selectedItemIds,
	onItemDrop,
	onItemSelect,
	onItemExpandChanged,
	onCreateClick,
	onSetListWidth,
	DndLongTaskMetric,
	renderers,
}: Props) => {
	const { listRenderers, chartRenderers, renderProvider } = getRenderersWithDefaults(renderers);
	const {
		renderListEmptyContent,
		renderListHeaderItem,
		renderListItemContent,
		renderListItemSecondaryContent,
		renderListItemCreate,
		renderListItemMenuOption,
		renderListItemModal,
	} = listRenderers;
	const {
		renderChartHeaderItem,
		renderChartItemContent,
		renderChartOverlay,
		renderChartTimelineOverlay,
	} = chartRenderers;

	return (
		<>
			<CustomScrollbars isHideScrollbar={isHideScrollbar}>
				{({ scrollElement, onContentResize }) => {
					const containerContent = (
						<div css={containerStyles}>
							<Header
								renderListHeaderItem={renderListHeaderItem}
								renderChartHeaderItem={renderChartHeaderItem}
							/>
							<Content
								isResizeEnabled={isResizeEnabled}
								DndLongTaskMetric={DndLongTaskMetric}
								onItemDrop={onItemDrop}
								// TODO: remove this
								onItemSelect={onItemSelect}
								onItemExpandChanged={onItemExpandChanged}
								onCreateClick={onCreateClick}
								onSetListWidth={onSetListWidth}
								onContentResize={onContentResize}
								renderListEmptyContent={renderListEmptyContent}
								renderListItemContent={renderListItemContent}
								renderListItemSecondaryContent={renderListItemSecondaryContent}
								renderListItemCreate={renderListItemCreate}
								renderChartItemContent={renderChartItemContent}
								renderChartTimelineOverlay={renderChartTimelineOverlay}
								renderListItemMenuOption={renderListItemMenuOption}
								renderListItemModal={renderListItemModal}
							/>
							<Borders />
							<DragMonitor DndLongTaskMetric={DndLongTaskMetric} />
							{fg('project_timeline_multi-select_and_checkboxes') ? (
								<ItemsHighlighter />
							) : (
								<ItemsSelector selectedItemIds={selectedItemIds} />
							)}
						</div>
					);

					return (
						<ScrollMetaContainer scope={scope} scrollElement={scrollElement}>
							<Virtualiser scrollElement={scrollElement} isEnabled={isVirtualiseEnabled}>
								{renderProvider({ children: containerContent })}
							</Virtualiser>
						</ScrollMetaContainer>
					);
				}}
			</CustomScrollbars>
			<StaticOverlay renderChartOverlay={renderChartOverlay} />
		</>
	);
};

TableMain.displayName = 'TableMain';
TableMain.whyDidYouRender = true;

export default memo<Props>(TableMain);
