import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { DEFAULT_RENDERER } from '../../../../../common/constants/index.tsx';
import type { BarContentRenderers, BarContentProps, BarContentState } from './types.tsx';

type Props = BarContentRenderers & BarContentProps & BarContentState;

const RoadmapBarContent = ({
	id,
	dragType,
	leftPosition,
	rightPosition,
	prevStartDate,
	prevDueDate,
	startDate,
	dueDate,
	startDateType,
	dueDateType,
	placeholder,
	isActive,
	isReadOnly,
	isMinimumBar,
	isColorInverted,
	renderBarIcon,
	renderDateLabel,
	renderDependencyHandler,
	onBarMouseLeave,
	getBarBoundingClientRect,
}: Props) => {
	const isHovered = isActive && !dragType;
	const isDraggingExplicit = isActive && dragType !== undefined;
	const isDragging = dragType !== undefined;

	const getRenderDependencyHandler = () =>
		!isDragging &&
		!isReadOnly &&
		renderDependencyHandler(
			{
				id,
				leftPosition,
				rightPosition,
				placeholder,
				isColorInverted,
				getBarBoundingClientRect,
				onBarMouseLeave,
			},
			{ isHovered },
		);

	const getRenderDateLabel = () =>
		isDraggingExplicit || isHovered
			? renderDateLabel({
					prevStartDate,
					prevDueDate,
					startDate,
					dueDate,
					startDateType,
					dueDateType,
					placeholder,
					getBarBoundingClientRect,
					dragType,
				})
			: null;

	const getRenderBarIcon = () =>
		!isMinimumBar &&
		!isDraggingExplicit &&
		renderBarIcon({
			id,
			leftPosition,
			rightPosition,
			...(fg('jsw_roadmaps_timeline-post-project-a11y-fix') ? { isColorInverted } : {}),
		});

	return (
		<>
			{getRenderDependencyHandler()}
			{getRenderDateLabel()}
			{getRenderBarIcon()}
		</>
	);
};

RoadmapBarContent.defaultProps = {
	renderBarIcon: DEFAULT_RENDERER,
	renderDateLabel: DEFAULT_RENDERER,
	renderDependencyHandler: DEFAULT_RENDERER,
};

export { RoadmapBarContent };
