import intersection from 'lodash/intersection';
import mapValues from 'lodash/mapValues';
import { N50, B400, G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	IssueId,
	IssueStatusCategoryId,
	VersionId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	Hash,
	IdentifiableHash,
} from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type {
	StatusCategory,
	StatusCategoryHash,
} from '@atlassian/jira-software-roadmap-model/src/status/index.tsx';
import type { VersionsHash } from '@atlassian/jira-software-roadmap-model/src/version/index.tsx';
import type { Progress } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/progress.tsx';
import { NO_CATEGORY, TODO, IN_PROGRESS, DONE } from '../../../../model/status/index.tsx';

type StatusKeys = typeof TODO | typeof IN_PROGRESS | typeof DONE;

export const getProgressHashPure = (
	parentIssueIds: IssueId[],
	childrenHash: Hash<IssueId[] | undefined>,
	issueStatusCategoryHash: Hash<StatusCategory | undefined>,
	statusCategoryKeyHash: Hash<StatusCategory>,
): Hash<Progress | undefined> => {
	const progressHash: Record<IssueId, Progress> = {};

	const createProgressFromCounts = (progressCounts: Record<StatusKeys, number>): Progress => [
		{
			id: DONE,
			name: statusCategoryKeyHash[DONE].name,
			count: progressCounts[DONE],
			color: fg('jsw_roadmaps_timeline-post-project-a11y-fix')
				? token('color.chart.success')
				: token('color.border.success', G300),
		},
		{
			id: IN_PROGRESS,
			name: statusCategoryKeyHash[IN_PROGRESS].name,
			count: progressCounts[IN_PROGRESS],
			color: fg('jsw_roadmaps_timeline-post-project-a11y-fix')
				? token('color.chart.information')
				: token('color.border.information', B400),
		},
		{
			id: TODO,
			name: statusCategoryKeyHash[TODO].name,
			count: progressCounts[TODO],
			color: fg('jsw_roadmaps_timeline-post-project-a11y-fix')
				? token('color.chart.neutral')
				: token('color.border', N50),
		},
	];

	// For each issue, build up a count of what child is in what status category
	parentIssueIds.forEach((issueId: IssueId) => {
		const childIssues = childrenHash[issueId];

		if (childIssues && childIssues.length > 0) {
			const progressCounts: Record<StatusKeys, number> = {
				[TODO]: 0,
				[IN_PROGRESS]: 0,
				[DONE]: 0,
			};

			childIssues.forEach((childIssueId: IssueId) => {
				const statusCategory = issueStatusCategoryHash[childIssueId];
				const statusCategoryKey = statusCategory?.key;

				if (statusCategoryKey && statusCategoryKey !== NO_CATEGORY) {
					progressCounts[statusCategoryKey] += 1;
				}
			});

			progressHash[issueId] = createProgressFromCounts(progressCounts);
		}
	});

	return progressHash;
};

export const getIssueStatusCategoryHashPure = (
	issueStatusCategoryIdHash: Hash<IssueStatusCategoryId | undefined>,
	fullStatusCategoryHash: StatusCategoryHash,
): Hash<StatusCategory | undefined> =>
	mapValues(issueStatusCategoryIdHash, (statusCategoryId?: IssueStatusCategoryId) =>
		statusCategoryId ? fullStatusCategoryHash[statusCategoryId] : undefined,
	);

export const getIssueEdgeReleaseDatesPure = (
	fullVersionHash: VersionsHash,
	sanitisedIssueVersionIdsHash: IdentifiableHash<IssueId, VersionId[]>,
): Hash<[number | undefined, number | undefined]> =>
	mapValues(sanitisedIssueVersionIdsHash, (versionIds: VersionId[]) => {
		let minVersionReleaseDate: number | undefined;
		let maxVersionReleaseDate: number | undefined;

		if (versionIds.length > 0) {
			versionIds.forEach((versionId: VersionId) => {
				const versionReleaseDate = fullVersionHash[versionId]?.releaseDate;

				if (versionReleaseDate === undefined) {
					return;
				}

				if (minVersionReleaseDate === undefined || versionReleaseDate < minVersionReleaseDate) {
					minVersionReleaseDate = versionReleaseDate;
				}

				if (maxVersionReleaseDate === undefined || versionReleaseDate > maxVersionReleaseDate) {
					maxVersionReleaseDate = versionReleaseDate;
				}
			});
		}

		return [minVersionReleaseDate, maxVersionReleaseDate];
	});

export const combineIssueIdsFilters = (...issueSets: string[][]): string[] =>
	intersection(...issueSets.filter((array) => array.length > 0));
