import { createSelector } from 'reselect';
import { getIsReadOnly, hasMacroConfig } from '../app/selectors.tsx';
import {
	hasCreateIssuesPermission,
	hasEditIssuesPermission,
	hasScheduleIssuesPermission,
} from '../configuration/selectors.tsx';

export const getCanUserEdit = createSelector(
	hasEditIssuesPermission,
	getIsReadOnly,
	hasMacroConfig,
	(canEditIssue: boolean, isReadyOnly: boolean, isMacroConfigExist: boolean): boolean =>
		canEditIssue && !isReadyOnly && !isMacroConfigExist,
);

export const getCanUserCreate = createSelector(
	hasCreateIssuesPermission,
	getIsReadOnly,
	hasMacroConfig,
	(canCreateIssue: boolean, isReadyOnly: boolean, isMacroConfigExist: boolean): boolean =>
		canCreateIssue && !isReadyOnly && !isMacroConfigExist,
);

export const getCanUserRank = createSelector(
	hasScheduleIssuesPermission,
	getIsReadOnly,
	hasMacroConfig,
	(canRankIssue: boolean, isReadyOnly: boolean, isMacroConfigExist: boolean): boolean =>
		canRankIssue && !isReadyOnly && !isMacroConfigExist,
);

export const getCanUserViewDetails = createSelector(
	hasMacroConfig,
	(isMacroConfigExist: boolean): boolean => !isMacroConfigExist,
);
