import { combineEpics } from 'redux-observable';
import type { StateEpic } from '../common/types.tsx';
import bulkChangeExpansion from './bulk-change-expansion.tsx';
import changeExpansion from './change-expansion.tsx';
import setHighlightedVersions from './set-highlighted-versions.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default combineEpics(
	setHighlightedVersions,
	changeExpansion,
	bulkChangeExpansion,
) as StateEpic;
