// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import { BASE_LEVEL } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import { CHILD_PLACEHOLDER_DAYS, PARENT_PLACEHOLDER_DAYS } from '../../../../../constants.tsx';

type ChartDateData = {
	startDate: number | undefined;
	dueDate: number | undefined;
	isDateError?: boolean;
};

export const getChartDateData = (
	startDate: number | undefined,
	dueDate: number | undefined,
	level: number,
	isChildIssuePlanningEnabled: boolean,
): ChartDateData => {
	const placeholderDays =
		isChildIssuePlanningEnabled && level === BASE_LEVEL
			? CHILD_PLACEHOLDER_DAYS
			: PARENT_PLACEHOLDER_DAYS;

	// All dates are defined
	if (startDate !== undefined && dueDate !== undefined) {
		const isDateError = startDate > dueDate;

		// if the dates are flipped, flip them back!
		return {
			startDate: isDateError ? dueDate : startDate,
			dueDate: isDateError ? startDate : dueDate,
			isDateError,
		};
	}

	if (startDate !== undefined) {
		// No due date - default to showing the due date one month after the start date

		const monthAfterStart = moment
			.utc(startDate)
			.add(placeholderDays, 'days')
			.startOf('day')
			.valueOf();

		return {
			startDate,
			dueDate: monthAfterStart,
		};
	}

	if (dueDate !== undefined) {
		// No start date - default to showing the start date once month before the due date
		const monthBeforeDue = moment
			.utc(dueDate)
			.subtract(placeholderDays, 'days')
			.startOf('day')
			.valueOf();

		return {
			startDate: monthBeforeDue,
			dueDate,
		};
	}

	// No dates - default to showing a bar that has no dates
	return {
		startDate: undefined,
		dueDate: undefined,
	};
};
