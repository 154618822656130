import type { MiddlewareAPI } from 'redux';
import difference from 'lodash/difference';
import union from 'lodash/union';
import without from 'lodash/without';
import { Observable } from 'rxjs/Observable';
import type {
	ExtendIssuesAction,
	ReplaceIssueAction,
} from '../../../state/entities/issues/actions.tsx';
import { type IssueHiddenAction, issueHidden } from '../../../state/flags/actions.tsx';
import type { State } from '../../../state/types.tsx';

const getHiddenCreatedIssueIds = (
	before: string[],
	after: string[],
	created: string[],
	removed: string[],
) => {
	const expected = without(union(before, created), ...removed);
	return difference(expected, after);
};

export const handleHiddenIssueCreated = (
	store: MiddlewareAPI<State>,
	before: string[],
	after: string[],
	actions: ReplaceIssueAction[],
): Observable<IssueHiddenAction> => {
	const created = actions.map((action) => action.payload.id);
	const removed = actions.map((action) => action.payload.temporaryId);

	const hidden = getHiddenCreatedIssueIds(before, after, created, removed);

	return hidden.length > 0 ? Observable.of(issueHidden({ ids: hidden })) : Observable.empty();
};

export const handleHiddenGlobalIssueCreated = (
	store: MiddlewareAPI<State>,
	before: string[],
	after: string[],
	actions: ExtendIssuesAction[],
): Observable<IssueHiddenAction> => {
	const created = actions.flatMap((action) => Object.keys(action.payload.hash));

	const hidden = getHiddenCreatedIssueIds(before, after, created, []);

	return hidden.length > 0 ? Observable.of(issueHidden({ ids: hidden })) : Observable.empty();
};
