import React, { Component } from 'react';
import noop from 'lodash/noop';
import WarningFlag from '@atlassian/jira-flags/src/common/ui/components/warning-flag';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { BoardId } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import { getEditFilterUrl } from '../../common/urls/index.tsx';
import messages from './messages.tsx';

type Props = {
	isAdmin: boolean;
	id: string | number;
	boardId: BoardId;
	onDismissed: (id: number | string) => void;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
export class UnableToRankIssueFlag extends Component<Props> {
	static defaultProps = {
		onDismissed: noop,
	};

	onDismissed = () => {
		const { id, onDismissed } = this.props;
		onDismissed(id);
	};

	getContent() {
		const {
			isAdmin,
			boardId,
			intl: { formatMessage },
		} = this.props;

		if (isAdmin) {
			return {
				description: formatMessage(messages.description),
				actions: [
					{
						content: formatMessage(messages.showJqlFilterForBoard),
						href: getEditFilterUrl(boardId),
					},
				],
			};
		}

		return {
			description: (
				<>
					{formatMessage(messages.description)}
					<br />
					{formatMessage(messages.contactAdmin)}
				</>
			),
		};
	}

	render() {
		const {
			id,
			intl: { formatMessage },
		} = this.props;

		return (
			<WarningFlag
				id={id}
				// @ts-expect-error Type error after Flag props have been fixed globally, to be fixed by owning team
				isAutoDismiss
				onDismissed={this.onDismissed}
				title={formatMessage(messages.title)}
				{...this.getContent()}
			/>
		);
	}
}

export default injectIntl(UnableToRankIssueFlag);
