import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'roadmap.standard-roadmap.flags.interacted-items-limit-exceeded.title',
		defaultMessage: 'You can only select {limit} issues at a time.',
		description: 'Flag title. Informs user that they have exceeded the interacted items limit.',
	},
	titleIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.interacted-items-limit-exceeded.title-issue-term-refresh',
		defaultMessage: 'You can only select {limit} work items at a time.',
		description: 'Flag title. Informs user that they have exceeded the interacted items limit.',
	},
});
