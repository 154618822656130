import memoizeOne from 'memoize-one';
import type { TimelineDuration } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import { CLOSED } from '../../../common/constants/interval.tsx';
import type { Interval } from '../../../common/types/interval.tsx';
import { getIntervalGroupPositions } from '../../../common/utils/interval/positions.tsx';
import type { SimpleInterval } from './common/types.tsx';

/* Usually it would be fine to leverage the existing interval position data structures.
 * But, we want to front-load the calculations so during interaction time we minimise the overhead
 * and complexity of the already demanding interaction logic.
 * - Filter out interval groups that should not be interacted with
 * - Calculate the positions that are going to be used to determine the target interval
 */

export const getSimpleIntervals = memoizeOne(
	(
		intervals: ReadonlyArray<Interval>,
		timelineDuration: TimelineDuration,
		timelineWidth: number,
	): ReadonlyArray<SimpleInterval> => {
		const intervalGroups = getIntervalGroupPositions(intervals, timelineDuration, timelineWidth);

		const simpleIntervals: Array<{
			leftPosition: number;
			rightPosition: number;
			targetId: string;
			intervalIds: string[];
		}> = [];

		intervalGroups.forEach((intervalGroup) => {
			const leftPosition = Math.max(intervalGroup.left, intervalGroup.targetIntervalLeft);
			const rightPosition = Math.max(intervalGroup.right, intervalGroup.targetIntervalRight);

			const targetInterval = intervalGroup.intervals.find((interval) => interval.state !== CLOSED);

			const intervalIds = intervals.map((interval) => interval.id);

			if (targetInterval) {
				simpleIntervals.push({
					targetId: targetInterval.id,
					leftPosition,
					rightPosition,
					intervalIds,
				});
			}
		});

		return simpleIntervals;
	},
);
