import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import { getGoalsHash } from '../../state/entities/goals/selectors.tsx';
import type { State } from '../../state/types.tsx';
import {
	type SetGoalFilterAction as Action,
	SET_GOAL_FILTER,
} from '../../state/ui/filters/actions.tsx';
import type { StateEpic } from '../common/types.tsx';
import updateQuery from '../common/update-query/index.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
	{ router: { push } },
) =>
	action$.ofType(SET_GOAL_FILTER).map((action: Action) => {
		const state = store.getState();
		const goals = getGoalsHash(state);

		return updateQuery(push, store.getState(), (query) => ({
			...query,
			goal:
				action.payload.length > 0
					? action.payload.reduce<string[]>((acc, id) => {
							const goal = goals[id];
							if (goal) {
								acc.push(goal.goal.key);
							}
							return acc;
						}, [])
					: undefined,
		}));
	})) as StateEpic;
