import type { Dispatch } from 'redux';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueFieldModification } from '../../model/issue/index.tsx';
import {
	updateAndPersistIssueWithoutSprintScopeCheck,
	type UpdateIssueAction,
	resetTransitionState,
	type ResetTransitionStateAction,
} from '../../state/entities/issues/actions.tsx';
import { getIssueKeyById } from '../../state/entities/issues/selectors.tsx';
import type { State } from '../../state/types.tsx';
import { InteractedItemsLimitExceededWithActionsFlag, type Props } from './view.tsx';

type Action = UpdateIssueAction | ResetTransitionStateAction;

type OwnProps = {
	id: string | number;
	transitionId: string;
	issueId: IssueId;
	hasOverdueReleaseDate: boolean | undefined;
	properties: IssueFieldModification;
	sourceEvent: UIAnalyticsEvent;
	onDismissed?: (id: string | number) => void;
};

type StateProps = {
	issueKey: IssueKey | undefined;
};

type DispatchProps = {
	onPersist: (id: number | string, analyticsEvent: UIAnalyticsEvent) => void;
	onUndo: (id: number | string, analyticsEvent: UIAnalyticsEvent) => void;
};

const mapStateToProps = (state: State, { issueId }: OwnProps): StateProps => ({
	issueKey: getIssueKeyById(state, issueId),
});

const mapDispatchToProps = (
	dispatch: Dispatch<Action>,
	{ transitionId, issueId, hasOverdueReleaseDate, properties, sourceEvent }: OwnProps,
): DispatchProps => ({
	onPersist: () => {
		dispatch(
			updateAndPersistIssueWithoutSprintScopeCheck(
				issueId,
				properties,
				sourceEvent,
				hasOverdueReleaseDate,
			),
		);
	},
	onUndo: () => {
		dispatch(resetTransitionState({ issueId, transitionId }));
	},
});

const mergeProps = (
	{ issueKey }: StateProps,
	dispatchProps: DispatchProps,
	{ id, onDismissed }: OwnProps,
): Props => ({
	...dispatchProps,
	id,
	issueKey,
	onDismissed,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	mergeProps,
)(InteractedItemsLimitExceededWithActionsFlag);
