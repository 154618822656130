import type { State } from './types.tsx';

export const DEFAULT_STATE: State = {
	activeItem: undefined,
	hasLingered: false,
	dragType: undefined,
	itemPositions: {},
	targetIntervalId: undefined,
	isHeaderModalActive: false,
	previewBaselinePosition: undefined,
};
