import { createSelector } from 'reselect';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { createSelectorWithDefaultEqual } from '../../../common/utils/reselect/index.tsx';
import { getSprints } from '../../configuration/selectors.tsx';
import { getIssueSprintIdsHash } from '../../entities/issues/selectors.tsx';
import type { State } from '../../types.tsx';
import { getTimelineDuration } from '../timeline/index.tsx';
import {
	createGetSanitizedSprints as createGetSanitizedSprintsPure,
	createGetIntersectingSprintId as createGetIntersectingSprintIdPure,
	createGetMostRecentSprintId as createGetMostRecentSprintIdPure,
	getSprintName as getSprintNamePure,
	getSprintState as getSprintStatePure,
	getSprintsHash as getSprintsHashPure,
	getSprintIntervals as getSprintIntervalsPure,
	getSanitisedIssueSprintsHash as getSanitisedIssueSprintsHashPure,
	getCurrentlyAssignedSprints as getCurrentlyAssignedSprintsPure,
} from './pure/index.tsx';

/**
 * Returns a function that returns sanitizes sprints.
 * - Swap start and end when appropriate.
 * - Adds ~24h to end to make the end day inclusive.
 * - Filter out sprints that are outside of the timeline.
 */
export const createGetSanitizedSprints = createSelector(getSprints, createGetSanitizedSprintsPure);

export const getSprintsHash = createSelector(getSprints, getSprintsHashPure);

export const createGetIntersectingSprintId = createSelector(
	getTimelineDuration,
	createGetSanitizedSprints,
	createGetIntersectingSprintIdPure,
);

export const getSprintState = createSelector(getSprintsHash, getSprintStatePure);

export const getSprintName = createSelector(getSprintsHash, getSprintNamePure);

/**
 * This "sanitised" selector should be used as it only selects sprints that are in the current board config and avoids getting cross project sprints.
 * This selector will ONLY return the active/future sprint which the issue is currently assigned to,
 * while it'll return previously assigned sprints (closed) when the issue is not assigned to any active/future sprint
 */
export const getSanitisedIssueSprintsHash = createSelector(
	getIssueSprintIdsHash,
	getSprintsHash,
	getSanitisedIssueSprintsHashPure,
);

export const getCurrentlyAssignedSprints = createSelector(
	getSanitisedIssueSprintsHash,
	getCurrentlyAssignedSprintsPure,
);

export const getIsCurrentlyAssignedToSprints = (state: State, id: IssueId): boolean =>
	getCurrentlyAssignedSprints(state)(id).length > 0;

// This selector is similar to getSanitisedIssueSprintIdsHash, except it filters out issues without any sprints.
// This is an optimization to avoid some unnecessary re-renders.
export const getSanitisedIssueSprintIdsHashLite = createSelector(
	getSanitisedIssueSprintsHash,
	(issueIdToSprints) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.keys(issueIdToSprints).reduce<Record<string, any>>((acc, key) => {
			const issueId: IssueId = key;
			if (issueIdToSprints[issueId].length > 0) {
				acc[`${issueId}`] = issueIdToSprints[issueId].map((sprint) => sprint.id);
			}
			return acc;
		}, {}),
);

export const createGetMostRecentSprintId = createSelectorWithDefaultEqual(
	getSanitisedIssueSprintIdsHashLite,
	createGetMostRecentSprintIdPure,
);

// returned function does not need to be memoized as it's just doing hash lookups, which is quite cheap
export const createGetIssueSprintDates = createSelector(
	getSprintsHash,
	createGetMostRecentSprintId,
	(sprintsHash, getMostRecentSprintId) => (issueId: IssueId) => {
		const sprintId = getMostRecentSprintId(issueId);
		if (sprintId !== undefined) {
			const sprint = sprintsHash[sprintId];

			return {
				inferredStartDate: sprint.startDate,
				inferredEndDate: sprint.endDate,
			};
		}

		return { inferredStartDate: undefined, inferredEndDate: undefined };
	},
);

export const getSprintIntervals = createSelector(getSprints, getSprintIntervalsPure);
