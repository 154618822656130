import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Hash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type { Sprint } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import {
	ROLLUP,
	EXPLICIT,
	INTERVAL,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import type { Placeholder } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/chart-item.tsx';
import type { DateType } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/date.tsx';
import { getPlaceholder } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/bar/placeholder.tsx';
import type { RolledUpDates } from '../dates/index.tsx';
import { fallbackToInferredDate } from './fallback-dates/index.tsx';

type ParentIssueDatesOld = {
	startDate: number | undefined;
	dueDate: number | undefined;
	isStartDateInferred: boolean;
	isDueDateInferred: boolean;
	isStartDatePlaceholder: boolean;
	isDueDatePlaceholder: boolean;
};

type IssueDates = {
	startDate: number | undefined;
	dueDate: number | undefined;
	startDateType: DateType;
	dueDateType: DateType;
	placeholder: Placeholder;
	childExceedsRolledUpParent?: undefined | boolean;
};

export const getParentLevelDatesOld = (
	issueStartDateHash: Hash<number | undefined>,
	issueDueDateHash: Hash<number | undefined>,
	parentRolledUpDatesHash: Hash<RolledUpDates>,
	issueId: IssueId,
): ParentIssueDatesOld => {
	const explicitStartDate = issueStartDateHash[issueId];
	const explicitDueDate = issueDueDateHash[issueId];

	const rolledUpDates = parentRolledUpDatesHash[issueId] || {
		inferredStartDate: undefined,
		inferredEndDate: undefined,
	};
	const { startDate, dueDate, isStartDatePlaceholder, isDueDatePlaceholder } =
		fallbackToInferredDate({ explicitStartDate, explicitDueDate }, rolledUpDates);

	return {
		startDate,
		dueDate,
		isStartDateInferred: explicitStartDate === undefined && startDate !== undefined,
		isDueDateInferred: explicitDueDate === undefined && dueDate !== undefined,
		isStartDatePlaceholder,
		isDueDatePlaceholder,
	};
};

const getChildExceedsRolledUpParent = (
	rolledUpDates: RolledUpDates,
	explicitStartDate?: number,
	explicitDueDate?: number,
	rollUpStartDate?: number,
	rollUpDueDate?: number,
): {
	childStartsAfterParent: boolean;
	childEndsBeforeParent: boolean;
} => ({
	childStartsAfterParent:
		explicitStartDate === undefined &&
		explicitDueDate !== undefined &&
		rolledUpDates.startDate !== undefined &&
		rollUpDueDate !== undefined &&
		rolledUpDates.startDate > rollUpDueDate &&
		rolledUpDates.latestStartDate !== undefined &&
		rolledUpDates.latestStartDate > explicitDueDate,
	childEndsBeforeParent:
		explicitStartDate !== undefined &&
		explicitDueDate === undefined &&
		rolledUpDates.dueDate !== undefined &&
		rollUpStartDate !== undefined &&
		rolledUpDates.dueDate < rollUpStartDate &&
		rolledUpDates.earliestDueDate !== undefined &&
		rolledUpDates.earliestDueDate < explicitStartDate,
});

export const getParentLevelDates = (
	explicitStartDate: number | undefined,
	explicitDueDate: number | undefined,
	parentRolledUpDates: RolledUpDates | undefined,
	isChildIssuePlanningEnabled: boolean,
): IssueDates => {
	const rolledUpDates =
		isChildIssuePlanningEnabled && parentRolledUpDates
			? parentRolledUpDates
			: {
					startDate: undefined,
					dueDate: undefined,
					latestStartDate: undefined,
					earliestDueDate: undefined,
					isStartDatePlaceholder: false,
					isDueDatePlaceholder: false,
				};
	const {
		startDate: rollUpStartDate,
		dueDate: rollUpDueDate,
		isStartDatePlaceholder,
		isDueDatePlaceholder,
	} = fallbackToInferredDate({ explicitStartDate, explicitDueDate }, rolledUpDates);

	const { childStartsAfterParent, childEndsBeforeParent } = getChildExceedsRolledUpParent(
		rolledUpDates,
		explicitStartDate,
		explicitDueDate,
		rollUpStartDate,
		rollUpDueDate,
	);

	const hasLeftPlaceholder = isChildIssuePlanningEnabled
		? isStartDatePlaceholder || rollUpStartDate === undefined || childEndsBeforeParent
		: rollUpStartDate === undefined;
	const hasRightPlaceholder = isChildIssuePlanningEnabled
		? isDueDatePlaceholder || rollUpDueDate === undefined || childStartsAfterParent
		: rollUpDueDate === undefined;

	return {
		startDate: rollUpStartDate,
		dueDate: rollUpDueDate,
		startDateType:
			explicitStartDate === undefined && rollUpStartDate !== undefined && !childStartsAfterParent
				? ROLLUP
				: EXPLICIT,
		dueDateType:
			explicitDueDate === undefined && rollUpDueDate !== undefined && !childEndsBeforeParent
				? ROLLUP
				: EXPLICIT,
		placeholder: getPlaceholder(hasLeftPlaceholder, hasRightPlaceholder),
		childExceedsRolledUpParent: childStartsAfterParent || childEndsBeforeParent,
	};
};

export const getBaseLevelDates = (
	explicitStartDate: number | undefined,
	explicitDueDate: number | undefined,
	sprints: Sprint[] | undefined,
	isSprintsPlanning: boolean,
	isChildIssuePlanningEnabled: boolean,
): IssueDates => {
	if (!isSprintsPlanning) {
		const issueStartDate = isChildIssuePlanningEnabled ? explicitStartDate : undefined;
		const issueDueDate = isChildIssuePlanningEnabled ? explicitDueDate : undefined;

		return {
			startDateType: EXPLICIT,
			dueDateType: EXPLICIT,
			placeholder: getPlaceholder(issueStartDate === undefined, issueDueDate === undefined),
			startDate: issueStartDate,
			dueDate: issueDueDate,
		};
	}

	const issueSprints = sprints || [];
	const inferredStartDate = isChildIssuePlanningEnabled ? issueSprints[0]?.startDate : undefined;
	const inferredDueDate = isChildIssuePlanningEnabled ? issueSprints[0]?.endDate : undefined;

	return {
		startDateType: INTERVAL,
		dueDateType: INTERVAL,
		placeholder: undefined,
		startDate: inferredStartDate,
		dueDate: inferredDueDate,
	};
};
