import React, { useEffect } from 'react';
import noop from 'lodash/noop';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type Props = {
	id: string | number;
	href: string;
	onDismissed: (id: string | number) => void;
};

const RoadmapErrorFlag = ({ id, href, onDismissed = noop }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onDismissedHandler = () => {
		onDismissed(id);
	};

	useEffect(() => {
		fireTrackAnalytics(
			createAnalyticsEvent({ action: 'viewed', actionSubject: 'roadmapErrorFlag' }),
			'viewed roadmapErrorFlag',
			{
				cause: 'unknown',
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ErrorFlag
			onDismissed={onDismissedHandler}
			id={id}
			title={formatMessage(messages.title)}
			description={formatMessage(messages.flagDescription)}
			actions={[
				{
					content: formatMessage(messages.reloadPageButton),
					href,
				},
			]}
		/>
	);
};

export default RoadmapErrorFlag;
