/** @jsx jsx */
import React, {
	memo,
	useCallback,
	type KeyboardEvent,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type MouseEvent,
} from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { IDENTIFY_SMALL_CHUNKS_OF_WORK_TASK } from '@atlassian/jira-onboarding-quickstart-core/src/common/constants.tsx';
import {
	useOpenTask,
	useHasNudgeActive,
} from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { RoadmapCreateEpicChildIssueButtonWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/roadmap-create-epic-child-issue/async.tsx';
import { RoadmapListItemContent } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/list-item-content/index.tsx';
import { INSIDE } from '@atlassian/jira-software-roadmap-timeline-table/src/common/constants.tsx';
import type { OnCreateClick } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/callbacks.tsx';
import messages from './messages.tsx';

type ListItemContentProps = JSX.LibraryManagedAttributes<
	typeof RoadmapListItemContent,
	ComponentProps<typeof RoadmapListItemContent>
>;
type Props = {
	isNudgeEnabled: boolean;
	id: IssueId;
} & ListItemContentProps & {
		onChildCreateClick: OnCreateClick;
	};

const ListItemContentWithNudge = ({
	isNudgeEnabled,
	onChildCreateClick,
	id,
	...otherProps
}: Props) => {
	const { formatMessage } = useIntl();
	const [openTask] = useOpenTask();
	const [hasNudgeActive] = useHasNudgeActive();

	const onClick = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			onChildCreateClick({ parentId: id, position: INSIDE });
		},
		[id, onChildCreateClick],
	);

	const onKeyDown = useCallback(
		(event: KeyboardEvent<HTMLDivElement>) => {
			if (event.key === 'Enter') {
				event.stopPropagation();
				onChildCreateClick({ parentId: id, position: INSIDE });
			}
		},
		[id, onChildCreateClick],
	);

	if (hasNudgeActive && openTask === IDENTIFY_SMALL_CHUNKS_OF_WORK_TASK) {
		return (
			<>
				<RoadmapListItemContent {...otherProps} />
				<RoadmapCreateEpicChildIssueButtonWrapperNudgeAsync>
					<div
						css={fakeChildCreateButtonStyles}
						onClick={onClick}
						role="button"
						aria-label={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.createChildIssueTermRefresh
								: messages.createChild,
						)}
						tabIndex={0}
						onKeyDown={onKeyDown}
					/>
				</RoadmapCreateEpicChildIssueButtonWrapperNudgeAsync>
			</>
		);
	}

	return <RoadmapListItemContent {...otherProps} />;
};

const ListItemContent = (props: Props) => {
	const { isNudgeEnabled, onChildCreateClick, id, ...otherProps } = props;

	if (!__SERVER__ && isNudgeEnabled) {
		return <ListItemContentWithNudge {...props} />;
	}
	return <RoadmapListItemContent {...otherProps} />;
};

ListItemContent.whyDidYouRender = true;

export default memo<Props>(ListItemContent);

const fakeChildCreateButtonStyles = css({
	top: `calc(50% - ${token('space.150', '12px')})`,
	right: token('space.075', '6px'),
	width: token('space.300', '24px'),
	height: token('space.300', '24px'),
	pointerEvents: 'auto',
	cursor: 'pointer',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&': {
		position: 'absolute',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before, &:after': {
		display: 'none',
	},
});
