// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { RoadmapChartItemInteractionProvider } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/controllers/table-providers/chart-item-interaction/main.tsx';
import type { RenderProviderProps as ProviderRendererProps } from '@atlassian/jira-software-roadmap-timeline-table/src/renderers/index.tsx';
import {
	getIsSprintsPlanningEnabled,
	getIsChildIssuePlanningEnabled,
} from '../../../../state/configuration/selectors.tsx';
import { getSprintIntervals } from '../../../../state/selectors/sprint/index.tsx';
import type { State } from '../../../../state/types.tsx';
import { getInteractiveChartItems, getSelectedItemIdsMemoized } from './selectors.tsx';

type StateProps = Omit<ComponentProps<typeof RoadmapChartItemInteractionProvider>, 'children'>;

const Provider = connect(
	(state: State): StateProps => ({
		items: getInteractiveChartItems(state),
		selectedItemIds: getSelectedItemIdsMemoized(state),
		intervals: getSprintIntervals(state),
		isDragAlongEnabled:
			getIsChildIssuePlanningEnabled(state) && !getIsSprintsPlanningEnabled(state),
	}),
	{},
)(RoadmapChartItemInteractionProvider);

export const renderProvider = ({ children }: ProviderRendererProps) => (
	<Provider>{children}</Provider>
);
