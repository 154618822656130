import { useCallback, type KeyboardEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { useEnablements } from '../../context/enablements/index.tsx';
import { useIsItemSelected, useIsItemSelectable } from '../../context/items/index.tsx';
import type { ItemId } from '../../types/item.tsx';

type ClickEventParams = {
	ctrlKey?: boolean;
	metaKey?: boolean;
	shiftKey: boolean;
	currentTarget: EventTarget | null;
};

export type ItemClickHandler = (
	eventParams: ClickEventParams,
	analyticsEvent: UIAnalyticsEvent,
	ref?: React.RefObject<HTMLElement>,
) => void;

export type ItemKeyDownHandler = (event: KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => void;

export const useItemSelect = (id: ItemId) => {
	const [{ isMultiSelectEnabled, isSelectEnabled }] = useEnablements();
	const [selected = false, { toggleItem, activateItem }] = useIsItemSelected(id);
	const [isItemSelectable = false] = useIsItemSelectable(id);

	const handleClick: ItemClickHandler = useCallback(
		({ ctrlKey, metaKey, shiftKey, currentTarget }, analyticsEvent, ref) => {
			const isCheckbox =
				currentTarget instanceof HTMLInputElement && currentTarget.type === 'checkbox';

			// User intention is to toggle the item when it's a checkbox or user using key modifiers
			const shouldOnlyToggleItem =
				isMultiSelectEnabled && (isCheckbox || ctrlKey || metaKey || shiftKey);

			if (shouldOnlyToggleItem && isItemSelectable) {
				return toggleItem(id, {
					analyticsEvent,
					withRange: shiftKey,
				});
			}

			if (isSelectEnabled && !shouldOnlyToggleItem) {
				return fg('jsw_roadmaps-timeline-bento-refocusing')
					? activateItem(id, ref)
					: activateItem(id);
			}
		},
		[isSelectEnabled, isMultiSelectEnabled, activateItem, id, isItemSelectable, toggleItem],
	);

	const handleKeyDown: ItemKeyDownHandler = useCallback(
		({ shiftKey, ctrlKey, metaKey, altKey, key }, analyticsEvent) => {
			if (
				isMultiSelectEnabled &&
				isItemSelectable &&
				shiftKey &&
				key === ' ' &&
				!(
					/* User may have intension other than toggling the item with these modifiers - we only handle Shift + Space */
					(ctrlKey || metaKey || altKey)
				)
			) {
				toggleItem(id, { analyticsEvent });
			}
		},
		[id, isItemSelectable, isMultiSelectEnabled, toggleItem],
	);

	return { selectable: isItemSelectable, selected, handleClick, handleKeyDown };
};
