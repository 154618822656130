import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getRoadmapLoadedAnalyticsData } from '../../state/selectors/analytics/index.tsx';
import type { State } from '../../state/types.tsx';
import MountAnalytic from './view.tsx';

export default connect(
	(state: State) => ({
		attributes: getRoadmapLoadedAnalyticsData(state),
	}),
	{},
)(MountAnalytic);
