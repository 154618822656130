/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@compiled/react';
import { zIndex } from '@atlassian/jira-software-roadmap-timeline-table/src/common/constants.tsx';
import { ISSUE_DETAILS, type PanelType } from '../../../model/panel/index.tsx';
import Resizer, { ResizerLazy } from './resizer/async.tsx';

export type Props = {
	panelType: PanelType;
	issuePanelRatio: number;
	setIssuePanelRatio: (r: number) => void;
};

export const RoadmapPanel = ({ panelType, issuePanelRatio, setIssuePanelRatio }: Props) => {
	useEffect(() => {
		ResizerLazy.preload();
	}, []);

	if (panelType === ISSUE_DETAILS) {
		return (
			<div css={resizerWrapperStyles}>
				<div css={staticContainerStyles} />
				<Resizer issuePanelRatio={issuePanelRatio} setIssuePanelRatio={setIssuePanelRatio} />
			</div>
		);
	}

	return null;
};

const staticContainerStyles = css({
	flex: '1 0 0',
	height: '100%',
});

const resizerWrapperStyles = css({
	position: 'absolute',
	display: 'flex',
	width: '100%',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.HIGHEST,
	pointerEvents: 'none',
});
