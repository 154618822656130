import type { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { usePortalScope } from '../context/index.tsx';

interface Props {
	children: ReactNode;
}

const Portal = ({ children }: Props) => {
	const { portalContainerElement } = usePortalScope();

	if (portalContainerElement) {
		return createPortal(children, portalContainerElement);
	}
	return null;
};

export default Portal;
