import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import projectIssueTypesConcreteQuery, {
	type projectIssueTypesQuery,
} from '@atlassian/jira-relay/src/__generated__/projectIssueTypesQuery.graphql';
import { type IssueTypeId, toIssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { generateProjectIssueTypesQueryVariables } from '@atlassian/jira-software-roadmap-data-queries/src/queries/project-issue-types/index.tsx';
import type { IdentifiableHash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type { HealthcheckIssueType } from '@atlassian/jira-software-roadmap-model/src/healthcheck/index.tsx';
import { LOAD_HEALTHCHECK_ISSUE_TYPES } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runQueryWithMetadata } from '../common/agg/run-query.tsx';
import { queryMetrics } from '../performance-analytics/index.tsx';

const analyticKey = 'jira.frontend.fe.software.roadmap.filter-roadmap-items';
const concurrentMetricDefinition = queryMetrics[LOAD_HEALTHCHECK_ISSUE_TYPES];

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (projectAri: Ari): Observable<IdentifiableHash<IssueTypeId, HealthcheckIssueType>> =>
	runQueryWithMetadata<projectIssueTypesQuery>(
		projectIssueTypesConcreteQuery,
		generateProjectIssueTypesQueryVariables(projectAri),
		analyticKey,
		concurrentMetricDefinition,
	).map(({ data }) => {
		const issueTypes: IdentifiableHash<IssueTypeId, HealthcheckIssueType> = {};

		data.jira.jiraProject.issueTypes.edges.forEach((edge) => {
			if (edge) {
				const {
					node: { issueTypeId, avatar, name, description },
				} = edge;
				issueTypes[toIssueTypeId(issueTypeId)] = {
					name,
					description: description || '',
					iconUrl: avatar.medium || '',
				};
			}
		});

		return issueTypes;
	});
