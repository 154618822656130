import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { RoadmapTimelineMode } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-overlay/timeline-mode/index.tsx';
import type { TimelineMode } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import { setTimelineMode } from '../../../../../../state/ui/filters/actions.tsx';

const onClick = (timelineMode: TimelineMode) => setTimelineMode(timelineMode);

export default connect(null, {
	onClick,
})(RoadmapTimelineMode);
