/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

const iconWrapperStyles = css({
	display: 'flex',
	width: '20px',
	height: '20px',
	borderRadius: '50%',
	alignItems: 'center',
	justifyContent: 'center',
	boxShadow: token('elevation.shadow.raised', '0 2px 4px 0 rgba(0, 0, 0, 0.25)'),
});

const iconWrapperExtraPadding = css({
	padding: token('space.025'),
});

const iconWrapperExtraBorderRadius = css({
	borderRadius: '3px',
});

type Props = {
	color: string;
	isColorInverted?: boolean;
};

const DependencyDragHandler = ({ color, isColorInverted }: Props) => (
	<div
		css={[
			iconWrapperStyles,
			isVisualRefreshEnabled() && !fg('visual-refresh_drop_1') && iconWrapperExtraPadding, // delete when cleaning up fg('visual-refresh_drop_1')
			isVisualRefreshEnabled() && fg('visual-refresh_drop_1') && iconWrapperExtraBorderRadius,
		]}
		style={{ backgroundColor: color }}
	>
		<LinkIcon
			LEGACY_size="small"
			label=""
			color={
				isColorInverted && fg('jsw_roadmaps_timeline-post-project-a11y-fix')
					? token('color.icon')
					: token('color.icon.inverse', N0)
			}
		/>
	</div>
);

export { DependencyDragHandler };
