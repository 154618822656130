import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import isEmpty from 'lodash/isEmpty';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import { Observable } from 'rxjs/Observable';
import { getSourceARI, isClassic } from '../../../state/app/selectors.tsx';
import {
	type DeriveFieldsRequestAction,
	DERIVE_FIELDS_REQUEST,
} from '../../../state/configuration/actions.tsx';
import { getBoardJql } from '../../../state/configuration/selectors.tsx';
import { getCustomFilters, getQuickFilters } from '../../../state/router/selectors.tsx';
import type { State } from '../../../state/types.tsx';
import {
	getFullQuickFilterHash,
	getFullCustomFilterHash,
} from '../../../state/ui/filters/selectors.tsx';
import type { StateEpic } from '../../common/types.tsx';
import { getInitializeDeriveFieldsAction } from './derive-fields.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<DeriveFieldsRequestAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(DERIVE_FIELDS_REQUEST).mergeMap(() => {
		const state = store.getState();
		const sourceAri = getSourceARI(state);
		if (sourceAri !== undefined) {
			const boardJQL = getBoardJql(state);
			if (isClassic(state)) {
				const quickFilters = getFullQuickFilterHash(state);
				const validQuickFilterIds = Object.keys(quickFilters);
				const appliedQuickFilterJQLs = getQuickFilters(state).reduce<string[]>(
					(acc, quickFilterId) => {
						if (validQuickFilterIds.indexOf(quickFilterId) > -1) {
							const quickFilterJQL = quickFilters[quickFilterId]?.query;
							if (quickFilterJQL !== undefined) {
								acc.push(quickFilterJQL);
							}
						}
						return acc;
					},
					[],
				);

				// skip derivedFields fetch if no filters applied
				return isEmpty(appliedQuickFilterJQLs)
					? Observable.empty<never>()
					: getInitializeDeriveFieldsAction(
							sourceAri,
							[...(boardJQL !== null ? [boardJQL] : []), ...appliedQuickFilterJQLs],
							[],
						);
			}
			const customFilters = getFullCustomFilterHash(state);
			const validCustomFilterIds = Object.keys(customFilters);
			const appliedCustomFilterIds = getCustomFilters(state).filter(
				(customFilterId) => validCustomFilterIds.indexOf(customFilterId) > -1,
			);

			// skip derivedFields fetch if no filters applied
			return isEmpty(appliedCustomFilterIds)
				? Observable.empty<never>()
				: getInitializeDeriveFieldsAction(
						sourceAri,
						boardJQL !== null ? [boardJQL] : [],
						appliedCustomFilterIds,
					);
		}

		return Observable.empty<never>();
	})) as StateEpic;
