import { createSelector } from 'reselect';
import { buildChannelString } from '@atlassian/jira-realtime/src/common/utils/pubsub.utils.tsx';
import { getCloudId } from '../app/selectors.tsx';
import { getProjectId } from '../configuration/selectors.tsx';

const EMPTY_CHANNELS: Array<string> = [];

export const getRealtimeChannels = createSelector(
	getCloudId,
	getProjectId,
	(cloudId, projectId) => {
		if (cloudId !== undefined) {
			return [buildChannelString(cloudId, projectId)];
		}

		return EMPTY_CHANNELS;
	},
);
