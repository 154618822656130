import { createSelector } from 'reselect';
import type { IssueId, IssueStatusCategoryId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IdentifiableHash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type { SubtaskHash } from '@atlassian/jira-software-roadmap-model/src/subtask/index.tsx';
import { getDoneStatusCategoryId } from '../entities/status-categories/selectors.tsx';
import { getFullSubtasksHash } from '../entities/subtasks/selectors.tsx';
import type { State } from '../types.tsx';

type IssueSubtaskProgress = {
	total: number;
	completed: number;
};

export const DEFAULT_PROGRESS_META_DATA = { total: 0, completed: 0 };
export const getIssueSubtaskProgressPure = (
	subtasksHash: SubtaskHash,
	doneStatusCategoryId: IssueStatusCategoryId | undefined,
): IdentifiableHash<IssueId, IssueSubtaskProgress> => {
	const subtaskProgressHash: IdentifiableHash<IssueId, IssueSubtaskProgress> = {};
	Object.keys(subtasksHash).forEach((issueId) => {
		const subtasks = subtasksHash[issueId];
		subtaskProgressHash[issueId] = {
			total: subtasks.length,
			completed: subtasks.filter(
				({ statusCategoryId }) => statusCategoryId === doneStatusCategoryId,
			).length,
		};
	});

	return subtaskProgressHash;
};

export const getIssueSubtaskProgressHash = createSelector(
	getFullSubtasksHash,
	getDoneStatusCategoryId,
	getIssueSubtaskProgressPure,
);

export const getIssueSubtaskProgress = (state: State, issueId: IssueId): IssueSubtaskProgress =>
	getIssueSubtaskProgressHash(state)[issueId] || DEFAULT_PROGRESS_META_DATA;

// Return { subtaskId: parentId } hash
export const getSubtaskParentHash = createSelector(
	getFullSubtasksHash,
	(subtaskHash: SubtaskHash) => {
		const parentHash: IdentifiableHash<IssueId, IssueId> = {};
		Object.keys(subtaskHash).forEach((issueId) => {
			subtaskHash[issueId].forEach(({ id: subtaskId }) => {
				parentHash[subtaskId] = issueId;
			});
		});

		return parentHash;
	},
);
