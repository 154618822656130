import React from 'react';
import type { ListItemModalProps } from '@atlassian/jira-software-roadmap-timeline-table/src/renderers/index.tsx';
import { BULK_EDIT_DATES, EDIT_DATES, EDIT_DEPENDENCY } from '../../../../constants.tsx';
import { RoadmapBulkEditDatesModalAsync } from './bulk-edit-dates-modal/async.tsx';
import { RoadmapEditDatesModalAsync } from './edit-dates-modal/async.tsx';
import { RoadmapEditDependencyModalAsync } from './edit-dependency-modal/async.tsx';

const ListItemModal = ({ id, optionId, triggerRef, setCloseModal }: ListItemModalProps) => {
	switch (optionId) {
		case EDIT_DATES:
			return (
				<RoadmapEditDatesModalAsync
					id={id}
					isOpen
					setCloseModal={setCloseModal}
					triggerRef={triggerRef}
				/>
			);

		case BULK_EDIT_DATES:
			return (
				<RoadmapBulkEditDatesModalAsync
					id={id}
					isOpen
					setCloseModal={setCloseModal}
					triggerRef={triggerRef}
				/>
			);

		case EDIT_DEPENDENCY:
			return (
				<RoadmapEditDependencyModalAsync
					id={id}
					isOpen
					setCloseModal={setCloseModal}
					triggerRef={triggerRef}
				/>
			);

		default:
			return null;
	}
};

export default ListItemModal;
