import {
	SET_FILTER_CONFIGURATION,
	type SetFilterConfigurationAction,
	QUICK_FILTERS_REQUEST,
	type QuickFiltersRequestAction,
	CUSTOM_FILTERS_REQUEST,
	type CustomFiltersRequestAction,
	JQL_FILTERS_SUCCESS,
	type JQLFiltersSuccessAction,
	QUICK_FILTERS_CLEAR,
	type QuickFiltersClearAction,
	CUSTOM_FILTERS_CLEAR,
	type CustomFiltersClearAction,
} from './actions.tsx';
import type { FilterState } from './types.tsx';

type Action =
	| SetFilterConfigurationAction
	| QuickFiltersRequestAction
	| CustomFiltersRequestAction
	| JQLFiltersSuccessAction
	| QuickFiltersClearAction
	| CustomFiltersClearAction;

const initialState: FilterState = {
	configuration: {
		quickFilters: {},
		customFilters: {},
	},
	issueIds: [],
	isJQLFilterActive: false,
	isJQLFilterLoading: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: FilterState = initialState, action: Action): FilterState => {
	switch (action.type) {
		case SET_FILTER_CONFIGURATION:
			return {
				...state,
				configuration: action.payload,
			};
		case CUSTOM_FILTERS_REQUEST:
		case QUICK_FILTERS_REQUEST:
			return {
				...state,
				isJQLFilterLoading: true,
			};
		case JQL_FILTERS_SUCCESS:
			return {
				...state,
				isJQLFilterActive: true,
				isJQLFilterLoading: false,
				issueIds: action.payload,
			};
		case CUSTOM_FILTERS_CLEAR:
		case QUICK_FILTERS_CLEAR:
			return {
				...state,
				isJQLFilterActive: initialState.isJQLFilterActive,
				isJQLFilterLoading: initialState.isJQLFilterLoading,
				issueIds: initialState.issueIds,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
