import memoizeOne from 'memoize-one';
import { N0, N20, N50A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { CREATE_ITEM_TYPE } from '../constants.tsx';
import type { FlattenedEnrichedItem } from '../types/hierarchy-enriched-item.tsx';

/**
 * Generate rows of height, child items' height will be combined into parent item's entry
 * Each entry is a new alternate row
 * All the children follow their parent's background color
 */
export const generateAlternateRows = memoizeOne(
	(items: FlattenedEnrichedItem[], itemHeight: number): number[] => {
		if (!items.length) {
			return [];
		}

		const rows: Array<number> = [];

		// we should only iterate on the top level items (depth === 0)
		// Only the root items alternate the bg color ¯\_(ツ)_/¯

		let createItemIndex = -1;
		items
			.filter(({ meta }) => meta?.parentId === undefined)
			.forEach((item: FlattenedEnrichedItem, index) => {
				// Row height includes child items' height which is already calculated,
				// and the item's height itself in meta

				// Only the last create-item row alternates bg color ¯\_(ツ)_/¯
				if (item.type === CREATE_ITEM_TYPE) {
					if (index === items.length - 1) {
						rows.push(itemHeight);
					} else {
						// save the create index, so we could add it up to the previous (or next) row after calculation
						createItemIndex = index;
					}
				} else {
					// This is an ITEM_TYPE,
					// childItemsHeight would be zero if the item is not expanded
					rows.push((item.meta?.childItemsHeight || 0) + itemHeight);
				}
			});

		// if there is a create item at the top level but not last item, add it to the previous row
		// if the create item is the first item, it'll add it to the first ITEM_TYPE
		if (createItemIndex !== -1) {
			rows[Math.max(0, createItemIndex - 1)] += itemHeight;
		}

		return rows;
	},
);

type RowsContainerStyle = {
	itemsBackground: string;
	totalHeight: number;
};

// Generate background and total height from row entries
export const generateStylesFromRows = memoizeOne((rows: number[]): RowsContainerStyle => {
	if (!rows.length) {
		return {
			itemsBackground: token('color.background.neutral', N50A),
			totalHeight: 0,
		};
	}

	const prefix = 'linear-gradient(to bottom,';
	let position = 0;
	/**
	 * Split rows background into smaller chunks due to a bug in Chrome browser
	 * Chrome renders blurry edges when there are more than ~6 entries
	 * https://bugs.chromium.org/p/chromium/issues/detail?id=1103863
	 */
	let count = 0;
	const chunkSize = 4;

	const rowsBackground = rows.reduce((accum: string, curr: number, index: number) => {
		const bgColor =
			index % 2 === 0 ? token('elevation.surface', N0) : token('elevation.surface.sunken', N20);
		let rowStyle = `${bgColor} ${position}px ${position + curr}px`;

		if (count === chunkSize || index === rows.length - 1) {
			count = 0;
			rowStyle += `,transparent ${position + curr}px)`;
			rowStyle += index !== rows.length - 1 ? `,${prefix}` : '';
		} else {
			count += 1;
			rowStyle += ',';
		}

		position += curr;
		return accum + rowStyle;
	}, prefix);

	return { itemsBackground: rowsBackground, totalHeight: position };
});

export const getItemsContainerStyle = memoizeOne(
	(items: FlattenedEnrichedItem[], itemHeight: number): RowsContainerStyle => {
		const rows = generateAlternateRows(items, itemHeight);
		return generateStylesFromRows(rows);
	},
);
