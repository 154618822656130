import { graphql } from 'react-relay';
import type { Observable } from 'rxjs/Observable';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type {
	RoadmapUpdateItemInput,
	updateRoadmapItemMutation$data,
	updateRoadmapItemMutation,
} from '@atlassian/jira-relay/src/__generated__/updateRoadmapItemMutation.graphql';
import { ISSUE_UPDATED } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runMutation } from '../common/agg/run-mutation.tsx';
import { mutationMetrics } from '../performance-analytics/index.tsx';

type InputParams = {
	sourceARI: Ari;
	input: RoadmapUpdateItemInput;
};

const mutationName = 'updateRoadmapItem';
const concurrentMetricDefinition = mutationMetrics[ISSUE_UPDATED];
const analyticKey = 'jira.frontend.fe.software.roadmap.issue-updated';

export const updateItemGraphqlOnServer = ({
	sourceARI,
	input,
}: InputParams): Observable<updateRoadmapItemMutation$data> => {
	return runMutation<updateRoadmapItemMutation>(
		mutationName,
		graphql`
			mutation updateRoadmapItemMutation($sourceARI: ID!, $input: RoadmapUpdateItemInput!) {
				roadmaps {
					updateRoadmapItem(sourceARI: $sourceARI, input: $input) {
						success
						errors {
							message
							extensions {
								... on RoadmapMutationErrorExtension {
									statusCode
									errorType
								}
							}
						}
					}
				}
			}
		`,
		{ sourceARI, input },
		analyticKey,
		concurrentMetricDefinition,
	);
};
