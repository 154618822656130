import { SET_METADATA, type SetMetadataAction } from './actions.tsx';
import type { MetadataState } from './types.tsx';

type Action = SetMetadataAction;

const defaultState: MetadataState = {
	traceIds: [],
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: MetadataState = defaultState, action: Action): MetadataState => {
	switch (action.type) {
		case SET_METADATA:
			return {
				...state,
				...action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
