import type React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { CreateItemAnchor } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/create.tsx';
import type { TooltipParams } from '../../../model/table/index.tsx';

// set/reset create item anchor

export const SET_CREATE_ITEM_ANCHOR = 'state.ui.table.SET_CREATE_ITEM_ANCHOR' as const;

export type SetCreateItemAnchorAction = {
	type: typeof SET_CREATE_ITEM_ANCHOR;
	payload: CreateItemAnchor;
};

export const setCreateItemAnchor = (payload: CreateItemAnchor): SetCreateItemAnchorAction => ({
	type: SET_CREATE_ITEM_ANCHOR,
	payload,
});

export const RESET_CREATE_ITEM_ANCHOR = 'state.ui.table.RESET_CREATE_ITEM_ANCHOR' as const;

export type ResetCreateItemAnchorAction = {
	type: typeof RESET_CREATE_ITEM_ANCHOR;
};

export const resetCreateItemAnchor = (): ResetCreateItemAnchorAction => ({
	type: RESET_CREATE_ITEM_ANCHOR,
});

// change expansion state

export const CHANGE_EXPANSION_STATE = 'state.ui.table.CHANGE_EXPANSION_STATE' as const;

export type ChangeExpansionStatePayload = {
	id: IssueId;
	isExpanded: boolean;
};

export type ChangeExpansionStateAction = {
	type: typeof CHANGE_EXPANSION_STATE;
	payload: ChangeExpansionStatePayload;
};

export const changeExpansionState = (
	payload: ChangeExpansionStatePayload,
): ChangeExpansionStateAction => ({
	type: CHANGE_EXPANSION_STATE,
	payload,
});

export const BULK_CHANGE_EXPANSION_STATE = 'state.ui.table.BULK_CHANGE_EXPANSION_STATE';

export type BulkChangeExpansionStatePayload = {
	ids: IssueId[];
	isExpanded: boolean;
};

export type BulkChangeExpansionStateAction = {
	type: typeof BULK_CHANGE_EXPANSION_STATE;
	payload: BulkChangeExpansionStatePayload;
};

export const bulkChangeExpansionState = (
	payload: BulkChangeExpansionStatePayload,
): BulkChangeExpansionStateAction => ({
	type: BULK_CHANGE_EXPANSION_STATE,
	payload,
});

export const EXPAND_ALL_ISSUES = 'state.ui.table.EXPAND_ALL_ISSUES';

export type ExpandAllIssuesAction = {
	type: typeof EXPAND_ALL_ISSUES;
};

export const expandAllIssues = (): ExpandAllIssuesAction => ({
	type: EXPAND_ALL_ISSUES,
});

export const COLLAPSE_ALL_ISSUES = 'state.ui.table.COLLAPSE_ALL_ISSUES';

export type CollapseAllIssuesAction = {
	type: typeof COLLAPSE_ALL_ISSUES;
};

export const collapseAllIssues = (): CollapseAllIssuesAction => ({
	type: COLLAPSE_ALL_ISSUES,
});

// show/hide table tooltip
export const SHOW_TOOLTIP = 'state.ui.table.SHOW_TOOLTIP';

export type ShowToolTipAction = {
	type: typeof SHOW_TOOLTIP;
	payload: TooltipParams;
};

export const showToolTip = (payload: TooltipParams): ShowToolTipAction => ({
	type: SHOW_TOOLTIP,
	payload,
});

export const HIDE_TOOLTIP = 'state.ui.table.HIDE_TOOLTIP';

export type HideTooltipAction = {
	type: typeof HIDE_TOOLTIP;
};

export const hideToolTip = (): HideTooltipAction => ({
	type: HIDE_TOOLTIP,
});

// select items
export const SELECT_ITEMS = 'state.ui.table.SELECT_ITEMS'; // Remove with project_timeline_multi-select_and_checkboxes

// Remove with project_timeline_multi-select_and_checkboxes
type SelectItemsParams = {
	id: IssueId;
	level: number;
	withCmd: boolean;
	withShift: boolean;
};

// Remove with project_timeline_multi-select_and_checkboxes
export type SelectItemsAction = {
	type: typeof SELECT_ITEMS;
	payload: SelectItemsParams;
	meta: { analyticsEvent: UIAnalyticsEvent };
};

// Remove with project_timeline_multi-select_and_checkboxes
export const selectItems = (
	payload: SelectItemsParams,
	analyticsEvent: UIAnalyticsEvent,
): SelectItemsAction => ({
	type: SELECT_ITEMS,
	payload,
	meta: { analyticsEvent },
});

// handle selected items change
export const SELECT_MULTIPLE_ITEMS = 'state.ui.table.SELECT_MULTIPLE_ITEMS';

export type SelectMultipleItems = {
	type: typeof SELECT_MULTIPLE_ITEMS;
	payload: {
		itemIds: IssueId[];
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const selectMultipleItems = (
	analyticsEvent: UIAnalyticsEvent,
	itemIds: IssueId[],
): SelectMultipleItems => ({
	type: SELECT_MULTIPLE_ITEMS,
	payload: { itemIds, analyticsEvent },
});

// handle selected items change
export const ACTIVATE_ITEM = 'state.ui.table.ACTIVATE_ITEM';

export type ActivateItem = {
	type: typeof ACTIVATE_ITEM;
	payload: {
		itemId: IssueId;
		ref?: React.RefObject<HTMLElement>;
	};
};

export const activateItem = (
	itemId: IssueId,
	ref?: React.RefObject<HTMLElement>,
): ActivateItem => ({
	type: ACTIVATE_ITEM,
	payload: { itemId, ref },
});

export const SET_SELECTED_ITEMS = 'state.ui.table.SET_SELECTED_ITEMS';

export type SetSelectedItemIdsAction = {
	type: typeof SET_SELECTED_ITEMS;
	payload: IssueId[];
};

export const setSelectedItemIds = (payload: IssueId[]): SetSelectedItemIdsAction => ({
	type: SET_SELECTED_ITEMS,
	payload,
});

export const SET_SELECTION_ANCHORS = 'state.ui.table.SET_SELECTION_ANCHORS';

export type SetSelectionAnchorsAction = {
	type: typeof SET_SELECTION_ANCHORS;
	payload: IssueId[];
};

export const setSelectionAnchors = (payload: IssueId[]): SetSelectionAnchorsAction => ({
	type: SET_SELECTION_ANCHORS,
	payload,
});

export const SET_ACTIVE_ITEM_REF = 'state.ui.table.SET_ACTIVE_ITEM_REF';

export type SetActiveItemRefAction = {
	type: typeof SET_ACTIVE_ITEM_REF;
	payload?: React.RefObject<HTMLElement>;
};

export const setActiveItemRef = (
	payload?: React.RefObject<HTMLElement>,
): SetActiveItemRefAction => ({
	type: SET_ACTIVE_ITEM_REF,
	payload,
});
