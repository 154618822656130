import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/zip';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getSafeId } from '../../../../state/entities/issues/selectors.tsx';
import type { State } from '../../../../state/types.tsx';

type Payload = {
	dependee: IssueId;
	dependency: IssueId;
};

type DependencyInput = {
	dependee: IssueId;
	dependency: IssueId;
};

export const getSafeDependencyInput = (
	state: State,
	payload: Payload,
): Observable<DependencyInput> =>
	Observable.zip(
		getSafeId(state, payload.dependee),
		getSafeId(state, payload.dependency),
		(safeDependeeId: IssueId, safeDependencyId: IssueId) => ({
			dependee: safeDependeeId,
			dependency: safeDependencyId,
		}),
	);
