import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { NEXTGEN } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import { INITIALIZE_APP_PROPS, type InitializeAppPropsAction } from './actions.tsx';
import type { AppState } from './types.tsx';

type Action = InitializeAppPropsAction;

const sourceAri = createAri({
	resourceOwner: '',
	cloudId: '',
	resourceType: 'board',
	resourceId: '',
});

const defaultState: AppState = {
	boardId: 0,
	loggedInAccountId: '',
	cloudId: undefined,
	activationId: undefined,
	sourceAri,
	isReadOnly: false,
	macroConfig: undefined,
	type: NEXTGEN,
	containerName: '',
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: AppState = defaultState, action: Action): AppState => {
	switch (action.type) {
		case INITIALIZE_APP_PROPS:
			return {
				...state,
				...action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
