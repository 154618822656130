import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { INTERACTED_ITEMS_LIMIT } from '../../constants.tsx';
import { getIssueChildrenHash } from '../entities/issues/selectors.tsx';
import type { State } from '../types.tsx';

export const getIsInteractedItemsOverLimit = (
	state: State,
	isBaseLevel: boolean,
	toBeSelectedItemIds: IssueId[],
): boolean => {
	if (isBaseLevel) {
		return toBeSelectedItemIds.length > INTERACTED_ITEMS_LIMIT;
	}

	const issueChildrenHash = getIssueChildrenHash(state);
	let childItemsNo = 0;

	toBeSelectedItemIds.forEach((issueId: IssueId) => {
		const childIds = issueChildrenHash[issueId] || [];

		childItemsNo += childIds.length;
	});

	return toBeSelectedItemIds.length + childItemsNo > INTERACTED_ITEMS_LIMIT;
};
