import React from 'react';
import noop from 'lodash/noop';
import { expVal } from '@atlassian/jira-feature-experiments';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import { useIntl } from '@atlassian/jira-intl';
import { docs } from '../../utils/docs.tsx';
import messages from './messages.tsx';

type Props = {
	id: string | number;
	onDismissed: (id: string | number) => void;
};

const IssueUpdatePermissionFlag = ({ id, onDismissed }: Props) => {
	const { formatMessage } = useIntl();

	const title = formatMessage(
		expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.titleUpdateIssueTermRefresh
			: messages.titleUpdate,
	);
	const description = formatMessage(
		expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.descriptionUpdateIssueTermRefresh
			: messages.descriptionUpdate,
	);
	const learnMore = formatMessage(messages.learnMore);

	const onDismissedCallback = () => {
		onDismissed?.(id);
	};

	return (
		<ErrorFlag
			onDismissed={onDismissedCallback}
			id={id}
			title={title}
			description={description}
			actions={[
				{
					content: learnMore,
					href: docs.JIRA_PERMISSIONS_OVERVIEW,
				},
			]}
		/>
	);
};

IssueUpdatePermissionFlag.defaultProps = {
	onDismissed: noop,
};

export default IssueUpdatePermissionFlag;
