// eslint-disable-next-line jira/restricted/react-component-props
import React, { memo, type ComponentProps } from 'react';
import { useTargetIntervalId } from '../../../../controllers/table-providers/chart-item-interaction/main.tsx';
import { IntervalBaseMarker } from './main.tsx';

type Props = JSX.LibraryManagedAttributes<
	typeof IntervalBaseMarker,
	ComponentProps<typeof IntervalBaseMarker>
>;

/* This component wraps the base marker component.
 * It controls whether the marker should react to contextual state changes.
 * Closed intervals will never be highlighted. Thus, it doesn't need to react to contextual state changes.
 */
const Marker = (props: Props) => {
	const { intervals } = props.intervalGroup;

	const [targetIntervalId] = useTargetIntervalId(intervals);

	return <IntervalBaseMarker targetIntervalId={targetIntervalId} {...props} />;
};

Marker.whyDidYouRender = true;
const IntervalMarker = memo<Props>(Marker);
IntervalMarker.displayName = 'IntervalMarker';

export { IntervalMarker };
