import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { Interval } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/interval.tsx';
import { getSprintIntervals } from '../../../../../state/selectors/sprint/index.tsx';
import type { State } from '../../../../../state/types.tsx';
import Sprints from './view.tsx';

type StateProps = {
	intervals: ReadonlyArray<Interval>;
};

const mapStateToProps = (state: State): StateProps => ({
	intervals: getSprintIntervals(state),
});

export default connect(mapStateToProps, {})(Sprints);
