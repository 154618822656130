import React, { type ComponentPropsWithoutRef } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { ROADMAP_PACKAGE_NAME } from '../../../../../../constants.tsx';
import type ChangeColorMenu from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const ChangeColorMenuLazy = lazyAfterPaint<typeof ChangeColorMenu>(
	() => import(/* webpackChunkName: "async-standard-roadmap-change-color-menu" */ './index'),
	{ ssr: false },
);

type Props = JSX.LibraryManagedAttributes<
	typeof ChangeColorMenu,
	ComponentPropsWithoutRef<typeof ChangeColorMenu>
>;

export const ChangeColorMenuAsync = (props: Props) => (
	<JSErrorBoundary
		id="async-standard-roadmap-change-color-menu"
		packageName={ROADMAP_PACKAGE_NAME}
		fallback="flag"
	>
		<Placeholder name="async-standard-roadmap-change-color-menu" fallback={null}>
			<ChangeColorMenuLazy {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
