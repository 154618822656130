import { onDragEnd } from './drag-end/index.tsx';
import { onDragStart } from './drag-start/index.tsx';
import { onDrag } from './drag/index.tsx';
import { onLingerEnd } from './linger-end/index.tsx';
import { onLingerStart } from './linger-start/index.tsx';
import { onLinger } from './linger/index.tsx';
import { onPreview } from './preview/index.tsx';
import { onToggleHeaderModal } from './toggle-header-modal/index.tsx';
import type { Actions } from './types.tsx';

export const actions: Actions = {
	onDrag,
	onDragEnd,
	onDragStart,
	onLinger,
	onLingerEnd,
	onLingerStart,
	onPreview,
	onToggleHeaderModal,
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Actions };
