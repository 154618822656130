import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { fetchCanUserInviteService } from '@atlassian/jira-software-roadmap-services/src/user-invite/index.tsx';
import { INITIALIZE_NON_CRITICAL_DATA } from '../../state/app/actions.tsx';
import { isClassic as getIsClassic } from '../../state/app/selectors.tsx';
import {
	type SetCanUserInviteAction,
	setCanUserInvite,
} from '../../state/configuration/actions.tsx';
import { getProjectId } from '../../state/configuration/selectors.tsx';
import type { State } from '../../state/types.tsx';
import type { StateEpic } from '../common/types.tsx';

type Action = SetCanUserInviteAction;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const getCanUserInviteEpic = ((
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
): Observable<Action> =>
	action$.ofType(INITIALIZE_NON_CRITICAL_DATA).switchMap(() => {
		const state = store.getState();
		const isCMP = getIsClassic(state);

		if (isCMP || !expValEquals('open_invite_for_open_tmp_projects', 'cohort', 'experiment')) {
			return Observable.of();
		}

		const projectId = Number(getProjectId(state));
		return fetchCanUserInviteService(projectId)
			.mergeMap((canUserInviteResponse) => Observable.of(setCanUserInvite(canUserInviteResponse)))
			.catch((error) => {
				if (!isClientFetchError(error)) {
					fireErrorAnalytics({
						error,
						meta: {
							id: 'timeline.can-user-invite.load.failure',
							packageName: 'jiraSoftwareRoadmapStandard',
							teamName: 'chronos',
						},
					});
				}
				return Observable.of();
			});
	})) as StateEpic;
