import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src/config.tsx';
import type { LevelOneSetting } from '@atlassian/jira-software-roadmap-model/src/filter/index.tsx';
import { getLevelOneSetting } from '../../../state/selectors/filters/index.tsx';
import type { State } from '../../../state/types.tsx';
import { setLevelOneSetting } from '../../../state/ui/views/actions.tsx';

export default defineSimpleStorage<State, LevelOneSetting | null>(
	() => 'levelOneViewSettings',
	(levelOneSetting: LevelOneSetting | null) =>
		levelOneSetting === null ? [] : [setLevelOneSetting(levelOneSetting)],
	(state) => getLevelOneSetting(state),
);
