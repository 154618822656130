import React from 'react';
import { ErrorBoundaryWithChunkErrorLogging } from '@atlassian/jira-onboarding-core/src/ui/error-boundary-with-chunk-error-logging/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyKanbanOnboardingModal = lazy(() =>
	import(
		/* webpackChunkName: "async-software-onboarding-modals-kanban-onboarding" */ './index'
	).then(({ KanbanOnboardingModal }) => KanbanOnboardingModal),
);

export const AsyncKanbanOnboardingModal = () => (
	<ErrorBoundaryWithChunkErrorLogging
		id="asyncKanbanOnboardingModalComponent"
		fallback="unmount"
		packageName="jiraSoftwareOnboardingModals"
		teamName="jlove-makkuro"
	>
		<Placeholder name="software-onboarding-modals-kanban-onboarding" fallback={<></>}>
			<LazyKanbanOnboardingModal />
		</Placeholder>
	</ErrorBoundaryWithChunkErrorLogging>
);
