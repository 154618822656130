import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage } from '@atlassian/jira-intl';
import type { DescriptionProps } from '../common/types.tsx';
import messages from './messages.tsx';

const ProjectDescription = ({ issueCount, issueUrls }: DescriptionProps) => {
	const getMessageDescriptor = () => {
		if (issueCount === 1) {
			return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.descriptionIssueTermRefresh
				: messages.description;
		}

		return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.descriptionMultipleIssuesIssueTermRefresh
			: messages.descriptionMultipleIssues;
	};
	const messageDescriptor = getMessageDescriptor();

	return <FormattedMessage {...messageDescriptor} values={{ issueUrls }} />;
};

export default ProjectDescription;
