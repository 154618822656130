import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import { batchActions } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import intersection from 'lodash/intersection';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ChangeEventTypes } from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import { PARENT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { PARENT_LEVEL } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import {
	ASSIGNEE,
	STATUS,
	PARENT_ID,
	type FieldName,
} from '@atlassian/jira-software-roadmap-model/src/issue-view/index.tsx';
import type { Issue } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import { filterItemsByJQLFilters } from '@atlassian/jira-software-roadmap-services/src/issues/filter.tsx';
import {
	mapIssueViewFieldToRoadmapField,
	convertIssueViewFieldValue,
	isFieldUpdateWithoutRequestEvent,
} from '../../../model/issue-view/index.tsx';
import { TODO_STATUS_CATEGORY_ID } from '../../../model/status/index.tsx';
import { getSourceARI } from '../../../state/app/selectors.tsx';
import {
	getStartDateCustomFieldId,
	getColorCustomFieldId,
	getEpicLinkCustomFieldId,
	getSprintCustomFieldId,
} from '../../../state/configuration/selectors.tsx';
import {
	RELOAD_ISSUE,
	extendIssues,
	removeIssue,
	updateIssuesSequence,
	type ReloadIssueAction as Action,
	type ExtendIssuesAction,
} from '../../../state/entities/issues/actions.tsx';
import {
	isIssueExists,
	getIssue,
	getIssueIds,
	getIssueLevel,
} from '../../../state/entities/issues/selectors.tsx';
import {
	updateSubtaskStatus,
	addSubtask,
	changeSubtaskParent,
	removeSubtasks,
} from '../../../state/entities/subtasks/actions.tsx';
import { getFullSubtasksHash } from '../../../state/entities/subtasks/selectors.tsx';
import { getUser } from '../../../state/entities/users/selectors.tsx';
import { type IssueHiddenAction, issueHidden } from '../../../state/flags/actions.tsx';
import { getQuickFilters, getCustomFilters } from '../../../state/router/selectors.tsx';
import { getSubtaskParentHash } from '../../../state/selectors/subtasks.tsx';
import type { State } from '../../../state/types.tsx';
import {
	jqlFiltersSuccess,
	type JQLFiltersSuccessAction,
} from '../../../state/ui/filters/actions.tsx';
import type { StateEpic } from '../../common/types.tsx';
import { reloadIssues } from '../common/reload.tsx';
import { createSetUpdatedConfiguration, createSetUpdatedFilter } from './utils.tsx';

const getRoadmapIssueFieldName = (
	state: State,
	fieldId: string | undefined,
): FieldName | undefined => {
	const colorFieldId = getColorCustomFieldId(state);
	const startDateCustomFieldId = getStartDateCustomFieldId(state);
	const epicLinkCustomFieldId = getEpicLinkCustomFieldId(state);
	const sprintCustomFieldId = getSprintCustomFieldId(state);
	return mapIssueViewFieldToRoadmapField(
		fieldId,
		startDateCustomFieldId,
		colorFieldId,
		epicLinkCustomFieldId,
		sprintCustomFieldId,
	);
};

const updateIssueSequenceOptimistically = (
	store: MiddlewareAPI<State>,
	issueIds: IssueId[],
	isRankAfter: boolean,
	rankId: number,
) => {
	const oldSequence = getIssueIds(store.getState());
	const issueIdsExisted = issueIds.filter((id) => isIssueExists(store.getState(), id));
	if (issueIdsExisted.length > 0) {
		const newSequence = oldSequence.filter((id) => !issueIdsExisted.includes(id));
		const rank = rankId.toString();
		const getInsertIndex = () => {
			if (!isRankAfter) {
				return newSequence.indexOf(rank);
			}
			return rank ? newSequence.indexOf(rank) + 1 : 0;
		};
		newSequence.splice(getInsertIndex(), 0, ...issueIdsExisted);

		return Observable.of(updateIssuesSequence(intersection(newSequence, oldSequence)));
	}
	return Observable.empty<never>();
};

/**
 * Checks fields that might not be able to be updated optimistically. An example of
 * such a field is assignee as it might not be part of critical data.
 * Status isn't updated optimistically to fetch if the issue is resolved from the backend
 */
const canUpdateIssueOptimistically = (
	state: State,
	fieldName: FieldName | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	newValue: any,
): boolean =>
	!(
		(fieldName === ASSIGNEE || fieldName === STATUS) &&
		newValue !== null &&
		getUser(state, newValue.accountId) === undefined
	);

/**
 * Checks if a field should be removed from the Roadmap. This function contains a
 * list of known field changes that can result in the issue being removed from the roadmap.
 *
 */
const shouldRemoveIssueFromRoadmap = (
	state: State,
	fieldName: FieldName | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	newValue: any,
): boolean => fieldName === PARENT_ID && (newValue === null || !isIssueExists(state, newValue.id));

const updateIssueOptimistically = (
	state: State,
	issueId: IssueId,
	fieldName: FieldName | undefined,
	issue: Issue,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	newValue: any,
): Observable<ExtendIssuesAction> =>
	fieldName
		? Observable.of(
				extendIssues({
					sequence: [],
					hash: {
						[issueId]: {
							...issue,
							[fieldName]: convertIssueViewFieldValue(fieldName, newValue),
						},
					},
				}),
			)
		: Observable.empty<never>();

const updateIssueField = (
	store: MiddlewareAPI<State>,
	fieldId: string | undefined,
	issueId: IssueId,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	newValue: any,
) => {
	const state = store.getState();
	const fieldName = getRoadmapIssueFieldName(state, fieldId);
	const issue = getIssue(state, issueId);

	// check, that the field is supported and updated values are primitives
	if (fieldName !== undefined && issue) {
		if (fieldName === PARENT_ID && getIssueLevel(state, issueId) === PARENT_LEVEL) {
			// do nothing when epic's parent was changed
			// we have selectors that assume issue is epic if it doesn't have parent, and we need to maintain that state
			return Observable.empty<never>();
		}

		let issueAction;
		if (shouldRemoveIssueFromRoadmap(state, fieldName, newValue)) {
			issueAction = Observable.of(removeIssue(issueId));
		} else if (canUpdateIssueOptimistically(state, fieldName, newValue)) {
			issueAction = updateIssueOptimistically(state, issueId, fieldName, issue, newValue);
		} else {
			issueAction = reloadIssues(store, [issueId]);
		}

		const setUpdatedFilter = createSetUpdatedFilter(state, issueId, newValue, fieldName);
		const setUpdatedConfiguration = createSetUpdatedConfiguration(state, newValue, fieldName);

		return Observable.merge(
			issueAction,
			setUpdatedFilter ? Observable.of(setUpdatedFilter) : Observable.empty<never>(),
			setUpdatedConfiguration ? Observable.of(setUpdatedConfiguration) : Observable.empty<never>(),
		);
	}
	return Observable.empty<never>();
};

const addChildIssue = (
	store: MiddlewareAPI<State>,
	childIssue: {
		id: string;
		issueKey?: string | undefined;
		issueLink?: string | undefined;
		issueSummary?: string | undefined;
		issueTypeIconUrl?: string | undefined;
		issueTypeName?: string | undefined;
	},
	quickFilterIds: string[],
	customFilterIds: string[],
) =>
	filterItemsByJQLFilters(getSourceARI(store.getState()), quickFilterIds, customFilterIds).map(
		(filterIssueIds) => {
			const createActions: Array<IssueHiddenAction | JQLFiltersSuccessAction> = [];
			const childIssueId = childIssue.id;

			const isHiddenByQuickFilter = !filterIssueIds.includes(childIssueId);

			createActions.push(jqlFiltersSuccess(filterIssueIds));

			if (isHiddenByQuickFilter) {
				createActions.push(issueHidden({ ids: [childIssueId] }));
			}

			return batchActions(createActions);
		},
	);

const updateSubtaskParent = (
	store: MiddlewareAPI<State>,
	issueId: IssueId,
	newParentId: IssueId,
) => {
	const state = store.getState();
	const oldParentId = getSubtaskParentHash(state)[issueId];
	if (oldParentId) {
		if (getFullSubtasksHash(state)[newParentId]) {
			return Observable.of(
				changeSubtaskParent({
					id: issueId,
					oldParentId,
					newParentId,
				}),
			);
		}
		// remove subtask if new parent does not exist in the hash
		return Observable.of(
			removeSubtasks([
				{
					id: issueId,
					parentId: oldParentId,
				},
			]),
		);
	}
	return Observable.empty<never>();
};

const isIssueSubtask = (state: State, issueId: IssueId): boolean =>
	Boolean(getSubtaskParentHash(state)[issueId]);

const isIssueSubtaskParent = (state: State, issueId: IssueId): boolean =>
	Boolean(getFullSubtasksHash(state)[issueId]);

const addJQLFiltersRequest = (
	store: MiddlewareAPI<State>,
	quickFilterIds: string[],
	customFilterIds: string[],
) =>
	filterItemsByJQLFilters(getSourceARI(store.getState()), quickFilterIds, customFilterIds).map(
		(filterIssueIds): JQLFiltersSuccessAction => jqlFiltersSuccess(filterIssueIds),
	);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(RELOAD_ISSUE).mergeMap((action: Action) => {
		const event = action.payload;
		const state = store.getState();

		const { issueId } = event;
		const quickFilterIds = getQuickFilters(state);
		const customFilterIds = getCustomFilters(state);
		const isJQLFiltersApplied = quickFilterIds.length > 0 || customFilterIds.length > 0;

		if (!isIssueExists(state, issueId)) return Observable.empty<never>();

		switch (event.type) {
			case ChangeEventTypes.CHILD_ISSUE_ADDED: {
				const {
					meta: { childIssue },
				} = event;

				if (isIssueSubtaskParent(state, issueId)) {
					return Observable.of(
						addSubtask({
							id: issueId,
							subtask: {
								id: childIssue.id,
								key: childIssue.issueKey || '',
								statusCategoryId: `${TODO_STATUS_CATEGORY_ID}`,
							},
						}),
					);
				}

				if (isJQLFiltersApplied) {
					try {
						return addChildIssue(store, childIssue, quickFilterIds, customFilterIds);
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (error: any) {
						log.safeErrorWithoutCustomerData(
							'roadmap.standard.ops.issue.optimistic-update',
							"Failed to handle optimistic update 'CHILD_ISSUE_ADDED' from the issue view",
							error,
						);
					}
				}

				break;
			}
			case ChangeEventTypes.FIELD_CHANGED: {
				const {
					meta: { fieldId, fieldValue },
				} = event;

				if (isFieldUpdateWithoutRequestEvent(fieldId)) {
					if (fieldId === STATUS && isIssueSubtask(state, issueId)) {
						const {
							statusCategory: { id: statusCategoryId },
						} = fieldValue;

						return Observable.of(
							updateSubtaskStatus({
								id: issueId,
								parentId: getSubtaskParentHash(state)[issueId],
								statusCategoryId,
							}),
						);
					}
					try {
						if (!fg('jsw-roadmap-state-change-based-issue-hidden-flags') && isJQLFiltersApplied) {
							return Observable.merge(
								addJQLFiltersRequest(store, quickFilterIds, customFilterIds),
								updateIssueField(store, fieldId, issueId, fieldValue),
							);
						}
						return updateIssueField(store, fieldId, issueId, fieldValue);
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (error: any) {
						log.safeErrorWithoutCustomerData(
							'roadmap.standard.ops.issue.optimistic-update',
							`Failed to handle optimistic update 'FIELD_CHANGED' from the issue view for field "${fieldId}"`,
							error,
						);
					}
				}
				break;
			}
			case ChangeEventTypes.FIELD_CHANGE_REQUESTED: {
				const {
					meta: { fieldId, fieldValue },
				} = event;

				try {
					// check for isFieldUpdateWithoutRequestEvent otherwise it will be called twice from ChangeEventTypes.FIELD_CHANGED
					if (!fg('jsw-roadmap-state-change-based-issue-hidden-flags') && isJQLFiltersApplied) {
						if (!isFieldUpdateWithoutRequestEvent(event.meta.fieldId)) {
							return Observable.merge(
								addJQLFiltersRequest(store, quickFilterIds, customFilterIds),
								updateIssueField(store, fieldId, issueId, fieldValue),
							);
						}
						break;
					}

					return updateIssueField(store, fieldId, issueId, fieldValue);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					log.safeErrorWithoutCustomerData(
						'roadmap.standard.ops.issue.optimistic-update',
						`Failed to handle optimistic update 'FIELD_CHANGE_REQUESTED' from the issue view for field "${fieldId}"`,
						error,
					);
				}
				break;
			}
			case ChangeEventTypes.ISSUE_CHILDREN_ORDER_CHANGED: {
				const {
					meta: { issueIds, rankId, isRankAfter },
				} = event;

				try {
					return updateIssueSequenceOptimistically(store, issueIds, isRankAfter, rankId);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					log.safeErrorWithoutCustomerData(
						'roadmap.standard.ops.issue.optimistic-update',
						`Failed to handle optimistic rank update from the issue view for child issue "${issueIds.join(
							',',
						)}"`,
						error,
					);
				}

				break;
			}
			case ChangeEventTypes.ISSUE_RELATIONSHIP_UPDATED: {
				const {
					meta: { newParentIssueId },
				} = event;

				if (isIssueSubtask(state, issueId) && newParentIssueId !== null) {
					return updateSubtaskParent(store, issueId, newParentIssueId);
				}

				try {
					if (!fg('jsw-roadmap-state-change-based-issue-hidden-flags') && isJQLFiltersApplied) {
						return Observable.merge(
							addJQLFiltersRequest(store, quickFilterIds, customFilterIds),
							updateIssueField(store, PARENT_TYPE, issueId, {
								id: newParentIssueId,
							}),
						);
					}
					return updateIssueField(store, PARENT_TYPE, issueId, {
						id: newParentIssueId,
					});
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					log.safeErrorWithoutCustomerData(
						'roadmap.standard.ops.issue.optimistic-update',
						`Failed to handle optimistic update 'ISSUE_RELATIONSHIP_UPDATED' from the issue view for field ${PARENT_TYPE}`,
						error,
					);
				}

				break;
			}
			case ChangeEventTypes.FIELD_CHANGE_FAILED: {
				// try to reload failed issue
				const {
					meta: { fieldId },
				} = event;

				if (getRoadmapIssueFieldName(store.getState(), fieldId) === undefined) {
					break;
				}
				if (!fg('jsw-roadmap-state-change-based-issue-hidden-flags') && isJQLFiltersApplied) {
					return Observable.merge(
						addJQLFiltersRequest(store, quickFilterIds, customFilterIds),
						reloadIssues(store, [issueId]),
					);
				}
				return reloadIssues(store, [issueId]);
			}
			case ChangeEventTypes.ISSUE_TYPE_CHANGED: {
				const {
					fieldId,
					meta: { typeName },
				} = event;

				const setUpdatedFilter = createSetUpdatedFilter(state, issueId, typeName, fieldId);
				if (setUpdatedFilter) {
					if (!fg('jsw-roadmap-state-change-based-issue-hidden-flags') && isJQLFiltersApplied) {
						return Observable.merge(
							addJQLFiltersRequest(store, quickFilterIds, customFilterIds),
							Observable.of(setUpdatedFilter),
						);
					}
					return Observable.of(setUpdatedFilter);
				}
				if (!fg('jsw-roadmap-state-change-based-issue-hidden-flags') && isJQLFiltersApplied) {
					return addJQLFiltersRequest(store, quickFilterIds, customFilterIds);
				}

				break;
			}
			default: {
				// do nothing for other event types
				break;
			}
		}

		return Observable.empty<never>();
	})) as StateEpic;
