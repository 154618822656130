import { useRef } from 'react';
import { standardizeLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

export const useCollator = () => {
	const { locale } = useTenantContext();
	const { current: collator } = useRef(new Intl.Collator(standardizeLocale(locale)));
	return {
		collator,
	};
};
