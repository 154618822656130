import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import difference from 'lodash/difference';
import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/defer';
import type { ExtendIssuesAction } from '../../../state/entities/issues/actions.tsx';
import { getIssueParentIdHash } from '../../../state/entities/issues/selectors.tsx';
import {
	type IssueDisappearsFromDisplayRangeAction,
	type IssueHiddenAction,
	issueDisappearsFromDisplayRange,
	issueHidden,
} from '../../../state/flags/actions.tsx';
import { getFilteredIssueIds } from '../../../state/selectors/issues/index.tsx';
import type { State } from '../../../state/types.tsx';
import { getExpandedItems } from '../../../state/ui/table/selectors.tsx';
import { awaitReapplyJQLFilters } from '../../common/filter.tsx';

const getHiddenScheduledIssueIds = (before: string[], after: string[], scheduled: string[]) => {
	const hidden = difference(before, after);
	return intersection(hidden, scheduled);
};

const getAlreadyHiddenScheduledIssueIds = (after: string[], scheduled: string[]) =>
	difference(scheduled, after);

const getCollapsedScheduledIssueIds = (store: MiddlewareAPI<State>, scheduled: string[]) => {
	const state = store.getState();
	const issueParentIdHash = getIssueParentIdHash(state);

	const issueIdsByParentIds = groupBy(scheduled, (id) => issueParentIdHash[id]);

	const issueParentIds = Object.keys(issueIdsByParentIds);
	const scheduledIssueParentIds = intersection(scheduled, issueParentIds);

	const expandedItems = getExpandedItems(state);

	const collapsedScheduledIssueParentIds = scheduledIssueParentIds.filter(
		(id) => !(expandedItems[id] ?? false),
	);

	return collapsedScheduledIssueParentIds.flatMap((id) => issueIdsByParentIds[id]);
};

export const handleHiddenIssueRescheduled = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action$: ActionsObservable<any>,
	store: MiddlewareAPI<State>,
	before: string[],
	after: string[],
	actions: ExtendIssuesAction[],
	isJQLFilterApplied: boolean,
): Observable<IssueDisappearsFromDisplayRangeAction | IssueHiddenAction> => {
	const scheduled = actions.flatMap((action) => Object.keys(action.payload.hash));
	const clientHiddenScheduled = getHiddenScheduledIssueIds(before, after, scheduled);

	if (clientHiddenScheduled.length > 0)
		return Observable.of(
			issueDisappearsFromDisplayRange({
				issueId: clientHiddenScheduled[0],
			}),
		);

	const clientAlreadyHiddenScheduled = getAlreadyHiddenScheduledIssueIds(after, scheduled);

	if (clientAlreadyHiddenScheduled.length > 0)
		return Observable.of(issueHidden({ ids: clientAlreadyHiddenScheduled }));

	const collapsedScheduledIssueIds = getCollapsedScheduledIssueIds(store, scheduled);

	if (collapsedScheduledIssueIds.length > 0)
		return Observable.of(issueHidden({ ids: collapsedScheduledIssueIds }));

	const asyncCheck = (): Observable<IssueHiddenAction> => {
		const state = store.getState();
		const final = getFilteredIssueIds(state);

		const serverHiddenScheduled = getHiddenScheduledIssueIds(before, final, scheduled);

		if (serverHiddenScheduled.length > 0)
			return Observable.of(
				issueHidden({
					ids: serverHiddenScheduled,
				}),
			);

		return Observable.empty();
	};

	if (isJQLFilterApplied)
		return Observable.concat(awaitReapplyJQLFilters(action$), Observable.defer(asyncCheck));

	return Observable.empty();
};
