import { ADD_USERS, type AddUsersAction } from './actions.tsx';
import type { UsersState } from './types.tsx';

export type Action = AddUsersAction;

const initialState: UsersState = {
	hash: {},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: UsersState = initialState, action: Action): UsersState => {
	switch (action.type) {
		case ADD_USERS:
			return {
				hash: {
					...state.hash,
					...action.payload,
				},
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
