import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	iconLabel: {
		id: 'roadmap.timeline-table-kit.common.summary.icon-label',
		defaultMessage: 'issue type {issueType}',
		description: 'Accessibility label for a issue type icon.',
	},
	iconLabelIssueTermRefresh: {
		id: 'roadmap.timeline-table-kit.common.summary.icon-label-issue-term-refresh',
		defaultMessage: '{issueType}',
		description: 'Accessibility label for a issue type icon.',
	},
});
