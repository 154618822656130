// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { MAP_FROM_CLIENT_SERVER_COLOR } from '@atlassian/jira-issue-epic-color/src/common/constants.tsx';
import {
	START_AND_DUE_DATE_PLACEHOLDER,
	START_DATE_PLACEHOLDER,
	DUE_DATE_PLACEHOLDER,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/chart-item.tsx';
import type {
	CustomFieldConfiguration,
	ColumnTransformers,
	ColumnTransformerIssueContext,
} from './types.tsx';

export const DATE_FORMAT_MOMENT = 'yyyy/MM/DD' as const;

export const escapeValue = (value: string | undefined) => {
	if (value === undefined) {
		return '""';
	}
	if (value.includes('"')) {
		return `"${value.replace(/"/g, '""')}"`;
	}

	return `"${value}"`;
};

export const formatDate = (date: number | undefined) =>
	escapeValue(date !== undefined ? moment.utc(date).format(DATE_FORMAT_MOMENT) : '');

export const getColumnTransformers: (context: CustomFieldConfiguration) => ColumnTransformers = ({
	startDateCustomFieldId,
	colorCustomFieldId,
	sprintCustomFieldId,
	isSprintsFeatureEnabled,
	hasInferredStartDueDateFields,
}) => [
	{
		fieldId: 'issuekey',
		transform: ({ issue }) => escapeValue(issue.key),
	},
	{
		fieldId: 'issuetype',
		transform: ({ issue }, { issueTypesHash }) =>
			escapeValue(issueTypesHash[issue.issueTypeId]?.name),
	},
	{
		fieldId: 'parent',
		transform: ({ issue }, { issuesHash }) =>
			escapeValue(issue.parentId?.value ? issuesHash[issue.parentId.value]?.key : undefined),
	},
	{
		fieldId: 'summary',
		transform: ({ issue }) => escapeValue(issue.summary.value),
	},
	...(ff('jsw.timeline.return-flagged-attribute-fe')
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			([
				{
					fieldId: 'flagged',
					transform: ({ issue }) => escapeValue(issue.flagged.toString().toLowerCase()),
				},
			] as ColumnTransformers)
		: []),
	{
		fieldId: 'status',
		transform: ({ issue }) => escapeValue(issue?.status?.name),
	},
	{
		fieldId: 'assignee',
		transform: ({ issue }, { userDisplayNameHash }) =>
			escapeValue(issue.assignee ? userDisplayNameHash[issue.assignee] : undefined),
	},
	...(isSprintsFeatureEnabled
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			([
				{
					fieldId: sprintCustomFieldId,
					transform: ({ issueId }, { issueSprintsHash }) =>
						escapeValue(issueSprintsHash?.[issueId]?.at(0)?.name),
				},
			] as ColumnTransformers)
		: []),

	{
		fieldId: startDateCustomFieldId,
		transform: ({ issue }: ColumnTransformerIssueContext) => formatDate(issue.startDate?.value),
	},

	// Inferred start due date fields are not used in JBusiness timeline
	...(hasInferredStartDueDateFields
		? [
				{
					fieldId: 'inferredStartDate',
					transform: ({ chartData }: ColumnTransformerIssueContext) =>
						formatDate(
							chartData?.startDate &&
								chartData.placeholder !== START_AND_DUE_DATE_PLACEHOLDER &&
								chartData.placeholder !== START_DATE_PLACEHOLDER
								? chartData.startDate
								: undefined,
						),
				},
			]
		: []),
	{
		fieldId: 'duedate',
		transform: ({ issue }) => formatDate(issue.dueDate.value),
	},
	// Inferred start due date fields are not used in JBusiness timeline
	...(hasInferredStartDueDateFields
		? [
				{
					fieldId: 'inferredDueDate',
					transform: ({ chartData }: ColumnTransformerIssueContext) =>
						formatDate(
							chartData?.dueDate &&
								chartData.placeholder !== START_AND_DUE_DATE_PLACEHOLDER &&
								chartData.placeholder !== DUE_DATE_PLACEHOLDER
								? chartData.dueDate
								: undefined,
						),
				},
			]
		: []),
	...(colorCustomFieldId
		? [
				{
					fieldId: colorCustomFieldId,
					transform: ({ issue }: ColumnTransformerIssueContext) =>
						escapeValue(MAP_FROM_CLIENT_SERVER_COLOR[issue.color.value]), // should be translated using getI18nColorPalette?
				},
			]
		: []),
];
