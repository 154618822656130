import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { MARKER_COLORS, WARNING } from '../../../../common/constants/key-date.tsx';

const FailedIcon = () =>
	isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2') ? (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle r="6" cx="8" cy="8" fill="currentColor" />
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0.0175781 8C0.0175478 12.4085 3.59133 15.9823 7.99981 15.9823C12.4083 15.9823 15.9821 12.4085 15.982 8C15.982 3.59155 12.4083 0.0178225 7.99981 0.0178223C3.59136 0.017822 0.0176084 3.59155 0.0175781 8ZM7.24981 9V3.75H8.74981V9H7.24981ZM7.99981 12.25C8.5521 12.25 8.99981 11.8023 8.99981 11.25C8.99981 10.6977 8.5521 10.25 7.99981 10.25C7.44753 10.25 6.99981 10.6977 6.99981 11.25C6.99981 11.8023 7.44753 12.25 7.99981 12.25Z"
				fill={MARKER_COLORS[WARNING]}
			/>
		</svg>
	) : (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
			/**
			 * AK inline-dialog's handleClickOutside does not recognise svg elements
			 * https://bitbucket.org/atlassian/atlassian-frontend/src/master/packages/design-system/inline-dialog/src/InlineDialog/index.tsx#lines-60
			 */

			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ pointerEvents: 'none' }}
		>
			<circle r="6" cx="8" cy="8" fill="currentColor" />
			<path
				fill={MARKER_COLORS[WARNING]}
				fillRule="evenodd"
				d="M.768 9.768a2.5 2.5 0 010-3.536L6.232.768a2.5 2.5 0 013.536 0l5.464 5.464a2.5 2.5 0 010 3.536l-5.464 5.464a2.5 2.5 0 01-3.536 0L.768 9.768zM7 4a1 1 0 112 0v4a1 1 0 01-2 0V4zm1 8a1 1 0 100-2 1 1 0 000 2z"
				clipRule="evenodd"
			/>
		</svg>
	);

export { FailedIcon };
