import { createSelector } from 'reselect';
import { createSelectorWithDefaultEqual } from '../../../common/utils/reselect/index.tsx';
import { getFullComponentHash } from '../../configuration/selectors.tsx';
import { getIssueComponentIdsHash } from '../../entities/issues/selectors.tsx';
import {
	createGetUniqueComponents as createGetUniqueComponentsPure,
	createGetComponents as createGetComponentsPure,
	createGetSanitisedIssueComponentIdsHash as createGetSanitisedIssueComponentIdsHashPure,
} from './pure/index.tsx';

export const getSanitisedIssueComponentIdsHash = createSelector(
	getIssueComponentIdsHash,
	getFullComponentHash,
	createGetSanitisedIssueComponentIdsHashPure,
);

export const getUniqueComponents = createSelector(
	getSanitisedIssueComponentIdsHash,
	createGetUniqueComponentsPure,
);

export const getComponents = createSelectorWithDefaultEqual(
	getUniqueComponents,
	getFullComponentHash,
	createGetComponentsPure,
);
