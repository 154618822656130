import { combineEpics } from 'redux-observable';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import errorHandlerEpicMiddleware from '@atlassian/jira-errors-handling/src/utils/epic-wrapper.tsx';
import { captureException } from '@atlassian/jira-errors-handling/src/utils/sentry/index.tsx';
import type { RoadmapType } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import { toRoadmapAppId } from '../constants.tsx';
import { generalError } from '../state/flags/actions.tsx';
import type { StateEpic } from './common/types.tsx';
import filter from './filter/index.tsx';
import healthcheck from './healthcheck/index.tsx';
import initialize from './initialize/index.tsx';
import issues from './issues/index.tsx';
import metrics from './metrics/index.tsx';
import panel from './panel/index.tsx';
import resourceCache from './resource-cache/index.tsx';
import table from './table/index.tsx';
import { getCanUserInviteEpic } from './user-invite/index.tsx';
import version from './version/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (type?: RoadmapType): StateEpic =>
	errorHandlerEpicMiddleware(
		toRoadmapAppId(type),
		'root',
		combineEpics(
			filter,
			healthcheck,
			initialize,
			issues,
			panel,
			resourceCache,
			table,
			version,
			metrics,
			getCanUserInviteEpic,
		),
		(error) => {
			if (error?.skipSentry === true) {
				// strip out skip sentry flag and make sure it's logged to sentry
				// we never expect unhandled errors, even 403s, so we need to log everything
				const errorWithoutSkipSentry = { ...error, skipSentry: false };
				captureException(`common.epic-error.${toRoadmapAppId(type)}.root`, errorWithoutSkipSentry);
			}
			return Observable.of(generalError());
		},
	);
