/** @jsx jsx */
import React, { memo, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { zIndex } from '../../../../common/constants.tsx';
import { useListWidth } from '../../../../common/context/list/index.tsx';
import type { OnSetListWidth } from '../../../../common/types/callbacks.tsx';
import type { DndLongTaskMetricComponent } from '../../../../common/types/drag-and-drop.tsx';
import LegacyResizer from './resizer/index-old.tsx';
import Resizer from './resizer/index.tsx';

/* ===========================================================================
 * === @atlaskit/pragmatic-drag-and-drop === //
 *
 * Once the jsw_roadmaps_timeline_table_custom_scroll_pdnd feature gate is
 * removed, this comment and the following feature gated ResizerComponent
 * can be removed. The legacy-resizer component module can also be
 * deleted in its entirety.
 *
 * TODO(FG-REMOVE): jsw_roadmaps_timeline_table_custom_scroll_pdnd
 *
 * https://switcheroo.atlassian.com/ui/gates/49cac7b3-a5f8-45d9-9533-08d52c4f3345
 *
 * =========================================================================== */
const ResizerComponent = componentWithFG(
	'jsw_roadmaps_timeline_table_custom_scroll_pdnd',
	Resizer,
	LegacyResizer,
);

const containerStyles = css({
	position: 'absolute',
	display: 'flex',
	top: 0,
	bottom: 0,
	left: 0,
	width: '100%',
	pointerEvents: 'none',
});

const listResizerContainerStyles = css({
	position: 'sticky',
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST_RESIZER,
});

type Props = {
	DndLongTaskMetric: DndLongTaskMetricComponent | undefined;
	onSetListWidth: OnSetListWidth;
};

const ListResizer = ({ DndLongTaskMetric, onSetListWidth }: Props) => {
	const [listWidth, { setListWidth }] = useListWidth();

	const onResize = useCallback(
		(width: number) => {
			setListWidth(width);
		},
		[setListWidth],
	);

	const onResizeEnd = useCallback(() => {
		onSetListWidth(listWidth);
	}, [listWidth, onSetListWidth]);

	return (
		<div css={containerStyles}>
			<div css={listResizerContainerStyles} style={{ width: `${listWidth}px` }}>
				<ResizerComponent
					width={listWidth}
					DndLongTaskMetric={DndLongTaskMetric}
					onResize={onResize}
					onResizeEnd={onResizeEnd}
				/>
			</div>
		</div>
	);
};

export default memo<Props>(ListResizer);
