import React, { memo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useTimelineState } from '../../../../common/context/timeline/index.tsx';
import type { ItemId } from '../../../../common/types/item.tsx';
import type { RenderChartItemContent } from '../../../../renderers/index.tsx';
import { getContainerHeight } from '../../../utils/get-container-height.tsx';

export type Props = {
	id: ItemId;
	depth: number;
	itemHeight: number;
	backgroundColor: string;
	renderChartItemContent: RenderChartItemContent;
};

/* A non interactive version of the chart-item with lightweight styles.
 * Leveraged during virtualisation to improve scroll performance.
 */
const ChartItemLite = ({
	id,
	depth,
	itemHeight,
	backgroundColor,
	renderChartItemContent,
}: Props) => {
	const rendererProps = { id };
	const rendererState = { isVirtual: true, isHovered: false };
	const [{ timelineWidth }] = useTimelineState();

	const height = getContainerHeight({ itemHeight, depth });

	return (
		<Box
			role="gridcell"
			tabIndex={-1}
			xcss={chartItemLiteContainerStyles}
			style={{
				width: `${timelineWidth}px`,
				height,
				backgroundColor,
			}}
		>
			{renderChartItemContent(rendererProps, rendererState)}
		</Box>
	);
};

const chartItemLiteContainerStyles = xcss({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	overflow: 'hidden',
});

export default memo<Props>(ChartItemLite);
