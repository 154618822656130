/** @jsx jsx */
import React, { memo, useCallback, useRef, type MouseEvent } from 'react';
import { css, jsx } from '@compiled/react';
import { mergeRefs } from 'use-callback-ref';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import Tooltip from '@atlaskit/tooltip';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { BEFORE, HALF_INLINE_CREATE_ICON_WIDTH, zIndex } from '../../../../../common/constants.tsx';
import { useCreateTrigger } from '../../../../../common/context/side-effect-marshal/focus-marshal/use-create-trigger/index.tsx';
import { useSideEffectMarshal } from '../../../../../common/context/side-effect-marshal/index.tsx';
import type { OnCreateClick } from '../../../../../common/types/callbacks.tsx';
import type { ItemId } from '../../../../../common/types/item.tsx';
import { fireUIAnalyticsEvent } from '../../../../../common/utils/analytics.tsx';

const buttonStyles = css({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	top: `${-HALF_INLINE_CREATE_ICON_WIDTH / 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	left: `${-HALF_INLINE_CREATE_ICON_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	width: `calc(100% + ${HALF_INLINE_CREATE_ICON_WIDTH}px)`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	height: `${HALF_INLINE_CREATE_ICON_WIDTH}px`,
	padding: 0,
	margin: 0,
	backgroundColor: 'transparent',
	border: 'none',
	cursor: 'pointer',
	'&:focus': {
		outline: 'none',
	},
});

const zIndexStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.INLINE_INDICATOR,
});

type Props = {
	id: ItemId;
	level: number;
	depth: number;
	parentId: ItemId | undefined;
	label: string;
	onInlineCreateClicked: OnCreateClick;
};

const CreateSiblingButton = ({
	id,
	level,
	depth,
	parentId,
	label,
	onInlineCreateClicked,
}: Props) => {
	const element = useRef<HTMLButtonElement | null>(null);
	const { onAddButtonMouseEnter, onAddButtonMouseLeave } = useSideEffectMarshal();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { createTriggerRef, onCreateTriggerClick } = useCreateTrigger(id, 'SIBLING');

	const onClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			// Do not consider clicking this as also clicking on the list item itself
			event.stopPropagation();
			onCreateTriggerClick();

			traceUFOPress('timeline-add-item-clicked', event.timeStamp);

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'issueCreateButton',
				attributes: {
					position: BEFORE,
					level,
					depth,
				},
			});

			fireUIAnalyticsEvent(analyticsEvent);

			// Hide the button after activating the input
			onAddButtonMouseLeave();

			onInlineCreateClicked({ beforeId: id, parentId, position: BEFORE });
		},
		[
			onCreateTriggerClick,
			createAnalyticsEvent,
			level,
			depth,
			onAddButtonMouseLeave,
			onInlineCreateClicked,
			id,
			parentId,
		],
	);

	const updateVisualIndicator = useCallback(
		(tabFocused: boolean) => {
			if (element.current) {
				const { top } = element.current.getBoundingClientRect();
				onAddButtonMouseEnter(top, tabFocused);
			}
		},
		[onAddButtonMouseEnter],
	);

	const onActiveHandler = useCallback(
		(tabFocused: boolean) => {
			if (tabFocused) {
				// When ICC is cancelled, there is a race condition between updating the issue list
				// and updating the top position of the visual indicator. This causes the visual indicator
				// to be positioned as if the ICC is still open. This setTimeout ensures the position of
				// the visual indicator is updated with the correct position after the issue list has updated.
				setTimeout(() => updateVisualIndicator(true), 10);
			} else {
				updateVisualIndicator(false);
			}
		},
		[updateVisualIndicator],
	);

	const onActive = useCallback(() => {
		onActiveHandler(false);
	}, [onActiveHandler]);

	const onActiveFocus = useCallback(() => {
		onActiveHandler(true);
	}, [onActiveHandler]);

	const onInactive = useCallback(() => {
		onAddButtonMouseLeave();
	}, [onAddButtonMouseLeave]);

	const createOnFocus =
		(tooltipCallback: (event: React.FocusEvent<HTMLButtonElement>) => void) =>
		(e: React.FocusEvent<HTMLButtonElement>) => {
			onActiveFocus();
			tooltipCallback(e);
		};

	const createOnBlur =
		(tooltipCallback: (event: React.FocusEvent<HTMLButtonElement>) => void) =>
		(e: React.FocusEvent<HTMLButtonElement>) => {
			onInactive();
			tooltipCallback(e);
		};

	return (
		<Tooltip content={label} position="left">
			{(tooltipProps) => (
				<button
					{...tooltipProps}
					ref={mergeRefs([tooltipProps.ref, element, createTriggerRef])}
					data-testid={`roadmap.timeline-table.components.list-item.create-sibling-button.container-${id}`}
					aria-label={label}
					css={[buttonStyles, zIndexStyle]}
					onMouseEnter={onActive}
					onMouseLeave={onInactive}
					onClick={onClick}
					onFocus={createOnFocus(tooltipProps.onFocus)}
					onBlur={createOnBlur(tooltipProps.onBlur)}
				/>
			)}
		</Tooltip>
	);
};

const CreateSiblingButtonOld = ({
	id,
	level,
	depth,
	parentId,
	label,
	onInlineCreateClicked,
}: Props) => {
	const element = useRef<HTMLButtonElement | null>(null);
	const { onAddButtonMouseEnter, onAddButtonMouseLeave } = useSideEffectMarshal();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			// Do not consider clicking this as also clicking on the list item itself
			event.stopPropagation();

			traceUFOPress('timeline-add-item-clicked', event.timeStamp);

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'issueCreateButton',
				attributes: {
					position: BEFORE,
					level,
					depth,
				},
			});

			fireUIAnalyticsEvent(analyticsEvent);

			// Hide the button after activating the input
			onAddButtonMouseLeave();

			onInlineCreateClicked({ beforeId: id, parentId, position: BEFORE });
		},
		[
			createAnalyticsEvent,
			level,
			depth,
			onAddButtonMouseLeave,
			onInlineCreateClicked,
			id,
			parentId,
		],
	);

	const onActiveHandler = useCallback(
		(tabFocused: boolean) => {
			if (element.current) {
				const { top } = element.current.getBoundingClientRect();
				onAddButtonMouseEnter(top, tabFocused);
			}
		},
		[onAddButtonMouseEnter],
	);

	const onActive = useCallback(() => {
		onActiveHandler(false);
	}, [onActiveHandler]);

	const onActiveFocus = useCallback(() => {
		onActiveHandler(true);
	}, [onActiveHandler]);

	const onInactive = useCallback(() => {
		onAddButtonMouseLeave();
	}, [onAddButtonMouseLeave]);

	const createOnFocus =
		(tooltipCallback: (event: React.FocusEvent<HTMLButtonElement>) => void) =>
		(e: React.FocusEvent<HTMLButtonElement>) => {
			onActiveFocus();
			tooltipCallback(e);
		};

	const createOnBlur =
		(tooltipCallback: (event: React.FocusEvent<HTMLButtonElement>) => void) =>
		(e: React.FocusEvent<HTMLButtonElement>) => {
			onInactive();
			tooltipCallback(e);
		};

	return (
		<Tooltip content={label} position="left">
			{(tooltipProps) => (
				<button
					{...tooltipProps}
					ref={mergeRefs([tooltipProps.ref, element])}
					data-testid={`roadmap.timeline-table.components.list-item.create-sibling-button.container-${id}`}
					aria-label={label}
					css={[buttonStyles, zIndexStyle]}
					onMouseEnter={onActive}
					onMouseLeave={onInactive}
					onClick={onClick}
					onFocus={createOnFocus(tooltipProps.onFocus)}
					onBlur={createOnBlur(tooltipProps.onBlur)}
				/>
			)}
		</Tooltip>
	);
};

export default memo<Props>(
	componentWithFG(
		'jsw_roadmaps_timeline_table_meatballs_menu_a11y',
		CreateSiblingButton,
		CreateSiblingButtonOld,
	),
);
