import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import {
	INCOMPLETE,
	type LevelOneQuerySetting,
	periodToLevelOneQuerySettingsMap,
} from '@atlassian/jira-software-roadmap-model/src/filter/index.tsx';
import type { Dependencies } from '../../model/common/index.tsx';
import type { State } from '../../state/types.tsx';
import {
	SET_LEVEL_ONE_SETTING,
	type SetLevelOneSettingAction as Action,
} from '../../state/ui/views/actions.tsx';
import type { StateEpic } from '../common/types.tsx';
import updateQuery from '../common/update-query/index.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
	{ router: { push } }: Dependencies,
) =>
	action$.ofType(SET_LEVEL_ONE_SETTING).map((action: Action) =>
		updateQuery(push, store.getState(), (query) => {
			let levelOneView: LevelOneQuerySetting | undefined = INCOMPLETE;
			if (action.payload.showCompleted) {
				levelOneView = periodToLevelOneQuerySettingsMap.get(action.payload.period);
			}

			return {
				...query,
				epic: levelOneView,
			};
		}),
	)) as StateEpic;
