/** @jsx jsx */
import React, { Component, type ComponentType } from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout.tsx';
import { contentHorizontalSpacing } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import killswitch from '@atlassian/jira-killswitch/src/index.tsx';
import { getWillShowNav3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav3.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { IccProvider } from '@atlassian/jira-platform-inline-card-create/src/services/context/index.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import PrefetchIssues from '@atlassian/jira-providers-issue/src/components/prefetch/index.tsx';
import type { IssueId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import ProjectKeyboardShortcuts from '@atlassian/jira-software-keyboard-shortcuts/src/ui/project-shortcuts/index.tsx';
import { AsyncCustomerTemplatesOnboardingModal } from '@atlassian/jira-software-onboarding-modals/src/ui/customer-templates-onboarding-modal/async.tsx';
import { AsyncKanbanOnboardingModal } from '@atlassian/jira-software-onboarding-modals/src/ui/kanban-onboarding-modal/async.tsx';
import { AsyncScrumOnboardingModal } from '@atlassian/jira-software-onboarding-modals/src/ui/scrum-onboarding-modal/async.tsx';
import { AsyncWelcomeModal } from '@atlassian/jira-software-onboarding-modals/src/ui/welcome-modal/async.tsx';
import type { BoardId } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import UFOLabel from '@atlassian/jira-ufo-label/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { InteractionMetricsExtraData } from '../metrics/index.tsx';
import GlobalIssueCreateDialogTrigger from './global-issue-create/dialog-trigger/index.tsx';
import GlobalIssueCreateListener from './global-issue-create/listener/index.tsx';
import TableOverlay from './table-overlay/index.tsx';

export type Props = {
	isHeaderHidden: boolean;
	isServer: boolean;
	isMacroView: boolean;
	isHealthy: boolean;
	isClassic: boolean;
	issueIds: IssueId[];
	shouldMountKeyboardShortcuts: boolean;
	boardId: BoardId;
	projectKey: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	DocumentTitle: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Header: ComponentType<Record<any, any>>;
	Table: ComponentType<{
		isServer: boolean;
	}>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Healthcheck: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	InteractionPerformanceMetric: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	RealtimeProvider: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	RouterUpdater: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	MountAnalytic: ComponentType<Record<any, any>>;
	onLoaded: () => void;
	onExperienceSuccess: () => void;
	objectId: string;
	projectId: ProjectId;
	boardType: string;
	childIssuePlanningMode?: string;
};

// eslint-disable-next-line jira/react/no-class-components
class StandardRoadmap extends Component<Props> {
	static defaultProps = {
		isHeaderHidden: false,
		onExperienceSuccess: noop,
	};

	componentDidMount() {
		const { isHealthy, onLoaded, onExperienceSuccess } = this.props;

		// The app was initialized in a valid state (no healthcheck)
		isHealthy && onLoaded();
		onExperienceSuccess();
	}

	shouldComponentUpdate(nextProps: Props) {
		return (
			this.props.isHealthy !== nextProps.isHealthy ||
			this.props.isHeaderHidden !== nextProps.isHeaderHidden ||
			this.props.isServer !== nextProps.isServer ||
			this.props.isMacroView !== nextProps.isMacroView
		);
	}

	componentDidUpdate(prevProps: Props) {
		const { isHealthy, onLoaded } = this.props;

		// The app was initialized in an invalid state (required healthcheck setup)
		if (isHealthy && !prevProps.isHealthy) {
			onLoaded();
		}
	}

	renderHeader() {
		const { Header } = this.props;

		return (
			<UFOSegment name="roadmap-header">
				<Header />
			</UFOSegment>
		);
	}

	renderRoadmap() {
		const { Table, isServer, RealtimeProvider, isMacroView, isHeaderHidden, issueIds } = this.props;

		return (
			<>
				{isHeaderHidden ? null : this.renderHeader()}
				<div
					data-fullscreen-id={getWillShowNav4() ? undefined : 'roadmap-container'}
					data-testid="roadmap.standard-roadmap.roadmap-container"
					css={[
						getWillShowNav4() && roadmapContainerStylesNewNavigation,
						!getWillShowNav4() && roadmapContainerStylesOld,
					]}
					style={{
						marginLeft: isMacroView ? '-1px' : 0,
						marginTop: isHeaderHidden ? '-1px' : 0,
					}}
				>
					<IccProvider>
						<UFOLabel name="roadmap-table">
							<Table isServer={isServer} />
						</UFOLabel>
					</IccProvider>
					{!isServer && <TableOverlay />}
					<PrefetchIssues issueIds={issueIds} />
				</div>
				<AsyncScrumOnboardingModal />
				<AsyncKanbanOnboardingModal />
				<AsyncCustomerTemplatesOnboardingModal />
				{!isMacroView && <AsyncWelcomeModal source="timeline" />}
				{!killswitch('roadmap_realtime') ? <RealtimeProvider /> : null}
			</>
		);
	}

	render() {
		const {
			isHealthy,
			isMacroView,
			isClassic,
			Healthcheck,
			DocumentTitle,
			RouterUpdater,
			boardId,
			projectKey,
			MountAnalytic,
			shouldMountKeyboardShortcuts,
			objectId,
			projectId,
			boardType,
			childIssuePlanningMode,
		} = this.props;

		return (
			<ContextualAnalyticsData
				containerType="project"
				containerId={projectId}
				objectType="board"
				objectId={objectId}
				attributes={{
					projectId,
					boardId,
					boardType,
					childIssuePlanningMode,
				}}
			>
				<DocumentTitle />
				<InteractionMetricsExtraData />
				<GlobalIssueCreateListener isMacroView={isMacroView} />
				<GlobalIssueCreateDialogTrigger />
				<div
					css={[
						containerStyles,
						// eslint-disable-next-line no-nested-ternary
						isMacroView
							? macroViewStyles
							: isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2')
								? nonMacroViewStyles
								: nonMacroViewStylesOld,
					]}
				>
					{isHealthy ? this.renderRoadmap() : <Healthcheck />}
				</div>
				{shouldMountKeyboardShortcuts && getWillShowNav3() && (
					<ProjectKeyboardShortcuts
						boardId={boardId}
						projectKey={projectKey}
						isCMPBoard={isClassic}
					/>
				)}
				<RouterUpdater />
				<MountAnalytic />
				<FireScreenAnalytics />
			</ContextualAnalyticsData>
		);
	}
}

export default StandardRoadmap;

const containerStyles = css({
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
	overflow: 'hidden',
});

const roadmapContainerStylesOld = css({
	flex: '1 1 auto',
	backgroundColor: token('elevation.surface', N0),
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.fullscreen': {
		position: 'absolute',
		zIndex: 1,
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		marginLeft: token('space.300', '24px'),
		marginTop: token('space.150', '12px'),
	},
});

const roadmapContainerStylesNewNavigation = css({
	flex: '1 1 auto',
	backgroundColor: token('elevation.surface', N0),
	position: 'relative',
});

const macroViewStyles = css({
	height: '100vh',
	paddingLeft: 0,
});

const nonMacroViewStylesOld = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP})`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingLeft: `${contentHorizontalSpacing}px`,
});

const nonMacroViewStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP})`,
	paddingLeft: `${token('space.300')}`,
});
