import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getContainerName } from '../../state/app/selectors.tsx';
import type { State } from '../../state/types.tsx';
import DocumentTitle from './view.tsx';

export default connect(
	(state: State) => ({
		projectName: getContainerName(state),
	}),
	{},
)(DocumentTitle);
