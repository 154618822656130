import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	titleAdd: {
		id: 'roadmap.standard-roadmap.flags.dependencies-permission.title-add',
		defaultMessage: "Sorry we couldn't create a dependency",
		description: 'Flag title. Informs a user that a dependency cannot be created.',
	},
	titleRemove: {
		id: 'roadmap.standard-roadmap.flags.dependencies-permission.title-remove',
		defaultMessage: "Sorry we couldn't delete a dependency",
		description: 'Flag title. Informs a user that a dependency cannot be deleted.',
	},
	description: {
		id: 'roadmap.standard-roadmap.flags.dependencies-permission.description',
		defaultMessage:
			"You don't have link issues permission. Check your issue link Jira setting or contact your Jira administrator for help.",
		description: 'Flag description. Informs a user why an issue cannot be linked.',
	},
	descriptionAdd: {
		id: 'roadmap.standard-roadmap.flags.dependencies-permission.description-add',
		defaultMessage:
			"You don't have permission to link issue {dependency} to {dependee}. Check your issue link Jira setting or contact your Jira administrator for help.",
		description: 'Flag description. Informs a user why an issue cannot be linked.',
	},
	descriptionRemove: {
		id: 'roadmap.standard-roadmap.flags.dependencies-permission.description-remove',
		defaultMessage:
			"You don't have permission to unlink issue {dependency} and {dependee}. Check your issue link Jira setting or contact your Jira administrator for help.",
		description: 'Flag description. Informs a user why an issue cannot be linked.',
	},
	learnMore: {
		id: 'roadmap.standard-roadmap.flags.dependencies-permission.learn-more',
		defaultMessage: 'Learn more',
		description: 'Learn more link title',
	},
	descriptionIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.dependencies-permission.description-issue-term-refresh',
		defaultMessage:
			"You don't have the permission to link work items. Check your Jira settings or contact your Jira administrator for help.",
		description: 'Flag description. Informs a user why an issue cannot be linked.',
	},
	descriptionAddIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.dependencies-permission.description-add-issue-term-refresh',
		defaultMessage:
			"You don't have permission to link work item {dependency} to {dependee}. Check your work item link Jira setting or contact your Jira administrator for help.",
		description: 'Flag description. Informs a user why an issue cannot be linked.',
	},
	descriptionRemoveIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.dependencies-permission.description-remove-issue-term-refresh',
		defaultMessage:
			"You don't have permission to unlink work item {dependency} and {dependee}. Check your work item link Jira setting or contact your Jira administrator for help.",
		description: 'Flag description. Informs a user why an issue cannot be linked.',
	},
});
