import {
	createStore,
	createHook,
	createContainer,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions/index.tsx';
import type { State } from './common/types.tsx';

const initialState: State = {
	left: 0,
	clientWidth: 0,
	indicatorLineOffset: 0,
};

export const timelineViewportStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'timeline-table.timeline-viewport',
});

export const useTimelineViewportActions = createActionsHook(timelineViewportStore);

export const useTimelineViewport = createHook(timelineViewportStore);

export const TimelineViewportProvider = createContainer<State, Actions, {}>(timelineViewportStore);
