import { batchActions } from 'redux-batched-actions';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { UPDATE_CRITICAL_DATA, type UpdateCriticalDataAction } from '../../state/app/actions.tsx';
import { getCriticalDataActions } from '../common/initialize/utils.tsx';
import type { StateEpic } from '../common/types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<UpdateCriticalDataAction>) =>
	action$
		.ofType(UPDATE_CRITICAL_DATA)
		.mergeMap((action: UpdateCriticalDataAction) =>
			Observable.of(batchActions(getCriticalDataActions(action.payload))),
		)) as StateEpic;
