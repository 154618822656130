import { MERGE_STATUS_CATEGORIES, type MergeStatusCategoriesAction } from './actions.tsx';
import type { StatusCategoriesState } from './types.tsx';

export type Action = MergeStatusCategoriesAction;

const initialState: StatusCategoriesState = {
	hash: {},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: StatusCategoriesState = initialState,
	action: Action,
): StatusCategoriesState => {
	switch (action.type) {
		case MERGE_STATUS_CATEGORIES:
			return {
				hash: {
					...state.hash,
					...action.payload,
				},
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
