import React from 'react';
import { ErrorBoundaryWithChunkErrorLogging } from '@atlassian/jira-onboarding-core/src/ui/error-boundary-with-chunk-error-logging/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyWelcomeModal = lazy(() =>
	import(/* webpackChunkName: "async-software-onboarding-modals-welcome-modal" */ './index').then(
		({ WelcomeModal }) => WelcomeModal,
	),
);

export const AsyncWelcomeModal = ({ source }: Props) => {
	return (
		<UFOSegment name="async-onboarding-welcome-modal">
			<ErrorBoundaryWithChunkErrorLogging
				id="asyncWelcomeModalComponent"
				fallback="unmount"
				packageName="jiraSoftwareOnboardingModals"
				teamName="jlove-makkuro"
			>
				<Placeholder name="software-onboarding-modals-welcome-modal" fallback={<></>}>
					<LazyWelcomeModal source={source} />
				</Placeholder>
			</ErrorBoundaryWithChunkErrorLogging>
		</UFOSegment>
	);
};
