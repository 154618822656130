import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/map';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { getDeriveFields } from '@atlassian/jira-software-roadmap-services/src/configuration/get-derive-fields.tsx';
import { transformDerivedFieldsHash } from '@atlassian/jira-software-roadmap-services/src/configuration/transform-derived-fields-hash.tsx';
import type { DeriveFields } from '@atlassian/jira-software-roadmap-services/src/configuration/types.tsx';
import { setDeriveFieldsHash } from '../../../state/configuration/actions.tsx';

export const getInitializeDeriveFieldsAction = (
	sourceAri: Ari,
	jqlContexts: string[],
	customFilterIds: string[],
) =>
	jqlContexts.length === 0 && customFilterIds.length === 0
		? Observable.empty<never>()
		: getDeriveFields(sourceAri, jqlContexts, customFilterIds).map((deriveFields: DeriveFields) => {
				const deriveFieldsHash = transformDerivedFieldsHash(deriveFields);
				return setDeriveFieldsHash(deriveFieldsHash);
			});
