// eslint-disable-next-line jira/restricted/react-component-props
import React, { Component, type ComponentType, type ComponentProps } from 'react';
import {
	LICENSE_STATE_ACTIVE,
	LICENSE_STATE_SUSPENDED,
} from '@atlassian/jira-shared-types/src/license-state.tsx';
import { AsyncNoSoftwareLicense } from '@atlassian/jira-spa-apps-common/src/error-screens/no-software-license/async.tsx';
import { AsyncSuspendedSoftwareLicense } from '@atlassian/jira-spa-apps-common/src/error-screens/suspended-software-license/async.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import { LicenseStateCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/license-state-check/index.tsx';

export const withLicenseCheck = <
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Props extends Record<string, any>,
	WrappedComponentType extends ComponentType<Props>,
>(
	WrappedComponent: ComponentType<
		JSX.LibraryManagedAttributes<WrappedComponentType, ComponentProps<WrappedComponentType>>
	>,
) =>
	// eslint-disable-next-line jira/react/no-class-components
	class WithLicenseCheck extends Component<
		JSX.LibraryManagedAttributes<WrappedComponentType, ComponentProps<WrappedComponentType>>
	> {
		static displayName = `WithLicenseCheck(${
			WrappedComponent.displayName || WrappedComponent.name || 'Component'
		})`;

		render() {
			return (
				<AuthenticationCheck>
					<LicenseStateCheck type="software">
						{({ licenseState }) => {
							if (licenseState === LICENSE_STATE_ACTIVE) {
								// TODO: a Readonly marker is introduced somehow, but I can't figure out where
								// @ts-expect-error - TS2322 - Type 'Readonly<LibraryManagedAttributes<WrappedComponentType, ComponentProps<WrappedComponentType>>> & Readonly<...>' is not assignable to type 'IntrinsicAttributes & LibraryManagedAttributes<WrappedComponentType, ComponentProps<WrappedComponentType>> & { ...; }'.
								return <WrappedComponent {...this.props} />;
							}
							if (licenseState === LICENSE_STATE_SUSPENDED) {
								return <AsyncSuspendedSoftwareLicense />;
							}
							return <AsyncNoSoftwareLicense />;
						}}
					</LicenseStateCheck>
				</AuthenticationCheck>
			);
		}
	};
