import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import type { BatchAction } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { getIssueChildrenHash } from '../../state/entities/issues/selectors.tsx';
import type { ExtendSubtasksAction } from '../../state/entities/subtasks/actions.tsx';
import { getFullSubtasksHash } from '../../state/entities/subtasks/selectors.tsx';
import type { State } from '../../state/types.tsx';
import {
	BULK_CHANGE_EXPANSION_STATE,
	type BulkChangeExpansionStateAction,
	CHANGE_EXPANSION_STATE,
	type ChangeExpansionStateAction,
} from '../../state/ui/table/actions.tsx';
import type { StateEpic } from '../common/types.tsx';
import { reloadSubtaskProgress } from '../issues/common/reload-subtask-progress.tsx';

type Action = ChangeExpansionStateAction | BulkChangeExpansionStateAction;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(CHANGE_EXPANSION_STATE, BULK_CHANGE_EXPANSION_STATE)
		.mergeMap((action: Action): Observable<never | BatchAction | ExtendSubtasksAction> => {
			const { type, payload } = action;
			const { isExpanded } = payload;

			if (!isExpanded) return Observable.empty<never>();

			const epicIds = type === CHANGE_EXPANSION_STATE ? [payload.id] : payload.ids;

			const state = store.getState();

			const issueIds = epicIds.flatMap((epicId) => getIssueChildrenHash(state)[epicId] ?? []);

			const fullSubtaskHash = getFullSubtasksHash(state);

			const issuesWithoutSubtask = issueIds.filter(
				(issueId) => fullSubtaskHash[issueId] === undefined,
			);

			if (issuesWithoutSubtask.length === 0) {
				return Observable.empty<never>();
			}

			return reloadSubtaskProgress(store, issuesWithoutSubtask);
		})) as StateEpic;
