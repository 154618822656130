import type { InteractionStates, Validator } from './types.tsx';

export const ROW_STATES: { [Property in InteractionStates]: Property } = {
	FOCUSED: 'FOCUSED',
	SELECTED: 'SELECTED',
	DRAGGED: 'DRAGGED',
	DRAGGED_OUT: 'DRAGGED_OUT',
	DRAGGED_OVER: 'DRAGGED_OVER',
};

export const VALID_INITIAL_STATES: Record<InteractionStates, boolean> = {
	FOCUSED: true,
	SELECTED: true,
	DRAGGED: true,
	DRAGGED_OUT: false,
	DRAGGED_OVER: false,
};

export const VALID_TRANSITIONS: Record<InteractionStates, Validator> = {
	FOCUSED: { SELECTED: true, DRAGGED: true },
	SELECTED: { DRAGGED: true },
	DRAGGED: { SELECTED: true, DRAGGED_OUT: true, DRAGGED_OVER: true },
	DRAGGED_OUT: { SELECTED: true, DRAGGED_OVER: true },
	DRAGGED_OVER: { SELECTED: true, DRAGGED_OUT: true },
};

export const DEFAULT_COUNTS: Record<InteractionStates, number> = {
	FOCUSED: 0,
	SELECTED: 0,
	DRAGGED: 0,
	DRAGGED_OUT: 0,
	DRAGGED_OVER: 0,
};
