import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/merge';
import { combineEpics } from 'redux-observable';

import { Observable } from 'rxjs/Observable';

import errorHandlerEpicMiddleware from '@atlassian/jira-errors-handling/src/utils/epic-wrapper.tsx';
import { captureException } from '@atlassian/jira-errors-handling/src/utils/sentry/index.tsx';
import { generalError } from '../../../state/flags/actions.tsx';
import type { StateEpic } from '../../common/types.tsx';
import initializeDerivedFields from './derive-fields/index.tsx';
import initializeGoals from './goals/index.tsx';
import initializeJqlFilters from './jql-filters/index.tsx';

const combineEpicsWithErrorHandling = (...epics: StateEpic[]): StateEpic => {
	const epicsWithErrorHandling = epics.map((epic) =>
		errorHandlerEpicMiddleware('nonCriticalRoadmap', 'initializeNonCritical', epic, (error) => {
			if (error?.skipSentry === true) {
				// strip out skip sentry flag and make sure it's logged to sentry
				// we never expect unhandled errors, even 403s, so we need to log everything
				const errorWithoutSkipSentry = { ...error, skipSentry: false };
				captureException(
					'common.epic-error.nonCriticalRoadmap.initializeNonCritical',
					errorWithoutSkipSentry,
				);
			}
			return Observable.of(generalError());
		}),
	);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return combineEpics(...epicsWithErrorHandling) as StateEpic;
};

export default combineEpicsWithErrorHandling(
	initializeJqlFilters,
	initializeGoals,
	initializeDerivedFields,
);
