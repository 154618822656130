import React, { Component } from 'react';
import noop from 'lodash/noop';
import { expVal } from '@atlassian/jira-feature-experiments';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	DEPENDENCY_ADD_ERROR,
	type DependenciesPermissionType,
} from '../../model/dependencies/index.tsx';
import { docs } from '../../utils/docs.tsx';
import messages from './messages.tsx';

export type Props = {
	id: string | number;
	type: DependenciesPermissionType;
	dependeeKey: IssueKey | undefined;
	dependencyKey: IssueKey | undefined;
	onDismissed: (id: string | number) => void;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
export class DependenciesPermissionFlag extends Component<Props> {
	static defaultProps = {
		type: DEPENDENCY_ADD_ERROR,
		onDismissed: noop,
	};

	onDismissed = () => {
		const { id, onDismissed } = this.props;
		onDismissed(id);
	};

	render() {
		const {
			type,
			dependeeKey,
			dependencyKey,
			intl: { formatMessage },
			...props
		} = this.props;

		const title =
			type === DEPENDENCY_ADD_ERROR
				? formatMessage(messages.titleAdd)
				: formatMessage(messages.titleRemove);

		const getDescriptionWithIssueKey = () => {
			if (type === DEPENDENCY_ADD_ERROR) {
				return formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.descriptionAddIssueTermRefresh
						: messages.descriptionAdd,
					{
						dependee: dependeeKey,
						dependency: dependencyKey,
					},
				);
			}

			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.descriptionRemoveIssueTermRefresh
					: messages.descriptionRemove,
				{
					dependee: dependeeKey,
					dependency: dependencyKey,
				},
			);
		};

		const getErrorFlagMessage = () => {
			if (dependeeKey && dependencyKey) {
				return getDescriptionWithIssueKey();
			}

			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.descriptionIssueTermRefresh
					: messages.description,
			);
		};

		return (
			<ErrorFlag
				{...props}
				onDismissed={this.onDismissed}
				id={this.props.id}
				title={title}
				description={getErrorFlagMessage()}
				actions={[
					{
						content: formatMessage(messages.learnMore),
						href: docs.JIRA_PERMISSIONS_OVERVIEW,
					},
				]}
			/>
		);
	}
}

export default injectIntl(DependenciesPermissionFlag);
