import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	titleUpdate: {
		id: 'roadmap.standard-roadmap.flags.issue-update-permission.title-update',
		defaultMessage: "We couldn't update your issue",
		description: 'Flag title. Informs a user that the issue cannot be updated.',
	},
	descriptionUpdate: {
		id: 'roadmap.standard-roadmap.flags.issue-update-permission.description-update',
		defaultMessage:
			"You don't have permission to update this issue. Check your permissions settings or contact your Jira administrator for help.",
		description: 'Flag description. Informs a user why the issue cannot be updated.',
	},
	learnMore: {
		id: 'roadmap.standard-roadmap.flags.issue-update-permission.learn-more',
		defaultMessage: 'Learn more',
		description: 'Learn more link title',
	},
	titleUpdateIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.issue-update-permission.title-update-issue-term-refresh',
		defaultMessage: "We couldn't update your work item",
		description: 'Flag title. Informs a user that the issue cannot be updated.',
	},
	descriptionUpdateIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.issue-update-permission.description-update-issue-term-refresh',
		defaultMessage:
			"You don't have permission to update this work item. Check your permissions settings or contact your Jira administrator for help.",
		description: 'Flag description. Informs a user why the issue cannot be updated.',
	},
});
