import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'roadmap.standard-roadmap.flags.interacted-items-limit-exceeded-with-actions.title',
		defaultMessage: 'Only {issueKey} has been updated',
		description: 'Flag title. Informs user that they have exceeded the interacted items limit.',
	},
	description: {
		id: 'roadmap.standard-roadmap.flags.interacted-items-limit-exceeded-with-actions.description',
		defaultMessage:
			'<b>{issueKey}</b> has more than {limit} child issues which exceeds the amount that can be updated at one time. Select fewer issues and try again.',
		description:
			'Flag description. Explains in more detail why the interacted items limit has been exceeded.',
	},
	undo: {
		id: 'roadmap.standard-roadmap.flags.interacted-items-limit-exceeded-with-actions.undo',
		defaultMessage: 'Undo change',
		description: 'Flag action. Undoes the change.',
	},
	persist: {
		id: 'roadmap.standard-roadmap.flags.interacted-items-limit-exceeded-with-actions.persist',
		defaultMessage: 'Got it',
		description: 'Flag action. Persists the change.',
	},
	descriptionIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.interacted-items-limit-exceeded-with-actions.description-issue-term-refresh',
		defaultMessage:
			'<b>{issueKey}</b> has more than {limit} work items which exceeds the amount that can be updated at one time. Select fewer and try again.',
		description:
			'Flag description. Explains in more detail why the interacted items limit has been exceeded.',
	},
});
