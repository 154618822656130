/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@compiled/react';
import { B100, N0, N20, N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import {
	DEFAULT_HEADER_ROW_ID,
	LIST_COLUMN_COORDINATE,
	zIndex,
} from '../../../common/constants.tsx';
import { useListWidth } from '../../../common/context/list/index.tsx';
import { useGridCell } from '../../../common/context/side-effect-marshal/focus-marshal/use-grid-cell/index.tsx';
import type { RenderHeaderItem } from '../../../renderers/index.tsx';

const rowHeaderCellStyles = css({
	position: 'sticky',
	flex: '0 0 auto',
	left: 0,
	padding: 0,
	backgroundColor: token('elevation.surface', N0),
	borderRadius: `${token('border.radius', '3px')} 0 0 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.ROW_HEADER,
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

const dateRowHeaderContainerStyles = css({
	position: 'absolute',
	boxSizing: 'border-box',
	width: '100%',
	height: '100%',
	backgroundColor: token('color.background.neutral', N20),
	borderTop: `1px solid ${token('color.border', N40)}`,
	borderBottom: `1px solid ${token('color.border', N40)}`,
	borderRadius: `${token('border.radius', '3px')} 0 0 0`,
});

const subRowHeaderContainerStyles = css({
	position: 'absolute',
	display: 'flex',
	flex: '0 0 auto',
	alignItems: 'center',
	boxSizing: 'border-box',
	width: '100%',
	height: '100%',
	padding: `0 ${token('space.200', '16px')} 0 ${token('space.100', '8px')}`,
	borderBottom: `1px solid ${token('color.border', N40)}`,
});

type Props = {
	id: string;
	itemHeight: number;
	renderListHeaderItem: RenderHeaderItem;
};

const ListHeaderCell = ({ id, itemHeight, renderListHeaderItem }: Props) => {
	const [listWidth] = useListWidth();

	const isDefaultHeader = id === DEFAULT_HEADER_ROW_ID;
	const cellRef = useGridCell(id, LIST_COLUMN_COORDINATE, isDefaultHeader);

	/* We need to pick a sensible grid entry point for the roving tab index. The simplest solution is to pick the upper-left most cell.
	 * While non-interactive headers do not need to be focusable, this is the most resilient to our various edge cases.
	 * For example, virtualisation, filtering, and expand states mean that table body row elements are not guaranteed to be in the DOM.
	 */
	const tabIndex = isDefaultHeader ? 0 : -1;

	return (
		<div
			ref={cellRef}
			role="rowheader"
			tabIndex={tabIndex}
			css={rowHeaderCellStyles}
			style={{ width: `${listWidth}px`, height: `${itemHeight}px` }}
		>
			{id === DEFAULT_HEADER_ROW_ID ? (
				<div css={dateRowHeaderContainerStyles}>{renderListHeaderItem({ id })}</div>
			) : (
				<div css={subRowHeaderContainerStyles}>{renderListHeaderItem({ id })}</div>
			)}
		</div>
	);
};

export default memo<Props>(ListHeaderCell);
