import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import {
	type RealtimeDispatchEventAction,
	REALTIME_DISPATCH_EVENT,
} from '../../../state/realtime/actions.tsx';
import { getCustomFilters, getQuickFilters } from '../../../state/router/selectors.tsx';
import type { State } from '../../../state/types.tsx';
import { reapplyJQLFilters } from '../../../state/ui/filters/actions.tsx';
import { isCurrentUser, isIssueEvent } from '../common/realtime.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<RealtimeDispatchEventAction>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(REALTIME_DISPATCH_EVENT)
		.filter(({ payload: { type, payload } }) => {
			const state = store.getState();

			const { atlassianId } = payload;

			const quickFilterIds = getQuickFilters(state);
			const customFilterIds = getCustomFilters(state);
			const isJQLFilterApplied = quickFilterIds.length > 0 || customFilterIds.length > 0;

			return isIssueEvent(type) && isCurrentUser(state, atlassianId) && isJQLFilterApplied;
		})
		.mergeMap(() => Observable.of(reapplyJQLFilters()));
