/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/filter';
import { useRelayEnvironment } from 'react-relay';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import type { CacheInvalidationType } from '../../common/types.tsx';
import CacheInvalidationSubject from '../resources-cache-invalidation-subject/index.tsx';
import useResourcesCache from '../use-resources-cache-tracker/index.tsx';
import { invalidateRelayStore } from './utils.tsx';

/*
 * This hook is for handling when a change is performed that should invalidate the cache of another
 * resource. In the current case, the BOARD and BACKLOG caches are intertwined such that a major change
 * in the BOARD will invalidate the BACKLOG.
 */
const useResourcesCacheInvalidation = (origin: CacheInvalidationType) => {
	const [resourcesRef] = useResourcesCache();
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();
	const [{ match }] = useRouter();
	const boardId = match.params.boardId || '';

	useEffect(() => {
		// We don't want to refresh every time we have a change. Instead we only tell it to refresh its cache
		// AFTER we leave. The reason for doing this is we get a fresh cached version AFTER we've done all
		// our actions (and we minimise the number of API calls)
		let needToClearResourcesCacheOnExit = false;

		// We only need to proc this once and then we don't care about any further invalidation events
		const subscription = CacheInvalidationSubject.filter((val) => val === origin)
			.take(1)
			.subscribe(() => {
				// If we get an invalidation specific to this page, we flush out the caches of
				// all other pages we have cached that could be impacted
				Object.keys(resourcesRef.current)
					.filter((key) => key !== origin)
					.forEach((key) => {
						// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<CacheInvalidationType, Resource>>'.
						resourcesRef.current[key].clear();
					});

				// Invalidate the boardScope resource in the Relay store
				invalidateRelayStore(environment, cloudId, boardId);

				// If we are allowed to refresh this cache on exit, set the flag for it
				if (origin in resourcesRef.current) {
					needToClearResourcesCacheOnExit = true;
				}
			});

		return () => {
			subscription.unsubscribe();

			if (needToClearResourcesCacheOnExit && origin in resourcesRef.current) {
				// Clear out this cache after we leave so when we come back we get a more up to date version.
				resourcesRef.current[origin].clear();

				// Invalidate the boardScope resource in the Relay store
				invalidateRelayStore(environment, cloudId, boardId);
			}
		};
	}, [origin, resourcesRef, environment, cloudId, boardId]);

	return [CacheInvalidationSubject] as const;
};

export default useResourcesCacheInvalidation;
