import React, { useCallback } from 'react';
import type { KeyDate } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/key-date.tsx';
import { RoadmapKeyDates } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-header-item/key-dates/index.tsx';
import { useTimelineState } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';
import RoadmapVersionFlyoutAsync from './flyout/async.tsx';

export type Props = {
	getVersionsForKeyDate: (today: number) => ReadonlyArray<KeyDate>;
	highlightedKeyDates: string[];
};

const ReleasesHeader = ({ getVersionsForKeyDate, highlightedKeyDates }: Props) => {
	const [{ today }] = useTimelineState();
	const keyDates = getVersionsForKeyDate(today);

	const renderPopupContent = useCallback(
		(
			selectedKeyDates: ReadonlyArray<KeyDate>,
			setInitialFocusRef?: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
		) => (
			<RoadmapVersionFlyoutAsync
				keyDates={selectedKeyDates}
				setInitialFocusRef={setInitialFocusRef}
			/>
		),
		[],
	);

	return (
		<RoadmapKeyDates
			keyDates={keyDates}
			highlightedKeyDates={highlightedKeyDates}
			renderPopupContent={renderPopupContent}
		/>
	);
};

export default ReleasesHeader;
