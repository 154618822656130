import type { CriticalData } from '@atlassian/jira-software-roadmap-services/src/initialize/types.tsx';
import type { AppProps } from '../../model/app/index.tsx';

// initialize static configuration of the app (base url, etc)
export const INITIALIZE_APP_PROPS = 'state.app.INITIALIZE_APP_PROPS' as const;

export type InitializeAppPropsAction = {
	type: typeof INITIALIZE_APP_PROPS;
	payload: AppProps;
};

export const initializeAppProps = (payload: AppProps): InitializeAppPropsAction => ({
	type: INITIALIZE_APP_PROPS,
	payload,
});

// Update critical-data
export const UPDATE_CRITICAL_DATA = 'state.app.UPDATE_CRITICAL_DATA' as const;

export type UpdateCriticalDataAction = {
	type: typeof UPDATE_CRITICAL_DATA;
	payload: CriticalData;
};

export const updateCriticalData = (payload: CriticalData): UpdateCriticalDataAction => ({
	type: UPDATE_CRITICAL_DATA,
	payload,
});

// initialize data required for first render / interaction
export const INITIALIZE_CRITICAL_DATA = 'state.app.INITIALIZE_CRITICAL_DATA' as const;

export type InitializeCriticalDataAction = {
	type: typeof INITIALIZE_CRITICAL_DATA;
	payload: CriticalData;
};

export const initializeCriticalData = (payload: CriticalData): InitializeCriticalDataAction => ({
	type: INITIALIZE_CRITICAL_DATA,
	payload,
});

// initialize data not required for first render / interaction can be delayed
export const INITIALIZE_NON_CRITICAL_DATA = 'state.app.INITIALIZE_NON_CRITICAL_DATA' as const;

export type InitializeNonCriticalDataAction = {
	type: typeof INITIALIZE_NON_CRITICAL_DATA;
};

export const initializeNonCriticalData = (): InitializeNonCriticalDataAction => ({
	type: INITIALIZE_NON_CRITICAL_DATA,
});
