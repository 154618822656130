import React, { Fragment, memo } from 'react';
import { listItemMenu } from '../../../../../../../common/constants.tsx';
import { ListItemMenuOption } from '../../../list-item-menu-option/index.tsx';
import type { ItemSectionOptionsProps } from '../../types.tsx';
import CreateItemMenu from './create-item-menu/index.tsx';
import MoveItemMenu from './move-item-menu/index.tsx';

const ItemSectionOptions = ({
	id,
	parentId,
	level,
	depth,
	isCreateChildEnabled,
	isCreateSiblingEnabled,
	onInlineCreateClicked,
	issuesSectionOptions,
	setInitialFocusRef,
	renderListItemMenuOption,
	setCloseMenu,
	setOpenModal,
	onDrop,
	onCreateTriggerClick,
}: ItemSectionOptionsProps) =>
	issuesSectionOptions.map(({ id: optionId }, index) => {
		switch (optionId) {
			case listItemMenu.CREATE_ITEM:
				return (
					<CreateItemMenu
						key={optionId}
						id={id}
						parentId={parentId}
						level={level}
						depth={depth}
						isCreateChildEnabled={isCreateChildEnabled}
						isCreateSiblingEnabled={isCreateSiblingEnabled}
						onInlineCreateClicked={onInlineCreateClicked}
						setInitialFocusRef={index === 0 ? setInitialFocusRef : undefined}
						onCreateTriggerClick={onCreateTriggerClick}
						setCloseMenu={setCloseMenu}
					/>
				);
			case listItemMenu.MOVE_ITEM:
				return (
					<MoveItemMenu
						id={id}
						key={optionId}
						level={level}
						parentId={parentId}
						setInitialFocusRef={index === 0 ? setInitialFocusRef : undefined}
						onDrop={onDrop}
						setCloseMenu={setCloseMenu}
					/>
				);
			default:
				return (
					<Fragment key={optionId}>
						{renderListItemMenuOption({
							id,
							optionId,
							setOpenModal: (event) => setOpenModal(event, optionId),
							setInitialFocusRef: index === 0 ? setInitialFocusRef : undefined,
							Option: ListItemMenuOption,
							onCreateTriggerClick,
							setCloseMenu,
						})}
					</Fragment>
				);
		}
	});

export default memo<ItemSectionOptionsProps>(ItemSectionOptions);
